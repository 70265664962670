import { useQuery } from '@tanstack/react-query'
import { group } from 'd3-array'
import { useContext, useEffect, useMemo } from 'react'
import { MastData, MastsAPI } from '../../client'
import MastContext from '../../contexts/MastContext'
import { queryClient } from '../../services/api'
import { formatDateWithoutTime } from '../../utils/formatDate'

export const DEFAULT_DATA = { mastData: [] as MastData[] }
export const CACHE_KEY_MAST_DATA = 'CACHE_KEY_MAST_DATA'
export const QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}
const DT_NOW = new Date()

const prefetchGetMastData = (mastId: number, tsIn: Date, tsFin: Date) => {
  queryClient.prefetchQuery(
    [CACHE_KEY_MAST_DATA, mastId, tsIn.toISOString(), tsFin.toISOString()],
    async () =>
      await MastsAPI.getMastData({
        mastId,
        dateIn: formatDateWithoutTime(tsIn),
        dateFin: formatDateWithoutTime(tsFin),
      }),
    QUERY_OPTIONS
  )
}

const useData = (dtIn: Date, dtFin: Date) => {
  const { mast, isLoading: mastIsLoading, allMasts, sensors } = useContext(MastContext)

  const { data: mastData, isLoading } = useQuery(
    [CACHE_KEY_MAST_DATA, mast.id, dtIn.toISOString(), dtFin.toISOString()],
    async () =>
      await MastsAPI.getMastData({
        mastId: mast.id,
        dateIn: formatDateWithoutTime(dtIn),
        dateFin: formatDateWithoutTime(dtFin),
      }),
    {
      ...QUERY_OPTIONS,
      enabled: mast.id !== 0,
    }
  )

  useEffect(() => {
    if (mast.id === 0 || isLoading) return

    const _mastIndex = allMasts.findIndex(m => m.id === mast.id)
    const mastAnterior = allMasts[_mastIndex - 1]
    const mastProximo = allMasts[_mastIndex + 1]

    if (mastAnterior) {
      const tsFin = new Date(mastAnterior.dataset_end)
      const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)
      prefetchGetMastData(mastAnterior.id, tsIn, tsFin)
    }
    if (mastProximo) {
      const tsFin = new Date(mastProximo.dataset_end)
      const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)
      prefetchGetMastData(mastProximo.id, tsIn, tsFin)
    }
  }, [mast.id, mastIsLoading, isLoading, sensors])

  const mastDataBySensor = useMemo(() => {
    if (!mastData) return group([] as MastData[], m => m.sensor_id)
    return group(mastData, m => m.sensor_id)
  }, [mastData])

  return {
    dataIsLoading: isLoading || mastIsLoading || (mast.id === 0 && dtFin > DT_NOW),
    mastData: mastData,
    mastDataBySensor,
  }
}

export default useData
