import { ExpandableSection, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core'
import { ReactNode } from 'react'

import useMobile from '../../../hooks/useMobile'
import useVertical from '../../../hooks/useVertical'

const FilterBar = ({ filters }: { filters?: ReactNode[] }) => {
  const isMobile = useMobile()
  const isVertical = useVertical()
  if (!filters) return null

  return (
    <div className='pf-v5-u-px-lg'>
      {isMobile || isVertical ? (
        <ExpandableSection toggleTextCollapsed='Exibir Menu' toggleTextExpanded='Ocultar Menu'>
          <Stack>
            {filters.map((f, i) => (
              <StackItem key={`stack-${i}`}>{f}</StackItem>
            ))}
            <StackItem isFilled />
          </Stack>
        </ExpandableSection>
      ) : (
        <Split className='filterBar'>
          <SplitItem isFilled />
          {filters.map((f, i) => (
            <SplitItem key={`split-${i}`}>{f}</SplitItem>
          ))}
        </Split>
      )}
    </div>
  )
}

export default FilterBar
