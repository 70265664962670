import { Button, Flex, FlexItem, Modal, ModalVariant, Stack, Text } from '@patternfly/react-core'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { OccurrencesAPI, Site } from '../../client'
import { ActionState, requestStateMessages } from '../../components/actionState'
import { queryClient } from '../../services/api'
import { getOcorrenciasQueryKey } from './constants'
import { MultiUploadImagens } from './MultiUploadImagens'
import { UploadXlsx } from './UploadXlsx'

export const ModalAdicionarOcorrencias = ({
  isOpenModelAdicionarOcorrencia,
  setIsOpenModelAdicionarOcorrencia,
  site,
}: {
  isOpenModelAdicionarOcorrencia: boolean
  setIsOpenModelAdicionarOcorrencia: React.Dispatch<React.SetStateAction<boolean>>
  site: Site
}) => {
  const [currentFiles, setCurrentFiles] = useState<File[]>([])

  const [planilhaFile, setPlanilhaFile] = useState<File | null>(null)
  const [isRejectedXlsx, setIsRejectedXlsx] = useState(false)
  const [isRejectedImagens, setIsRejectedImagens] = useState(false)
  const [mensagemImagensError, setMensagemImagensError] = useState('')
  const [actionState, setActionState] = useState<ActionState>('idle')

  const handleCloseModal = () => {
    setIsOpenModelAdicionarOcorrencia(false)
    setCurrentFiles([])
    setPlanilhaFile(null)
    setIsRejectedXlsx(false)
    setIsRejectedImagens(false)
    setActionState('idle')
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: OccurrencesAPI.workbookUpload,
    onSuccess: async () => {
      await queryClient.invalidateQueries(getOcorrenciasQueryKey(site.site_id))
      setActionState('success')
      setTimeout(() => {
        handleCloseModal()
      }, 750)
    },
    onError: (erro: AxiosError) => {
      console.error(erro)
      setActionState('failure')
    },
    onMutate: () => {
      setActionState('pending')
      let isError = false
      if (!planilhaFile) {
        setIsRejectedXlsx(true)
        isError = true
      }
      if (currentFiles.length < 1) {
        setIsRejectedImagens(true)
        isError = true
      }
      if (isError) {
        setActionState('failure')
        throw new Error('Erro ao adicionar ocorrências')
      }
    },
  })

  const handleSubmit = async () => {
    if (planilhaFile) {
      mutate({ siteId: site.site_id, formData: { xlsx: planilhaFile, images: currentFiles } })
    }
  }

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleCloseModal()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      isOpen={isOpenModelAdicionarOcorrencia}
      onClose={handleCloseModal}
      variant={ModalVariant.small}
      title='Adicionar ocorrências'
      actions={[
        <Flex key='container-flex-action' style={{ width: '100%' }}>
          <FlexItem align={{ default: 'alignRight' }}>
            <Button
              id='salvar-loading-button'
              key='create'
              variant='primary'
              form='modal-with-form-form'
              onClick={handleSubmit}
              className='green'
              spinnerAriaValueText='Loading'
              spinnerAriaLabelledBy='salvar-loading-button'
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {isLoading ? 'Salvando' : 'Salvar'}
            </Button>
          </FlexItem>
          <FlexItem>
            <Button key='cancel' variant='secondary' isDanger onClick={handleCloseModal}>
              Cancelar
            </Button>
          </FlexItem>
        </Flex>,
      ]}
    >
      <Stack hasGutter>
        {actionState !== 'idle' && (
          <FlexItem flex={{ default: 'flex_1' }} alignSelf={{ default: 'alignSelfCenter' }}>
            {
              requestStateMessages({
                titleSuccess: 'Ocorrências adicionadas',
                titleFailure: 'Houve um erro ao adicionar ocorrências',
              })[actionState]
            }
          </FlexItem>
        )}
        <Flex direction={{ default: 'column' }}>
          <FlexItem style={{ margin: 0, padding: 0 }}>
            <Text>
              Planilha Excel <span style={{ color: 'red' }}>*</span>
            </Text>
          </FlexItem>
          <FlexItem flex={{ default: 'flex_1' }}>
            <UploadXlsx
              planilhaFile={planilhaFile}
              setPlanilhaFile={setPlanilhaFile}
              isRejectedXlsx={isRejectedXlsx}
              setIsRejectedXlsx={setIsRejectedXlsx}
            />
          </FlexItem>
        </Flex>
        <Flex direction={{ default: 'column' }}>
          <FlexItem style={{ margin: 0, padding: 0 }}>
            <Text>
              Fotos <span style={{ color: 'red' }}>*</span>
            </Text>
          </FlexItem>
          <FlexItem flex={{ default: 'flex_1' }}>
            <MultiUploadImagens
              currentFiles={currentFiles}
              setCurrentFiles={setCurrentFiles}
              isRejectedImagens={isRejectedImagens}
              setIsRejectedImagens={setIsRejectedImagens}
              mensagemImagensError={mensagemImagensError}
              setMensagemImagensError={setMensagemImagensError}
            />
          </FlexItem>
        </Flex>
      </Stack>
    </Modal>
  )
}
