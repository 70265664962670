import { Card, CardBody, CardFooter, Modal, ModalVariant } from '@patternfly/react-core'
import { News } from '../../../client'

interface IProps {
  isModalOpen: boolean
  handleModalToggle: () => void
  news?: News[]
}

function ModalNews(props: IProps) {
  const { isModalOpen, handleModalToggle, news } = props

  function formatDate(str: string) {
    return str.split('-').reverse().join('/')
  }

  return (
    <Modal
      aria-label='news modal'
      variant={ModalVariant.small}
      title='Novidades'
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[]}
    >
      {news &&
        news
          .sort((a, b) => (a.news_date < b.news_date ? 1 : -1))
          .map((n, idx) => {
            return (
              <div key={idx}>
                <Card>
                  <CardBody>{n.news_text}</CardBody>
                  <CardFooter>Publicado em: {formatDate(n.news_date)}</CardFooter>
                </Card>
                <br />
              </div>
            )
          })}
    </Modal>
  )
}

export default ModalNews
