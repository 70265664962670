import { GridItem } from '@patternfly/react-core'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

import { bin, range } from 'd3-array'

const HistogramPlot = ({ chartData, signals }) => {
  const colorScale = [
    COLORS.azul_meia_noite,
    COLORS.verde,
    COLORS.laranja_chama,
    '#226CB5',
    '#93EB73',
    '#FAB43C',
    '#2BBFF0',
    COLORS.limao,
    '#FFE560',
  ]

  const selectedSignals = chartData.signals.map(s => s.signal_id)

  const layout = {
    template: layoutTemplate,
    margin: { b: 50, t: 10, l: 50, r: 50 },
    autosize: true,
    yaxis: { title: 'Frequência de Ocorrência' },
  }

  const traces = chartData.signals.map((s, v) => {
    const signal = signals.find(si => si.signal_id === s.signal_id)
    if (!signal) return {}
    const { min_possible, max_possible } = signal
    const count_filter = 2
    const axis_bin = (max_possible - min_possible) / 15
    const signalData = s.data.map(sd => sd.val)
    let bins = bin().thresholds(range(min_possible, max_possible, axis_bin))(signalData)
    bins = bins.filter(b => b.length >= count_filter)

    Object.assign(layout, {
      [`xaxis${v === 0 ? '' : v + 1}`]: {
        ...defaultAxis,
        title: `${signal.description}`,
        showticklabels: true,
        type: 'category',
        tickangle: 45,
        fixedrange: true,
      },
      [`yaxis${v === 0 ? '' : v + 1}`]: {
        ...defaultAxis,
        title: 'Frequência de Ocorrência',
        fixedrange: true,
      },
    })

    return {
      signal,
      x: bins.map(b => Math.round(b.x0)).slice(1),
      y: bins.map(b => b.length),
      name: signal.signal,
      type: 'bar',
      showLegend: true,
      marker: { color: colorScale[v] },
      xaxis: v === 0 ? 'x1' : `x${v + 1}`,
      yaxis: v === 0 ? 'y1' : `y${v + 1}`,
    }
  })

  const config = {
    ...defaultConfig,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
    ],
  }
  const gridSize = 6 / selectedSignals.length

  return selectedSignals.map((signal_id, i) => {
    return (
      <GridItem key={`hist_grid_${i}`} md={12} lg={gridSize}>
        <Plot
          data={traces.flat().filter(t => t?.signal?.signal_id === signal_id)}
          layout={layout}
          showlegend
          config={config}
          useResizeHandler
          style={{ width: '100%', height: '32vh' }}
        />
      </GridItem>
    )
  })
}

export default HistogramPlot
