import { Location } from 'react-router'

export function replaceFilterParams(
  location: Location | URL,
  entities: number[] | Date[],
  itemKeyName: string
): string
export function replaceFilterParams<T>(
  location: Location | URL,
  entities: T[],
  itemKeyName: keyof T
): string
export function replaceFilterParams<T>(
  location: Location | URL,
  entities: T[],
  itemKeyName: keyof T | string
): string {
  const items = entities.map(e => {
    const num = Object.hasOwn(Object(e), itemKeyName)
      ? Number(e[itemKeyName as keyof T])
      : Number(e)
    return num
  })

  const { search, pathname } = location
  const newSearch = `${String(itemKeyName)}=${JSON.stringify(items)}`
  if (search === '') {
    return pathname + '?' + newSearch
  }
  if (search.includes(String(itemKeyName))) {
    return (pathname + search).replace(
      new RegExp(`${String(itemKeyName)}=\\[[\\d|,]*\\]`),
      newSearch
    )
  }

  return pathname + search + '&' + newSearch
}

export function getFilterParams(itemKeyName: string): number[]
export function getFilterParams<T>(itemKeyName: keyof T): number[]
export function getFilterParams<T>(itemKeyName: keyof T | string): number[] {
  const url = new URL(window.location.href)
  const items = url.searchParams.get(String(itemKeyName))
  if (items === null) {
    return []
  }
  return JSON.parse(items)
}
