import { Marker } from '@react-google-maps/api'
import { towerMapType } from '.'
import tower_icon from '../../assets/img/tower_icon.png'
import tower_icon_solar from '../../assets/img/tower_icon_solar.png'
import { Masts } from '../../client'

const Torres = ({
  towersIsLoading,
  masts,
  showTowers,
  showTowersAma,
  towerMap,
}: {
  towersIsLoading: boolean
  showTowers: boolean
  showTowersAma: boolean
  masts?: Masts[]
  towerMap: towerMapType
}) => {
  const iconSize = new google.maps.Size(40, 40)

  const towerIcon = {
    url: tower_icon,
    size: iconSize,
    scaledSize: iconSize,
  }

  const solarIcon = {
    url: tower_icon_solar,
    size: iconSize,
    scaledSize: iconSize,
  }

  if (towersIsLoading || !masts) return null

  return (
    <>
      {masts
        .map(tower => {
          const config = towerMap[tower.id]

          if (
            (!config?.ama && showTowers && !!config?.visible !== false) ||
            (config?.ama && showTowersAma && !!config?.visible !== false)
          )
            return (
              <Marker
                key={`${tower.id}-${tower.name}`}
                position={{ lat: tower.lat, lng: tower.lon }}
                icon={config?.solar ? solarIcon : towerIcon}
                title={`${tower.name}`}
              />
            )

          return null
        })
        .filter(e => e)}
    </>
  )
}

export default Torres
