import {
  Brand,
  Button,
  Masthead,
  MastheadBrand,
  MastheadContent,
  MastheadMain,
  MastheadToggle,
  Split,
  SplitItem,
} from '@patternfly/react-core'
import { BarsIcon, OutlinedQuestionCircleIcon } from '@patternfly/react-icons'
import { useQuery } from '@tanstack/react-query'
import { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import ImgBrand from '../../../assets/img/logo-nav.svg'
import { NewsAPI } from '../../../client'
import { getToken, logout } from '../../../services/auth'
import ColorScale from '../../../utils/colorScale'
import ModalNews from './ModalNews'

const Header = ({
  isNavOpen,
  setNavOpen,
  handleModalHelpToggle,
  helpContent,
}: {
  isNavOpen: boolean
  setNavOpen: (b: boolean) => void
  handleModalHelpToggle: () => void
  helpContent?: ReactNode
}) => {
  const [isModalNewsOpen, setModaNewsOpen] = useState(false)

  const token = getToken()

  const { isLoading: newsIsLoading, data: news } = useQuery(
    ['news'],
    async () => NewsAPI.getAll({}),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60,
      enabled: !!token,
    }
  )

  const handleLogOut = () => {
    logout()
    window.location.href = '/'
  }

  return (
    <>
      <Masthead className='page-header' backgroundColor='dark' display={{ default: 'inline' }}>
        <MastheadToggle>
          <Button
            variant='plain'
            onClick={() => setNavOpen(!isNavOpen)}
            aria-label='global-nav-controller'
          >
            <BarsIcon />
          </Button>
        </MastheadToggle>
        <MastheadMain>
          <MastheadBrand>
            <Link className='pf-v5-u-m-md' to='/home'>
              <Brand src={ImgBrand} alt='Rio Energy' />
            </Link>
          </MastheadBrand>
        </MastheadMain>
        <MastheadContent>
          <Split className='pf-v5-u-w-100'>
            <SplitItem isFilled></SplitItem>
            <SplitItem>
              {helpContent && (
                <Button
                  className='pf-v5-u-mr-3xl pf-v5-u-mr-lg-on-lg'
                  variant='link'
                  isInline
                  onClick={handleModalHelpToggle}
                  style={{ textTransform: 'none', color: ColorScale.branco }}
                >
                  <OutlinedQuestionCircleIcon />
                </Button>
              )}
            </SplitItem>

            <SplitItem>
              <Button
                variant='link'
                style={{ color: 'white' }}
                onClick={() => {
                  if (newsIsLoading) return
                  setModaNewsOpen(!isModalNewsOpen)
                }}
              >
                Novidades
              </Button>
            </SplitItem>
            <SplitItem>
              <Button
                className='pf-v5-u-display-inline-on-md pf-v5-u-mr-lg'
                style={{ display: 'none', color: 'white' }}
                variant='link'
                onClick={handleLogOut}
              >
                Sair
              </Button>
            </SplitItem>
          </Split>
        </MastheadContent>
      </Masthead>
      <ModalNews
        isModalOpen={isModalNewsOpen}
        handleModalToggle={() => setModaNewsOpen(!isModalNewsOpen)}
        news={news}
      />
    </>
  )
}

export default Header
