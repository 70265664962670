import {
  Select,
  SelectOption,
  SelectOptionObject,
  SelectVariant,
} from '@patternfly/react-core/deprecated'
import React, { useState } from 'react'
import { IExibicao } from '.'

interface IShowSelectProps {
  exibicao: IExibicao
  toggleShowTowers: (showTurbs: boolean) => void
  toggleShowTowersAma: (showTurbsAma: boolean) => void
  toggleShowKmlLayer: (showKmlLayer: boolean) => void
  toggleShowTurbsData: (showTurbsData: boolean) => void
}

const ShowSelect = (props: IShowSelectProps) => {
  const { toggleShowTowersAma, toggleShowTowers, toggleShowKmlLayer, toggleShowTurbsData } = props

  const [selected, setSelected] = useState<(string | SelectOptionObject)[]>([
    'Torres AMA',
    'Demais torres anemométricas',
    'Contorno do parque',
    'Dados dos aerogeradores',
  ])
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = (isOpen: boolean) => {
    setIsOpen(!!isOpen)
  }

  const handleToggleConditions = (value: string, condition: boolean) => {
    switch (value) {
      case 'Torres AMA':
        toggleShowTowersAma(condition)
        break

      case 'Demais torres anemométricas':
        toggleShowTowers(condition)
        break

      case 'Contorno do parque':
        toggleShowKmlLayer(condition)
        break

      case 'Dados dos aerogeradores':
        toggleShowTurbsData(condition)
        break
    }
  }

  const onSelect = (
    _event: React.MouseEvent | React.ChangeEvent,
    selection: string | SelectOptionObject
  ) => {
    if (selected?.includes(selection)) {
      handleToggleConditions(String(selection), false)

      setSelected(selected.filter(item => item !== selection))
    } else {
      handleToggleConditions(String(selection), true)

      setSelected([...selected, selection])
    }
  }

  const optionsProps = [
    {
      value: 'Torres AMA',
    },
    {
      value: 'Demais torres anemométricas',
    },
    {
      value: 'Contorno do parque',
    },
    {
      value: 'Dados dos aerogeradores',
    },
  ]

  const options = optionsProps.map((props, index) => <SelectOption key={index} {...props} />)

  return (
    <>
      <div className='pf-v5-u-p-sm'>
        <Select
          variant={SelectVariant.checkbox}
          aria-label='Select Input'
          onToggle={(_event, isOpen: boolean) => onToggle(isOpen)}
          onSelect={onSelect}
          selections={selected}
          isOpen={isOpen}
          placeholderText='Camadas'
          aria-labelledby='checkbox-select-with-counts-id'
          isCheckboxSelectionBadgeHidden
        >
          {options}
        </Select>
      </div>
    </>
  )
}

export default ShowSelect
