import React, { useContext, useMemo } from 'react'

import mapCEC from '../../../assets/img/sitemap-cinza-cec.png'
import mapITA from '../../../assets/img/sitemap-cinza-ita.png'
import mapSDB from '../../../assets/img/sitemap-cinza-sdb.png'
import mapSDB3 from '../../../assets/img/sitemap-cinza-sdb3.png'
import imgMapa from '../../../assets/img/sitemap-cinza.png'
import SitesContext from '../../../contexts/SitesContext'

interface ISiteLocationMapProps {
  overSiteId: number
}

const SiteLocationMap = ({ overSiteId }: ISiteLocationMapProps) => {
  const { sites } = useContext(SitesContext)

  const opacitySite = (id: string) => {
    if (!opacitySiteSelected) return 0
    if (opacitySiteSelected.site === id) {
      return 1
    } else {
      return 0
    }
  }

  const opacitySiteSelected = useMemo(() => {
    if (sites) {
      return sites.find(el => el.site_id === overSiteId)
    }
    return null
  }, [overSiteId, sites])

  return (
    <div className='mapa'>
      <img src={imgMapa} alt='' style={{ opacity: 1 }} />
      <img src={mapCEC} alt='' style={{ opacity: opacitySite('CEC') }} />
      <img src={mapITA} alt='' style={{ opacity: opacitySite('ITA') }} />
      <img src={mapSDB} alt='' style={{ opacity: opacitySite('SDB') }} />
      <img src={mapSDB3} alt='' style={{ opacity: opacitySite('SDB3') }} />
    </div>
  )
}

export default SiteLocationMap
