import { WindnessMonthly } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import months from '../../utils/months'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const ChartAverageSpeedMonthly = ({
  monthlyData,
  revision,
  isLoading,
}: {
  monthlyData?: WindnessMonthly[]
  revision: number
  isLoading: boolean
}) => {
  if (isLoading) return null

  const data = [
    {
      x: months,
      y: monthlyData?.map(yd => yd?.stddev),
      name: 'Incerteza',
      marker: { color: COLORS.verde },
      type: 'bar',
      yaxis: 'y',
    },
    {
      x: months,
      y: monthlyData?.map(yd => yd?.avg),
      name: 'Média Longo Prazo',
      marker: { color: COLORS.azul_pantone },
      line: { dash: 'dot', width: 2 },
      yaxis: 'y2',
    },
    {
      x: months,
      y: monthlyData?.map(yd => yd?.min),
      name: 'Mínimo Longo Prazo',
      marker: { color: COLORS.azul_meia_noite },
      line: { dash: 'dot', width: 2 },
      yaxis: 'y2',
    },
    {
      x: months,
      y: monthlyData?.map(yd => yd?.max),
      name: 'Máximo Longo Prazo',
      marker: { color: COLORS.azul_escuro },
      line: { dash: 'dot', width: 2 },
      yaxis: 'y2',
    },
    {
      x: months,
      y: monthlyData?.map(yd => yd?.actual_cy),
      name: 'Realizado',
      marker: { color: COLORS.laranja_chama },
      line: { dash: 'dot', width: 2 },
      yaxis: 'y2',
    },
  ]
  const layout = {
    template: layoutTemplate,
    margin: { b: 50, t: 10, l: 50, r: 50 },
    yaxis: {
      ...defaultAxis,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      title: 'Incerteza mensal [%]',
      fixedrange: true,
      range: [0, 25],
      side: 'left',
    },
    yaxis2: {
      ...defaultAxis,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      title: 'Velocidade do vento [m/s]',
      fixedrange: true,
      range: [0, 12],
      side: 'right',
      overlaying: 'y',
    },
    xaxis: {
      ...defaultAxis,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      type: 'category',
      fixedrange: true,
      title: 'Mês',
    },
  }
  const style = {
    width: '100%',
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '3.5rem' }),
  }
  const config = {
    ...defaultConfig,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
    ],
  }

  return (
    <div className='pf-v5-u-my-sm-on-sm'>
      <Plot
        key={`plot_mensal_${revision}`}
        data={data}
        layout={layout}
        config={config}
        style={style}
        revision={revision}
        useResizeHandler
      />
    </div>
  )
}

export default ChartAverageSpeedMonthly
