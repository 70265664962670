import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const ScatterPlot = ({ chartData, signals }) => {
  const layout = { template: layoutTemplate }

  const colorScale = [
    COLORS.azul_meia_noite,
    '#2BBFF0',
    COLORS.laranja_chama,
    COLORS.amarelo_cyber,
    COLORS.verde_malachite,
    COLORS.limao,
  ]

  const [signalA, signalB] = signals
  const sigA = chartData.signals.find(si => si.signal_id === signalA.signal_id)
  const sigB = chartData.signals.find(si => si.signal_id === signalB.signal_id)

  if (!sigA || !sigB) return <></>

  Object.assign(layout, {
    xaxis: {
      ...defaultAxis,
      title: signalA.description,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
    },
    yaxis: {
      ...defaultAxis,
      title: signalB.description,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
    },
  })

  const medicoes = new Map()
  const groupPairs = []
  const { min_possible: a_min_possible, max_possible: a_max_possible } = signalA
  const { min_possible: b_min_possible, max_possible: b_max_possible } = signalB

  const axis_bin = (a_max_possible - a_min_possible) / 5

  // Equivalente a left join de A em B
  sigA.data.forEach(e => medicoes.set(e.ts, e.val))
  sigB.data.forEach(e => medicoes.set(e.ts, [medicoes.get(e.ts), e.val]))

  medicoes.forEach(val => {
    let a, b
    try {
      ;[a, b] = val
    } catch (TypeError) {
      return
    }
    if (a >= a_max_possible || a <= a_min_possible || b >= b_max_possible || b <= b_min_possible) {
      return
    }
    const bin = { bin: Math.round(a / axis_bin) * axis_bin, mVarA: a, mVarB: b }
    groupPairs.push(bin)
  })
  const uniqueKeys = [...new Set(groupPairs.map(e => e.bin))].sort((a, b) => a - b)

  const lineVals = uniqueKeys.map(k => {
    const toGroup = groupPairs.filter(pg => pg.bin === k)
    if (toGroup.length < 2) return null

    const _mVarA = toGroup.map(e => e.mVarA).reduce((a, b) => a + b, 0) / toGroup.length
    const _mVarB = toGroup.map(e => e.mVarB).reduce((a, b) => a + b, 0) / toGroup.length

    return { bin: k, mVarA: _mVarA, mVarB: _mVarB }
  })

  const scatter = {
    x: sigA.data.map(d => d.val),
    y: sigB.data.map(d => d.val),
    type: 'scatter',
    mode: 'markers',
    marker: { color: colorScale[0], size: 3 },
    name: 'Pontos',
  }
  const lvs = lineVals.filter(e => e)
  const line = {
    x: lvs.map(e => e.mVarA),
    y: lvs.map(e => e.mVarB),
    type: 'scatter',
    mode: 'lines',
    line: { color: colorScale[1], width: 3 },
    name: 'Linha',
  }

  const config = { ...defaultConfig }

  return (
    <div>
      <Plot
        data={[scatter, line]}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: '100%', height: '33vh' }}
      />
    </div>
  )
}

export default ScatterPlot
