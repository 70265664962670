import { PickOptional } from '@patternfly/react-core'
import { Select as BaseSelect, SelectOption, SelectProps } from '@patternfly/react-core/deprecated'
import { css } from '@patternfly/react-styles'
import { useState } from 'react'
import LoadingButton from '../LoadingButton'

const Select = <T extends object>({
  items,
  itemValueName,
  itemKeyName,
  selected,
  onToggle,
  onChange,
  isLoading,
  selectProps,
  className,
  position = 'right',
  maxHeight = 600,
  isCloseOnChange = true,
  placeholderText = 'item',
  enableFilter = false,
}: {
  itemValueName: keyof T
  itemKeyName: keyof T
  selected: T
  items: any[]
  isLoading?: boolean
  maxHeight?: number
  isCloseOnChange?: boolean
  onToggle?: (isOpen: boolean) => void
  className?: string
  onChange?: (selected: T) => void
  position?: 'left' | 'right'
  selectProps?: PickOptional<SelectProps>
  placeholderText?: string
  enableFilter?: boolean
}) => {
  const [isOpen, setOpen] = useState(false)

  const handleToggle = (isExpanded: boolean) => {
    setOpen(isExpanded)
    if (onToggle) onToggle(isExpanded)
  }
  const handleSelect = (_: any, selection: any) => {
    if (onChange) onChange(items.find(i => i[itemValueName] === selection))
    if (isCloseOnChange) setOpen(false)
  }
  if (!items || items.length === 0 || isLoading) {
    return <LoadingButton />
  }

  const mapSelectOptions = (entities: any[]) =>
    entities.map(t => (
      <SelectOption
        id={`selection_${t[itemKeyName]}_${t[itemValueName]}`}
        key={`${t[itemKeyName]}_${t[itemValueName]}`}
        value={`${t[itemValueName]}`}
      />
    ))

  const options = mapSelectOptions(items)

  const onFilter = (_: any, textInput: string) => {
    if (textInput === '') return options

    return mapSelectOptions(
      items.filter(e => String(e[itemValueName]).toLowerCase().includes(textInput.toLowerCase()))
    )
  }

  return (
    <div
      style={{ marginRight: 0, paddingTop: 4 }}
      className={className ? css(className) : css('pf-v5-u-p-sm')}
    >
      <BaseSelect
        id={`select_${JSON.stringify(items.map(i => i[itemKeyName]))}`}
        position={position}
        isOpen={isOpen}
        onToggle={(_event, isExpanded: boolean) => handleToggle(isExpanded)}
        onSelect={handleSelect}
        selections={selected ? String(selected[itemValueName]) : selected}
        maxHeight={maxHeight}
        hasInlineFilter={enableFilter}
        noResultsFoundText={`Nenhum ${placeholderText} encontrado`}
        inlineFilterPlaceholderText={`Filtre por ${placeholderText}`}
        onFilter={onFilter}
        {...selectProps}
      >
        {!enableFilter
          ? items.map(item => {
              const suffix = `${item[itemKeyName]}_${item[itemValueName]}`
              return (
                <SelectOption
                  inputId={`SelectOptionInputId${suffix}`}
                  id={`SelectOptionId${suffix}`}
                  key={suffix}
                  value={item[itemValueName]}
                >
                  {` ${item[itemValueName]} `}
                </SelectOption>
              )
            })
          : options}
      </BaseSelect>
    </div>
  )
}

export default Select
