import { ExpandableSection, ExpandableSectionToggle, Icon, Panel } from '@patternfly/react-core'
import { ExternalLinkAltIcon, ThIcon } from '@patternfly/react-icons'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Turbines, TurbinesHealthEvent, TurbineSystems } from '../../client'
import EntityTable from '../../components/EntityTable'
import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateWithoutTime } from '../../utils/formatDate'
import './eventsTable.style.css'
import { farol } from './TurbineHealth/commons'

const PADDING =
  'var(--pf-v5-c-expandable-section__toggle--PaddingTop)' +
  'var(--pf-v5-c-expandable-section__toggle--PaddingRight)' +
  'var(--pf-v5-c-expandable-section__toggle--PaddingBottom)' +
  'var(--pf-v5-c-panel__header--PaddingLeft)'

const fifteenDays = (date: Date, operation: 'add' | 'sub') => {
  const newDate = new Date(date)
  if (operation === 'add') {
    newDate.setDate(newDate.getDate() + 15)
  } else {
    newDate.setDate(newDate.getDate() - 15)
  }
  return newDate
}

const rangeParamsUrl = (startDate: string) => {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)

  let start = new Date(startDate)
  let end = fifteenDays(start, 'add')

  if (end >= today) {
    end = today
    start = fifteenDays(end, 'sub')
  }

  return `start=${formatDateWithoutTime(start)}&end=${formatDateWithoutTime(end)}`
}

const EventsTable = ({
  title,
  isLoading,
  isExpanded,
  setIsExpanded,
  events,
  turbsMap,
  systemMap,
  showLink = true,
  showTurbine = true,
}: {
  title: string
  isLoading: boolean
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  events?: TurbinesHealthEvent[]
  turbsMap: Map<number, Turbines>
  systemMap?: Map<number, TurbineSystems>
  showLink?: boolean
  showTurbine?: boolean
}) => {
  const { site } = useContext(SitesContext)

  const sortedEvents = useMemo(() => {
    return events?.sort((a, b) => a?.turb_id - b?.turb_id)
  }, [events])
  const toggleId = 'events-toggle'

  return (
    <Panel style={{ background: ColorScale.branco }} className='pf-v5-u-box-shadow-md'>
      <ExpandableSectionToggle
        style={{
          padding: PADDING,
          display: 'flex',
          alignItems: 'center',
        }}
        onToggle={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
        toggleId={toggleId}
      >
        <div>
          <b className='expandable-title'>{title}</b>{' '}
        </div>
      </ExpandableSectionToggle>

      <ExpandableSection
        toggleId={toggleId}
        isDetached={true}
        onToggle={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      >
        <EntityTable<TurbinesHealthEvent>
          items={sortedEvents || []}
          isLoading={isLoading}
          itemKeyName='turb_id'
          isCompact
          columnConfig={[
            {
              key: 'turb_id',
              description: 'Turbine',
              formatter: val => turbsMap.get(val)?.name || '',
              hidden: !showTurbine,
            },
            {
              key: 'system_id',
              description: 'System',
              formatter: val => systemMap?.get(val)?.system || '',
            },
            {
              key: 'alarm_level',
              description: 'Alarm',
              formatter: val => farol.get(`${val}`) || '',
            },
            {
              key: 'ts_in',
              description: 'Start',
              formatter: val => (val ? formatDateWithoutTime(new Date(val)) : ''),
            },
            {
              key: 'ts_fin',
              description: 'End',
              formatter: val => (val ? formatDateWithoutTime(new Date(val)) : ''),
            },
            {
              key: 'turb_id',
              description: <ThIcon />,
              formatter: (val, instance) => (
                <Link
                  target={'_blank'}
                  to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${val}?system=${systemMap
                    ?.get(instance.system_id)
                    ?.system.replace(' ', '+')}&${rangeParamsUrl(instance.ts_in)}`}
                >
                  <Icon title='Event details' color={ColorScale.cinza_medio}>
                    <ExternalLinkAltIcon />
                  </Icon>
                </Link>
              ),
              hidden: !showLink,
            },
          ]}
          noDataMessage='Nenhum evento encontrado para o período, aerogerador e sistema selecionados.'
          style={{
            maxHeight: calcVerticalHeight({ customOffset: '7rem' }),
            minHeight: '15rem',
          }}
        />
      </ExpandableSection>
    </Panel>
  )
}

export default EventsTable
