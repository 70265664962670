import LegendHide from '../../../assets/gif/plotly/legend-hide-plot.gif'
import LegendIsolate from '../../../assets/gif/plotly/legend-isolate-plot.gif'

import ImgGif from '../../shared/ImgGif'

export const stepHideLegend = (
  <>
    <div>
      <h4>
        <strong>Retirar sinal através das legendas</strong>
      </h4>
      <p>Clique na legenda do sinal para desativar ou ativar a visualização do mesmo no gráfico.</p>
      <p>Clique 2 vezes na legenda para ativar todos novamente.</p>

      <ImgGif src={LegendHide} />
    </div>
  </>
)

export const stepIsolateLegend = (
  <>
    <div>
      <h4>
        <strong>Isolar sinal no gráfico</strong>
      </h4>
      <p>Clique 2 vezes na legenda do sinal para poder isolar no gráfico.</p>
      <p>Clique 2 vezes na legenda para ativar todos novamente.</p>

      <ImgGif src={LegendIsolate} />
    </div>
  </>
)
