import { List, ListItem, Text, TextContent, TextVariants } from '@patternfly/react-core'

const Help = () => {
  return (
    <div>
      <TextContent>
        <Text component={TextVariants.h2}>Categorias do resumo anual</Text>
        <Text>
          <List className='pf-v5-u-my-lg'>
            <ListItem>Média de longo-prazo:</ListItem>
            <ListItem>Média do ano:</ListItem>
            <ListItem>Desvio:</ListItem>
            <ListItem>Projeção para o ano:</ListItem>
            <ListItem>Probabilidade:</ListItem>
            <ListItem>IAV (Interannual Variability):</ListItem>
            <ListItem>Sensibilidade:</ListItem>
          </List>
        </Text>
      </TextContent>
    </div>
  )
}

export default Help
