import {
  debounce,
  InputGroup,
  InputGroupItem,
  InputGroupText,
  TextInput,
} from '@patternfly/react-core'
import { OutlinedCalendarAltIcon } from '@patternfly/react-icons'
import { css } from '@patternfly/react-styles'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import useMobile from '../../hooks/useMobile'

const datetimePattern = /[1-2]0[0-4][0-9]\/[0-1][0-9]\/[0-3][0-9] [0-2]?[0-9]:[0-5][0-9]/g
const datePattern = /[1-2]0[0-4][0-9]\/[0-1][0-9]\/[0-3][0-9]/g

const CalendarTextInput = ({
  value,
  onChange,
  onToggleCalendar,
  onClickText,
  showCalendarIcon = true,
  variant = 'date',
  validate,
  className = 'pf-v5-u-my-sm',
}: {
  value: Date
  onChange: (newDate: Date) => void
  onToggleCalendar?: () => void
  variant?: 'date' | 'datetime'
  showCalendarIcon?: boolean
  onClickText?: () => void
  validate?: (newDate: Date) => boolean
  className?: string
}) => {
  const dt = DateTime.fromJSDate(value)
  const dtPattern = { date: datePattern, datetime: datetimePattern }[variant]
  const datetimeFormat = variant === 'date' ? 'yyyy/MM/dd' : 'yyyy/MM/dd HH:mm'
  const [innerValue, setInnerValue] = useState<string>(dt.toFormat(datetimeFormat))
  const isMobile = useMobile()
  const [validated, setValidated] = useState<'success' | 'warning' | 'error' | 'default'>('default')

  const debounceDelay = 750
  const debounceOnChange = debounce(onChange, debounceDelay)

  const validateTextInput = (newText: string) => {
    const newDate = new Date(newText)
    if (!Number.isNaN(newDate.getDay()) && !!newText.match(dtPattern)) {
      return newDate
    }
    return false
  }

  const handleTextInput = (newText: string) => {
    setInnerValue(newText)
    const validDate = validateTextInput(newText)
    if (!validDate) {
      setValidated('error')
      return
    }
    if (validate && !validate(validDate)) {
      setValidated('warning')
      return
    }
    setValidated('default')
    debounceOnChange(validDate)
  }

  useEffect(() => {
    setInnerValue(DateTime.fromJSDate(value).toFormat(datetimeFormat))
    setValidated('default')
  }, [value])

  return (
    <InputGroup className={css(className)} style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
      <InputGroupItem isFill>
        <TextInput
          aria-label='datetime-input'
          validated={validated}
          value={innerValue}
          onChange={(_event, newText: string) => handleTextInput(newText)}
          type='text'
          style={
            isMobile
              ? { maxWidth: '100%', textAlign: 'center' }
              : { maxWidth: variant === 'datetime' ? '9.5rem' : '8rem', textAlign: 'center' }
          }
          onClick={onClickText}
          placeholder={datetimeFormat.toLowerCase()}
        />
      </InputGroupItem>
      <InputGroupItem>
        {showCalendarIcon ? (
          <InputGroupText className='icon-calendar-text-input'>
            <OutlinedCalendarAltIcon color='black' onClick={onToggleCalendar} />
          </InputGroupText>
        ) : null}
      </InputGroupItem>
    </InputGroup>
  )
}

export default CalendarTextInput
