import { Stack, StackItem } from '@patternfly/react-core'
import { sum } from 'd3-array'
import { useContext } from 'react'
import { CounterDataTemporalMonthlyTurb, Turbines } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  DEFAULT_PLOT_CONFIG,
  PLOT_TEMPORAL_MONTH_WTG,
} from './constants'
import useData from './useData'

interface WTGTotals {
  [key: number]: number
}

const PLOT_HEIGHT = calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '10rem' })
const PLOT_STYLE = { width: '100%', height: PLOT_HEIGHT }

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 70, t: 20, r: 20, l: 50 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    tickangle: -90,
    tickfont: { size: 9 },
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const makeTraces = (
  turbs: Turbines[],
  monthlyTurbs: CounterDataTemporalMonthlyTurb[],
  totals: WTGTotals
) => {
  const _defaultAxis = { ...defaultAxis, type: 'bar', hoverinfo: 'all' }

  return COUNTERDATA_TYPE_LIST.flatMap(cat => {
    return turbs.map(t => {
      const catValue = monthlyTurbs.find(mf => mf.turb_id === t.turb_id)
      if (!catValue) return null

      return {
        ..._defaultAxis,
        y: [(Number(catValue[cat]) / totals[t.turb_id]) * 100],
        x: [t.name],
        name: COUNTERDATA_TYPES[cat].description,
        marker: { color: COUNTERDATA_TYPES[cat].color },
      }
    })
  })
}

const PlotMonthPark = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
}) => {
  const { isLoading: dataIsLoading, monthlyTurbs: allMonthlyTurbs } = useData(dateIn, dateFin)
  const { turbs } = useContext(SitesContext)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTH_WTG}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  const monthlyTurbs = allMonthlyTurbs.filter(
    e =>
      new Date(e.ts).getUTCMonth() === dateIn.getUTCMonth() ||
      new Date(e.ts).getUTCMonth() === dateFin.getUTCMonth()
  )

  const monthlyTotals = {}

  monthlyTurbs.forEach(dd =>
    Object.assign(monthlyTotals, {
      [dd.turb_id]: sum(COUNTERDATA_TYPE_LIST.map(cat => dd[cat])),
    })
  )

  const middle = Math.floor(turbs.length / 2)
  const upperTurbs = turbs.slice(0, middle)
  const bottomTurbs = turbs.slice(middle, turbs.length)

  const upperData = makeTraces(upperTurbs, monthlyTurbs, monthlyTotals)
  const bottomData = makeTraces(bottomTurbs, monthlyTurbs, monthlyTotals)

  return (
    <Stack>
      <StackItem>
        <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
          <Plot
            divId={PLOT_TEMPORAL_MONTH_WTG}
            useResizeHandler
            key={revision}
            data={upperData.filter(t => t)}
            layout={PLOT_LAYOUT}
            config={DEFAULT_PLOT_CONFIG}
            style={PLOT_STYLE}
            onLegendClick={() => false}
          />
        </div>
      </StackItem>
      <StackItem>
        <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
          <Plot
            divId={PLOT_TEMPORAL_MONTH_WTG}
            useResizeHandler
            key={revision}
            data={bottomData.filter(t => t)}
            layout={PLOT_LAYOUT}
            config={DEFAULT_PLOT_CONFIG}
            style={PLOT_STYLE}
            onLegendClick={() => false}
          />
        </div>
      </StackItem>
    </Stack>
  )
}

export default PlotMonthPark
