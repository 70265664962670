import { Grid, GridItem, Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import SitesContext from '../../contexts/SitesContext'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'

import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import useVertical from '../../hooks/useVertical'
import api from '../../services/api'
import MultiSelect from '../shared/DeprecatedMultiSelect'
import Enviar from '../shared/Enviar'
import HistogramPlot from './HistogramPlot'
import ScatterPlot from './ScatterPlot'
import TimeSeriesPlot from './TimeSeriesPlot'

const placeholderData = { signals: [{ signal_id: 0 }] }

const queryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
  placeholderData,
}

const formatDate = date => date?.toISOString()?.split('T')[0]

const fetchData = async props => {
  const { siteId, signals, ts_in, ts_fin } = props
  if (signals.filter(s => s?.site_id === siteId).length === 0) return placeholderData

  const params = new URLSearchParams({ ts_in: formatDate(ts_in), ts_fin: formatDate(ts_fin) })
  signals
    .sort((a, b) => a.signal_id - b.signal_id)
    .forEach(s => params.append('signal_id', s.signal_id))

  const response = await api.get(`/sites/${siteId}/production/bopdata`, { params })
  console.log(
    'Data fetched',
    siteId,
    signals.map(s => s.signal_id)
  )

  return response.data
}

const CurvaPotenciaBOP = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const { site, bopSignals, isLoading: sitesIsLoading } = useContext(SitesContext)

  const defaultPreSignals = bopSignals.filter(s =>
    [site.signal_id_enere_bay, site.signal_id_eneatdel_bay].includes(s.signal_id)
  )
  const [selectedSignals, setSelectedSignals] = useState([])
  const [preSelectedSignals, setPreSelectedSignals] = useState([])

  const today = new Date()
  const [tsFin, setTsFin] = useState(new Date(Date.parse(today) - 1 * 86400000))
  const [tsIn, setTsIn] = useState(new Date(Date.parse(today) - 8 * 86400000))
  const [preTsFin, setPreTsFin] = useState(new Date(Date.parse(today) - 1 * 86400000))
  const [preTsIn, setPreTsIn] = useState(new Date(Date.parse(today) - 8 * 86400000))

  const _selSignals = selectedSignals.length === 0 ? defaultPreSignals : selectedSignals

  const { data: chartData, isLoading: chartIsLoading } = useQuery(
    [
      'pw_curve_bop',
      site?.site_id,
      tsIn,
      tsFin,
      _selSignals.map(s => `${s?.signal}_${s?.site_id}`),
    ],
    async () =>
      fetchData({ siteId: site.site_id, signals: _selSignals, ts_in: tsIn, ts_fin: tsFin }),
    {
      ...queryOptions,
      enabled: !!site?.site_id,
    }
  )

  const handleEnviar = () => {
    setSelectedSignals(preSelectedSignals)
    setTsIn(preTsIn)
    setTsFin(preTsFin)
  }

  const handleSiteSwitch = async () => {
    if (!site || !site.site_id || bopSignals.length === 0) return

    const _defaultSignals = bopSignals.filter(s =>
      [site.signal_id_enere_bay, site.signal_id_eneatdel_bay].includes(s.signal_id)
    )
    setPreSelectedSignals(_defaultSignals)
    setSelectedSignals(_defaultSignals)
  }

  useEffect(() => {
    handleSiteSwitch()
  }, [site?.site_id, bopSignals.length])

  const isLoading =
    sitesIsLoading ||
    chartIsLoading ||
    chartData === placeholderData ||
    !bopSignals.some(s => s.site_id === site?.site_id)
  const filtersKey = `${site?.site_id}_${isLoading ? 'a' : 'b'}'`

  return (
    <PageFrame
      pageName='Curva de Potência BOP'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isLoading={isLoading}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      filters={[
        <SelectSite enableUrlParams key='SelectSite' />,
        <MultiSelect
          key={`'multi_select_${filtersKey}'`}
          site={site}
          entities={bopSignals}
          entityName='signal'
          entityIdName='signal_id'
          selected={preSelectedSignals}
          setSelected={setPreSelectedSignals}
          placeholderText='Sinais'
          defaultEntities={defaultPreSignals}
          minSelections={2}
          maxSelections={3}
        />,
        <DatetimeRangePicker
          key='DatetimeRangePicker'
          value={{ startDate: preTsIn, endDate: preTsFin }}
          onChange={(newStart, newEnd) => {
            setPreTsIn(newStart)
            setPreTsFin(newEnd)
          }}
          calendarVariant='date'
          maxEnabled={today}
        />,
        <Enviar key={`update_${filtersKey}`} onClick={handleEnviar} isFetching={isLoading} />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid>
              <GridItem lg={6} md={12}>
                {!isLoading && <ScatterPlot chartData={chartData} signals={preSelectedSignals} />}
              </GridItem>
              {!isLoading && <HistogramPlot chartData={chartData} signals={bopSignals} />}
              <GridItem lg={12} md={12}>
                {!isLoading && (
                  <TimeSeriesPlot chartData={chartData} signals={bopSignals} revision={isNavOpen} />
                )}
              </GridItem>
            </Grid>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default CurvaPotenciaBOP
