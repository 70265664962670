import COLORS from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const TimeSeriesPlot = ({ chartData, signals, revision }) => {
  const colorScale = [
    COLORS.azul_meia_noite,
    COLORS.verde,
    COLORS.laranja_chama,
    '#226CB5',
    '#93EB73',
    '#FAB43C',
    '#2BBFF0',
    COLORS.limao,
    '#FFE560',
  ]

  const layout = { template: layoutTemplate }
  const domain = chartData.signals.length > 2 ? [0.055, 0.99] : [0.01, 0.99]

  const traces = chartData.signals.map((s, v) => {
    const signal = signals.find(si => si.signal_id === s.signal_id)
    if (!signal || !signal?.description) return {}

    Object.assign(layout, {
      [`yaxis${v === 0 ? '' : v + 1}`]: {
        title: { text: `${signal.description}`, font: { color: colorScale[v] } },
        [`${v === 0 ? '__ignore__' : 'overlaying'}`]: 'y',
        side: v % 2 === 0 ? 'left' : 'right',
        anchor: 'free',
        position: v === 2 ? 0.05 : v % 2 === 0 ? 0 : 1,
        range: [signal.min_possible, signal.max_possible],
        autorange: false,
        linewidth: 2,
        gridwidth: 2,
        mirror: 'ticks',
        zeroline: false,
      },
      [`xaxis${v === 0 ? '' : v + 1}`]: {
        domain,
        type: 'datetime',
        linewidth: 2,
        gridwidth: 2,
        mirror: 'ticks',
        zeroline: false,
      },
    })

    return {
      x: s.data.map(d => d.ts),
      y: s.data.map(d => d.val),
      type: 'scatter',
      marker: { color: colorScale[v] },
      name: signal.signal,
      yaxis: v === 0 ? 'y1' : `y${v + 1}`,
    }
  })

  const style = { width: '100%', height: '45vh' }

  return (
    <div>
      <Plot
        useResizeHandler
        key={revision}
        data={traces.flat()}
        layout={layout}
        config={defaultConfig}
        style={style}
      />
    </div>
  )
}

export default TimeSeriesPlot
