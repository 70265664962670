import { Grid, GridItem, Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { range } from 'd3-array'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Windness, WindnessAPI } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import months from '../../utils/months'
import DownloadButton from '../shared/DownloadButton'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import SelectYears from '../shared/selects/SelectYears'
import ChartAverageSpeedMonthly from './ChartAverageSpeedMonthly'
import ChartWindnessYearly from './ChartWindnessYearly'
import Help from './Help'
import TableAnnualResume from './TableAnnualResume'
import TableMonthlyResume from './TableMonthlyResume'

const NUMBER_OF_YEARS = 22

const generateYears = (numberOfYears: number) => {
  const max = new Date().getFullYear()
  const min = max - numberOfYears
  return range(min, max + 1).sort((a, b) => b - a)
}

const parseFloatCell = (cell?: number | null, decimal = 2) => {
  if (!cell) return ''
  const parse = decimal === 0 ? Math.round(cell) : Number(cell.toFixed(decimal))
  return isNaN(parse) ? '' : parse
}

const placeholderData = { yearly: [], monthly: [] }
const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const get_data = async (siteId?: number, year?: number) => {
  if (!siteId || !year) return placeholderData

  return await WindnessAPI.getAll({ siteId, year })
}

const prefetch = async (siteId: number, yearSelected: number) => {
  await queryClient.prefetchQuery(
    ['windness', siteId, yearSelected],
    async () => get_data(siteId, yearSelected),
    defaultQueryOptions
  )
}

const PageWindness = () => {
  const defaultYear = new Date().getFullYear()

  const [yearSelected, setYearSelected] = useState(defaultYear)
  const { site, setActiveSite, sites, isLoading: sitesIsLoading } = useContext(SitesContext)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const [chartData, setChartData] = useState<Windness>(placeholderData)
  const years = generateYears(NUMBER_OF_YEARS)
  const { siteName } = useParams()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const { isLoading: chartIsLoading, data: _chartData } = useQuery(
    ['windness', site?.site_id, yearSelected],
    async () => get_data(site?.site_id, yearSelected),
    queryOptions
  )

  useEffect(() => {
    if (chartIsLoading || sitesIsLoading || !_chartData) return

    setChartData(_chartData)
  }, [_chartData, chartIsLoading, sitesIsLoading])

  const isLoading = chartData.yearly.length === 0 || sitesIsLoading
  const isContentLoading = chartData.yearly.length !== 0 && chartIsLoading

  useEffect(() => {
    if (isLoading) return
    sites.filter(s => s.site_id !== site.site_id).forEach(s => prefetch(s.site_id, yearSelected))
  }, [isLoading, yearSelected])

  const monthlyResumeRows = useMemo(() => {
    if (chartData.monthly.length !== undefined && chartData.monthly.length > 0) {
      return chartData.monthly
        .sort((a, b) => a.mm - b.mm)
        .map(el => {
          return [
            months[el.mm - 1],
            parseFloatCell(el?.actual_cy),
            parseFloatCell(el?.avg),
            parseFloatCell(el?.delta),
            parseFloatCell(el?.probability || 0, 0),
          ]
        })
    } else {
      return months.map(el => [el, '', '', '', ''])
    }
  }, [chartData.monthly])

  const annualResumeRows = useMemo(() => {
    const selectYearData = chartData.yearly.find(e => e.yyyy === yearSelected)
    const latestMonthData = [...chartData.monthly]
      .filter(e => e.ytd_mean)
      .sort((a, b) => b.mm - a.mm)
      .shift()

    const monthName = latestMonthData ? months[latestMonthData.mm - 1] : 'N/A'
    if (selectYearData) {
      const ytd_long_term = parseFloatCell(latestMonthData?.ytd_longterm)
      const momm = parseFloatCell(selectYearData.momm)
      return [
        [`Média de longo-prazo (Jan - ${monthName}):`, `${ytd_long_term} m/s`],
        [`Média do ano (Jan - ${monthName}):`, `${parseFloatCell(latestMonthData?.ytd_mean)} m/s`],
        ['Desvio:', `${parseFloatCell(latestMonthData?.ytd_delta)} %`],
        ['Média de longo prazo:', `${momm} m/s`],
        ['Projeção para o ano:', `${parseFloatCell(selectYearData.avg)} m/s`],
        ['Probabilidade:', `${parseFloatCell(selectYearData.probability)} %`],
        ['IAV (Interannual Variability):', `${parseFloatCell(selectYearData.iav)} %`],
        ['Sensibilidade:', `${parseFloatCell(selectYearData.sensitivity)}`],
      ]
    } else {
      return [
        ['Média de longo-prazo YTD:', ''],
        ['Média do ano YTD:', ''],
        ['Desvio:', ''],
        ['Média de longo prazo:', ''],
        ['Projeção para o ano:', ''],
        ['Probabilidade:', ''],
        ['IAV (Interannual Variability):', ''],
        ['Sensibilidade:', ''],
      ]
    }
  }, [chartData.yearly, yearSelected])

  const revision = isNavOpen ? 1 : 2

  useEffect(() => {
    let _yearSelect = Number(searchParams.get('ano'))
    if (!_yearSelect || _yearSelect > defaultYear || _yearSelect < defaultYear - NUMBER_OF_YEARS) {
      _yearSelect = defaultYear
    }
    const _siteName = siteName || site.site

    const _site = sites.find(e => e.site.toLowerCase() === _siteName.toLowerCase())

    if (_site) setActiveSite(_site.site_id)
    setYearSelected(_yearSelect)
  }, [location, sitesIsLoading])

  return (
    <PageFrame
      pageName='Windness'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      isContentLoading={isContentLoading}
      helpContent={<Help />}
      filters={[
        <SelectSite key='s1' enableUrlParams />,
        <SelectYears
          yearSelected={yearSelected}
          setYearSelected={setYearSelected}
          yearList={years}
          key={`s2_${yearSelected}`}
        />,
        <DownloadButton
          key={`dl_btn_${site.site_name}`}
          label='Exportar'
          url={`/sites/${site.site_id}/windness/report?${new URLSearchParams({
            yyyy: String(yearSelected),
          })}`}
          filename={`report_windness_${site.site_name}_${yearSelected}.xlsx`}
        />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid hasGutter>
              <GridItem lg={4} md={12}>
                <TableAnnualResume rows={annualResumeRows} title={`Resumo Anual ${yearSelected}`} />
                <TableMonthlyResume
                  rows={monthlyResumeRows}
                  title={`Resumo mensal ${yearSelected}`}
                />
              </GridItem>
              <GridItem lg={8} md={12}>
                <ChartWindnessYearly
                  yearlyData={chartData.yearly}
                  revision={revision}
                  isLoading={isLoading}
                />
                <ChartAverageSpeedMonthly
                  monthlyData={chartData.monthly}
                  revision={revision}
                  isLoading={isLoading}
                />
              </GridItem>
            </Grid>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default PageWindness
