import { Button } from '@patternfly/react-core'
import { IExibicao } from '.'

interface IButtonsExibiçãoProps {
  exibicao: IExibicao
  setExibicao: (_exibicao: IExibicao) => void
}

export const ButtonsExibição = (props: IButtonsExibiçãoProps) => {
  const { exibicao, setExibicao } = props

  return (
    <div className='pf-v5-u-p-sm'>
      <Button
        variant={exibicao === 'performance' ? 'primary' : 'control'}
        onClick={() => setExibicao('performance')}
      >
        Perfomance
      </Button>
      <Button
        variant={exibicao === 'ocorrencias' ? 'primary' : 'control'}
        onClick={() => setExibicao('ocorrencias')}
      >
        Ocorrências
      </Button>
    </div>
  )
}
