import { Button } from '@patternfly/react-core'
import api, { REACT_APP_API_URL } from '../../services/api'

const DownloadButton = ({ url, filename, label }) => {
  const downloadFile = async () => {
    try {
      const response = await api.get(`${REACT_APP_API_URL}${url}`, {
        headers: { accept: 'application/json' },
        responseType: 'blob',
      })
      const { data, headers } = response
      const blob = new Blob([data], { type: headers['content-type'] })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Button className='pf-v5-u-m-sm' variant='control' onClick={downloadFile}>
      {label}
    </Button>
  )
}

export default DownloadButton
