import { CSSProperties, useContext, useRef } from 'react'
import { ProductionTurbDataSignals, Turbines } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { COLOR_SCALES } from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot, { csvIcon, Plotly } from '../shared/Plot'
import { PLOT_DIVS } from './constants'
import Context from './Context'
import { ACTIONS } from './reducer'

interface IBin {
  bin: number
  mVarA: any
  mVarB: any
}

interface IData {
  ts: string
  turb: Turbines
  turbName: string
  turb_name: string
  x: number
  y: number
  name: string
  mode: string
  signalX: {
    signal: string
  }
  signalY: {
    signal: string
  }
  marker: {
    color: string
  }
}

interface IPoints {
  data: IData
  y: number
  curveNumber: number
  x: number
  pointIndex: number
  yaxis: {
    _id: string
  }
}

interface ICurrent {
  x: number
  y: number
  ts: string
  turb_id: number
  turb_name: string
  pointIndex: number
  curveNumber: number
  signalX: string
  signalY: string
}
interface ITraces {
  x: number[]
  y: number[]
  ts: string[]
  type: string
  mode: string
  marker: {
    color: string
    size: number
  }
  name: string
  signalX: ProductionTurbDataSignals | undefined
  signalY: ProductionTurbDataSignals | undefined
  turb: Turbines
  turbName: string
}
interface ILines {
  x: (number | undefined)[]
  y: (number | undefined)[]
  ts?: string[]
  type: string
  mode: string
  line: {
    color: string
    width: number
  }
  name: string
}

function isITraces(data: ITraces | ILines): data is ITraces {
  return 'signalX' in data
}

const formatData = (data: ITraces | ILines) => {
  if (!isITraces(data)) return undefined
  return data?.x?.map((_, index) => ({
    name: data.name,
    x: data.x?.[index] || '',
    y: data.y?.[index] || '',
    signalX: data?.signalX?.signal,
    signalY: data?.signalY?.signal,
    turb_name: data.turbName,
    ts: data.ts?.[index],
  }))
}

const gerarCsv = (selectionData: ICurrent[] | undefined, allData: (ITraces | ILines)[][]) => {
  const allDataFormatted = allData?.flatMap(dataMap => {
    return dataMap.flatMap(data => formatData(data))
  })

  const data = selectionData?.length && selectionData?.length > 0 ? selectionData : allDataFormatted

  if (!data || data.length === 0) return console.error('objeto para gerar CSV está indefinido')

  let csv = 'timestamp,x_valor,x_signal,y_valor,y_sinal,aerogerador\n'
  data.forEach(i => {
    const x = Number(Number(i?.x).toFixed(2))
    const y = Number(Number(i?.y).toFixed(2))
    if (i) csv += `${i?.ts},${x},${i?.signalX},${y},${i?.signalY},${i?.turb_name}\n`
  })

  const csvElement = document.createElement('a')
  csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  csvElement.target = '_blank'
  csvElement.download = 'dispersion_selection.csv'
  csvElement.click()
}
const colorScale = COLOR_SCALES.categorical

const ScatterPlot = ({ style, revision }: { style: CSSProperties; revision: number }) => {
  const { turbsMap, turbSignalMap } = useContext(SitesContext)

  const { turbdata, signalsSelected, scatterPlot, signals, dispatch } = useContext(Context)

  const layout = {
    template: layoutTemplate,
    hovermode: 'closest',
    annotations: scatterPlot.annotations,
  }

  const current: ICurrent[] = []

  const selectionsRef = useRef(current)

  const traces: (ITraces | ILines)[][] = turbdata.map((t, i) => {
    if (!t || signalsSelected.length < 2 || signals.length < 2) return []

    const turb = turbsMap.get(t.turb_id)

    const [signalA, signalB] = signalsSelected
      .map(s => signals.find(si => si.signal_id === s))
      .filter(s => Boolean(s))

    if (!signalA || !signalB) return []

    const sigA = t.signals.find(si => si.signal_id === signalA.signal_id)
    const sigB = t.signals.find(si => si.signal_id === signalB.signal_id)

    if (!sigA || !sigB || !signalA || !signalB || !turb) return []

    Object.assign(layout, {
      [`xaxis${i === 0 ? '' : i + 1}`]: {
        ...defaultAxis,
        title: `${signalA.description}`,
        zeroline: true,
      },
      [`yaxis${i === 0 ? '' : i + 1}`]: {
        ...defaultAxis,
        title: `${signalB.description}`,
      },
    })

    const medicoes = new Map()
    const groupPairs: IBin[] = []
    const { a7_axis_bin, min_possible: a_min_possible, max_possible: a_max_possible } = signalA
    const { a7_count_filter, min_possible: b_min_possible, max_possible: b_max_possible } = signalB

    // Equivalente a left join de A em B
    sigA.data.forEach(e => medicoes.set(e.ts, e.val))
    sigB.data.forEach(e => medicoes.set(e.ts, [medicoes.get(e.ts), e.val]))

    medicoes.forEach(val => {
      let a, b
      try {
        ;[a, b] = val
      } catch (TypeError) {
        return
      }
      if (
        a >= a_max_possible ||
        a <= a_min_possible ||
        b >= b_max_possible ||
        b <= b_min_possible
      ) {
        return
      }
      const bin = { bin: Math.round(a / a7_axis_bin) * a7_axis_bin, mVarA: a, mVarB: b }
      groupPairs.push(bin)
    })
    const uniqueKeys = [...new Set(groupPairs.map(e => e.bin))].sort((a, b) => a - b)

    const lineValues = uniqueKeys.map(k => {
      const toGroup = groupPairs.filter(pg => pg.bin === k)
      if (toGroup.length < a7_count_filter) return null

      const _mVarA = toGroup.map(e => e.mVarA).reduce((a, b) => a + b, 0) / toGroup.length
      const _mVarB = toGroup.map(e => e.mVarB).reduce((a, b) => a + b, 0) / toGroup.length

      return { bin: k, mVarA: _mVarA, mVarB: _mVarB }
    })

    const scatter = {
      x: sigA.data.map(d => d.val),
      y: sigB.data.map(d => d.val),
      ts: sigB.data.map(d => d.ts),
      type: 'scatter',
      mode: 'markers',
      marker: { color: colorScale[i][1], size: 3 },
      name: `${turb.name} Pontos`,
      signalX: turbSignalMap.get(sigA.signal_id),
      signalY: turbSignalMap.get(sigB.signal_id),
      turb,
      turbName: turb.name,
    }
    const lvs = lineValues.filter(e => e)
    const line = {
      x: lvs.map(e => e?.mVarA),
      y: lvs.map(e => e?.mVarB),
      type: 'scatter',
      mode: 'lines',
      line: { color: colorScale[i][3], width: 3 },
      name: `${turb.name} Linha`,
    }

    return [scatter, line]
  })

  const config = {
    ...defaultConfig,
    modeBarButtonsToAdd: [
      {
        name: 'exportar dados selecionados em .csv',
        icon: csvIcon,
        click: () => gerarCsv(selectionsRef.current, traces),
      },
      ...defaultConfig.modeBarButtonsToAdd,
    ],
  }

  const onClick = (e: { points: IPoints[] }) => {
    if (!scatterPlot.annotationMode) return
    if (e.points[0].data.mode === 'lines') return

    const _annotations = e.points.map(p => {
      const { data, yaxis, x, y } = p

      const haveAnnotations = scatterPlot.annotations.length > 0

      return {
        text: `${data.turbName}<br>${data.signalX.signal}: ${x.toFixed(2)} - ${
          data.signalY.signal
        }: ${y.toFixed(2)}`,
        ax: haveAnnotations ? scatterPlot.annotations[0].ax : -75,
        ay: haveAnnotations ? scatterPlot.annotations[0].ay : -75,
        bordercolor: data.marker.color,
        borderwidth: 2,
        bgcolor: 'white',
        borderpad: 5,
        font: { color: 'black' },
        yref: yaxis._id,
        standoff: 5,
        captureevents: true,
        x,
        y,
      }
    })
    dispatch({
      type: ACTIONS.SET_SCATTER_ANNOTATIONS,
      payload: {
        scatterPlot: { ...scatterPlot, annotations: [...scatterPlot.annotations, ..._annotations] },
      },
    })
  }

  const onSelected = (e: { points: IPoints[] }) => {
    selectionsRef.current = e?.points.map(p => {
      return {
        x: p.x,
        y: p.y,
        ts: p.data.ts[p.pointIndex],
        turb_id: p.data.turb.turb_id,
        turb_name: p.data.turb.name,
        pointIndex: p.pointIndex,
        curveNumber: p.curveNumber,
        signalX: p.data.signalX.signal,
        signalY: p.data.signalY.signal,
      }
    })
  }

  const onHover = (e: { points: IPoints[] }) => {
    const point = e.points[0]
    const { x, y, curveNumber } = point
    if (curveNumber % 2 !== 0) return

    Plotly.Fx.hover(PLOT_DIVS.TIMESERIES, [
      { curveNumber: curveNumber + 0, yval: x },
      { curveNumber: curveNumber + 1, yval: y },
    ])
  }
  const onClickAnnotation = (e: any) => {
    dispatch({
      type: ACTIONS.SET_SCATTER_ANNOTATIONS,
      payload: {
        scatterPlot: {
          ...scatterPlot,
          annotations: [
            ...scatterPlot.annotations.filter(p => {
              return (
                Math.trunc(Number(p.x)) !== Math.trunc(e.annotation.x) &&
                Math.trunc(Number(p.y)) !== Math.trunc(e.annotation.y)
              )
            }),
          ],
        },
      },
    })
  }

  return (
    <div>
      <Plot
        id={PLOT_DIVS.SCATTER}
        data={traces.flat()}
        layout={layout}
        config={config}
        useResizeHandler
        key={revision}
        style={style}
        onHover={onHover}
        onClick={onClick}
        onSelected={onSelected}
        onClickAnnotation={onClickAnnotation}
      />
    </div>
  )
}

export default ScatterPlot
