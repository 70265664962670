import { sum } from 'd3-array'
import { useContext } from 'react'

import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { defaultAxis } from '../../utils/plotly'
import Plot from '../shared/Plot'
import { categories, DEFAULT_PLOT_CONFIG, PLOT_TEMPORAL_MONTH_WTG } from './constants'
import useData from './useData'

const PLOT_HEIGHT = calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '10rem' })
const PLOT_STYLE = { width: '100%', height: PLOT_HEIGHT }

const PLOT_LAYOUT = {
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  showlegend: true,
  margin: { b: 70, t: 20, r: 20, l: 50 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Parque',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotMonthParkTotal = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
  categorySelection,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
}) => {
  const { isLoading: dataIsLoading, monthlyParks: allMonthlyParks } = useData(dateIn, dateFin)
  const { windFarms } = useContext(SitesContext)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTH_WTG}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  const monthlyParks = allMonthlyParks.filter(
    e =>
      new Date(e.ts).getUTCMonth() === dateIn.getUTCMonth() ||
      new Date(e.ts).getUTCMonth() === dateFin.getUTCMonth()
  )

  const y = windFarms.map(wf => {
    const mp = monthlyParks.find(mp => mp.wf_id === wf.wf_id)

    if (!mp) return undefined

    const downtime = sum(categorySelection.downtime.map(cat => mp[cat]))
    const online = sum(categorySelection.online.map(cat => mp[cat]))

    return (online / (online + downtime)) * 100
  })

  const data = [
    {
      ...defaultAxis,
      y,
      x: windFarms.map(wf => wf.wf),
      name: 'Disponibilidade Temporal',
      type: 'bar',
      marker: {
        color: ColorScale.verde,
      },
    },
  ]

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_MONTH_WTG}
        useResizeHandler
        key={revision}
        data={[...data.filter(t => t)]}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthParkTotal
