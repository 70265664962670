import Arrow from '../Arrow'

const getMonthDelta = (dtIn: Date, dtFin: Date): number => {
  const deltaYear = dtIn.getFullYear() - dtFin.getFullYear()
  const deltaMonth = dtIn.getMonth() + 1 - (dtFin.getMonth() + 1)
  const delta = deltaYear * 12 + deltaMonth
  return delta
}
const getMonthYear = (dt: Date) => {
  return dt.getFullYear() * 100 + (dt.getMonth() + 1)
}

const DateArrow = ({
  direction,
  tsIn,
  tsFin,
  setTsIn,
  setTsFin,
  variant = 'datetime',
  className,
}: {
  direction: 'left' | 'right'
  tsIn: Date
  tsFin: Date
  setTsIn: (newDate: Date) => void
  setTsFin: (newDate: Date) => void
  variant?: 'datetime' | 'date' | 'month'
  className?: string
}) => {
  const today = new Date()

  const onClickMonthVariant = () => {
    const delta = direction === 'left' ? getMonthDelta(tsIn, tsFin) : getMonthDelta(tsFin, tsIn)

    if (getMonthYear(tsFin) >= getMonthYear(today) && direction === 'right') return

    let newTsIn
    let newTsFin

    try {
      newTsIn = new Date(tsIn.getFullYear(), tsIn.getMonth() + delta, tsIn.getDate())
      newTsFin = new Date(tsFin.getFullYear(), tsFin.getMonth() + delta, tsFin.getDate())
    } catch (RangeError) {
      return
    }
    setTsIn(newTsIn)
    setTsFin(newTsFin)
  }

  const onClickDatetimeVariant = () => {
    const delta =
      direction === 'left' ? tsIn.getTime() - tsFin.getTime() : tsFin.getTime() - tsIn.getTime()
    if (tsFin >= today && direction === 'right') return

    let newTsIn
    let newTsFin

    try {
      newTsIn = new Date(tsIn.getTime() + delta)
      newTsFin = new Date(tsFin.getTime() + delta + 1000) // One second less to avoid overlapping
    } catch (RangeError) {
      return
    }

    if (newTsIn > today || newTsFin > today || newTsIn >= newTsFin) return

    setTsIn(newTsIn)
    setTsFin(newTsFin)
  }
  const onClick = variant === 'month' ? onClickMonthVariant : onClickDatetimeVariant

  return <Arrow direction={direction} onClick={onClick} className={className} />
}

export default DateArrow
