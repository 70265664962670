import { Text, TextContent } from '@patternfly/react-core'
import { ArrowRightIcon } from '@patternfly/react-icons'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { ProductionAPI } from '../../../../client'
import { queryClient as qq } from '../../../../services/api'
import { fmtDateStringToBRLDateString } from '../../../../utils/formatDate'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  placeholderData: {
    active_power: 0,
    power_factor: 0,
    ndir: 0,
    nws: 0,
    ts: '',
    site_id: 0,
  },
}

const ProductionInfos = ({ siteId }: { siteId: number }) => {
  ProductionAPI.getRealtimePark({ siteId })

  const { data } = useQuery(
    ['prod_realtime', siteId],
    () => ProductionAPI.getRealtimePark({ siteId }),
    queryOptions
  )
  const { active_power, power_factor, ndir, nws, ts } = data ?? queryOptions.placeholderData

  useEffect(() => {
    const interval = setInterval(() => {
      qq.invalidateQueries(['prod_realtime', siteId])
    }, 1000 * 15)
    return () => clearInterval(interval)
  }, [])

  return (
    <TextContent className='pf-v5-u-mt-md'>
      <Text component='p'>
        Potência Ativa: {active_power ? `${active_power} MW` : active_power}{' '}
      </Text>
      <Text component='p'>Fator de Potência (bay): {power_factor}</Text>
      <Text component='p'>Velocidade Média: {nws ? `${nws}m/s` : nws} </Text>
      <Text component='p'>
        Direção Média: {ndir ? `${ndir}º` : ndir}{' '}
        {!!nws && <ArrowRightIcon style={{ transform: `rotate(${(ndir ?? 0) + 90}deg)` }} />}
      </Text>
      <Text component='p' style={{ color: 'gray', fontSize: '10.5rem' }}>
        {ts === '' ? <div>{'  '}</div> : fmtDateStringToBRLDateString(ts)}
      </Text>
    </TextContent>
  )
}

export default ProductionInfos
