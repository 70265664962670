import { Spinner } from '@patternfly/react-core'

const style = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center' as const,
  justifyContent: 'center',
}

const LoaderSpinner = () => {
  return (
    <div style={style}>
      <Spinner />
    </div>
  )
}

export default LoaderSpinner
