import {
  Alert,
  Button,
  Divider,
  NumberInput,
  Panel,
  PanelMain,
  PanelMainBody,
  Radio,
  Stack,
  StackItem,
  Switch,
} from '@patternfly/react-core'
import { useContext } from 'react'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import Context from './Context'

import { TimesIcon, UndoIcon } from '@patternfly/react-icons'
import { useLocation, useNavigate } from 'react-router'
import { replaceFilterParams } from '../../utils/url'
import { ACTIONS } from './reducer'

const SettingsPanel = () => {
  const { dispatch, layout, scatterPlot, timeseriesPlot, maxTurbs, maxSignals, experimentalMode } =
    useContext(Context)

  const navigate = useNavigate()
  const location = useLocation()

  const handleChangeLayout = (layout: number) => {
    dispatch({
      type: ACTIONS.SET_LAYOUT,
      payload: { layout },
    })
    const newLocation = replaceFilterParams(location, [layout], 'layout')
    navigate(newLocation)
  }

  return (
    <Panel isScrollable>
      <PanelMain maxHeight={calcVerticalHeight({ customOffset: '5rem' })} tabIndex={0}>
        <PanelMainBody>
          <Stack hasGutter className='pf-v5-u-mx-lg'>
            <StackItem>
              <h2 className='pf-v5-u-m-md'>Layout da página</h2>
              <Radio
                isChecked={layout === 1}
                name='layout-radio-1'
                onChange={() => handleChangeLayout(1)}
                label='Padrão'
                id='layout-radio'
                value={1}
              />
              <Radio
                isChecked={layout === 2}
                name='layout-radio-2'
                onChange={() => handleChangeLayout(2)}
                label='Curva de Potência'
                id='layout-radio-2'
                value={2}
              />
              <Radio
                isChecked={layout === 3}
                name='layout-radio-3'
                onChange={() => handleChangeLayout(3)}
                label='Página Cheia'
                id='layout-radio-3'
                value={3}
              />
            </StackItem>
            <StackItem>
              <h2 className='pf-v5-u-m-md'>Scatter Plot</h2>
              <Switch
                className='pf-v5-u-mr-sm'
                label='Modo anotação habilitado'
                labelOff='Modo anotação desabilitado'
                aria-label='sp-annotation-mode'
                isChecked={scatterPlot.annotationMode}
                onChange={() => {
                  dispatch({
                    type: ACTIONS.TOGGLE_SCATTER_ANNOTATION_MODE,
                    payload: {},
                  })
                }}
              />
              <Button
                className='pf-v5-u-mr-sm'
                isDanger
                variant='secondary'
                isDisabled={!scatterPlot.annotationMode}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.SET_SCATTER_ANNOTATIONS,
                    payload: {
                      scatterPlot: {
                        annotationMode: scatterPlot.annotationMode,
                        annotations: [],
                      },
                    },
                  })
                }}
              >
                <TimesIcon />
              </Button>
              <Button
                variant='secondary'
                isDisabled={!scatterPlot.annotationMode}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.TRIGGER_SCATTER_ANNOTATION_ROTATION,
                    payload: {},
                  })
                }}
              >
                <UndoIcon />
              </Button>
            </StackItem>
            <StackItem>
              <h2 className='pf-v5-u-m-md'>Serie Temporal</h2>
              <Switch
                className='pf-v5-u-mr-sm'
                label='Modo anotação habilitado'
                labelOff='Modo anotação desabilitado'
                isChecked={timeseriesPlot.annotationMode}
                onChange={() => {
                  dispatch({ type: ACTIONS.TOGGLE_TIMESERIES_ANNOTATION_MODE, payload: {} })
                }}
              />
              <Button
                className='pf-v5-u-mr-sm'
                isDanger
                variant='secondary'
                isDisabled={!timeseriesPlot.annotationMode}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.SET_TIMESERIES_ANNOTATIONS,
                    payload: {
                      timeseriesPlot: {
                        annotationMode: timeseriesPlot.annotationMode,
                        annotations: [],
                        selectionMode: timeseriesPlot.selectionMode,
                      },
                    },
                  })
                }}
              >
                <TimesIcon />
              </Button>
              <Button
                variant='secondary'
                isDisabled={!timeseriesPlot.annotationMode}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.TRIGGER_TIMESERIES_ANNOTATION_ROTATION,
                    payload: {},
                  })
                }}
              >
                <UndoIcon />
              </Button>
              <Switch
                label='Modo seleção habilitado'
                labelOff='Modo seleção desabilitado'
                isChecked={timeseriesPlot.selectionMode}
                onChange={() => {
                  dispatch({ type: ACTIONS.TOGGLE_TIMESERIES_SELECTION_MODE, payload: {} })
                }}
              />
            </StackItem>
            <StackItem>
              <Divider className='pf-v5-u-m-lg' />
            </StackItem>
            <StackItem>
              <Switch
                label='Modo experimental habilitado'
                labelOff='Modo experimental desabilitado'
                isChecked={experimentalMode}
                onChange={() => {
                  dispatch({ type: ACTIONS.TOGGLE_EXPERIMENTAL_MODE, payload: {} })
                }}
              />
            </StackItem>
            {experimentalMode && (
              <StackItem>
                <Alert isInline variant='warning' title='Modo experimental Habilitado'>
                  <span>
                    Tenha em mente que no modo experimental, a aplicação pode não funcionar da
                    maneira esperada e/ou consumir muitos recursos de CPU e Memoria.
                  </span>
                </Alert>
              </StackItem>
            )}
            <StackItem>
              <NumberInput
                value={maxTurbs}
                min={1}
                max={6}
                onMinus={() => {
                  dispatch({
                    type: ACTIONS.SET_MAX_TURBS,
                    payload: { maxTurbs: maxTurbs - 1 },
                  })
                }}
                onPlus={() => {
                  dispatch({
                    type: ACTIONS.SET_MAX_TURBS,
                    payload: { maxTurbs: maxTurbs + 1 },
                  })
                }}
                inputName='aeroLimit'
                inputAriaLabel='number input aeroLimit'
                minusBtnAriaLabel='minus aeroLimit'
                plusBtnAriaLabel='plus aeroLimit'
                isDisabled={!experimentalMode}
              />
              <span className='pf-v5-u-m-md'>Núm. Máximo de aerogeradores</span>
            </StackItem>
            <StackItem>
              <NumberInput
                value={maxSignals}
                min={3}
                max={6}
                onMinus={() => {
                  dispatch({
                    type: ACTIONS.SET_MAX_SIGNALS,
                    payload: { maxSignals: maxSignals - 1 },
                  })
                }}
                onPlus={() => {
                  dispatch({
                    type: ACTIONS.SET_MAX_SIGNALS,
                    payload: { maxSignals: maxSignals + 1 },
                  })
                }}
                inputName='input signalLimit'
                inputAriaLabel='number input signalLimit'
                minusBtnAriaLabel='minus signalLimit'
                plusBtnAriaLabel='plus signalLimit'
                isDisabled={!experimentalMode}
              />
              <span className='pf-v5-u-m-md'>Número máximo de sinais</span>
            </StackItem>
            <StackItem isFilled> </StackItem>
            <StackItem> </StackItem>
          </Stack>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}

export default SettingsPanel
