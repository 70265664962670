import { Bullseye, Spinner } from '@patternfly/react-core'

const Loading = () => {
  return (
    <Bullseye>
      <Spinner className='pf-v5-u-m-xl' size='xl' aria-label='Carregando' />
    </Bullseye>
  )
}

export default Loading
