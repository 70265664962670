import { Button } from '@patternfly/react-core'
import leftArrow from '../assets/img/fa-angle-left.svg'
import rightArrow from '../assets/img/fa-angle-right.svg'

const wrapperStyle = { display: 'inline', cursor: 'pointer' }
const arrowStyle = {
  height: '100%',
  color: 'white',
  minWidth: '0.5rem',
  verticalAlign: 'middle',
}

const Arrow = ({
  direction,
  onClick,
  variant = 'control',
  className = 'pf-v5-u-my-sm',
}: {
  direction: 'left' | 'right'
  variant?: 'plain' | 'control'
  onClick: () => void
  className?: string
}) => {
  const icon = direction === 'left' ? leftArrow : rightArrow
  return (
    <Button
      style={wrapperStyle}
      className={className}
      variant={variant}
      icon={<img src={icon} style={arrowStyle} alt='Arrow' />}
      onClick={onClick}
    />
  )
}

export default Arrow
