import {
  Grid,
  GridItem,
  Panel,
  PanelHeader,
  PanelMain,
  PanelMainBody,
  Stack,
  StackItem,
} from '@patternfly/react-core'
import { CSSProperties, useContext, useState } from 'react'
import { Masts } from '../../../client'
import EntityDataList from '../../../components/EntityDataList'
import EntityTable from '../../../components/EntityTable'
import MastContext, { Sensors } from '../../../contexts/MastContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { fmtDateStringToBRLDateString } from '../../../utils/formatDate'
import Page from '../../shared/Page'
import ModalMast from './ModalMast'

const mainTableStyle: CSSProperties = {
  height: calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    pgHasFilter: false,
    customOffset: '4rem',
  }),
  minHeight: '12rem',
}

const MastSettings = () => {
  const {
    mast,
    allMasts,
    isLoading: mastIsLoading,
    sensors,
    setActiveMast,
  } = useContext(MastContext)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const [isModalUpdateOpen, setModalUpdateOpen] = useState(false)

  const handleUpdateMast = (mast: Masts) => {
    setActiveMast(mast)
    setModalUpdateOpen(true)
  }

  return (
    <Page
      pageName='Mast Settings'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isContentLoading={false}
      isLoading={false}
    >
      <Grid hasGutter>
        <GridItem md={7}>
          <Panel className='pf-v5-u-box-shadow-sm'>
            <PanelHeader>
              <b>Torres Anemométricas</b>
            </PanelHeader>
            <PanelMain>
              <PanelMainBody>
                <EntityTable<Masts>
                  items={allMasts}
                  style={mainTableStyle}
                  isLoading={mastIsLoading}
                  onSelect={setActiveMast}
                  onClickEdit={handleUpdateMast}
                  selected={mast}
                  itemKeyName='id'
                  actionsLabel='Editar'
                  columnConfig={[
                    { key: 'id', description: 'Id' },
                    { key: 'name', description: 'Name' },
                    { key: 'tipo_datalogger', description: 'Tipo Datalogger' },
                    { key: 'active_datalogger_serial', description: 'N. Serie Datalogger' },
                    { key: 'height', description: 'Altura' },
                  ]}
                />
              </PanelMainBody>
            </PanelMain>
          </Panel>
        </GridItem>
        <GridItem md={5}>
          <Stack hasGutter>
            <StackItem>
              <Panel isScrollable className='pf-v5-u-box-shadow-sm'>
                <PanelHeader>
                  <b>{mast.name}</b>
                </PanelHeader>
                <PanelMain>
                  <PanelMainBody>
                    <EntityDataList<Masts>
                      instance={mast}
                      itemKeyName='id'
                      rowConfig={[
                        { key: 'tipo_filtro', description: 'Tipo Filtro Email' },
                        { key: 'filtro', description: 'Valor Filtro Email' },
                        {
                          key: 'dataset_start',
                          description: 'Inicio Dados',
                          formatter: fmtDateStringToBRLDateString,
                        },
                        {
                          key: 'dataset_end',
                          description: 'Final Dados',
                          formatter: fmtDateStringToBRLDateString,
                        },
                        { key: 'lat', description: 'Latitude' },
                        { key: 'lon', description: 'Longitude' },
                        { key: 'pasta', description: 'Pasta' },
                        { key: 'is_enabled', description: 'Está ativa' },
                      ]}
                    />
                  </PanelMainBody>
                </PanelMain>
              </Panel>
            </StackItem>
            <StackItem isFilled>
              <Panel isScrollable className='pf-v5-u-box-shadow-sm'>
                <PanelHeader>
                  <b>Sensores</b>
                </PanelHeader>
                <PanelMain>
                  <PanelMainBody>
                    <EntityTable<Sensors>
                      items={sensors}
                      isLoading={mastIsLoading}
                      itemKeyName='id'
                      columnConfig={[
                        { key: 'label', description: 'Label' },
                        { key: 'description', description: 'Tipo Sinal' },
                        { key: 'height', description: 'Altura' },
                        { key: 'id', description: 'Id' },
                      ]}
                    />
                  </PanelMainBody>
                </PanelMain>
              </Panel>
            </StackItem>
          </Stack>
        </GridItem>
      </Grid>
      <ModalMast
        isOpen={isModalUpdateOpen}
        onClose={() => setModalUpdateOpen(!isModalUpdateOpen)}
        mast={mast}
        activeMast={setActiveMast}
      />
    </Page>
  )
}

export default MastSettings
