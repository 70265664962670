import { sum } from 'd3-array'
import { useContext } from 'react'
import { CounterDataTemporalMonthlyPark, WindFarms } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { defaultAxis } from '../../utils/plotly'
import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  DEFAULT_PLOT_CONFIG,
  PLOT_TEMPORAL_MONTH_WTG,
} from './constants'
import useData from './useData'

interface WTGTotals {
  [key: number]: number
}

const PLOT_HEIGHT = calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '10rem' })
const PLOT_STYLE = { width: '100%', height: PLOT_HEIGHT }

const PLOT_LAYOUT = {
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 70, t: 20, r: 20, l: 50 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Parque',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const makeTraces = (
  windFarms: WindFarms[],
  monthlyTurbs: CounterDataTemporalMonthlyPark[],
  totals: WTGTotals
) => {
  const _defaultAxis = { ...defaultAxis, type: 'bar', hoverinfo: 'all' }

  return COUNTERDATA_TYPE_LIST.flatMap(cat => {
    return windFarms.map((wdf, i) => {
      const catValue = monthlyTurbs.find(mf => mf.wf_id === wdf.wf_id)
      if (!catValue) return null

      return {
        ..._defaultAxis,
        y: [(Number(catValue[cat]) / totals[wdf.wf_id]) * 100],
        x: [wdf.wf],
        name: COUNTERDATA_TYPES[cat].description,
        marker: { color: COUNTERDATA_TYPES[cat].color },
        showlegend: i === 0,
      }
    })
  })
}

const PlotMonthPark = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
}) => {
  const { isLoading: dataIsLoading, monthlyParks: allMonthlyParks } = useData(dateIn, dateFin)
  const { windFarms } = useContext(SitesContext)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTH_WTG}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  const monthlyParks = allMonthlyParks.filter(
    e =>
      new Date(e.ts).getUTCMonth() === dateIn.getUTCMonth() ||
      new Date(e.ts).getUTCMonth() === dateFin.getUTCMonth()
  )

  const monthlyTotals = {}

  monthlyParks.forEach(dd =>
    Object.assign(monthlyTotals, {
      [dd.wf_id]: sum(COUNTERDATA_TYPE_LIST.map(cat => dd[cat])),
    })
  )

  const data = makeTraces(windFarms, monthlyParks, monthlyTotals)

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_MONTH_WTG}
        useResizeHandler
        key={revision}
        data={[...data.filter(t => t)]}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthPark
