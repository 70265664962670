/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SettingsTowerMap } from '../models/SettingsTowerMap';
import type { SettingsTurbineMap } from '../models/SettingsTurbineMap';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsAPI {

  /**
   * Get Towers
   * @returns SettingsTowerMap Successful Response
   * @throws ApiError
   */
  public static getTowers(): CancelablePromise<Array<SettingsTowerMap>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/towers/settings',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Update Tower
   * @returns SettingsTowerMap Successful Response
   * @throws ApiError
   */
  public static updateTower({
    requestBody,
  }: {
    requestBody: SettingsTowerMap,
  }): CancelablePromise<SettingsTowerMap> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/towers/settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Get Site Turbines
   * @returns SettingsTurbineMap Successful Response
   * @throws ApiError
   */
  public static getSiteTurbines({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<SettingsTurbineMap>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines/settings',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Update Turbine
   * @returns SettingsTurbineMap Successful Response
   * @throws ApiError
   */
  public static updateTurbine({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: SettingsTurbineMap,
  }): CancelablePromise<SettingsTurbineMap> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/{site_id}/turbines/settings',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

}
