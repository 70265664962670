import MovePlot from '../../../assets/gif/plotly/move-plot.gif'
import HomeRestartIcon from '../../../assets/img/icon-home-restart.svg'
import PanIcon from '../../../assets/img/icon-pan.svg'

import ImgGif from '../../shared/ImgGif'

export const stepMove = (
  <>
    <h4>
      <strong>Mover o gráfico</strong>
    </h4>
    <p>
      A opção <img src={PanIcon} style={{ width: '1.5em' }} alt='Ícone para mover' /> permite mover
      a área clicando e arrastando o gráfico.
    </p>
    <ImgGif src={MovePlot} alt='Movendo o gráfico com o mouse' />
    <p>
      Para retornar, clique 2 vezes no gráfico ou clique na opção{' '}
      <img src={HomeRestartIcon} style={{ width: '1.5em' }} alt='' /> .
    </p>
  </>
)
