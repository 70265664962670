import { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Masts } from '../../client'
import Select from '../../components/selects/Select'
import MastContext from '../../contexts/MastContext'

const SelectMasts = () => {
  const { mast, allMasts, setActiveMast, isLoading } = useContext(MastContext)

  const { mastId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchPath = ''

    if (mastId && mast.id !== Number(mastId)) {
      const newLocation = location.pathname.includes(mastId)
        ? location.pathname.replace(mastId, `${mast.id}`)
        : `${location.pathname}/${mast.id}`

      navigate(newLocation + searchPath)
      return
    }
    const newLocation = location.pathname.includes(`${mast.id}`)
      ? location.pathname
      : `${location.pathname}/${mast.id}`

    navigate(newLocation + searchPath)
  }, [isLoading, mast])

  return (
    <Select<Masts>
      className='pf-u-v5-m-none'
      itemKeyName='id'
      itemValueName='name'
      selected={mast}
      items={allMasts}
      onChange={setActiveMast}
      isLoading={isLoading}
    />
  )
}

export default SelectMasts
