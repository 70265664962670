import { WindnessYearly } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const ChartWindnessYearly = ({
  yearlyData,
  revision,
  isLoading,
}: {
  yearlyData?: WindnessYearly[]
  revision: number
  isLoading: boolean
}) => {
  if (isLoading) return null

  const chartData = yearlyData?.slice(yearlyData?.length - 20, yearlyData?.length)
  const years = chartData?.map(yd => yd.yyyy)

  const data = [
    {
      ...defaultAxis,
      x: years,
      y: chartData?.map(yd => yd?.p10),
      name: 'P10',
      marker: { color: COLORS.azul_pantone },
      line: { dash: 'dot', width: 2 },
    },
    {
      ...defaultAxis,
      x: years,
      y: chartData?.map(yd => yd.delta),
      name: 'Desvio anual',
      type: 'bar',
      marker: { color: COLORS.verde },
    },
    {
      ...defaultAxis,
      x: years,
      y: chartData?.map(yd => yd.p90),
      name: 'P90',
      line: { dash: 'dot', width: 3 },
      marker: { color: COLORS.azul_meia_noite },
    },
  ]
  const layout = {
    template: layoutTemplate,
    margin: { b: 60, t: 10, l: 50, r: 20 },
    yaxis: {
      range: [-13, 13],
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      title: 'Delta [%]',
      fixedrange: true,
    },
    xaxis: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      type: 'category',
      fixedrange: true,
      title: 'Ano',
    },
  }
  const style = {
    width: '100%',
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '2rem' }),
  }

  const config = {
    ...defaultConfig,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
    ],
  }

  return (
    <div className='pf-v5-u-my-sm-on-sm'>
      <Plot
        key={`plot_anual_${revision}`}
        data={data}
        layout={layout}
        config={config}
        style={style}
        revision={revision}
        useResizeHandler
      />
    </div>
  )
}

export default ChartWindnessYearly
