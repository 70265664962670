import { Button, Flex, FlexItem, Modal, ModalVariant } from '@patternfly/react-core'

interface IProps {
  isModalOpen: boolean
}

function ModalSessionExpired(props: IProps) {
  const { isModalOpen } = props

  const handleLogOut = () => {
    window.location.href = '/login'
  }

  return (
    <Modal
      variant={ModalVariant.small}
      title='Sessão expirada'
      titleIconVariant='warning'
      aria-label='sessão expirada'
      className='session-modal'
      id='session-modal'
      isOpen={isModalOpen}
      disableFocusTrap
      showClose={false}
    >
      Sua sessão expirou. Faça o login novamente.
      <Flex key='session-flex-action' style={{ width: '100%' }}>
        <FlexItem align={{ default: 'alignRight' }}>
          <Button
            key='new-login'
            variant='primary'
            id='session-modal-button'
            onClick={handleLogOut}
            className='green'
          >
            Fazer login
          </Button>
        </FlexItem>
      </Flex>
    </Modal>
  )
}

export default ModalSessionExpired
