import { Text } from '@patternfly/react-core'

import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import Plot from '../shared/Plot'
import plotConfigButtons from './plotConfigButtons'
import plotTemplate from './plotTemplate'

import { AvailabilityTurbineMonthly, Turbines } from '../../client'

interface IProps {
  dataChart: AvailabilityTurbineMonthly[]
  yearSelected: number
  monthSelected: number
  turbs: Turbines[]
  isLoading: boolean
  revision: number
}

const ChartAvailableWindTurbinesMonthly = (props: IProps) => {
  const { dataChart, yearSelected, monthSelected, turbs, isLoading, revision } = props

  if (isLoading || dataChart.length === 0) return null

  const filteredData = dataChart
    .filter(el => el.yyyy === yearSelected && el.mm === monthSelected)
    .sort((a, b) => a.turb_id - b.turb_id)

  const availabilityData = filteredData.map(e => e.available)
  const bopParadaData = filteredData.map(e => e.bop_parada)
  const bopRestricData = filteredData.map(e => e.bop_restric)
  const turbineParadaData = filteredData.map(e => e.turbine_parada)
  const turbineDerateData = filteredData.map(e => e.turbine_derate)

  const turbNames = turbs.map(t => t.name)
  const middle = Math.floor(turbNames.length / 2)
  const chartAxes = {
    x1: turbNames.slice(0, middle),
    x2: turbNames.slice(middle, turbNames.length),
    arrAvailableDatax1: availabilityData.slice(0, middle),
    arrAvailableDatax2: availabilityData.slice(middle, turbNames.length),
    arrBopParadaDatax1: bopParadaData.slice(0, middle),
    arrBopParadaDatax2: bopParadaData.slice(middle, turbNames.length),
    arrBopRestricDatax1: bopRestricData.slice(0, middle),
    arrBopRestricDatax2: bopRestricData.slice(middle, turbNames.length),
    arrTurbineParadaDatax1: turbineParadaData.slice(0, middle),
    arrTurbineParadaDatax2: turbineParadaData.slice(middle, turbNames.length),
    arrTurbineDerateDatax1: turbineDerateData.slice(0, middle),
    arrTurbineDerateDatax2: turbineDerateData.slice(middle, turbNames.length),
  }

  const style = {
    display: 'none',
    width: '100%',
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '3.5rem' }),
  }

  const dataCima = [
    {
      x: chartAxes.x1,
      y: chartAxes.arrAvailableDatax1,
      name: 'Disponível',
      type: 'bar',
      marker: {
        color: COLORS.verde,
      },
    },
    {
      x: chartAxes.x1,
      y: chartAxes.arrBopParadaDatax1,
      name: 'BOP parada',
      type: 'bar',
      marker: {
        color: COLORS.amarelo_pantone,
      },
    },
    {
      x: chartAxes.x1,
      y: chartAxes.arrBopRestricDatax1,
      name: 'BOP restrição ONS',
      type: 'bar',
      marker: {
        color: COLORS.laranja_international,
      },
    },
    {
      x: chartAxes.x1,
      y: chartAxes.arrTurbineParadaDatax1,
      name: 'Turbina parada',
      type: 'bar',
      marker: {
        color: COLORS.roxo_indigo,
      },
    },
    {
      x: chartAxes.x1,
      y: chartAxes.arrTurbineDerateDatax1,
      name: 'Turbina derate',
      type: 'bar',
      marker: {
        color: COLORS.azul_pantone,
      },
    },
  ]
  const dataBaixo = [
    {
      x: chartAxes.x2,
      y: chartAxes.arrAvailableDatax2,
      name: 'Disponível',
      type: 'bar',
      showlegend: false,
      marker: {
        color: COLORS.verde,
      },
    },
    {
      x: chartAxes.x2,
      y: chartAxes.arrBopParadaDatax2,
      name: 'BOP parada',
      type: 'bar',
      showlegend: false,
      marker: {
        color: COLORS.amarelo_pantone,
      },
    },
    {
      x: chartAxes.x2,
      y: chartAxes.arrBopRestricDatax2,
      name: 'BOP restrição ONS',
      type: 'bar',
      showlegend: false,
      marker: {
        color: COLORS.laranja_international,
      },
    },
    {
      x: chartAxes.x2,
      y: chartAxes.arrTurbineParadaDatax2,
      name: 'Turbina parada',
      type: 'bar',
      showlegend: false,
      marker: {
        color: COLORS.roxo_indigo,
      },
    },
    {
      x: chartAxes.x2,
      y: chartAxes.arrTurbineDerateDatax2,
      name: 'Turbina derate',
      type: 'bar',
      showlegend: false,
      marker: {
        color: COLORS.azul_pantone,
      },
    },
  ]
  const dataVertical = [
    {
      type: 'bar',
      name: 'Disponível',
      x: availabilityData.reverse(),
      y: turbNames.reverse(),
      orientation: 'h',
      marker: {
        color: COLORS.verde,
      },
    },
    {
      type: 'bar',
      name: 'BOP parada',
      x: bopParadaData.reverse(),
      y: turbNames.reverse(),
      orientation: 'h',
      marker: {
        color: COLORS.amarelo_pantone,
      },
    },
    {
      type: 'bar',
      name: 'BOP restrição ONS',
      x: bopRestricData.reverse(),
      y: turbNames.reverse(),
      orientation: 'h',
      marker: {
        color: COLORS.laranja_international,
      },
    },
    {
      type: 'bar',
      name: 'Turbina parada',
      x: turbineParadaData.reverse(),
      y: turbNames.reverse(),
      orientation: 'h',
      marker: {
        color: COLORS.roxo_indigo,
      },
    },
    {
      type: 'bar',
      name: 'Turbina derate',
      x: turbineDerateData.reverse(),
      y: turbNames.reverse(),
      orientation: 'h',
      marker: {
        color: COLORS.azul_pantone,
      },
    },
  ]
  const layoutCima = {
    template: plotTemplate,
    autosize: true,
    barmode: 'stack',
    yaxis: { fixedrange: true },
    xaxis: {
      fixedrange: true,
      automargin: true,
      tickangle: -90,
      tickfont: { size: 13 },
    },
    margin: { b: 0, t: 0, r: 0, l: 20 },
    legend: {
      itemclick: false,
      itemdoubleclick: false,
      traceorder: 'normal',
      y: '1.1',
      font: { size: 11 },
    },
  }
  const layoutBaixo = {
    template: plotTemplate,
    autosize: true,
    barmode: 'stack',
    yaxis: {
      fixedrange: true,
    },
    xaxis: {
      fixedrange: true,
      automargin: true,
      tickangle: -90,
      tickfont: {
        size: 13,
      },
    },
    margin: {
      b: 10,
      t: 0,
      r: 0,
      l: 20,
    },
  }
  const config = {
    displaylogo: false,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toImage',
    ],
  }
  const styleVertical = {
    display: 'block',
    width: '100%',
    height: `${turbNames.length * 30}px`,
  }
  const layoutVertical = {
    autosize: true,
    template: plotTemplate,
    hovermode: 'y unified',
    barmode: 'stack',
    yaxis: {
      range: [-0.5, turbNames.length - 0.5],
      fixedrange: true,
      automargin: true,
    },
    xaxis: {
      range: [0, 100],
      fixedrange: true,
      tickfont: {
        size: 13,
      },
    },
    legend: {
      traceorder: 'normal',
      x: '0.4',
      y: `${turbNames.length > 40 ? 1.015 : 1.04}`,
      font: {
        size: 10.5,
      },
    },
    margin: {
      b: 20,
      t: 50,
      r: 0,
      l: 0,
    },
  }

  return (
    <div className='pf-v5-u-mt-0'>
      <Text component='h2' className='no-bold'>
        Disponibilidade energética dos aerogeradores
      </Text>
      <Plot
        divId='ChartAvailableWindTurbinesMonthly_0'
        className='pf-v5-u-display-block-on-md'
        style={style}
        data={dataCima}
        layout={layoutCima}
        config={config}
        useResizeHandler
        key={`ChartTurbinesMonthly_0_${revision}`}
      />
      <Plot
        divId='ChartAvailableWindTurbinesMonthly_1'
        className='pf-v5-u-display-block-on-md pf-v5-u-mt-sm'
        style={style}
        data={dataBaixo}
        layout={layoutBaixo}
        config={config}
        useResizeHandler
        key={`ChartTurbinesMonthly_1_${revision}`}
      />
      <Plot
        divId='ChartAvailableWindTurbinesMonthly_2'
        className='pf-v5-u-display-none-on-md'
        data={dataVertical}
        layout={layoutVertical}
        config={plotConfigButtons}
        useResizeHandler
        style={styleVertical}
        key={`ChartTurbinesMonthly_2_${revision}`}
      />
    </div>
  )
}

export default ChartAvailableWindTurbinesMonthly
