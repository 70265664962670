/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlarmHours } from '../models/AlarmHours';
import type { AlarmTurbs } from '../models/AlarmTurbs';
import type { AlarmView } from '../models/AlarmView';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlarmsAPI {

  /**
   * Get All
   * @returns AlarmView Successful Response
   * @throws ApiError
   */
  public static getAll({
    siteId,
    tsIn,
    tsFin,
    turbId,
    limit = 150,
    offset,
  }: {
    siteId: number,
    tsIn: string,
    tsFin: string,
    turbId?: (number | null),
    limit?: number,
    offset?: number,
  }): CancelablePromise<AlarmView> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/alarms',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'turb_id': turbId,
        'limit': limit,
        'offset': offset,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Extended
   * @returns AlarmView Successful Response
   * @throws ApiError
   */
  public static getExtended({
    siteId,
    tsIn,
    tsFin,
    turbId,
    limit = 150,
    offset,
  }: {
    siteId: number,
    tsIn: string,
    tsFin: string,
    turbId?: (number | null),
    limit?: number,
    offset?: number,
  }): CancelablePromise<AlarmView> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/extended_alarms',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'turb_id': turbId,
        'limit': limit,
        'offset': offset,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Hours Summary
   * @returns AlarmHours Successful Response
   * @throws ApiError
   */
  public static getHoursSummary({
    siteId,
    tsIn,
    tsFin,
    turbId,
  }: {
    siteId: number,
    tsIn: string,
    tsFin: string,
    turbId?: number,
  }): CancelablePromise<Array<AlarmHours>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/alarms/hours',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'turb_id': turbId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbs Summary
   * @returns AlarmTurbs Successful Response
   * @throws ApiError
   */
  public static getTurbsSummary({
    siteId,
    tsIn,
    tsFin,
    turbId,
  }: {
    siteId: number,
    tsIn: string,
    tsFin: string,
    turbId?: number,
  }): CancelablePromise<Array<AlarmTurbs>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/alarms/turbines',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'turb_id': turbId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Alarm Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAlarmReport({
    siteId,
    turbId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    turbId?: number,
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/alarms/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'turb_id': turbId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
