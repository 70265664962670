const calcVerticalHeight = ({
  gridSize = 1,
  elementSize = 1,
  spacerSize = 'md',
  pgHasFilter = true,
  // calcVerticalHeight distribui igualmente o offset entre os elementos
  customOffset = '0rem',
}: {
  gridSize?: number
  elementSize?: number
  spacerSize?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
  pgHasFilter?: boolean
  customOffset?: string | number
}) => {
  const spacers = {
    no: '0rem',
    xs: 'var(--pf-v5-global--spacer--xs)',
    sm: 'var(--pf-v5-global--spacer--sm)',
    md: 'var(--pf-v5-global--spacer--md)',
    lg: 'var(--pf-v5-global--spacer--lg)',
    xl: 'var(--pf-v5-global--spacer--xl)',
    '2xl': 'var(--pf-v5-global--spacer--2xl)',
    '3xl': 'var(--pf-v5-global--spacer--3xl)',
    '4xl': 'var(--pf-v5-global--spacer--4xl)',
  }
  const spacer = spacers[spacerSize]

  const telao = sessionStorage.getItem('telao')
  const header = telao === 'on' ? '0rem' : 'var(--a7-header--height)'
  const pageContentPadding = telao === 'on' ? spacers.xl : spacers.lg
  const filterBar = pgHasFilter ? 'var(--a7-filter-bar--height)' : '0rem'
  const _customOffset = typeof customOffset === 'number' ? `${customOffset}rem` : customOffset

  return `
    calc((
      100vh -
      ${_customOffset} -
      ${spacers.xs} -
      ${header} -
      ${filterBar} -
      ${spacer} -
      (${pageContentPadding})*2
    ) / ${gridSize / elementSize})`
}

export default calcVerticalHeight
