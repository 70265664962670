import { Page as BasePage, PageSection } from '@patternfly/react-core'
import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { Buffer } from 'buffer'
import { ReactNode, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IPagesMaintenance, PagesAPI } from '../../../client'
import { getToken } from '../../../services/auth'
import ErrorPage from './ErrorPage'
import FilterBar from './FilterBar'
import Header from './Header'
import LoaderBar from './LoaderBar'
import LoaderSpinner from './LoaderSpinner'
import Maintenance from './Maintenance'
import ModalHelp from './ModalHelp'
import ModalSessionExpired from './ModalSessionExpired'
import Sidebar from './Sidebar'

const Page = ({
  pageName,
  children,
  filters,
  siteName,
  siteId,
  isNavOpen,
  setNavOpen,
  isLoading = false,
  helpContent,
  isContentLoading = false,
}: {
  pageName: string
  children: ReactNode
  filters?: ReactNode[]
  siteName?: string
  siteId?: number
  isNavOpen: boolean
  setNavOpen: (isNavOpen: boolean) => void
  isLoading?: boolean
  helpContent?: ReactNode
  isContentLoading?: boolean
}) => {
  const [isModalHelpOpen, setModalHelpOpen] = useState(false)

  const handleModalHelpToggle = () => setModalHelpOpen(!isModalHelpOpen)

  const [isModalSessionOpen, setModalSessionOpen] = useState(false)

  const token = getToken()

  useEffect(() => {
    if (!token) return
    const buffer = Buffer.from(token.split('.')[1], 'base64')
    const { exp } = JSON.parse(buffer.toString())
    const expiry = new Date(exp * 1000)
    const dtNow = new Date()
    const timeout = expiry.getTime() - dtNow.getTime() - 1000 * 60 * 5
    const timer = setTimeout(() => {
      setModalSessionOpen(true)
    }, timeout)
    return () => clearTimeout(timer)
  }, [token])

  useEffect(() => {
    document.title = `A7 | ${pageName} ${siteName ?? ''}`
  }, [siteName])

  const [searchParams] = useSearchParams()
  const searchObj = Object.fromEntries(searchParams)
  let telao = searchObj?.telao as string | null
  if (telao === 'on') {
    sessionStorage.setItem('telao', telao)
  } else {
    telao = sessionStorage.getItem('telao')
  }

  let content

  const { isLoading: maintIsLoading, data: maintenancePages } = useQuery(
    ['maintenancePages'],
    PagesAPI.getMaintenance,
    { refetchOnWindowFocus: true, retry: false, staleTime: 1000 * 60 * 10 }
  )
  let maint: IPagesMaintenance | undefined

  if (siteId !== undefined && maintenancePages && maintenancePages.length > 0) {
    maint = maintenancePages.find(m => m.page_name === pageName && m.site_id === siteId)
  }

  if ((isLoading || maintIsLoading) && !maint) {
    content = <LoaderSpinner />
  } else if (maint) {
    content = <Maintenance message={maint.description} />
  } else {
    content = children
  }

  let header
  let sidebar
  let telaoWrapperClass
  if (telao === 'on') {
    header = <></>
    sidebar = <></>
    telaoWrapperClass = 'pf-v5-u-m-md'
  } else {
    header = (
      <Header
        isNavOpen={isNavOpen}
        setNavOpen={setNavOpen}
        handleModalHelpToggle={handleModalHelpToggle}
        helpContent={helpContent}
      />
    )
    sidebar = (
      <Sidebar
        handleModalHelpToggle={handleModalHelpToggle}
        isNavOpen={isNavOpen}
        helpContent={helpContent}
      />
    )
    telaoWrapperClass = ''
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <>
        <BasePage header={header} sidebar={sidebar}>
          <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
            <PageSection
              variant='light'
              padding={{ default: 'noPadding' }}
              stickyOnBreakpoint={{ default: 'top' }}
            >
              <FilterBar filters={filters} />
            </PageSection>
            <LoaderBar isLoading={isContentLoading} />
            <PageSection className={telaoWrapperClass}>{content}</PageSection>
          </Sentry.ErrorBoundary>
        </BasePage>

        <ModalSessionExpired isModalOpen={isModalSessionOpen} />

        <ModalHelp
          isModalOpen={isModalHelpOpen}
          handleModalToggle={handleModalHelpToggle}
          helpContent={helpContent}
        />
      </>
    </Sentry.ErrorBoundary>
  )
}

export default Page
