/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IPagesMaintenance } from '../models/IPagesMaintenance';
import type { Pages } from '../models/Pages';
import type { PagesMaintenance } from '../models/PagesMaintenance';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PagesAPI {

  /**
   * Get All
   * @returns Pages Successful Response
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<Pages>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/pages',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Get Maintenance
   * @returns IPagesMaintenance Successful Response
   * @throws ApiError
   */
  public static getMaintenance(): CancelablePromise<Array<IPagesMaintenance>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/pages/maintenance',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Update Maintenance
   * @returns PagesMaintenance Successful Response
   * @throws ApiError
   */
  public static updateMaintenance({
    requestBody,
  }: {
    requestBody: PagesMaintenance,
  }): CancelablePromise<PagesMaintenance> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/pages/maintenance',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Create Maintenance
   * @returns PagesMaintenance Successful Response
   * @throws ApiError
   */
  public static createMaintenance({
    requestBody,
  }: {
    requestBody: PagesMaintenance,
  }): CancelablePromise<PagesMaintenance> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/pages/maintenance',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Delete Maintenance
   * @returns string Successful Response
   * @throws ApiError
   */
  public static deleteMaintenance({
    pmId,
  }: {
    pmId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/admin/pages/maintenance/{pm_id}',
      path: {
        'pm_id': pmId,
      },
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

}
