import { Divider, Split, SplitItem, Stack, StackItem, Title } from '@patternfly/react-core'
import useVertical from '../../hooks/useVertical'
import CalendarDate from './CalendarDate'
import CalendarDatetime from './CalendarDatetime'
import CalendarMonth from './CalendarMonth'

interface ICalendarDatetimeRangeProps {
  startDate: Date
  endDate: Date
  onChangeStart: (newDate: Date) => void
  onChangeEnd: (newDate: Date) => void
  startLabel?: string
  endLabel?: string
  variant?: 'date' | 'datetime' | 'month'
  minEnabled?: Date
  maxEnabled?: Date
  validators?: ((date: Date) => boolean)[]
  allowEqualDates?: boolean
}

const CalendarDatetimeRange = ({
  startDate,
  endDate,
  onChangeStart,
  onChangeEnd,
  startLabel,
  endLabel,
  variant = 'datetime',
  minEnabled,
  maxEnabled,
  validators,
  allowEqualDates,
}: ICalendarDatetimeRangeProps) => {
  const isVertical = useVertical()
  const Wrapper = isVertical ? Stack : Split
  const WrapperItem = isVertical ? StackItem : SplitItem
  const CalendarComponent = {
    date: CalendarDate,
    datetime: CalendarDatetime,
    month: CalendarMonth,
  }[variant]

  return (
    <Wrapper>
      <WrapperItem>
        {startLabel && (
          <Title className='pf-v5-u-mb-0 pf-v5-u-mx-md' headingLevel='h2'>
            {startLabel}
          </Title>
        )}
        <CalendarComponent
          date={startDate}
          onChange={(_, dt) => onChangeStart(dt)}
          endDate={endDate}
          minEnabled={minEnabled}
          maxEnabled={maxEnabled}
          validators={validators}
          allowEqualDates={allowEqualDates}
        />
      </WrapperItem>
      {isVertical ? (
        <WrapperItem>
          <Divider className='pf-v5-u-my-md' />
        </WrapperItem>
      ) : (
        <WrapperItem style={{ borderLeft: '0.1rem solid lightgray' }}> </WrapperItem>
      )}
      <WrapperItem>
        {endLabel && (
          <Title className='pf-v5-u-mb-0 pf-v5-u-mx-md' headingLevel='h2'>
            {endLabel}
          </Title>
        )}
        <CalendarComponent
          date={endDate}
          onChange={(_, dt) => onChangeEnd(dt)}
          startDate={startDate}
          minEnabled={minEnabled}
          maxEnabled={maxEnabled}
          validators={validators}
          allowEqualDates={allowEqualDates}
        />
      </WrapperItem>
    </Wrapper>
  )
}

export default CalendarDatetimeRange
