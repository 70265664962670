import { Button, Divider, Panel, PanelHeader } from '@patternfly/react-core'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'

import { IPagesMaintenance, PagesAPI, PagesMaintenance } from '../../client'
import EntityTable from '../../components/EntityTable'
import SitesContext from '../../contexts/SitesContext'
import { queryClient, QUERY_OPTIONS } from '../../services/api'

import { ActionState } from '../../components/actionState'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import PageFrame from '../shared/Page'
import { MAINTENANCE_PAGES_QUERY_KEY, PAGES_QUERY_KEY } from './constants'
import ModalPageMaintenance from './ModalPageMaintenance'

function PagManutencao() {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const { sites, isLoading: sitesLoading } = useContext(SitesContext)

  const maintenance = useQuery(MAINTENANCE_PAGES_QUERY_KEY, PagesAPI.getMaintenance, QUERY_OPTIONS)
  const pages = useQuery(PAGES_QUERY_KEY, PagesAPI.getAll, QUERY_OPTIONS)

  const isLoading = sitesLoading || maintenance.isLoading || pages.isLoading

  const [description, setDescription] = useState<PagesMaintenance['description']>(null)
  const [selectedSite, setSelectedSite] = useState<PagesMaintenance['site_id']>(1)
  const [selectedPage, setSelectedPage] = useState<PagesMaintenance['page_id']>('')
  const [selectedMainId, setSelectedMainId] = useState<PagesMaintenance['pm_id']>()
  const [selectDelete, setSelectDelete] = useState<IPagesMaintenance>()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [modalType, setModalType] = useState('create')
  const [actionState, setActionState] = useState<ActionState>('idle')

  const handleModalToggle = ({
    desc = '',
    selSite = sites[0]?.site_id,
    selPage = '',
    mdlType = 'create',
    selPM,
  }: {
    desc?: string | null
    selSite?: number
    selPage?: string
    mdlType?: string
    selPM?: string
  } = {}) => {
    setModalType(mdlType)
    setDescription(desc)
    setSelectedSite(selSite)
    setSelectedPage(selPage)
    setSelectedMainId(selPM)
    setModalOpen(!isModalOpen)
    setActionState('idle')
  }

  const _MUTATE_ON_SUCCESS = () => {
    setActionState('success')
    queryClient.invalidateQueries({ queryKey: MAINTENANCE_PAGES_QUERY_KEY })
  }

  const _MUTATE_ON_MUTATE = () => setActionState('pending')
  const _MUTATE_ON_ERROR = () => setActionState('failure')

  const handleEdit = useMutation(PagesAPI.updateMaintenance, {
    onSuccess: _MUTATE_ON_SUCCESS,
    onMutate: _MUTATE_ON_MUTATE,
    onError: _MUTATE_ON_ERROR,
  })
  const handleCreate = useMutation(PagesAPI.createMaintenance, {
    onSuccess: _MUTATE_ON_SUCCESS,
    onMutate: _MUTATE_ON_MUTATE,
    onError: _MUTATE_ON_ERROR,
  })
  const handleDelete = useMutation(PagesAPI.deleteMaintenance, {
    onSuccess: _MUTATE_ON_SUCCESS,
    onMutate: _MUTATE_ON_MUTATE,
    onError: _MUTATE_ON_ERROR,
  })

  const modalInfo = {
    edit: {
      title: 'Editar',
      callback: handleEdit,
      startSeleted: {
        page: pages?.data?.find(p => p.page_id === selectedPage)?.page_id,
        site: selectedSite,
      },
    },
    create: {
      title: 'Criar',
      callback: handleCreate,
      startSeleted: { page: '', site: '' },
    },
  }[modalType]

  return (
    <PageFrame
      pageName='Páginas em Manutenção'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <Button
          key='criar-manutencao'
          variant='control'
          className='pf-v5-u-m-sm'
          onClick={() => handleModalToggle()}
        >
          Criar Manutenção
        </Button>,
      ]}
    >
      <div className='pf-v5-u-box-shadow-md'>
        <Panel>
          <PanelHeader>
            <b>Páginas em Manutenção</b>
          </PanelHeader>
          <Divider />
          <EntityTable<IPagesMaintenance>
            items={maintenance?.data || []}
            itemKeyName='pm_id'
            isCompact
            columnConfig={[
              { key: 'page_name', description: 'Página' },
              { key: 'site_name', description: 'Site' },
              { key: 'description', description: 'Descrição' },
            ]}
            onClickEdit={item => {
              handleModalToggle({
                desc: item.description,
                selSite: item.site_id,
                mdlType: 'edit',
                selPage: item.page_id,
                selPM: item.pm_id as string,
              })
            }}
            onClickDelete={item => {
              setSelectedMainId(item.pm_id as string)
              setIsDeleteModalOpen(true)
              setSelectDelete(item)
            }}
          />
        </Panel>
      </div>
      {isModalOpen && (
        <ModalPageMaintenance
          description={description}
          handleModalToggle={handleModalToggle}
          isModalOpen={isModalOpen}
          modalInfo={modalInfo}
          pages={pages?.data}
          selectedMainId={selectedMainId}
          selectedPage={selectedPage}
          selectedSite={selectedSite}
          setDescription={setDescription}
          setSelectedPage={setSelectedPage}
          setSelectedSite={setSelectedSite}
          sites={sites}
          actionState={actionState}
        />
      )}
      <ModalConfirmationWarning<IPagesMaintenance>
        title='Remover Manutenção'
        isOpen={isDeleteModalOpen}
        handleModalToggle={() => setIsDeleteModalOpen(prev => !prev)}
        handleSubmit={() => handleDelete.mutate({ pmId: selectedMainId as string })}
        alertMessage='Tem certeza que deseja remover a manutenção?'
        actionState={actionState}
        element={selectDelete as IPagesMaintenance}
        additionalInformation={
          <EntityTable<IPagesMaintenance>
            items={[selectDelete as IPagesMaintenance]}
            itemKeyName='pm_id'
            isCompact
            columnConfig={[
              { key: 'page_name', description: 'Página' },
              { key: 'site_name', description: 'Site' },
              { key: 'description', description: 'Descrição' },
            ]}
          />
        }
      />
    </PageFrame>
  )
}

export default PagManutencao
