import * as localeDictionary from 'plotly.js/lib/locales/pt-br.js'
import { TurbinesHealthSummary } from '../../../client'
import { formatDateMonthStr } from '../../../utils/formatDate'
import Plot from '../../shared/Plot'

interface IHeatmapPlotSummaryProps {
  testTitle?: string
  dataHeatmap: TurbinesHealthSummary[] | undefined
}

const HeatmapPlotSummary = (props: IHeatmapPlotSummaryProps) => {
  const { dataHeatmap, testTitle } = props

  if (!dataHeatmap) return null

  const colorscaleValue = [
    [0.0, '#00d45c'],
    [0.5, '#F7CF00'],
    [1.0, '#b20c1d'],
  ]

  const [x, y, z] = [Array(), Array(), Array()]

  dataHeatmap.forEach(item => {
    x.push(formatDateMonthStr(new Date(item.ts)))
    y.push(item.test)
    z.push(item.val)
  })

  const xHeatmap = [...new Set(x)]
  const yHeatmap = [...new Set(y)]

  const zHeatmap = yHeatmap
    .map((_, i) => {
      return z.slice(i * xHeatmap.length, (i + 1) * xHeatmap.length)
    })
    .reverse()

  const dataPlot = [
    {
      z: zHeatmap,
      x: xHeatmap,
      y: yHeatmap,
      xgap: 0.5,
      ygap: 0.5,
      type: 'heatmap',
      hoverongaps: true,
      colorscale: colorscaleValue,
      zmin: 0,
      zmax: 100,
      colorbar: {
        len: 1.04,
        outlinecolor: '#fff',
      },
      showscale: false,
      hovertemplate: 'Data: %{x} <br>Test: %{y} <br>Value: %{z}',
    },
  ]

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'normal',
      }}
    >
      <p style={{ minWidth: '230px', textAlign: 'left' }}>{testTitle}</p>
      <Plot
        data={dataPlot}
        className='pf-v5-u-mx-2xl-on-lg plot'
        style={{
          height: '60px',
          width: '100%',
          paddingLeft: '18px',
          minWidth: '450px',
        }}
        layout={{
          autosize: true,
          dragmode: false,
          paper_bgcolor: '#00000000',
          plot_bgcolor: '#00000000',
          hoverlabel: {
            bgcolor: '#FFF',
            tickformat: '%d %b',
            namelength: 30,
            font: { size: 14 },
          },
          margin: { l: 0, r: 0, b: 15, t: 15, pad: 5 },
          yaxis: {
            automargin: true,
            fixedrange: true,
            tickfont: { size: 13 },
            showticklabels: false,
          },
          xaxis: {
            visible: false,
            fixedrange: true,
            type: 'category',
          },
        }}
        useResizeHandler
        config={{
          responsive: true,
          displaylogo: false,
          displayModeBar: false,
          locales: { 'pt-BR': localeDictionary },
          locale: 'pt-BR',
        }}
      />
    </div>
  )
}

export default HeatmapPlotSummary
