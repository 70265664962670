import { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Site } from '../../../client/models/Site'
import Select from '../../../components/selects/Select'
import SitesContext from '../../../contexts/SitesContext'

const SelectSite = ({
  onChange,
  enableUrlParams,
  overwriteQueryParams,
}: {
  enableUrlParams?: boolean
  overwriteQueryParams?: boolean
  onChange?: (site: Site) => void
}) => {
  const { isLoading, site, sites, setActiveSite } = useContext(SitesContext)

  const { siteName } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!enableUrlParams || isLoading) return

    const searchPath = overwriteQueryParams ? '' : location.search

    if (siteName && site.site.toLowerCase() !== siteName.toLowerCase()) {
      const newLocation = location.pathname.includes(siteName)
        ? location.pathname.replace(siteName, site.site.toLowerCase())
        : `${location.pathname}/${site.site.toLowerCase()}`

      navigate(newLocation + searchPath)
      return
    }
    const newLocation = location.pathname.includes(site.site.toLowerCase())
      ? location.pathname
      : `${location.pathname}/${site.site.toLowerCase()}`

    navigate(newLocation + searchPath)
  }, [isLoading, site])

  const handleChange = (site: Site) => {
    if (onChange !== undefined) onChange(site)
    setActiveSite(site.site_id)
  }

  return (
    <Select<Site>
      selected={site}
      items={sites}
      itemKeyName='site_id'
      itemValueName='site_name'
      onChange={handleChange}
      isLoading={isLoading}
    />
  )
}

export default SelectSite
