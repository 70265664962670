import { Divider, Panel, PanelHeader, PanelMain } from '@patternfly/react-core'
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import ColorScale from '../../utils/colorScale'

const columns = ['Mês', 'Realizado [m/s]', 'P50 [m/s]', 'Desvio [%]', 'Prob. Excedência [%]']

const TableMonthlyResume = ({ rows, title }: { rows: (string | number)[][]; title: string }) => {
  return (
    <Panel
      style={{
        minHeight: '15rem',
        background: ColorScale.branco,
      }}
      className='pf-v5-u-my-sm'
    >
      <PanelHeader>
        <b>{title}</b>
      </PanelHeader>
      <Divider />
      <PanelMain>
        <Table variant='compact' aria-label='container-table-windness-mensal'>
          <Thead>
            <Tr>
              {columns.map((col, i) => (
                <Th modifier='wrap' key={String(col) + '_' + String(i)}>
                  {col}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, idx) => (
              <Tr key={JSON.stringify(row) + String(idx)}>
                {row.map((col, colIdx) => (
                  <Td key={String(col) + '_' + String(colIdx)} dataLabel={columns[colIdx]}>
                    {col}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PanelMain>
    </Panel>
  )
}

export default TableMonthlyResume
