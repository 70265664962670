import CriticidadeMuitoBaixo from '../assets/img/1-muito-baixo.png'
import CriticidadeBaixo from '../assets/img/2-baixo.png'
import CriticidadeMedio from '../assets/img/3-medio.png'
import CriticidadeAlto from '../assets/img/4-alto.png'

const iconSize = 50

export interface ICriticidade {
  id?: number
  name?: string
  icon?: string
  url?: string
  height?: number
  width?: number
}
export type ICriticidadeNivel = '0' | '1' | '2' | '3' | '4'

export type ICriticidadeGroup = {
  readonly [key in ICriticidadeNivel]: ICriticidade
}

const criticidadesNames: ICriticidadeGroup = {
  '0': {
    id: 0,
    name: 'Todas Criticidades',
    icon: CriticidadeMuitoBaixo,
    url: CriticidadeMuitoBaixo,
    height: iconSize,
    width: iconSize,
  },
  '1': {
    id: 1,
    name: 'Muito Baixo',
    icon: CriticidadeBaixo,
    url: CriticidadeBaixo,
    height: iconSize,
    width: iconSize,
  },
  '2': {
    id: 2,
    name: 'Baixo',
    icon: CriticidadeBaixo,
    url: CriticidadeBaixo,
    height: iconSize,
    width: iconSize,
  },
  '3': {
    id: 3,
    name: 'Medio',
    icon: CriticidadeMedio,
    url: CriticidadeMedio,
    height: iconSize,
    width: iconSize,
  },
  '4': {
    id: 4,
    name: 'Alto',
    icon: CriticidadeAlto,
    url: CriticidadeAlto,
    height: iconSize,
    width: iconSize,
  },
}

export default criticidadesNames
