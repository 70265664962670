import { ProductionTurbDataSignals as Signals, Site, Turbines } from '../../client'
import { rotatePoint } from '../../utils/geometry'
import { IPlotAnnotation, IPlotSelectedDataPoints } from '../../utils/plotly'
import { getFilterParams } from '../../utils/url'

enum ACTIONS {
  INITIALIZE,
  SWITCH_SITE,
  SET_SELECTED_TURBS,
  SET_SELECTED_SIGNALS,
  SET_SELECTED_START_DATE,
  SET_SELECTED_END_DATE,
  SET_LAYOUT,
  SET_SCATTER_ANNOTATIONS,
  SET_TIMESERIES_ANNOTATIONS,
  SET_MAX_TURBS,
  SET_MAX_SIGNALS,
  TRIGGER_FETCH,
  TRIGGER_TIMESERIES_ANNOTATION_ROTATION,
  TRIGGER_SCATTER_ANNOTATION_ROTATION,
  TOGGLE_SCATTER_ANNOTATION_MODE,
  TOGGLE_TIMESERIES_ANNOTATION_MODE,
  TOGGLE_TIMESERIES_SELECTION_MODE,
  TOGGLE_EXPERIMENTAL_MODE,
}

const DEFAULTS = {
  MAX_TURBS: 2,
  MAX_SIGNALS: 3,
  MAX_EXPERIMENTAL_TURBS: 2,
  MAX_EXPERIMENTAL_SIGNALS: 3,
  LAYOUT: 1,
  SITE_ID: 1,
  TURBS_SELECTED: [1],
  START_DATE: new Date(new Date().getTime() - 8 * 86400000),
  END_DATE: new Date(new Date().getTime() - 1 * 86400000),
}

export interface State {
  site: Site
  sites: Site[]
  turbs: Turbines[]
  siteTurbs: Turbines[]
  turbsSelected: number[]
  signals: Signals[]
  siteSignals: Signals[]
  signalsSelected: number[]
  startDate: Date
  endDate: Date
  startDateSelected: Date
  endDateSelected: Date
  layout: number
  scatterPlot: {
    annotations: IPlotAnnotation[]
    annotationMode: boolean
  }
  timeseriesPlot: {
    annotations: IPlotAnnotation[]
    annotationMode: boolean
    selectionMode: boolean
  }
  selectedDataPoints: IPlotSelectedDataPoints[]
  maxTurbs: number
  maxSignals: number
  experimentalMode: boolean
}

export const DEFAULT_STATE: State = {
  site: {} as Site,
  sites: new Array<Site>(),
  turbs: new Array<Turbines>(),
  siteTurbs: new Array<Turbines>(),
  turbsSelected: DEFAULTS.TURBS_SELECTED,
  signals: new Array<Signals>(),
  siteSignals: new Array<Signals>(),
  signalsSelected: [],
  startDate: DEFAULTS.START_DATE,
  endDate: DEFAULTS.END_DATE,
  startDateSelected: DEFAULTS.START_DATE,
  endDateSelected: DEFAULTS.END_DATE,
  layout: DEFAULTS.LAYOUT,
  scatterPlot: {
    annotations: [],
    annotationMode: false,
  },
  timeseriesPlot: {
    annotations: [],
    annotationMode: false,
    selectionMode: false,
  },
  selectedDataPoints: [],
  maxTurbs: DEFAULTS.MAX_TURBS,
  maxSignals: DEFAULTS.MAX_SIGNALS,
  experimentalMode: false,
}
export interface IAction {
  type: ACTIONS
  payload: Partial<State>
}

export const reducer = (_state: State | object, action: IAction): State => {
  const state = _state as State
  if (Object.keys(_state).length === 0) {
    console.error('Store ainda não inicializado execute ACTIONS.INITIALIZE')
    return state
  }

  // Para não precisar checar novamente em cada action
  if (
    ACTIONS.INITIALIZE !== action.type &&
    (!state?.site || !state?.siteTurbs || !state?.siteSignals)
  ) {
    console.error('Store ainda não inicializado completamente')
    return state
  }

  const turbIdParams = getFilterParams<Turbines>('turb_id')
  const signalIdParams = getFilterParams<Signals>('signal_id')
  const layoutParams = getFilterParams('layout')
  const period = getFilterParams('period')

  let startDataParams: Date = DEFAULT_STATE.startDate
  let endDateParams: Date = DEFAULT_STATE.endDate
  if (period.length >= 2) {
    startDataParams = new Date(period[0])
    endDateParams = new Date(period[1])
  }
  if (
    startDataParams > endDateParams ||
    startDataParams > new Date() ||
    endDateParams > new Date()
  ) {
    startDataParams = DEFAULT_STATE.startDate
    endDateParams = DEFAULT_STATE.endDate
  }

  const { type, payload } = action
  /*eslint no-case-declarations: 0*/
  switch (type) {
    case ACTIONS.INITIALIZE:
      if (!payload.site || !payload.siteTurbs || !payload.siteSignals) {
        console.error('Payload inválido para ACTIONS.INITIALIZE')
        return state
      }

      let initSignalsSelected: number[]
      if (signalIdParams.length > 0) {
        initSignalsSelected = signalIdParams
      } else if (payload?.signalsSelected) {
        initSignalsSelected = payload.signalsSelected
      } else {
        initSignalsSelected = DEFAULT_STATE.signalsSelected
      }

      let initTurbSelected: number[]
      if (turbIdParams.length > 0) {
        initTurbSelected = turbIdParams
      } else if (payload?.turbsSelected) {
        initTurbSelected = payload.turbsSelected
      } else {
        initTurbSelected = DEFAULT_STATE.turbsSelected
      }

      return {
        ...DEFAULT_STATE,
        ...payload,
        signalsSelected: initSignalsSelected,
        turbsSelected: initTurbSelected,
        experimentalMode:
          signalIdParams.length > DEFAULT_STATE.maxSignals ||
          turbIdParams.length > DEFAULT_STATE.maxTurbs,
        maxSignals:
          signalIdParams.length > DEFAULT_STATE.maxSignals
            ? signalIdParams.length
            : DEFAULT_STATE.maxSignals,
        maxTurbs:
          turbIdParams.length > DEFAULT_STATE.maxTurbs
            ? turbIdParams.length
            : DEFAULT_STATE.maxTurbs,
        layout: layoutParams[0] ?? DEFAULT_STATE.layout,
        startDate: startDataParams,
        endDate: endDateParams,
        startDateSelected: startDataParams,
        endDateSelected: endDateParams,
      }

    case ACTIONS.SWITCH_SITE:
      return {
        ...state,
        startDate: DEFAULT_STATE.startDate,
        endDate: DEFAULT_STATE.endDate,
        startDateSelected: DEFAULT_STATE.startDate,
        endDateSelected: DEFAULT_STATE.endDate,
      }
    /* ----- ----- */
    case ACTIONS.SET_SELECTED_TURBS:
      if (!payload.turbsSelected) {
        console.error('Payload inválido para ACTIONS.SET_SELECTED_TURBS')
        return state
      }
      return { ...state, turbsSelected: payload.turbsSelected }

    case ACTIONS.SET_SELECTED_SIGNALS:
      if (!payload.signalsSelected) {
        console.error('Payload inválido para ACTIONS.SET_SELECTED_SIGNALS')
        return state
      }

      return { ...state, signalsSelected: payload.signalsSelected }

    case ACTIONS.SET_SELECTED_START_DATE:
      if (!payload.startDateSelected) {
        console.error('Payload inválido para ACTIONS.SET_SELECTED_START_DATE')
        return state
      }

      return { ...state, startDateSelected: payload.startDateSelected }

    case ACTIONS.SET_SELECTED_END_DATE:
      if (!payload.endDateSelected) {
        console.error('Payload inválido para ACTIONS.SET_SELECTED_END_DATE')
        return state
      }

      return { ...state, endDateSelected: payload.endDateSelected }

    case ACTIONS.SET_LAYOUT:
      if (!payload?.layout) {
        console.error('Payload inválido para ACTIONS.SET_LAYOUT')
        return state
      }

      return { ...state, layout: payload.layout }

    case ACTIONS.SET_SCATTER_ANNOTATIONS:
      if (!payload?.scatterPlot) {
        console.error('Payload inválido para ACTIONS.SET_SCATTER_ANNOTATIONS')
        return state
      }

      return { ...state, scatterPlot: payload.scatterPlot }

    case ACTIONS.SET_TIMESERIES_ANNOTATIONS:
      if (!payload?.timeseriesPlot) {
        console.error('Payload inválido para ACTIONS.SET_TIMESERIES_ANNOTATIONS')
        return state
      }

      return { ...state, timeseriesPlot: payload.timeseriesPlot }

    case ACTIONS.SET_MAX_TURBS:
      if (!payload?.maxTurbs) {
        console.error('Payload inválido para ACTIONS.SET_MAX_TURBS')
        return state
      }

      return { ...state, maxTurbs: payload.maxTurbs }

    case ACTIONS.SET_MAX_SIGNALS:
      if (!payload?.maxSignals) {
        console.error('Payload inválido para ACTIONS.SET_MAX_SIGNALS')
        return state
      }

      return { ...state, maxSignals: payload.maxSignals }

    case ACTIONS.TRIGGER_FETCH:
      return {
        ...state,
        startDate: state.startDateSelected,
        endDate: state.endDateSelected,
        turbs: state.siteTurbs.filter(t => state.turbsSelected.includes(t.turb_id)),
        signals: state.siteSignals.filter(s => state.signalsSelected.includes(s.signal_id)),
      }

    case ACTIONS.TRIGGER_TIMESERIES_ANNOTATION_ROTATION:
      return {
        ...state,
        timeseriesPlot: {
          ...state.timeseriesPlot,
          annotations: state.timeseriesPlot.annotations.map(an => {
            const { ax, ay } = an
            const { x, y } = rotatePoint(ax, ay, -45)
            return { ...an, ax: x, ay: y }
          }),
        },
      }

    case ACTIONS.TRIGGER_SCATTER_ANNOTATION_ROTATION:
      return {
        ...state,
        scatterPlot: {
          ...state.scatterPlot,
          annotations: state.scatterPlot.annotations.map(an => {
            const { ax, ay } = an
            const { x, y } = rotatePoint(ax, ay, -45)
            return { ...an, ax: x, ay: y }
          }),
        },
      }

    case ACTIONS.TOGGLE_SCATTER_ANNOTATION_MODE:
      return {
        ...state,
        scatterPlot: {
          annotationMode: !state.scatterPlot.annotationMode,
          annotations: [],
        },
      }

    case ACTIONS.TOGGLE_TIMESERIES_ANNOTATION_MODE:
      return {
        ...state,
        timeseriesPlot: {
          annotationMode: !state.timeseriesPlot.annotationMode,
          annotations: [],
          selectionMode: state.timeseriesPlot.selectionMode,
        },
      }

    case ACTIONS.TOGGLE_TIMESERIES_SELECTION_MODE:
      return {
        ...state,
        timeseriesPlot: {
          annotationMode: state.timeseriesPlot.annotationMode,
          annotations: state.timeseriesPlot.annotations,
          selectionMode: !state.timeseriesPlot.selectionMode,
        },
      }

    case ACTIONS.TOGGLE_EXPERIMENTAL_MODE:
      if (state.experimentalMode) {
        return {
          ...state,
          maxTurbs: DEFAULTS.MAX_TURBS,
          maxSignals: DEFAULTS.MAX_SIGNALS,
          experimentalMode: false,
        }
      }

      return {
        ...state,
        maxTurbs: DEFAULTS.MAX_EXPERIMENTAL_TURBS,
        maxSignals: DEFAULTS.MAX_EXPERIMENTAL_SIGNALS,
        experimentalMode: true,
      }

    default:
      return state
  }
}
export { ACTIONS }
