import {
  Button,
  DualListSelector,
  FlexItem,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextInput,
} from '@patternfly/react-core'
import { useMutation } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { UserIn, UserOut, UsersAPI, UserUpdate } from '../../client'
import { ActionState, requestStateMessages } from '../../components/actionState'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import { SetStateGeneric } from '../../utils/SetStateGeneric'
import {
  dataValidator,
  DefaultOptions,
  getAvailableOptions,
  getIdList,
  mutateOptions,
  OPTIONS_DEFAULT,
} from './constants'

function ModalUser({
  isModalOpen,
  handleModalToggle,
  instanceDefault,
  setInstanceDefault,
  type,
}: {
  isModalOpen: boolean
  handleModalToggle: () => void
  instanceDefault?: UserOut | undefined
  setInstanceDefault?: SetStateGeneric<UserOut | undefined>
  type: 'create' | 'edit'
}) {
  const { groups } = useContext(PermissionContext)
  const { sites } = useContext(SitesContext)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [userNameValue, setUserNameValue] = useState<string>('')
  const [userEmailValue, setUserEmailValue] = useState<string>('')
  const [passwordValue, setPasswordValue] = useState<string>('')

  const [groupsOptions, setGroupsOptions] = useState<DefaultOptions>(OPTIONS_DEFAULT)
  const [sitesOptions, setSitesOptions] = useState<DefaultOptions>(OPTIONS_DEFAULT)

  const setGroupIdList = (chosenOptions: string[]) => {
    const groupsIdList: string[] = []

    groups?.forEach(g => {
      chosenOptions?.forEach(el => {
        if (el === g?.name) groupsIdList.push(g.id)
      })
    })

    return groupsIdList
  }

  const setSiteIdsList = (chosenOptions: string[]) => {
    const sitesIdList: number[] = []

    sites?.forEach(g => {
      chosenOptions?.forEach(el => {
        if (el === g?.site_name && g?.site_id) sitesIdList.push(g.site_id)
      })
    })

    return sitesIdList
  }

  const onGroupsListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setGroupsOptions({ availableOptions: newAvailableOptions, chosenOptions: newChosenOptions })
  }
  const onSitesListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setSitesOptions({ availableOptions: newAvailableOptions, chosenOptions: newChosenOptions })
  }

  const onMutate = () => setActionState('pending')
  const onError = () => setActionState('failure')

  const { mutate: createUser } = useMutation({
    mutationFn: (body: UserIn) => UsersAPI.create({ requestBody: body }),
    onMutate,
    onError,
    onSuccess: () => {
      mutateOptions.onSuccess()
      setActionState('success')
    },
  })

  const { mutate: updateUser } = useMutation({
    mutationFn: (body: UserUpdate) => UsersAPI.update({ requestBody: body }),
    onMutate,
    onError,
    onSuccess: () => {
      mutateOptions.onSuccess()
      setActionState('success')
    },
  })

  function handleSubmit() {
    const _body = {
      id: instanceDefault?.id || undefined,
      disabled: instanceDefault ? instanceDefault?.disabled : false,
      username: userNameValue?.length > 0 ? userNameValue : instanceDefault?.username,
      email: userEmailValue?.length > 0 ? userEmailValue : instanceDefault?.email,
      password: passwordValue?.length > 0 ? passwordValue : undefined,
    }

    if (type === 'create') {
      const body = {
        ..._body,
        group_ids: setGroupIdList(groupsOptions.chosenOptions),
        site_ids: setSiteIdsList(sitesOptions.chosenOptions),
      } as UserIn

      if (dataValidator(body)) createUser(body)
      else {
        console.log('[ModalUser.tsx - handleSubmit - create] Dados inválidos')
        console.log(JSON.stringify(body))
      }
    }

    if (type === 'edit') {
      if (!instanceDefault) {
        console.error('[ModalUser.tsx - handleSubmit - update] instanceDefault is undefined')
        return
      }
      const body = {
        ..._body,
        group_ids: getIdList<string>(groups, groupsOptions.chosenOptions, 'name', 'id'),
        site_ids: getIdList<number>(sites, sitesOptions.chosenOptions, 'site_name', 'site_id'),
      } as UserUpdate

      if (dataValidator(body)) updateUser(body)
      else {
        console.log('[ModalUser.tsx - handleSubmit - update] Dados inválidos')
        console.log(JSON.stringify(body))
      }
    }
  }

  useEffect(() => {
    if (groups?.length && type === 'create') {
      setGroupsOptions({ availableOptions: groups?.map(g => g.name), chosenOptions: [] })
    }
  }, [groups, type])

  useEffect(() => {
    if (sites?.length && type === 'create') {
      setSitesOptions({ availableOptions: sites?.map(s => s.site_name), chosenOptions: [] })
    }
  }, [sites, type])

  useEffect(() => {
    if (instanceDefault && type === 'edit') {
      setUserNameValue(instanceDefault.username)
      setUserEmailValue(instanceDefault.email)
      const sitesNames = instanceDefault.site_ids.map(el => {
        const found = sites.find(a => a.site_id === el)
        return found?.site_name
      }) as string[]
      setSitesOptions({
        availableOptions: getAvailableOptions(sites, sitesNames, 'site_name'),
        chosenOptions: sitesNames,
      })

      setGroupsOptions({
        availableOptions: getAvailableOptions(groups, instanceDefault.group_ids, 'name'),
        chosenOptions: instanceDefault.group_names,
      })
    } else {
      setUserNameValue('')
      setUserEmailValue('')
    }
  }, [instanceDefault, type])

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
        setInstanceDefault && setInstanceDefault(undefined)
        setActionState('idle')
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      variant={ModalVariant.small}
      title={`${type === 'create' ? 'Criar' : 'Editar'} usuário`}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button key='create' variant='primary' form='modal-with-form-form' onClick={handleSubmit}>
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      {actionState !== 'idle' && (
        <FlexItem flex={{ default: 'flex_1' }} alignSelf={{ default: 'alignSelfCenter' }}>
          {requestStateMessages()[actionState]}
        </FlexItem>
      )}
      <Form id='modal-with-form-form'>
        <FormGroup label='Nome' isRequired fieldId='simple-form-name-01'>
          <TextInput
            isRequired
            type='text'
            id='simple-form-name-01'
            name='simple-form-name-01'
            value={userNameValue}
            onChange={(_, value) => setUserNameValue(value)}
          />
        </FormGroup>
        <FormGroup label='Senha' isRequired fieldId='simple-form-password-01'>
          <TextInput
            isRequired
            type='password'
            id='simple-form-senha-01'
            name='simple-form-senha-01'
            autoComplete='new-password'
            value={passwordValue}
            onChange={(_, value) => setPasswordValue(value)}
          />
        </FormGroup>
        <FormGroup label='Email' isRequired fieldId='simple-form-email-01'>
          <TextInput
            isRequired
            type='email'
            id='simple-form-email-01'
            name='simple-form-email-01'
            autoComplete='new-email'
            value={userEmailValue}
            onChange={(_, value) => setUserEmailValue(value)}
          />
        </FormGroup>

        <DualListSelector
          availableOptionsTitle='Grupos'
          availableOptions={groupsOptions.availableOptions.sort()}
          chosenOptionsTitle='Grupos selecionados'
          chosenOptions={groupsOptions.chosenOptions.sort()}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onGroupsListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='DualListSelectorGroups'
        />
        <DualListSelector
          availableOptionsTitle='Sites'
          availableOptions={sitesOptions.availableOptions.sort()}
          chosenOptionsTitle='Sites selecionados'
          chosenOptions={sitesOptions.chosenOptions.sort()}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onSitesListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='DualListSelectorSites'
        />
      </Form>
    </Modal>
  )
}

export default ModalUser
