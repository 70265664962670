import {
  Alert,
  Button,
  Grid,
  GridItem,
  Modal,
  Split,
  SplitItem,
  Text,
  TextArea,
  TextContent,
  TextVariants,
  ValidatedOptions,
} from '@patternfly/react-core'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Alarms,
  DowntimeAero,
  DowntimeAPI,
  DowntimeComponentes,
  DowntimeTipos,
  Turbines,
} from '../../client'
import { ActionState, requestStateMessages } from '../../components/actionState'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import Empty from '../../components/Empty'
import Select from '../../components/selects/Select'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { toISOString } from '../../utils/formatDate'
import {
  CACHE_KEY_WTG,
  Responsabilidade,
  RESPONSABILIDADES,
  TABLE_HEADER_HEIGHT,
} from './constants'
import PlotExpectedEnergy from './PlotExpectedEnergy'
import { formatAlarmDate } from './TableAero'
import useData, { keyFromDate } from './useData'
interface DowntimeTiposSelect extends DowntimeTipos {
  label_categoria: string
}

const MODAL_WRAPPER_STYLE = {
  minHeight: '20rem',
  height: calcVerticalHeight({ customOffset: 3, pgHasFilter: false }),
}

const ModalAero = ({
  isModalOpen,
  handleModalToggle,
  eventSelected,
  selectedDate,
  alarms,
  handleDeleteClick,
  modalConfirmation,
}: {
  isModalOpen: boolean
  handleModalToggle: () => void
  eventSelected?: DowntimeAero
  selectedDate: Date
  alarms?: Alarms[]
  handleDeleteClick?: (item: string) => void
  modalConfirmation: React.ReactNode
}) => {
  const { turbs, site } = useContext(SitesContext)
  const { me } = useContext(PermissionContext)
  const { componentes, tipos, dataIsLoading } = useData(selectedDate)
  const isMobile = useMobile()

  const tiposSelect: DowntimeTiposSelect[] = useMemo(() => {
    return tipos
      .filter(tipo => tipo.tipo === 'AERO')
      .map(tipo => {
        return { ...tipo, label_categoria: tipo.categoria }
      })
  }, [tipos, dataIsLoading])

  const minDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
  const maxDate = new Date(selectedDate.getFullYear(), 11, 30)

  const defaultPeriod = { tsIn: minDate, tsFin: maxDate }

  const [tsIn, setTsIn] = useState<Date>(
    eventSelected?.ts_in ? new Date(eventSelected.ts_in) : defaultPeriod.tsIn
  )
  const [tsFin, setTsFin] = useState<Date>(
    eventSelected?.ts_fin ? new Date(eventSelected.ts_fin) : defaultPeriod.tsFin
  )
  const [aero, setAero] = useState<Turbines>(
    turbs.find(t => t.turb_id === eventSelected?.turb_id) ?? turbs[0]
  )
  const [selectedTipo, setSelectedTipo] = useState(
    tiposSelect.find(t => t.id === eventSelected?.tipo_id) ?? tiposSelect[0]
  )
  const [selectedComponent, setSelectedComponent] = useState(
    componentes.find(t => t.id === eventSelected?.component_id) ?? componentes[0]
  )
  const [description, setDescription] = useState<string | null>(eventSelected?.descricao ?? '')

  const [responsabilidade, setResponsabilidade] = useState<Responsabilidade>(RESPONSABILIDADES[1])
  const [alertActive, setAlertActive] = useState(false)
  const [isError406, setIsError406] = useState(false)
  const [requestState, setRequestState] = useState<ActionState>('idle')
  const handleSubmit = async () => {
    if (description === '') {
      setAlertActive(true)
      return
    }
    setRequestState('pending')
    const requestBody = {
      ts_in: toISOString(tsIn),
      ts_fin: toISOString(tsFin),
      turb_id: aero.turb_id,
      tipo_id: selectedTipo.id,
      component_id: selectedComponent.id,
      descricao: description,
      username: me.current_user.username,
      id: eventSelected?.id,
      responsabilidade_re: responsabilidade.id,
    }
    const requestMethod = eventSelected
      ? DowntimeAPI.updateDowntimeAero
      : DowntimeAPI.createDowntimeAero

    try {
      await requestMethod({ siteId: site.site_id, requestBody })
    } catch (error: any) {
      console.log(error.status)
      setIsError406(error.status === 406)
      setRequestState('failure')
      setAlertActive(true)
      return
    }
    setRequestState('success')

    queryClient.invalidateQueries([CACHE_KEY_WTG, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const closeModal = () => {
    setTsIn(defaultPeriod.tsIn)
    setTsFin(defaultPeriod.tsFin)
    setAlertActive(false)
    setIsError406(false)
    setRequestState('idle')
    handleModalToggle()
    setResponsabilidade(RESPONSABILIDADES[1])
  }

  useEffect(() => {
    if (requestState === 'success') {
      const timerCloseModalAero = setTimeout(() => {
        closeModal()
      }, 750)
      return () => clearTimeout(timerCloseModalAero)
    }
  }, [requestState])

  useEffect(() => {
    setAlertActive(false)
    setRequestState('idle')
  }, [description, tsIn, tsFin, aero, selectedTipo, selectedComponent])

  useEffect(() => {
    if (!eventSelected) {
      setSelectedTipo(tiposSelect[1])
      setAero(turbs[0])
      setSelectedComponent(componentes[0])
      setTsIn(defaultPeriod.tsIn)
      setTsFin(defaultPeriod.tsFin)
      setDescription('')
      setResponsabilidade(RESPONSABILIDADES[1])
      return
    }
    setSelectedTipo(tiposSelect.find(t => t.id === eventSelected.tipo_id) ?? tiposSelect[1])
    setSelectedComponent(
      componentes.find(t => t.id === eventSelected.component_id) ?? componentes[0]
    )
    setDescription(eventSelected?.descricao ?? '')
    setAero(turbs.find(t => t.turb_id === eventSelected.turb_id) ?? turbs[0])
    setResponsabilidade(
      RESPONSABILIDADES.find(r => eventSelected?.responsabilidade_re === r.id) ??
        RESPONSABILIDADES[1]
    )
    if (eventSelected?.ts_in && new Date(eventSelected.ts_in).getTime() !== tsIn.getTime())
      setTsIn(new Date(eventSelected.ts_in))

    if (eventSelected?.ts_fin && new Date(eventSelected.ts_fin).getTime() !== tsFin.getTime())
      setTsFin(new Date(eventSelected.ts_fin))
  }, [eventSelected, dataIsLoading, isModalOpen])

  return (
    <Modal
      width='95%'
      aria-label='expected-energy-modal'
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      id='ModalEnergyExpected'
      showClose={false}
      actions={[
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <Split hasGutter>
            <SplitItem>
              <Button variant='primary' onClick={handleSubmit}>
                Salvar
              </Button>
            </SplitItem>
            <SplitItem>
              <Button variant='secondary' isDanger onClick={closeModal}>
                Cancelar
              </Button>
            </SplitItem>
          </Split>
          {handleDeleteClick && eventSelected && (
            <Button
              variant='danger'
              isDanger
              onClick={() => {
                handleDeleteClick(eventSelected?.id as string)
              }}
            >
              Deletar
            </Button>
          )}
        </div>,
      ]}
    >
      {modalConfirmation}
      <div style={MODAL_WRAPPER_STYLE}>
        <Grid hasGutter>
          <GridItem md={12} lg={5}>
            <GridItem>
              <TextContent className='pf-v5-u-mb-md'>
                <Text component={TextVariants.h1}>Cadastro Paradas Aerogerador</Text>
                <Text component={TextVariants.pre}>{eventSelected?.id}</Text>
              </TextContent>
            </GridItem>
            <GridItem>
              {requestStateMessages()[requestState]}
              {alertActive && (
                <GridItem lg={12}>
                  {tsIn > tsFin && (
                    <Alert
                      variant='danger'
                      className='pf-v5-u-my-sm'
                      isInline
                      title='Data inicial deve ser menor que data final'
                    />
                  )}
                  {description === '' && (
                    <Alert
                      variant='danger'
                      className='pf-v5-u-my-sm'
                      isInline
                      title='Preencha o campo de Descrição'
                    />
                  )}
                  {isError406 && (
                    <Alert
                      variant='danger'
                      isInline
                      className='pf-v5-u-my-sm'
                      title='Já existe um evento com essas características'
                    />
                  )}
                </GridItem>
              )}
            </GridItem>
            <GridItem>
              <Grid hasGutter>
                <GridItem lg={6}>
                  <span>Aero</span>
                  <Select<Turbines>
                    items={turbs}
                    itemValueName={'name'}
                    itemKeyName={'turb_id'}
                    selected={aero}
                    onChange={setAero}
                    selectProps={{ menuAppendTo: 'parent' }}
                    enableFilter
                    placeholderText='Aero'
                  />
                </GridItem>
                <GridItem lg={6}>
                  <span>Responsabilidade</span>
                  <Select<Responsabilidade>
                    items={RESPONSABILIDADES}
                    itemValueName={'nome'}
                    itemKeyName={'id'}
                    selected={responsabilidade}
                    onChange={setResponsabilidade}
                    selectProps={{ menuAppendTo: 'parent' }}
                  />
                </GridItem>
                <GridItem lg={12}>
                  <span>Período</span>
                  <DatetimeRangePicker
                    value={{ startDate: tsIn, endDate: tsFin }}
                    onChange={(newStart: Date, newEnd: Date) => {
                      setTsIn(newStart)
                      setTsFin(newEnd)
                    }}
                    variant='label'
                    calendarVariant='datetime'
                    minEnabled={minDate}
                    maxEnabled={maxDate}
                    allowEqualDates
                  />
                </GridItem>
                <GridItem lg={6}>
                  <span>Tipo</span>
                  <Select<DowntimeTiposSelect>
                    items={tiposSelect}
                    itemValueName={'label_categoria'}
                    itemKeyName={'id'}
                    selected={selectedTipo}
                    enableFilter
                    selectProps={{ menuAppendTo: 'parent' }}
                    onChange={setSelectedTipo}
                  />
                </GridItem>
                <GridItem lg={6}>
                  <span>Componente</span>
                  <Select<DowntimeComponentes>
                    items={componentes}
                    itemValueName={'component'}
                    itemKeyName={'id'}
                    selected={selectedComponent}
                    enableFilter
                    onChange={setSelectedComponent}
                    selectProps={{ menuAppendTo: 'parent' }}
                  />
                </GridItem>
                <GridItem lg={12}>
                  <span>Descrição</span>
                  <TextArea
                    placeholder='Descrição'
                    height={100}
                    type='text'
                    aria-label='description input field'
                    value={description ?? ''}
                    onChange={(_, text) => setDescription(text)}
                    resizeOrientation='vertical'
                    validated={
                      description === '' && alertActive
                        ? ValidatedOptions.error
                        : ValidatedOptions.default
                    }
                  />
                </GridItem>
                <GridItem lg={12}>
                  <span>Alarmes</span>
                  <OuterScrollContainer
                    className='pf-v5-u-box-shadow-md pf-v5-u-my-sm'
                    style={{ maxHeight: '20rem' }}
                  >
                    <InnerScrollContainer>
                      {/* TODO: Table atual com comportamento muito especifico em ações. Não implementado "EntityTable" */}
                      <Table aria-label='Alarms Table' variant='compact' isStickyHeader>
                        <Thead>
                          <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                            <Th>Inicio</Th>
                            <Th>Fim</Th>
                            <Th modifier='nowrap'>Duração [min]</Th>
                            <Th>Alarme</Th>
                            <Th>Descrição</Th>
                            <Th>Ações</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {alarms && alarms.length > 0 ? (
                            alarms.map(a => {
                              return (
                                <Tr
                                  key={`${a.turb_id}_${a.site_id}_${a.error}_${a.ts_fin}_${a.ts_in}`}
                                >
                                  <Td dataLabel='Inicio'>{formatAlarmDate(a.ts_in)}</Td>
                                  <Td dataLabel='Fim'>{formatAlarmDate(a.ts_fin)}</Td>
                                  <Td dataLabel='Duração'>{a.dur_min}</Td>
                                  <Td dataLabel='Alarme'>{a.error}</Td>
                                  <Td dataLabel='Descrição'>{a.description}</Td>
                                  <Td dataLabel='Ações'>
                                    <Button
                                      variant='secondary'
                                      onClick={() => {
                                        if (!eventSelected) return
                                        const descricao = ` ${description} Alarme: '${a.error}'  Descrição: '${a.description}' `
                                        setDescription(descricao)
                                      }}
                                    >
                                      Utilizar
                                    </Button>
                                  </Td>
                                </Tr>
                              )
                            })
                          ) : (
                            <Tr>
                              <Th colSpan={6}>
                                <Empty title='Sem alarmes no período selecionado' />
                              </Th>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                    </InnerScrollContainer>
                  </OuterScrollContainer>
                </GridItem>
              </Grid>
            </GridItem>
          </GridItem>
          {isMobile ? null : (
            <GridItem md={12} lg={7}>
              <PlotExpectedEnergy
                event={{
                  aero: aero?.name || '',
                  categoria: '',
                  componente: '',
                  inicio: tsIn.toISOString(),
                  fim: tsFin.toISOString(),
                  usuario: '',
                  turb_id: aero?.turb_id,
                  responsabilidade_re: responsabilidade.id,
                  descricao: description,
                  tipo_id: selectedTipo?.id,
                  ts_in: tsIn.toISOString(),
                  ts_fin: tsFin.toISOString(),
                  component_id: selectedComponent?.id,
                  username: me.current_user.username,
                }}
                tsIn={tsIn}
                tsFin={tsFin}
                style={{ width: '100%', height: calcVerticalHeight({ customOffset: 3 }) }}
              />
            </GridItem>
          )}
        </Grid>
      </div>
    </Modal>
  )
}

export default ModalAero
