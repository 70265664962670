import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { AlarmTurbs, Turbines } from '../../client'
import ColorScale from '../../utils/colorScale'

interface IProps {
  dataTable: AlarmTurbs[]
  turbs: Turbines[]
  style: { height: string }
  EmptyComponent: React.ReactNode
}

const columns = ['Nº Ocorrências', 'WTG', 'Nº Alarme', 'Descrição']

const TableAlarmsTurbines = (props: IProps) => {
  const { dataTable, turbs, style, EmptyComponent } = props

  const getTurbName = (id: number) => {
    const findTurb = turbs.find(el => el.turb_id === id)
    if (findTurb) return findTurb.name
    return '-'
  }
  return (
    <div style={{ backgroundColor: ColorScale.branco }}>
      <OuterScrollContainer style={style} className='pf-v5-u-box-shadow-sm'>
        <InnerScrollContainer>
          <Table variant='compact' aria-label='container-table-alarms-turbs' isStickyHeader>
            <Thead>
              <Tr>
                {columns.map(col => (
                  <Th modifier='wrap' key={col}>
                    {col}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {dataTable.map(el => (
                <Tr key={`${el.turb_id}_${el.error}`}>
                  <Td dataLabel={columns[0]}>{Math.floor(el.alarm_count)}</Td>
                  <Td dataLabel={columns[1]}>{getTurbName(el.turb_id)}</Td>
                  <Td dataLabel={columns[2]}>{el.error}</Td>
                  <Td dataLabel={columns[3]}>{el.description}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {dataTable.length === 0 && EmptyComponent}
        </InnerScrollContainer>
      </OuterScrollContainer>
    </div>
  )
}

export default TableAlarmsTurbines
