import { Button, Panel, Stack, StackItem, Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { range } from 'd3-array'
import { useContext, useMemo, useState } from 'react'
import { DowntimeAero, DowntimeBop, DowntimeONS, Masts } from '../../client'
import Select from '../../components/selects/Select'
import MastContext, { MastProvider, Sensors } from '../../contexts/MastContext'
import Page from '../shared/Page'
import Plot from '../shared/Plot'

const ComponentTest = () => {
  const { mast, allMasts, setActiveMast } = useContext(MastContext)
  const [isNavOpen, setNavOpen] = useState(true)

  const data = [
    //pwrExp
    {
      x: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'], // todo período
      y: [15, 14, 13, 12, 11, 10, 9, 8, 7], // valores da linha verde
      mode: 'lines',
      name: 'Linha Verde',
      line: { color: 'green' },
    },
    //pwr
    {
      x: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'], // todo período
      y: [7, 8, 9, 10, 11, 12, 13, 14, 15], // valores da linha azul
      mode: 'lines',
      name: 'Linha Azul',
      line: { color: 'blue', dash: 'dot' },
    },
    // [12,13,14,15] período que quero hachurar
    //hachura onde pwr < pwrExp
    {
      // Combinação dos pontos para preencher a área entre as linhas
      x: ['12:00', '13:00', '14:00', '15:00', '16:00', '16:00', '15:00', '14:00', '13:00', '12:00'], // Fechando o loop
      y: [11, 10, 9, 8, 7, 15, 14, 13, 12, 11], // Linha verde + Linha azul (reverso)
      fill: 'toself',
      fillpattern: {
        shape: '/', // Padrão de hachura
        size: 10,
      },
      opacity: 0.5,
      line: { color: 'rgba(0,0,0,0)' }, // Linha invisível
      name: 'Área Hachurada',
    },
  ]

  const layout = {
    title: 'Gráfico com Hachura entre Linhas',
    xaxis: { title: 'Hora' },
    yaxis: { title: 'Valores' },
  }

  return (
    <Page
      pageName='Component Test'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isContentLoading={false}
      isLoading={false}
      filters={[
        <Select<Masts>
          className='pf-u-v5-m-none'
          key='filter-mast'
          itemKeyName='id'
          itemValueName='name'
          selected={mast}
          items={allMasts}
          onChange={setActiveMast}
        />,
      ]}
    >
      <Plot data={data} layout={layout} />
    </Page>
  )
}

const Wrapper = () => (
  <MastProvider>
    <ComponentTest />
  </MastProvider>
)

export default Wrapper
