import {
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Stack,
  TextInput,
} from '@patternfly/react-core'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import semImagem from '../../assets/img/semimagem.png'
import { CivilOcorrencia, OccurrencesAPI, Site } from '../../client'
import { ActionState, requestStateMessages } from '../../components/actionState'
import { queryClient, REACT_APP_API_URL } from '../../services/api'
import { hasValue } from '../../utils/hasValue'
import { getOcorrenciasQueryKey } from './constants'
import EditImage from './EditImage'

export const ModalEditarOcorrencia = ({
  isOpenModalEditarOcorrencia,
  setIsOpenModalEditarOcorrencia,
  ocorrencia,
  setOcorrencia,
  site,
}: {
  isOpenModalEditarOcorrencia: boolean
  setIsOpenModalEditarOcorrencia: React.Dispatch<React.SetStateAction<boolean>>
  ocorrencia: CivilOcorrencia
  setOcorrencia: React.Dispatch<React.SetStateAction<CivilOcorrencia | null>>
  site: Site
}) => {
  const [data_origem, setData_origem] = useState(ocorrencia?.data_origem || '')
  const [situacao, setSituacao] = useState(ocorrencia?.situacao || '')
  const [lat, setLat] = useState(ocorrencia?.lat || null)
  const [lon, setLon] = useState(ocorrencia?.lon || null)
  const [natureza_origem, setNatureza_origem] = useState(ocorrencia?.natureza_origem || '')
  const [criticidade, setCriticidade] = useState(ocorrencia?.criticidade || '')
  const [data_relatorio, setData_relatorio] = useState(ocorrencia?.data_relatorio || '')
  const [descricao_ocorrencia, setDescricao_ocorrencia] = useState(
    ocorrencia?.descricao_ocorrencia || ''
  )
  console.log({ lat, lon })
  const imgPath = ocorrencia?.img_path
    ? `${REACT_APP_API_URL}/static/${ocorrencia.img_path}`
    : semImagem

  const [newImageUrl, setNewImageUrl] = useState('')
  const [fileImage, setFileImage] = useState(null)
  const [isSalvarLoading, setIsSalvarLoading] = useState(false)
  const [actionState, setActionState] = useState<ActionState>('idle')
  const [titleFailure, setTitleFailure] = useState('Houve um erro ao atualizar a ocorrência')

  const handleCloseModal = () => {
    setIsOpenModalEditarOcorrencia(false)
    setOcorrencia(null)
    setIsSalvarLoading(false)
    setActionState('idle')
  }

  const { mutate: ocorrenciaFotoMutate } = useMutation({
    mutationFn: OccurrencesAPI.updateImage,
    onSuccess: async () => {
      await queryClient.invalidateQueries(getOcorrenciasQueryKey(site.site_id))
      setActionState('success')
    },
    onError: (error: AxiosError) => {
      console.error(error)
      setIsSalvarLoading(false)
      setTitleFailure('Houve um erro ao atualizar a imagem da ocorrência')
      setActionState('failure')
    },
  })

  const { mutate: ocorrenciaMutate } = useMutation({
    mutationFn: OccurrencesAPI.update,
    onSuccess: async () => {
      if (hasValue(fileImage)) {
        ocorrenciaFotoMutate({
          ocorrenciaId: ocorrencia.id as string,
          formData: { image: fileImage as any },
        })
      } else {
        await queryClient.invalidateQueries(getOcorrenciasQueryKey(site.site_id))
        setActionState('success')
      }
    },
    onError: (error: AxiosError) => {
      console.error(error)
      setIsSalvarLoading(false)
      setTitleFailure('Houve um erro ao atualizar os dados da ocorrência')
      setActionState('failure')
    },
  })

  const handleSubmit = async () => {
    setActionState('pending')
    setIsSalvarLoading(true)

    const requestBody = {
      id: ocorrencia.id as string,
      data_origem,
      situacao,
      lat,
      lon,
      natureza_origem,
      criticidade,
      data_relatorio,
      descricao_ocorrencia,
    }

    if (
      !hasValue(requestBody.data_origem) ||
      !hasValue(requestBody.situacao) ||
      !hasValue(requestBody.lat) ||
      !hasValue(requestBody.lon) ||
      !hasValue(requestBody.natureza_origem) ||
      !hasValue(requestBody.criticidade) ||
      !hasValue(requestBody.data_relatorio) ||
      !hasValue(requestBody.descricao_ocorrencia)
    ) {
      setTitleFailure('Preencha todos os campos obrigatórios')
      setActionState('failure')
      setIsSalvarLoading(false)
      return
    }

    ocorrenciaMutate({ requestBody })
  }

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleCloseModal()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      isOpen={isOpenModalEditarOcorrencia}
      onClose={handleCloseModal}
      variant={ModalVariant.medium}
      title='Editar ocorrência'
      actions={[
        <Flex key='container-flex-action' style={{ width: '100%' }}>
          <FlexItem align={{ default: 'alignRight' }}>
            <Button
              id='salvar-loading-button'
              key='create'
              variant='primary'
              form='modal-with-form-form'
              onClick={handleSubmit}
              className='green'
              spinnerAriaValueText='Loading'
              spinnerAriaLabelledBy='salvar-loading-button'
              isLoading={isSalvarLoading}
              isDisabled={isSalvarLoading}
            >
              {isSalvarLoading ? 'Salvando' : 'Salvar'}
            </Button>
          </FlexItem>
          <FlexItem>
            <Button key='cancel' variant='secondary' isDanger onClick={handleCloseModal}>
              Cancelar
            </Button>
          </FlexItem>
        </Flex>,
      ]}
    >
      <Stack hasGutter>
        {actionState !== 'idle' && (
          <FlexItem flex={{ default: 'flex_1' }} alignSelf={{ default: 'alignSelfCenter' }}>
            {
              requestStateMessages({
                titleSuccess: 'Ocorrência atualizada',
                titleFailure,
              })[actionState]
            }
          </FlexItem>
        )}
        <Form id='modal-with-form-form'>
          <Grid lg={6} hasGutter>
            <GridItem>
              <FormGroup label='Data origem' isRequired fieldId='simple-form-data-origem'>
                <TextInput
                  isRequired
                  type='text'
                  id='data_origem'
                  name='data_origem'
                  {...(ocorrencia?.data_origem && { defaultValue: ocorrencia.data_origem })}
                  onChange={(_event, val) => setData_origem(val)}
                />
              </FormGroup>
            </GridItem>
            <GridItem>
              <FormGroup label='Situação' isRequired fieldId='simple-form-situacao'>
                <TextInput
                  isRequired
                  type='text'
                  id='situacao'
                  name='situacao'
                  {...(ocorrencia?.situacao && { defaultValue: ocorrencia.situacao })}
                  onChange={(_event, val) => setSituacao(val)}
                />
              </FormGroup>
            </GridItem>
          </Grid>
          <Grid hasGutter>
            <GridItem lg={6}>
              <FormGroup label='Latitude' isRequired fieldId='simple-form-latitude'>
                <TextInput
                  isRequired
                  type='text'
                  id='lat'
                  name='lat'
                  {...(ocorrencia?.lat && { defaultValue: ocorrencia.lat })}
                  onChange={(_event, val) => setLat(hasValue(val) ? Number(val) : null)}
                />
              </FormGroup>
            </GridItem>
            <GridItem lg={6} lgRowSpan={5}>
              <FormGroup label='Foto da ocorrência' isRequired fieldId='simple-form-foto'>
                <EditImage
                  imgPath={imgPath}
                  setFileImage={setFileImage}
                  newImageUrl={newImageUrl}
                  setNewImageUrl={setNewImageUrl}
                />
              </FormGroup>
            </GridItem>
            <GridItem lg={6}>
              <FormGroup label='Longitude' isRequired fieldId='simple-form-longitude'>
                <TextInput
                  isRequired
                  type='text'
                  id='lon'
                  name='lon'
                  {...(ocorrencia?.lon && { defaultValue: ocorrencia.lon })}
                  onChange={(_event, val) => setLon(hasValue(val) ? Number(val) : null)}
                />
              </FormGroup>
            </GridItem>
            <GridItem lg={6}>
              <FormGroup
                label='Natureza de origem'
                isRequired
                fieldId='simple-form-natureza-origem'
              >
                <TextInput
                  isRequired
                  type='text'
                  id='natureza_origem'
                  name='natureza_origem'
                  {...(ocorrencia?.natureza_origem && { defaultValue: ocorrencia.natureza_origem })}
                  onChange={(_event, val) => setNatureza_origem(val)}
                />
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label='Criticidade' isRequired fieldId='simple-form-criticidade'>
                <TextInput
                  isRequired
                  type='text'
                  id='criticidade'
                  name='criticidade'
                  {...(ocorrencia?.criticidade && { defaultValue: ocorrencia.criticidade })}
                  onChange={(_event, val) => setCriticidade(val)}
                />
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label='Data relatório' isRequired fieldId='simple-form-longitude'>
                <TextInput
                  isRequired
                  type='text'
                  id='data_relatorio'
                  name='data_relatorio'
                  {...(ocorrencia?.data_relatorio && { defaultValue: ocorrencia.data_relatorio })}
                  onChange={(_event, val) => setData_relatorio(val)}
                />
              </FormGroup>
            </GridItem>
          </Grid>

          <Grid lg={6} hasGutter>
            <GridItem span={12}>
              <FormGroup
                label='Descrição da ocorrência'
                isRequired
                fieldId='simple-form-natureza-origem'
              >
                <TextInput
                  isRequired
                  type='text'
                  id='descricao_ocorrencia'
                  name='descricao_ocorrencia'
                  {...(ocorrencia?.descricao_ocorrencia && {
                    defaultValue: ocorrencia.descricao_ocorrencia,
                  })}
                  onChange={(_event, val) => setDescricao_ocorrencia(val)}
                />
              </FormGroup>
            </GridItem>
          </Grid>
        </Form>
      </Stack>
    </Modal>
  )
}
