import { range } from 'd3-array'
import * as localeDictionary from 'plotly.js/lib/locales/pt-br.js'
import { helpIcon } from '../features/shared/Plot'
import COLORS from './colorScale'

export const defaultAxis = {
  gridcolor: COLORS.cinza_claro,
  linecolor: COLORS.cinza_claro,
  linewidth: 2,
  gridwidth: 2,
  mirror: 'ticks',
  zeroline: false,
  tickfont: { color: COLORS.cinza_escuro },
}

export const defaultConfig = {
  displaylogo: false,
  responsive: true,
  toImageButtonOptions: {
    format: 'svg',
    filename: 'a7-graph',
    scale: 1.2,
  },
  modeBarButtonsToRemove: ['select2d', 'autoScale2d'],
  modeBarButtonsToAdd: [
    {
      name: 'Ajuda',
      icon: helpIcon,
      click: () => window.open('/help-plotly', '_blank'),
    },
  ],
  locales: { 'pt-BR': localeDictionary },
  locale: 'pt-BR',
}

export const defaultLayout = {
  paper_bgcolor: COLORS.branco,
  plot_bgcolor: COLORS.branco_clear,
  showlegend: true,
  hovermode: 'x unified',
  autosize: true,
  margin: { b: 50, t: 50, l: 50, r: 50 },
  legend: {
    orientation: 'h',
    yanchor: 'middle',
    xanchor: 'center',
    x: '0.5',
    y: '1.1',
  },
  yaxis: defaultAxis,
  xaxis: defaultAxis,
}

range(2, 20).forEach(i => {
  const overrides = { ...defaultAxis, gridcolor: COLORS.branco }
  Object.assign(defaultLayout, { [`xaxis${i}`]: overrides, [`yaxis${i}`]: overrides })
})

export const layoutTemplate = { layout: defaultLayout }

export interface IPlotAnnotation {
  text: string
  ax: number
  ay: number
  bordercolor: string
  borderwidth: number
  bgcolor: string
  borderpad: number
  font: {
    color: string
  }
  yref: string
  x: string | number
  y: string | number
}

export interface IPlotData {
  y: string
  x: string
  name: string
  mode: string
  signalX: {
    signal: string
  }
  signalY: {
    signal: string
  }
  marker: {
    color: string
  }
}

export interface IPlotDataPoints {
  data: IPlotData
  y: number
  curveNumber: number
  x: string
  pointIndex: number
  yaxis: {
    _id: string
  }
}

export interface IPlotSelectedDataPoints {
  pointIndex: number
  x: string
  y: number
  curveNumber: number
  name: string
}
