import { Stack, StackItem } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { index } from 'd3-array'
import { useContext, useMemo, useState } from 'react'
import { TurbineHealthAPI, TurbinesHealthEvent, TurbineSystems } from '../../../client'
import SitesContext from '../../../contexts/SitesContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import EventsTable from '../EventsTable'

const sortEvents = (a: TurbinesHealthEvent, b: TurbinesHealthEvent) => {
  return new Date(b.ts_in).getTime() - new Date(a.ts_in).getTime()
}

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

const fetchData = async (siteId?: number) => {
  if (!siteId) return
  return TurbineHealthAPI.getTurbineHealthEvents({ siteId, deltaDays: 180 })
}

const Events = ({ searchValue }: { searchValue: string }) => {
  const [isActiveExpanded, setIsActiveExpanded] = useState(true)

  const { site, isLoading: siteIsloading, turbsMap } = useContext(SitesContext)
  const { isLoading: systemIsloading, data: systemMap } = useQuery(
    ['turb-health-system'],
    async () => {
      const systems = await TurbineHealthAPI.getTurbineSystems()
      return index(systems, (s: TurbineSystems) => s.id)
    },
    defaultQueryOptions
  )

  const { isLoading: eventsIsloading, data: events } = useQuery(
    ['turb-health-events', site?.site_id],
    async () => fetchData(site?.site_id),
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id,
    }
  )

  const isLoading = siteIsloading || eventsIsloading || systemIsloading

  const eventsData = useMemo(() => {
    if (!events) return
    const data = Array()

    events.forEach(event => {
      if (searchValue.length > 0) {
        const turbName = turbsMap.get(event.turb_id)?.name
        if (turbName?.toLowerCase().includes(searchValue.toLowerCase())) {
          data.push(event)
        }
      } else {
        data.push(event)
      }
    })

    return data
  }, [events, searchValue])

  const activeEvents = eventsData?.filter(e => !e.ts_fin).sort(sortEvents)
  const eventLog = eventsData?.filter(e => e.ts_fin).sort(sortEvents)

  const style = {
    height: calcVerticalHeight({ gridSize: 1, elementSize: 1 }),
    minHeight: '15rem',
    background: 'transparent',
  }

  return (
    <Stack style={style} hasGutter>
      <StackItem>
        <EventsTable
          title='Active Events'
          isLoading={isLoading}
          isExpanded={isActiveExpanded}
          setIsExpanded={isExpanded => setIsActiveExpanded(isExpanded)}
          events={activeEvents}
          turbsMap={turbsMap}
          systemMap={systemMap}
          key={site.site_id}
        />
      </StackItem>
      <StackItem>
        <EventsTable
          title='Event Log'
          isExpanded={!isActiveExpanded}
          setIsExpanded={isExpanded => setIsActiveExpanded(!isExpanded)}
          events={eventLog}
          isLoading={isLoading}
          turbsMap={turbsMap}
          systemMap={systemMap}
        />
      </StackItem>
    </Stack>
  )
}

export default Events
