import {
  Form,
  FormGroup,
  MultipleFileUpload,
  MultipleFileUploadMain,
  MultipleFileUploadStatusItem,
} from '@patternfly/react-core'
import UploadIcon from '@patternfly/react-icons/dist/esm/icons/upload-icon'
import { InnerScrollContainer } from '@patternfly/react-table'
import { useEffect, useState } from 'react'
import { StatusResponseType } from '.'
import { MultipleFileUploadStatus } from './MultipleFileUpload'

interface readFile {
  fileName: string
  data?: string
  loadResult?: 'danger' | 'success'
  loadError?: DOMException
}

export const MultiUploadImagens = ({
  currentFiles,
  setCurrentFiles,
  setIsRejectedImagens,
  isRejectedImagens,
  mensagemImagensError,
  setMensagemImagensError,
}: {
  currentFiles: File[]
  setCurrentFiles: React.Dispatch<React.SetStateAction<File[]>>
  isRejectedImagens: boolean
  setIsRejectedImagens: React.Dispatch<React.SetStateAction<boolean>>
  mensagemImagensError: string
  setMensagemImagensError: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [readFileDataImagens, setReadFileDataImagens] = useState<readFile[]>([])
  const [showStatus, setShowStatus] = useState<boolean>(false)
  const [statusIcon, setStatusIcon] = useState<StatusResponseType>('inProgress')
  // TODO: Refatorar
  if (!showStatus && currentFiles.length > 0) {
    setShowStatus(true)
  }

  useEffect(() => {
    if (currentFiles.length === 0) {
      setReadFileDataImagens([])
      setShowStatus(false)
    }
  }, [currentFiles])

  useEffect(() => {
    if (readFileDataImagens.length < currentFiles.length) {
      setStatusIcon('inProgress')
    } else if (readFileDataImagens.every(file => file.loadResult === 'success')) {
      setStatusIcon('success')
    } else {
      setStatusIcon('danger')
    }
  }, [readFileDataImagens, currentFiles])

  const removeFiles = (namesOfFilesToRemove: string[]) => {
    const newCurrentFiles = currentFiles.filter(
      currentFile => !namesOfFilesToRemove.some(fileName => fileName === currentFile.name)
    )
    setCurrentFiles(newCurrentFiles)
    const newReadFiles = readFileDataImagens.filter(
      readFile => !namesOfFilesToRemove.some(fileName => fileName === readFile.fileName)
    )
    setReadFileDataImagens(newReadFiles)
  }
  const handleFileDrop = (droppedFiles: File[]) => {
    const currentFileNames = currentFiles.map(file => file.name)
    const reUploads = droppedFiles.filter(droppedFile =>
      currentFileNames.includes(droppedFile.name)
    )
    Promise.resolve()
      .then(() => removeFiles(reUploads.map(file => file.name)))
      .then(() => setCurrentFiles(prevFiles => [...prevFiles, ...droppedFiles]))
  }
  const handleReadSuccess = (data: string, file: File) => {
    setReadFileDataImagens(prevReadFiles => [
      ...prevReadFiles,
      {
        data,
        fileName: file.name,
        loadResult: 'success',
      },
    ])
    setIsRejectedImagens(false)
  }
  const handleReadFail = (error: DOMException, file: File) => {
    setReadFileDataImagens(prevReadFiles => [
      ...prevReadFiles,
      {
        loadError: error,
        fileName: file.name,
        loadResult: 'danger',
      },
    ])
  }

  const handleFileRejected = () => {
    setIsRejectedImagens(true)
  }

  const successfullyReadFileCount = readFileDataImagens.filter(
    fileData => fileData.loadResult === 'success'
  ).length

  return (
    <Form>
      <FormGroup fieldId='text-file-with-restrictions'>
        <MultipleFileUpload
          onFileDrop={(_event, droppedFiles: File[]) => handleFileDrop(droppedFiles)}
          dropzoneProps={{
            accept: { 'image/jpeg': ['.jpg'], 'image/png': ['.png'] },
            onDropRejected: handleFileRejected,
          }}
          label='Imagens'
        >
          <MultipleFileUploadMain
            titleIcon={<UploadIcon />}
            titleText='Arraste e solte imagens aqui...'
            style={{ padding: 5 }}
          />
          {showStatus && (
            <MultipleFileUploadStatus
              statusToggleText={`${successfullyReadFileCount} de ${currentFiles.length} imagens carregadas para upload`}
              statusToggleIcon={statusIcon}
            >
              <InnerScrollContainer style={{ width: '100%', maxHeight: 170, overflow: 'auto' }}>
                {currentFiles.map(file => (
                  <MultipleFileUploadStatusItem
                    file={file}
                    key={file.name}
                    onClearClick={() => removeFiles([file.name])}
                    onReadSuccess={handleReadSuccess}
                    onReadFail={handleReadFail}
                    required
                  />
                ))}
              </InnerScrollContainer>
            </MultipleFileUploadStatus>
          )}
        </MultipleFileUpload>
      </FormGroup>
    </Form>
  )
}
