import { useState } from 'react'
import brandImg2 from '../../logo.svg'
import './login.css'

import api from '../../services/api'
import { login } from '../../services/auth'

import { useLocation, useNavigate } from 'react-router-dom'

import { Button, ExpandableSection, LoginForm, LoginPage, Spinner } from '@patternfly/react-core'
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon'

const PageLogin = () => {
  const [showHelperText, setShowHelperText] = useState(false)
  const [username, setUsername] = useState('')
  const [isValidUsername, setValidUsername] = useState(true)
  const [password, setPassword] = useState('')
  const [isValidPassword, setValidPassword] = useState(true)
  const [isRememberMeChecked, setRememberMeChecked] = useState(true)
  const [isFetchingData, setFetchingData] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const loginSpinner = <Spinner size='lg' />
  const navigate = useNavigate()

  const handleUsernameChange = value => {
    setUsername(value)
  }

  const handlePasswordChange = value => {
    setPassword(value)
  }
  const onRememberMeClick = () => {
    const remember = !isRememberMeChecked
    setRememberMeChecked(remember)
  }

  const onLoginButtonClick = async event => {
    event.preventDefault()
    if (isFetchingData) return

    const validUsername = !!isValidUsername
    const validPassword = !!isValidPassword

    setValidUsername(validUsername)
    setValidPassword(validPassword)

    if (username && password) {
      try {
        setFetchingData(true)
        const request = { username: username.trim(), password: password.trim() }
        const data = Object.keys(request)
          .map(key => `${key}=${encodeURIComponent(request[key])}`)
          .join('&')
        const response = await api.post('/login', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        const token = response.data.access_token
        const remenberMe = isRememberMeChecked
        login(token, remenberMe)
        navigate('/home')
      } catch (err) {
        setFetchingData(false)
        setShowHelperText(true)
      }
    }
  }

  const onLoginAdd = e => {
    e.preventDefault()
    window.location.href = `${process.env.REACT_APP_API_URL}/login/sso`
  }

  const loginForm = (
    <ExpandableSection
      toggleText='Entrar sem SSO'
      onToggle={() => setIsExpanded(!isExpanded)}
      isExpanded={isExpanded}
      className='expandable-login'
    >
      <LoginForm
        showHelperText={showHelperText}
        helperText='Credenciais Inválidas'
        helperTextIcon={<ExclamationCircleIcon />}
        usernameLabel='Nome de usuário'
        usernameValue={username}
        onChangeUsername={(_event, value) => handleUsernameChange(value)}
        isValidUsername={isValidUsername}
        passwordLabel='Senha'
        passwordValue={password}
        isShowPasswordEnabled
        onChangePassword={(_event, value) => handlePasswordChange(value)}
        isValidPassword={isValidPassword}
        loginButtonLabel={isFetchingData ? loginSpinner : 'Login'}
        rememberMeLabel='Mantenha-me conectado.'
        isRememberMeChecked={isRememberMeChecked}
        onChangeRememberMe={onRememberMeClick}
        onLoginButtonClick={onLoginButtonClick}
      />
    </ExpandableSection>
  )

  const images = {
    lg: '/assets/images/pfbg_1200.jpg',
    sm: '/assets/images/pfbg_768.jpg',
    sm2x: '/assets/images/pfbg_768@2x.jpg',
    xs: '/assets/images/pfbg_576.jpg',
    xs2x: '/assets/images/pfbg_576@2x.jpg',
  }

  const search = useLocation().search
  const code = new URLSearchParams(search).get('code')

  if (code) {
    login(code, isRememberMeChecked)
    window.location.href = '/home'
  }

  return (
    <LoginPage
      footerListVariants='inline'
      brandImgSrc={brandImg2}
      brandImgAlt='Rio Energy'
      backgroundImgSrc={images.lg}
      loginTitle='Faça login na sua conta'
      loginSubtitle={
        <Button isBlock onClick={onLoginAdd}>
          Login Corporativo
        </Button>
      }
    >
      {loginForm}
    </LoginPage>
  )
}
export default PageLogin
