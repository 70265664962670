import { sum } from 'd3-array'
import ColorScale from '../../utils/colorScale'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import { categories, DEFAULT_PLOT_CONFIG, PLOT_STYLE, PLOT_TEMPORAL_DAILY } from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Dia',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotDailyTotal = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
  categorySelection,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
}) => {
  const { isLoading: dataIsLoading, daily } = useData(dateIn, dateFin)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_DAILY}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  const days = daily.map(dd => dd.ts).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

  const y = days.map(day => {
    const dd = daily.find(m => new Date(m.ts).getTime() === new Date(day).getTime())

    if (!dd) return undefined

    const downtime = sum(categorySelection.downtime.map(cat => dd[cat]))
    const online = sum(categorySelection.online.map(cat => dd[cat]))

    return (online / (online + downtime)) * 100
  })

  const data = [
    {
      ...defaultAxis,
      y,
      x: days,
      name: 'Disponibilidade Temporal',
      type: 'bar',
      marker: { color: ColorScale.verde },
    },
  ]

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_DAILY}
        useResizeHandler
        key={revision}
        data={data}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
      />
    </div>
  )
}

export default PlotDailyTotal
