import { Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { useState } from 'react'
import { EnergyBridgePage } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'

import useVertical from '../../hooks/useVertical'
import Plot from '../shared/Plot'

interface IPlotPropTypes {
  revision: number
  energyBridge: EnergyBridgePage
  isLoading: boolean
}

const CATEGORIES: { name: string; attr: string; range: number[] }[] = [
  { name: 'Wind Regime', attr: 'windness_gwh', range: [-50, 50] },
  { name: 'Electrical Efficiency', attr: 'electrical_eff_gwh', range: [-5, 5] },
  { name: 'Energy-Based Availability', attr: 'energy_av_gwh', range: [-50, 50] },
  { name: 'Grid Curtailment', attr: 'curtailment_gwh', range: [-5, 5] },
  { name: 'Power Performance', attr: 'power_perf_gwh', range: [-5, 5] },
  { name: 'Uncertainty', attr: 'remainder_gwh', range: [-10, 10] },
]

const PlotEnergyBridgeSeries = ({ revision, energyBridge, isLoading }: IPlotPropTypes) => {
  const [activeTabKey, setActiveTabKey] = useState(0)
  const isVertical = useVertical()
  if (isLoading) return null

  const category = CATEGORIES[activeTabKey] || CATEGORIES[0]

  const layout = {
    template: layoutTemplate,
    margin: { b: 45, t: 10, l: 50, r: 30 },
    hovermode: 'x',
    showlegend: false,
    yaxis: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      fixedrange: true,
      zeroline: true,
      title: `(Δ) ${category.name} [%]`,
      range: category.range,
    },
    xaxis: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      fixedrange: true,
      type: 'category',
    },
  }
  const config = { ...defaultConfig, displayModeBar: false }
  const style = {
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '7rem' }),
    minHeight: '12rem',
    minWidth: isVertical ? '700px' : '300px',
  }
  const formatText = (value: number, budget_gwh: number) =>
    `${value.toFixed(2)} GWh ${((value / budget_gwh) * 100).toFixed(2)}%`

  const series = energyBridge.series.map((eb: any) => ({
    x: eb.ts,
    y: (eb[category.attr] / eb.budget_gwh) * 100,
  }))

  series.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime())

  const sortedTSs = series.map(data => data.x)
  const sortedValues = series.map(data => data.y.toFixed(2))

  const data = [
    {
      name: category.name,
      type: 'bar',
      marker: { color: COLORS.cinza_medio },
      hovertext: series.map(data => {
        const foundElement = energyBridge.series.find((eb: any) => eb.ts === data.x)
        return formatText(data.y, foundElement ? foundElement.budget_gwh : 0)
      }),
      x: sortedTSs,
      y: sortedValues,
    },
  ]

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onSelect={(_, eventKey) => {
          setActiveTabKey(eventKey as number)
        }}
        aria-label='category-tabs'
        isOverflowHorizontal
        isFilled
      >
        {CATEGORIES.map((cat, i) => (
          <Tab
            key={i}
            eventKey={i}
            title={<TabTitleText>{cat.name}</TabTitleText>}
            aria-label={cat.name}
          >
            {' '}
          </Tab>
        ))}
      </Tabs>
      <div style={{ overflowX: 'auto' }}>
        <Plot
          divId='EnergyBridgeTotalsSeries'
          useResizeHandler
          data={data}
          layout={layout}
          config={config}
          style={style}
          key={revision + activeTabKey}
        />
      </div>
    </div>
  )
}

export default PlotEnergyBridgeSeries
