import {
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Split,
  SplitItem,
} from '@patternfly/react-core'
import { EditIcon, FilterIcon, Remove2Icon, SearchIcon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { ThSortType } from '@patternfly/react-table/dist/esm/components/Table/base/types'
import { CSSProperties, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'

interface IColumnConfig<T> {
  key: keyof T
  description: string | JSX.Element
  formatter?: (val: any, instance: T) => string | number | JSX.Element
  onFilterClick?: (val: any) => void
  hidden?: boolean
  hiddenExportXlsx?: boolean
}

const EntityTable = <T extends object>({
  items,
  itemKeyName,
  columnConfig,
  selected,
  onSelect,
  onClickDelete,
  onClickEdit,
  noDataMessage = 'Nenhum dado encontrado.',
  className = '',
  isLoading = false,
  tableClassName = '',
  isCompact = true,
  actionsLabel = 'Ações',
  style,
  selectedMode = 'select',
  checkboxSelected,
  setCheckboxSelected,
  isFilterable = false,
  onClearFilters,
  triggerExportXlsx,
  onExportCompleteXlsx,
}: {
  items: any[]
  itemKeyName: keyof T
  columnConfig?: IColumnConfig<T>[]
  selected?: T
  noDataMessage?: string
  isLoading?: boolean
  className?: string
  tableClassName?: string
  isCompact?: boolean
  onSelect?: (instance: T) => void
  onClickDelete?: (instance: T) => void
  onClickEdit?: (instance: T) => void
  style?: CSSProperties
  actionsLabel?: string
  /**
   * @description `select`
   * - Permite selecionar uma linha
   * - Trabalha em conjunto com `onSelect` e `selected`
   *
   * @description `checkbox`
   * - Permite selecionar várias linhas
   * - Trabalha em conjunto com `onCheck`, `checkboxSelected` e `setCheckboxSelected`
   * @default 'select'
   */
  selectedMode?: 'select' | 'checkbox'
  /**
   * @description Array de instâncias com checkbox selecionado
   * - Obrigatório quando `selectedMode` é `checkbox`
   */
  checkboxSelected?: T[]
  /**
   * @description Função para setar as instâncias selecionadas
   * - Obrigatório quando `selectedMode` é `checkbox`
   */
  setCheckboxSelected?: React.Dispatch<React.SetStateAction<T[]>>
  /**
   * @description Indica se a funcionalidade de filtro está habilitada
   */
  isFilterable?: boolean
  /**
   * @description Função executada para limpar os filtros aplicados
   */
  onClearFilters?: () => void
  /**
   * @description Dispara a exportação para XLSX
   */
  triggerExportXlsx?: boolean
  /**
   * @description Função disparada após a exportação para XLSX
   */
  onExportCompleteXlsx?: () => void
}) => {
  const hasActions = onClickDelete || onClickEdit
  const [activeSortIndex, setActiveSortIndex] = useState<number>()
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>()

  const [shifting, setShifting] = useState(false)
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = useState<number | null>(null)

  const selectableInstance = items.filter(id => id)
  const setInstanceSelected = (repo: T, isSelecting = true) => {
    if (!setCheckboxSelected) {
      if (selectedMode === 'checkbox') {
        console.error(
          'É necessário definir setCheckboxSelected para poder selecionar todas as linhas'
        )
      }
      return
    }
    setCheckboxSelected(prevSelected => {
      const otherSelectedData = prevSelected.filter(r => r !== repo)
      return isSelecting ? [...otherSelectedData, repo] : otherSelectedData
    })
  }

  const onCheckAll = (isSelecting = true) => {
    if (!setCheckboxSelected) {
      if (selectedMode === 'checkbox') {
        console.error(
          'É necessário definir setCheckboxSelected para poder selecionar todas as linhas'
        )
      }
      return
    }
    setCheckboxSelected(isSelecting ? selectableInstance.map(r => r) : [])
  }

  const onCheck = (repo: T, isSelecting: boolean, rowIndex: number) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex)
      intermediateIndexes.forEach(index => setInstanceSelected(items[index], isSelecting))
    } else {
      setInstanceSelected(repo, isSelecting)
    }
    setRecentSelectedRowIndex(rowIndex)
  }

  useEffect(() => {
    if (selectedMode !== 'checkbox') return
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(true)
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(false)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  useEffect(() => {
    if (triggerExportXlsx) {
      const dataToExport = sortedEntities.map(instance => {
        const instanceToExport: { [key: string]: any } = {}
        columnConfig?.forEach(col => {
          if (col.hiddenExportXlsx || typeof col.description !== 'string') return
          instanceToExport[col.description] = col.formatter
            ? col.formatter(instance[col.key], instance)
            : instance[col.key]
        })
        return instanceToExport
      })
      exportToXlsx(dataToExport)
      if (onExportCompleteXlsx) onExportCompleteXlsx()
    }
  }, [triggerExportXlsx])

  if (!items || items.length === 0 || isLoading) {
    return (
      <div style={{ backgroundColor: 'white' }} className={className}>
        <OuterScrollContainer style={style}>
          <InnerScrollContainer>
            {columnConfig ? (
              <Table
                variant={isCompact ? 'compact' : undefined}
                aria-label={'entity-table'}
                isStickyHeader
                className={tableClassName}
              >
                <Thead>
                  <Tr>
                    {columnConfig.map((col, i) => {
                      if (col.hidden) return null
                      return (
                        <Th key={`${col.description}-${i}`} className='pf-m-wrap'>
                          {col.description}
                        </Th>
                      )
                    })}
                    {hasActions ? <Th>{actionsLabel}</Th> : null}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td colSpan={columnConfig.length + 1}>
                      <EmptyEntityTable
                        noDataMessage={noDataMessage}
                        isFilterable={isFilterable}
                        onClearFilters={onClearFilters}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <EmptyEntityTable
                noDataMessage={noDataMessage}
                isFilterable={isFilterable}
                onClearFilters={onClearFilters}
              />
            )}
          </InnerScrollContainer>
        </OuterScrollContainer>
      </div>
    )
  }

  const columns = columnConfig
    ? columnConfig
    : (Object.keys(items[0]).map(k => ({ key: k, description: k })) as IColumnConfig<T>[])

  const entityKeys = columns.map(e => e.key) as (keyof T)[]

  const onRowClick = (instance: T) => {
    if (!onSelect) {
      console.log('Must define onSelect in order to be able to select rows')
      return
    }
    onSelect(instance)
  }
  const isClickable = !!onSelect && selectedMode === 'select'
  const isCheckbox = !!onCheck && selectedMode === 'checkbox'

  let sortedEntities = items

  if (activeSortIndex !== undefined) {
    sortedEntities = [...items]
      .sort((a, b) => {
        const aValue = a[entityKeys[activeSortIndex]]
        const bValue = b[entityKeys[activeSortIndex]]

        if (typeof aValue === 'number') {
          // Numeric sort
          if (activeSortDirection === 'asc') {
            return (aValue as number) - (bValue as number)
          }
          return (bValue as number) - (aValue as number)
        } else {
          // String sort
          if (activeSortDirection === 'asc') {
            return String(aValue).localeCompare(bValue as string)
          }
          return String(bValue).localeCompare(aValue as string)
        }
      })
      .sort((a, b) => {
        if (a[entityKeys[activeSortIndex]] === b[entityKeys[activeSortIndex]]) {
          return String(a[itemKeyName]).localeCompare(String(b[itemKeyName]))
        }
        return 0
      })
  } else {
    sortedEntities = [...items].sort((a, b) =>
      String(a[itemKeyName]).localeCompare(String(b[itemKeyName]))
    )
  }

  const getSortParams = (columnIndex: number): ThSortType => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
      defaultDirection: 'asc',
    },
    onSort: (_event: any, index: any, direction: any) => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  return (
    <div style={{ backgroundColor: 'white' }} className={className}>
      <OuterScrollContainer style={style}>
        <InnerScrollContainer>
          <Table
            variant={isCompact ? 'compact' : undefined}
            aria-label={'entity-table'}
            isStickyHeader
            className={tableClassName}
          >
            <Thead>
              <Tr>
                {isCheckbox && onCheckAll && (
                  <Th
                    select={{
                      onSelect: (_event, isSelecting) => onCheckAll(isSelecting),
                      isSelected: checkboxSelected?.length === items.length,
                    }}
                  />
                )}
                {columns.map((col, i) => {
                  if (col.hidden) return null
                  return (
                    <Th
                      key={`${col.description}-${i}`}
                      sort={getSortParams(i)}
                      className='pf-m-wrap'
                    >
                      {col.description}
                    </Th>
                  )
                })}
                {hasActions ? <Th>{actionsLabel}</Th> : null}
              </Tr>
            </Thead>
            <Tbody>
              {sortedEntities.map((instance, i) => (
                <Tr
                  key={`${instance[itemKeyName]}-${i}`}
                  {...(isClickable && {
                    isRowSelected: selected && instance[itemKeyName] === selected[itemKeyName],
                    onRowClick: () => isClickable && onRowClick(instance),
                    isClickable,
                  })}
                >
                  {isCheckbox && (
                    <Td
                      select={{
                        rowIndex: i,
                        onSelect: (_event, isSelecting) => onCheck(instance, isSelecting, i),
                        isSelected: checkboxSelected?.includes(instance) || false,
                      }}
                    />
                  )}
                  {columns.map((col, i) => {
                    if (col.hidden) return null
                    return (
                      <Td
                        {...(typeof col.description === 'string'
                          ? { dataLabel: col.description }
                          : {})}
                        key={`${col.description}-${i}`}
                      >
                        {col.formatter
                          ? col.formatter(instance[col.key], instance)
                          : instance[col.key]}{' '}
                        {col.onFilterClick && (
                          <FilterIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (col.onFilterClick) col.onFilterClick(instance[col.key])
                            }}
                          />
                        )}
                      </Td>
                    )
                  })}
                  {hasActions ? (
                    <Td modifier='nowrap' dataLabel={actionsLabel}>
                      <Split>
                        {onClickDelete && (
                          <SplitItem>
                            <Remove2Icon
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickDelete(instance)}
                            />
                          </SplitItem>
                        )}
                        {onClickEdit && (
                          <SplitItem>
                            <EditIcon
                              className='pf-v5-u-mx-sm'
                              title='Editar'
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickEdit(instance)}
                            />
                          </SplitItem>
                        )}
                      </Split>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InnerScrollContainer>
      </OuterScrollContainer>
    </div>
  )
}

function EmptyEntityTable({
  noDataMessage = 'Nenhum dado encontrado.',
  isFilterable = false,
  onClearFilters,
}: {
  noDataMessage?: string
  isFilterable?: boolean
  onClearFilters?: () => void
}) {
  return (
    <EmptyState variant={EmptyStateVariant.lg}>
      <EmptyStateHeader
        titleText={noDataMessage}
        icon={<EmptyStateIcon icon={SearchIcon} />}
        headingLevel='h3'
      />
      <EmptyStateFooter>
        {isFilterable && (
          <>
            <EmptyStateBody>Limpe o filtro e tente novamente.</EmptyStateBody>
            <Button variant='link' onClick={onClearFilters}>
              Limpar filtro
            </Button>
          </>
        )}
      </EmptyStateFooter>
    </EmptyState>
  )
}

const exportToXlsx = (data: Array<{ [key: string]: any }>, fileName = 'export.xlsx') => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default EntityTable
