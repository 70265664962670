import {
  Button,
  Panel,
  PanelFooter,
  PanelHeader,
  PanelMain,
  PanelMainBody,
} from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { FlagEvents, MastsAPI } from '../../../client'
import EntityTable from '../../../components/EntityTable'
import MastContext from '../../../contexts/MastContext'
import useMobile from '../../../hooks/useMobile'
import Page from '../../shared/Page'
import SelectMasts from '../SelectMasts'

export const QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}
const KEY_EVENT_FLAGS = 'KEY_EVENT_FLAGS'

const EventFlagging = () => {
  const { mast, isLoading: mastIsLoading } = useContext(MastContext)
  const isMobile = useMobile()
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const { data: _flaggedEvents, isLoading: flaggedEventsIsLoading } = useQuery(
    [KEY_EVENT_FLAGS, mast.id],
    () => MastsAPI.getFlagEvents({ mastId: mast.id }),
    { ...QUERY_OPTIONS, enabled: mast.id !== 0, placeholderData: [] as FlagEvents[] }
  )
  const flaggedEvents = _flaggedEvents ?? []

  return (
    <Page
      pageName='EventFlagging'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isContentLoading={flaggedEventsIsLoading}
      isLoading={mastIsLoading}
      filters={[<SelectMasts key='SelectMasts' />]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelHeader>
          <b>Relação de Eventos Sinalizados</b>
        </PanelHeader>
        <PanelMain>
          <PanelMainBody>
            <EntityTable<FlagEvents> items={flaggedEvents} itemKeyName='id' />
          </PanelMainBody>
          <PanelFooter>
            <Button
              onClick={console.log}
              style={{
                height: '36px',
                margin: 0,
                ...(isMobile && { width: '100%' }),
              }}
            >
              Adicionar
            </Button>
          </PanelFooter>
        </PanelMain>
      </Panel>
    </Page>
  )
}

export default EventFlagging
