import { Button } from '@patternfly/react-core'
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon'

const stepComponent = () => {
  return (
    <>
      <p>
        Acesse a página de ajuda dos gráficos para obter dicas de como manipular os plots e aumentar
        suas possibilidades de visualizar os dados no A7.
      </p>
      <Button onClick={() => window.open('/help-plotly', '_blank')} style={{ marginTop: '10px' }}>
        Ir para Ajuda
      </Button>
    </>
  )
}

export const stepHelpPlotlyLink = [
  {
    name: (
      <div>
        <ExternalLinkAltIcon /> Dicas para os gráficos
      </div>
    ),
    component: stepComponent(),
    stepNavItemProps: { navItemComponent: 'a', href: '/help-plotly', target: '_blank' },
  },
]
