import { Grid, GridItem, Text } from '@patternfly/react-core'
import React from 'react'

import maintenance from '../../assets/img/maintenance.svg'

const PageNoData = props => {
  const { message } = props
  const messageText = message || 'Sem dados'

  return (
    <Grid
      className='pf-v5-u-w-75 pf-v5-u-w-50-on-md pf-v5-u-w-33-on-lg pf-v5-u-m-auto'
      style={{ textAlign: 'center' }}
    >
      <GridItem sm={12} md={12} lg={12}>
        <img src={maintenance} alt='sem dados' id='maintenance-img' />
        <Text className='pf-v5-u-font-size-lg pf-v5-u-m-auto'>{messageText}</Text>
      </GridItem>
    </Grid>
  )
}

export default PageNoData
