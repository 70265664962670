import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

import { AlarmTurbs, Turbines } from '../../client'

interface IProps {
  chartData: AlarmTurbs[]
  turbs: Turbines[]
  revision: number
  isLoading: boolean
}

const ChartAlarmsTurbines = (props: IProps) => {
  const { chartData, turbs, revision, isLoading } = props
  if (isLoading) return null

  const layout = {
    template: layoutTemplate,
    showlegend: false,
    margin: { b: 60, t: 15, l: 60, r: 30 },
    yaxis: { ...defaultAxis, automargin: true, title: 'Número de Ocorrências', fixedrange: true },
    xaxis: { ...defaultAxis, automargin: true, title: 'Aerogeradores', fixedrange: true },
  }

  const config = {
    ...defaultConfig,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toImage',
    ],
  }

  const trace = {
    x: chartData.map(e => turbs?.find(t => t.turb_id === e?.turb_id)?.name),
    y: chartData.map(e => e.alarm_count),
    type: 'bar',
    marker: { color: COLORS.verde_malachite },
    yaxis: 'y',
    xaxis: 'x',
  }

  return (
    <div>
      <Plot
        className='pf-v5-u-mb-md pf-v5-u-box-shadow-sm'
        key={`${revision}`}
        data={[trace]}
        layout={layout}
        config={config}
        useResizeHandler
        revision={revision}
        style={{ width: '100%', height: '40vh' }}
      />
    </div>
  )
}

export default ChartAlarmsTurbines
