import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { ProductionAPI, ProductionTurbDataSignals as Signals, Turbdata, Turbines } from '../client'
import SitesContext from '../contexts/SitesContext'
import { queryClient } from '../services/api'

const defaultData: Turbdata[] = []

const defaultQueryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 15,
  placeholderData: defaultData,
}

const formatDate = (date: Date) => date.toISOString().split('T')[0]

const sortSignals = (signals: Signals[]) => {
  // Ordenação deve ser feita sem alterar o array original para não afetar o estado da interface
  return [...signals].sort((a, b) => (a.signal > b.signal ? 1 : -1))
}

const sortTurbs = (turbs: Turbines[]) => {
  // Ordenação deve ser feita sem alterar o array original para não afetar o estado da interface
  return [...turbs].sort((a, b) => a.turb_id - b.turb_id)
}

const fetchData = async ({
  siteId,
  signals,
  turbs,
  tsIn,
  tsFin,
}: {
  siteId: number
  signals: Signals[]
  turbs: Turbines[]
  tsIn: Date
  tsFin: Date
}) => {
  if (!siteId) return defaultData
  if (signals.filter(s => s).length === 0) return defaultData
  if (turbs.filter(t => t.site_id === siteId).length === 0) return defaultData

  if (
    tsIn > tsFin ||
    tsIn > new Date() ||
    tsFin > new Date() ||
    tsIn.getTime() === tsFin.getTime()
  ) {
    console.error('Invalid date range', tsIn, tsFin)
    return defaultData
  }

  // Ordenando os sinais para melhorar a taxa de acerto do cache
  const signalId = sortSignals(signals).map(s => s.signal_id)
  const turbId = sortTurbs(turbs).map(t => t.turb_id)

  const data = await ProductionAPI.getTurbdata({
    siteId,
    signalId,
    turbId,
    tsIn: formatDate(tsIn),
    tsFin: formatDate(tsFin),
  })
  console.log('Data fetched', siteId, signalId, turbId)
  return data
}

const prefetch = async ({
  siteId,
  signals,
  turbs,
  tsIn,
  tsFin,
}: {
  siteId: number
  signals: Signals[]
  turbs: Turbines[]
  tsIn: Date
  tsFin: Date
}) => {
  await queryClient.prefetchQuery(
    ['turbdata', siteId, signals, turbs, tsIn, tsFin],
    async () => {
      const data = await fetchData({
        siteId,
        signals,
        turbs,
        tsIn,
        tsFin,
      })
      return data
    },
    defaultQueryOptions
  )
}

const useTurbdata = (
  selectedTurbs: Turbines[],
  selectedSignals: Signals[],
  tsIn: Date,
  tsFin: Date
) => {
  const { site, sites, isLoading: sitesIsLoading } = useContext(SitesContext)
  const { isLoading, data, isFetching } = useQuery(
    ['turbdata', site?.site_id, selectedSignals, selectedTurbs, tsIn, tsFin],
    async () => {
      return await fetchData({
        siteId: site?.site_id,
        signals: selectedSignals,
        turbs: selectedTurbs,
        tsIn,
        tsFin,
      })
    },
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id,
      onSuccess: async () => {
        for (const st of sites.filter(s => s.site_id !== site.site_id)) {
          await prefetch({
            siteId: st.site_id,
            signals: selectedSignals,
            turbs: selectedTurbs,
            tsIn,
            tsFin,
          })
        }
      },
    }
  )
  const turbdata = data as Turbdata[]
  if (!turbdata) {
    console.error('Erro ao carregar turbdata')
    return { turbdata: defaultData, isLoading: true, isFetching: false }
  }

  return { turbdata, isLoading: isLoading || sitesIsLoading, isFetching }
}

export default useTurbdata
