import { Card, CardBody, CardTitle, GalleryItem, Text, TextContent } from '@patternfly/react-core'
import { Site } from '../../../client'
import AvailableInfos from './AvailableInfos'
import ProductionInfos from './ProductionInfos'

interface ISiteInfosCardProps {
  setOverSiteId: (site_id: number) => void
  site: Site
}
const SiteInfosCard = ({ setOverSiteId, site }: ISiteInfosCardProps) => {
  const handleMouse = () => {
    setOverSiteId(site.site_id)
  }

  return (
    <GalleryItem onMouseEnter={() => handleMouse()}>
      <Card>
        <CardTitle>
          <TextContent>
            <Text component='h2'>{site.site_name}</Text>
          </TextContent>
        </CardTitle>
        <CardBody>
          <AvailableInfos site={site} siteId={site.site_id} />
          <ProductionInfos siteId={site.site_id} />
        </CardBody>
      </Card>
    </GalleryItem>
  )
}

export default SiteInfosCard
