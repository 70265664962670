import { sum } from 'd3-array'
import ColorScale from '../../utils/colorScale'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'
import { sortByDateKey } from '../../utils/sort'
import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  DEFAULT_PLOT_CONFIG,
  PLOT_STYLE,
  PLOT_TEMPORAL_DAILY_TURBS,
} from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Dia',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotDailyTurb = ({
  isLoading,
  dateIn,
  dateFin,
  turbId,
  revision,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  turbId: number
  revision: number
}) => {
  const { isLoading: dataIsLoading, dailyTurbs: allDailyTurbs } = useData(dateIn, dateFin)

  const dailyTurbs = sortByDateKey(
    allDailyTurbs.filter(e => e.turb_id === turbId),
    'ts',
    'asc'
  )

  if (isLoading || dataIsLoading || dailyTurbs.length === 0) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_DAILY_TURBS}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  const dtNow = new Date()

  const days = dailyTurbs
    .map(dd => dd.ts)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

  const _defaultAxis = { ...defaultAxis, x: days, hoverinfo: 'all', type: 'bar' }

  const dailyTotals = dailyTurbs.map(dd => sum(COUNTERDATA_TYPE_LIST.map(cat => dd[cat])))

  const data = COUNTERDATA_TYPE_LIST.map(cat => {
    return {
      ..._defaultAxis,
      y: [...dailyTurbs]
        .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())
        .map((dd, i) => {
          if (new Date(dd.ts) > dtNow) return null
          return Math.round(((dd[cat] ?? 0) / dailyTotals[i]) * 10000) / 100
        }),
      name: COUNTERDATA_TYPES[cat].description,
      marker: { color: COUNTERDATA_TYPES[cat].color },
    }
  })

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_DAILY_TURBS}
        useResizeHandler
        key={revision}
        data={data}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
      />
    </div>
  )
}

export default PlotDailyTurb
