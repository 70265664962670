import {
  AlarmView,
  AvailabilityPowerCurveEvents,
  DowntimeAero,
  DowntimeBop,
  DowntimeONS,
} from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'

export interface Responsabilidade {
  id: boolean
  nome: string
}

export const RESPONSABILIDADES: Responsabilidade[] = [
  { id: true, nome: 'Rio Energy' },
  { id: false, nome: 'Fabricante' },
]

export const GRID_SIZE = 6
export const TAB_HEADER_HEIGHT = 2
export const TOOLBAR_HEIGHT = 2
export const FOOTER_HEIGHT = 0.5
export const TABLE_HEADER_HEIGHT = 2.5

export const FILTER_HEIGHT = 2.5
export const TABLE_MIN_HEIGHT = '5rem'
export const BOTTOM_BUTTON_HEIGHT = 2.5
export const BOTTOM_BUTTON_MARGIN = 1
export const PAGE_BOTTOM_MARGIN = 1
export const PAGE_GRID_SIZE = 2
export const EMPTY_STATE_STYLE = {
  height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '15rem' }),
}
export const TABLE_WRAPPER_HEIGHT_STYLE = {
  height: calcVerticalHeight({
    pgHasFilter: true,
    gridSize: PAGE_GRID_SIZE,
    elementSize: 1,
    customOffset: 2.6,
  }),
}
export const CACHE_KEY_WTG = 'downtime_wtg'
export const CACHE_KEY_BOP = 'downtime_bop'
export const CACHE_KEY_ONS = 'downtime_ons'
export const CACHE_KEY_OPT = 'downtime_options'
export const CACHE_KEY_ALARMS = 'alarms_for_downtime'
export const CACHE_KEY_ALARMS_E = 'alarms_ext_for_downtime'
export const CACHE_KEY_PC = 'power_curve_reclassification'
export const CACHE_KEY_PC_POINTS = 'power_curve_reclassification_points'
export const CACHE_KEY_POWER_CURVE = 'power_curve_av_pc'
export const QUERY_KEY_EXP_PWR = 'expected_energy_turb'

export const DEFAULT_DATA = {
  options: { classificacao: [], componentes: [], setor: [], tipos: [] },
  downtimeAero: [] as DowntimeAero[],
  downtimeBop: [] as DowntimeBop[],
  downtimeOns: [] as DowntimeONS[],
  pcReclassification: [] as AvailabilityPowerCurveEvents[],
  alarms: { alarms: [], count: 0 } as AlarmView,
}
