import COLORS from '../../utils/colorScale'

const plotTemplate = {
  layout: {
    dragmode: false,
    hovermode: 'x unified',
    hoverlabel: {
      bgcolor: '#FFF',
      namelength: 30,
      font: {
        color: COLORS.color_font_plot,
        family: 'RedHatText, Overpass, overpass, helvetica, arial, sans-serif',
        size: 13,
      },
    },
    yaxis: {
      range: [0, 100],
      fixedrange: true,
      autorange: false,
      showgrid: true,
      gridcolor: COLORS.grid_color_plot,
      tickfont: {
        color: COLORS.color_font_plot,
        family: 'RedHatText, Overpass, overpass, helvetica, arial, sans-serif',
        size: 11,
      },
      showline: true,
      mirror: 'ticks',
      linecolor: COLORS.grid_color_plot,
    },
    xaxis: {
      showgrid: true,
      fixedrange: true,
      gridcolor: COLORS.grid_color_plot,
      tickfont: {
        color: COLORS.color_font_plot,
        family: 'RedHatText, Overpass, overpass, helvetica, arial, sans-serif',
        size: 11,
      },
      showline: true,
      mirror: 'ticks',
      linecolor: COLORS.grid_color_plot,
    },
    yaxis2: {
      showgrid: true,
      gridcolor: COLORS.grid_color_plot,
      tickfont: {
        color: COLORS.color_font_plot,
        family: 'RedHatText, Overpass, overpass, helvetica, arial, sans-serif',
        size: 11,
      },
      showline: true,
      mirror: 'ticks',
      linecolor: COLORS.grid_color_plot,
    },
    xaxis2: {
      showgrid: true,
      gridcolor: COLORS.grid_color_plot,
      tickfont: {
        color: COLORS.color_font_plot,
        family: 'RedHatText, Overpass, overpass, helvetica, arial, sans-serif',
        size: 13,
      },
      showline: true,
      mirror: 'ticks',
      linecolor: COLORS.grid_color_plot,
    },
    legend: {
      orientation: 'h',
      yanchor: 'middle',
      xanchor: 'center',
      x: '0.5',
      y: '1.15',
      font: {
        color: COLORS.color_font_plot,
        family: 'RedHatText, Overpass, overpass, helvetica, arial, sans-serif',
        size: 9.8,
      },
    },
    margin: {
      b: 20,
      t: 0,
      r: 0,
      l: 20,
    },
    paper_bgcolor: COLORS.transparent_bg,
    plot_bgcolor: COLORS.transparent_bg,
  },
}

export default plotTemplate
