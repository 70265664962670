/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_image_sites_occurrences__ocorrencia_id__image_put } from '../models/Body_update_image_sites_occurrences__ocorrencia_id__image_put';
import type { Body_workbook_upload_sites__site_id__occurrences_workbook_upload_post } from '../models/Body_workbook_upload_sites__site_id__occurrences_workbook_upload_post';
import type { CivilOcorrencia } from '../models/CivilOcorrencia';
import type { CivilOcorrenciaVM } from '../models/CivilOcorrenciaVM';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OccurrencesAPI {

  /**
   * Get All
   * @returns CivilOcorrencia Successful Response
   * @throws ApiError
   */
  public static getAll({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<CivilOcorrencia>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/occurrences',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Image
   * @returns string Successful Response
   * @throws ApiError
   */
  public static updateImage({
    ocorrenciaId,
    formData,
  }: {
    ocorrenciaId: string,
    formData: Body_update_image_sites_occurrences__ocorrencia_id__image_put,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/occurrences/{ocorrencia_id}/image',
      path: {
        'ocorrencia_id': ocorrenciaId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update
   * @returns any Successful Response
   * @throws ApiError
   */
  public static update({
    requestBody,
  }: {
    requestBody: CivilOcorrenciaVM,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/occurrences',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete
   * @returns string Successful Response
   * @throws ApiError
   */
  public static delete({
    ocorrenciaId,
  }: {
    ocorrenciaId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/sites/occurrences/{ocorrencia_id}',
      path: {
        'ocorrencia_id': ocorrenciaId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Workbook Upload
   * @returns any Successful Response
   * @throws ApiError
   */
  public static workbookUpload({
    siteId,
    formData,
  }: {
    siteId: number,
    formData: Body_workbook_upload_sites__site_id__occurrences_workbook_upload_post,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sites/{site_id}/occurrences/workbook_upload',
      path: {
        'site_id': siteId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
