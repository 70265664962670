import { Alert, Button, Modal, ModalVariant } from '@patternfly/react-core'
import { useEffect } from 'react'
import { ActionState, requestStateMessages } from '../../components/actionState'

function ModalConsolidar<Type>({
  title,
  isOpen,
  handleModalToggle,
  handleSubmit,
  element,
  additionalInformation,
  actionState = 'idle',
  alertMessage = 'Tem certeza que deseja consolidar esses eventos?',
  pendingMessage = 'Consolidando eventos',
  successMessage = 'Eventos consolidados com sucesso',
  failureMessage = 'Ocorreu um erro ao consolidar',
}: {
  title: string
  isOpen: boolean
  handleModalToggle: () => void
  handleSubmit: (element: Type) => void
  element: Type
  additionalInformation?: React.ReactNode
  actionState: ActionState
  alertMessage?: string
  pendingMessage?: string
  successMessage?: string
  failureMessage?: string
}) {
  useEffect(() => {
    if (isOpen && actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
    if (isOpen && actionState === 'failure') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
      }, 5000)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      variant={ModalVariant.large}
      title={title}
      aria-label='modal-consolidar'
      id='modal-consolidar'
      isOpen={isOpen}
      onClose={handleModalToggle}
      actions={[
        <Button
          key='create'
          variant='primary'
          onClick={() => {
            handleSubmit(element)
            if (actionState === 'success') handleModalToggle()
          }}
        >
          Consolidar
        </Button>,
        <Button key='cancelar' variant='secondary' onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      {
        requestStateMessages({
          titlePending: pendingMessage,
          titleSuccess: successMessage,
          titleFailure: failureMessage,
        })[actionState]
      }
      {alertMessage}
      {additionalInformation}
    </Modal>
  )
}

export default ModalConsolidar
