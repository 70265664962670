import { InternMap, range } from 'd3-array'
import { CSSProperties, useContext } from 'react'
import { MastData } from '../../../client'
import MastContext from '../../../contexts/MastContext'
import useMobile from '../../../hooks/useMobile'
import { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../../utils/plotly'
import Plot from '../../shared/Plot'

const config = { ...defaultConfig }
const style: CSSProperties = {
  width: '100%',
  height: '100%',
}

const windRoseLayout = {
  template: layoutTemplate,
  polar: {
    gridshape: 'linear',
    radialaxis: {
      angle: 45,
      tickangle: 45,
      type: 'linear',
    },
    angularaxis: {
      thetaunit: 'degrees',
      direction: 'clockwise',
      period: 6,
    },
  },
  legend: {
    x: 0.5,
    y: 1.15,
    xanchor: 'center',
    yanchor: 'bottom',
  },
}

const PlotWindRose = ({
  revision,
  mastDataBySensor,
}: {
  revision: number
  mastDataBySensor: InternMap<number, MastData[]>
}) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const windDirectionSensors = allSensors.filter(s => s.type === 'WDR')

  const directionData = windDirectionSensors
    .flatMap((sensor, i) => {
      const mastData = mastDataBySensor.get(sensor.id)
      if (!mastData) {
        return null
      }

      const step = 2
      const bins = range(0, 360, step)
      const histogram = bins.map(b => 0)
      let total = 0

      mastData.forEach(md => {
        const bin = Math.trunc(md.avg / step)
        histogram[bin] = histogram[bin] + 1
        total = total + 1
      })

      const sensorColor: string = plot_color_scale_200[i]

      return [
        {
          type: 'scatterpolar',
          name: `${sensor.label} - AVG`,
          r: histogram.map(i => (i / total) * 100),
          theta: bins,
          fill: 'toself',
          line: {
            color: sensorColor,
          },
        },
      ]
    })
    .filter(d => d)

  const isMobile = useMobile()
  if (directionData.length === 0) return null
  return (
    <div style={style}>
      <Plot
        divId={`PlotWindRose${mast.name}`}
        key={`${mast.name}-${revision}`}
        useResizeHandler
        data={directionData}
        layout={windRoseLayout}
        config={{ ...config, ...(isMobile && { displayModeBar: false }) }}
        style={style}
        revision={revision}
      />
    </div>
  )
}
export default PlotWindRose
