import {
  Alert,
  Button,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  Stack,
  StackItem,
  TextInput,
} from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import { MastRawMapping } from '../../../client'
import { MastsAPI } from '../../../client/services/MastsAPI'
import { ActionState } from '../../../components/actionState'
import DatetimeRangePicker from '../../../components/calendar/DatetimeRangePicker'
import Select from '../../../components/selects/Select'
import MastContext, { Sensors } from '../../../contexts/MastContext'
import PermissionContext from '../../../contexts/PermissionContext'
import { queryClient } from '../../../services/api'
import { toISOString } from '../../../utils/formatDate'
import { KEY_RAW_MAPPINGS, METRICS } from '../constants'

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar o evento' isInline />,
}

const ModalRawMapping = ({
  isOpen,
  onClose,
  modalType,
  rawMapping,
}: {
  isOpen: boolean
  onClose: () => void
  modalType: 'create' | 'update'
  rawMapping?: MastRawMapping
}) => {
  const { mast, sensors } = useContext(MastContext)
  const [metricSelected, setMetricSelected] = useState<{ name: string }>(METRICS[0])
  const [sensorSelected, setSensorSelected] = useState<Sensors>(sensors[0])
  const [nameValue, setNameValue] = useState('')

  const [tsIn, setTsIn] = useState(new Date())
  const [tsFin, setTsFin] = useState(new Date())

  useEffect(() => {
    setTsIn(
      modalType === 'create' ? new Date(mast.dataset_start) : new Date(rawMapping?.ts_in as string)
    )
    setTsFin(
      modalType === 'create' ? new Date(mast.dataset_end) : new Date(rawMapping?.ts_fin as string)
    )
    if (modalType === 'update') {
      setNameValue(rawMapping?.column_name as string)
      setMetricSelected({ name: rawMapping?.metric as string })
      setSensorSelected(
        sensors.find((sensor: Sensors) => sensor.id === rawMapping?.sensor_id) ?? sensors[0]
      )
    }
  }, [isOpen])

  const { me } = useContext(PermissionContext)
  const [alertActive, setAlertActive] = useState(false)
  const [isError406, setIsError406] = useState(false)
  const [requestState, setRequestState] = useState<ActionState>('idle')

  const handleSubmit = async () => {
    if (nameValue === '') {
      setAlertActive(true)
      return
    }
    setRequestState('pending')
    const requestBody = {
      mast_id: mast.id,
      sensor_id: sensorSelected.id,
      ts_in: toISOString(tsIn),
      ts_fin: toISOString(tsFin),
      column_name: nameValue,
      metric: metricSelected.name,
      username: me.current_user.username,
    }
    const requestBodyUpdate = {
      id: rawMapping?.id,
      mast_id: mast.id,
      sensor_id: sensorSelected.id,
      ts_in: toISOString(tsIn),
      ts_fin: toISOString(tsFin),
      column_name: nameValue,
      metric: metricSelected.name,
      username: me.current_user.username,
    }
    const requestMethod =
      modalType === 'update' ? MastsAPI.updateRawMapping : MastsAPI.createRawMapping

    try {
      await requestMethod({
        mastId: mast.id,
        requestBody: modalType === 'create' ? requestBody : requestBodyUpdate,
      })
    } catch (error: any) {
      console.log(error.status)
      setIsError406(error.status === 406)
      setRequestState('failure')
      setAlertActive(true)
      return
    }
    setRequestState('success')

    queryClient.invalidateQueries([KEY_RAW_MAPPINGS, mast.id])
  }

  const closeModal = () => {
    setNameValue('')
    setAlertActive(false)
    setIsError406(false)
    setRequestState('idle')
    onClose()
  }

  useEffect(() => {
    if (requestState === 'success') {
      const timerCloseModalAero = setTimeout(() => {
        closeModal()
      }, 750)
      return () => clearTimeout(timerCloseModalAero)
    }
  }, [requestState])

  useEffect(() => {
    setAlertActive(false)
    setRequestState('idle')
  }, [tsIn, tsFin, mast, sensorSelected, metricSelected])

  useEffect(() => {
    if (sensors) {
      setSensorSelected(sensors[0])
    }
  }, [sensors])

  const verb = modalType === 'update' ? 'Atualizar' : 'Criar'

  return (
    <Modal
      variant={ModalVariant.small}
      title={`${verb} Mapeamento de Coluna`}
      isOpen={isOpen}
      onClose={onClose}
      width={'500px'}
      actions={[
        <Button
          key='create'
          variant='primary'
          form='modal-with-form-form'
          onClick={handleSubmit}
          isBlock
        >
          Salvar
        </Button>,
        <Button key='cancelar' variant='danger' isDanger onClick={onClose} isBlock>
          Cancelar
        </Button>,
      ]}
    >
      <Stack hasGutter>
        <StackItem>
          <Split hasGutter>
            <SplitItem>
              <span>Sensor</span>
              <Select<Sensors>
                items={sensors}
                itemKeyName='id'
                itemValueName='label'
                selected={sensorSelected}
                onChange={e => setSensorSelected(e)}
                selectProps={{ width: '200px', menuAppendTo: 'parent' }}
              />
            </SplitItem>
            <SplitItem>
              <span>Métrica</span>
              <Select<{ name: string }>
                items={METRICS}
                itemKeyName='name'
                itemValueName='name'
                selected={metricSelected}
                onChange={e => setMetricSelected(e)}
                selectProps={{ width: '200px', menuAppendTo: 'parent' }}
              />
            </SplitItem>
          </Split>
        </StackItem>
        <StackItem>
          <span>Coluna</span>
          <TextInput
            isRequired
            type='text'
            id='name-text-input-modal-raw-mapping'
            value={nameValue ? nameValue : ''}
            onChange={(_, value) => setNameValue(value)}
          />
        </StackItem>
        <StackItem>
          <span>Período</span>
          <DatetimeRangePicker
            value={{ startDate: tsIn, endDate: tsFin }}
            onChange={(newStart, newEnd) => {
              setTsIn(newStart)
              setTsFin(newEnd)
            }}
            maxEnabled={new Date(mast.dataset_end)}
            minEnabled={new Date(mast.dataset_start)}
            calendarVariant='datetime'
            variant='label'
          />
        </StackItem>
      </Stack>

      {REQUEST_STATE_MESSAGES[requestState]}
      {alertActive && (
        <>
          {(tsIn < new Date(mast.dataset_start) || tsFin > new Date(mast.dataset_end)) && (
            <Alert
              variant='danger'
              className='pf-v5-u-my-sm'
              isInline
              title='Data inicial deve ser menor que data final'
            />
          )}
          {nameValue === '' && (
            <Alert
              variant='danger'
              className='pf-v5-u-my-sm'
              isInline
              title='Preencha o campo de coluna'
            />
          )}
          {!sensorSelected && !sensors[0] && (
            <Alert
              variant='danger'
              className='pf-v5-u-my-sm'
              isInline
              title='Selecione um sensor'
            />
          )}
          {isError406 && (
            <Alert
              variant='danger'
              isInline
              className='pf-v5-u-my-sm'
              title='Já existe um evento com essas características'
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default ModalRawMapping
