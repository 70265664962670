import {
  Alert,
  Button,
  Icon,
  Popover,
  PopoverPosition,
  Split,
  SplitItem,
} from '@patternfly/react-core'

import { CheckIcon, OutlinedCalendarAltIcon } from '@patternfly/react-icons'

import { css } from '@patternfly/react-styles'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import useMobile from '../../hooks/useMobile'
import { replaceFilterParams } from '../../utils/url'
import ButtonsRange, { IConfigButtonProps } from './ButtonsRange'
import CalendarDatetimeRange from './CalendarDatetimeRange'
import CalendarTextInput from './CalendarTextInput'
import DateArrow from './DateArrow'
import ModalCalendar from './ModalCalendar'
import MonthTextInput from './MonthTextInput'

const DatetimeRangePicker = ({
  value,
  onChange,
  startLabel = 'Inicio',
  endLabel = 'Fim',
  variant = 'arrows',
  calendarVariant = 'datetime',
  isButtonsRangeVisible = false,
  configButtonsRange,
  removeButtonsRange,
  minEnabled,
  maxEnabled,
  validators,
  rangeValidators,
  messageAlert,
  allowEqualDates,
  enableUrlParams = false,
  className = 'pf-v5-u-my-sm',
  showCalendarIcon = true,
}: {
  value: { startDate: Date; endDate: Date }
  minEnabled?: Date
  maxEnabled?: Date
  onChange: (newStart: Date, newEnd: Date) => void
  startLabel?: string
  endLabel?: string
  variant?: 'arrows' | 'label' | 'simple'
  calendarVariant?: 'datetime' | 'date' | 'month'
  isButtonsRangeVisible?: boolean
  configButtonsRange?: IConfigButtonProps[]
  removeButtonsRange?: string[]
  validators?: ((date: Date) => boolean)[]
  rangeValidators?: ((startDate: Date, endDate: Date) => boolean)[]
  messageAlert?: string | null
  allowEqualDates?: boolean
  enableUrlParams?: boolean
  className?: string
  showCalendarIcon?: boolean
}) => {
  const { startDate, endDate } = value
  const [innerStartDate, setInnerStartDate] = useState(startDate)
  const [innerEndDate, setInnerEndDate] = useState(endDate)
  const [isVisible, setIsVisible] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const isMobile = useMobile()
  const navigate = useNavigate()
  const location = useLocation()
  const messageDefault = 'Teste escolher outro período.'

  const validateRange = (startDate: Date, endDate: Date): [boolean, Date, Date] => {
    if (
      !!rangeValidators &&
      [...rangeValidators]
        .map(rangeValidator => rangeValidator(startDate, endDate))
        .some(validation => validation === false)
    ) {
      return [false, startDate, endDate]
    }
    if (
      (startDate >= endDate && !allowEqualDates) ||
      (calendarVariant === 'datetime'
        ? startDate >= endDate && allowEqualDates
        : startDate > endDate && allowEqualDates)
    ) {
      return [false, startDate, endDate]
    }
    return [true, startDate, endDate]
  }

  useEffect(() => {
    setInnerStartDate(startDate)
    setInnerEndDate(endDate)
  }, [startDate, endDate])

  let icon
  if (!isValid) {
    icon = (
      <Icon color='red'>
        <OutlinedCalendarAltIcon />
      </Icon>
    )
  } else if (isVisible) {
    icon = (
      <Icon color='green'>
        <CheckIcon />
      </Icon>
    )
  } else {
    icon = (
      <Icon color='black'>
        <OutlinedCalendarAltIcon />
      </Icon>
    )
  }

  const handleSetInnerEndDate = (value: Date) => {
    const [isValid, _d, endDate] = validateRange(innerStartDate, value)
    setInnerEndDate(endDate)
    setIsValid(isValid)
  }
  const handleSetInnerStartDate = (value: Date) => {
    const [isValid, startDate, _d] = validateRange(value, innerEndDate)
    setInnerStartDate(startDate)
    setIsValid(isValid)
  }

  const _onChangeConfirm = () => {
    const [isValid, startDate, endDate] = validateRange(innerStartDate, innerEndDate)
    if (!isValid) return
    onChange(startDate, endDate)
    if (!enableUrlParams || !isValid) return
    const newLocation = replaceFilterParams(location, [innerStartDate, innerEndDate], 'period')
    navigate(newLocation)
  }

  const toggleVisible = () => {
    if (!isVisible) return setIsVisible(true)
    _onChangeConfirm()
    setIsVisible(false)
  }

  useEffect(() => {
    if (variant === 'arrows') _onChangeConfirm()
  }, [innerStartDate, innerEndDate])

  const calendarDatetime = (
    <div className='pf-v5-u-p-sm'>
      <CalendarDatetimeRange
        startDate={innerStartDate}
        endDate={innerEndDate}
        onChangeStart={handleSetInnerStartDate}
        onChangeEnd={handleSetInnerEndDate}
        startLabel={startLabel}
        endLabel={endLabel}
        variant={calendarVariant}
        minEnabled={minEnabled}
        maxEnabled={maxEnabled}
        validators={validators}
        allowEqualDates={allowEqualDates}
      />
      {isButtonsRangeVisible && calendarVariant !== 'month' && (
        <ButtonsRange
          startDate={innerStartDate}
          setEndDate={handleSetInnerEndDate}
          configButtonsRange={configButtonsRange}
          removeButtonsRange={removeButtonsRange}
        />
      )}
      {!isValid && (
        <Alert isInline variant='warning' component='h3' title='Período Inválido'>
          <p>{messageAlert && rangeValidators ? messageAlert : messageDefault}</p>
        </Alert>
      )}
    </div>
  )
  const toggleButton = showCalendarIcon ? (
    <Button className={css(className)} variant='control' icon={icon} onClick={toggleVisible} />
  ) : (
    <></>
  )

  return (
    <div className='pf-v5-u-mx-sm'>
      <Split>
        {variant === 'simple' ? null : (
          <SplitItem>
            {variant === 'arrows' ? (
              <DateArrow
                direction='left'
                tsIn={innerStartDate}
                tsFin={innerEndDate}
                setTsIn={setInnerStartDate}
                setTsFin={setInnerEndDate}
                variant={calendarVariant}
                className={css(className)}
              />
            ) : (
              <Button className={css(className)} variant='control' onClick={toggleVisible}>
                {startLabel}
              </Button>
            )}
          </SplitItem>
        )}
        <SplitItem isFilled>
          {calendarVariant === 'month' ? (
            <MonthTextInput
              date={innerStartDate}
              showCalendarIcon={false}
              onClick={toggleVisible}
              className={css(className)}
            />
          ) : (
            <CalendarTextInput
              value={innerStartDate}
              onChange={date => {
                const [isValid, startDate, _d] = validateRange(date, innerEndDate)
                setIsValid(isValid)
                if (!isValid) return
                setInnerStartDate(startDate)
              }}
              variant={calendarVariant}
              showCalendarIcon={false}
              className={css(className)}
            />
          )}
        </SplitItem>
        {variant === 'label' && (
          <SplitItem>
            <Button className={css(className)} variant='control' onClick={toggleVisible}>
              {endLabel}
            </Button>
          </SplitItem>
        )}
        <SplitItem>
          {calendarVariant === 'month' ? (
            <MonthTextInput
              date={innerEndDate}
              showCalendarIcon={false}
              onClick={toggleVisible}
              className={css(className)}
            />
          ) : (
            <CalendarTextInput
              value={innerEndDate}
              onChange={date => {
                const [isValid, _d, endDate] = validateRange(startDate, date)
                setIsValid(isValid)
                if (!isValid) return
                setInnerEndDate(endDate)
              }}
              variant={calendarVariant}
              showCalendarIcon={false}
              className={css(className)}
            />
          )}
        </SplitItem>
        <SplitItem>
          {isMobile ? (
            <>
              {toggleButton}
              <ModalCalendar isModalOpen={isVisible} toggleModal={toggleVisible}>
                {calendarDatetime}
              </ModalCalendar>
            </>
          ) : (
            <Popover
              aria-label='DateTimeRangePickerPopover'
              position={PopoverPosition.bottom}
              enableFlip
              flipBehavior={['bottom', 'bottom-end', 'bottom-start', 'top-end', 'top-start']}
              hideOnOutsideClick
              hasAutoWidth
              hasNoPadding
              withFocusTrap={false}
              showClose={false}
              isVisible={isVisible && !isMobile}
              bodyContent={calendarDatetime}
            >
              {toggleButton}
            </Popover>
          )}
        </SplitItem>

        {variant === 'simple' ? null : (
          <SplitItem>
            {variant === 'arrows' ? (
              <DateArrow
                direction='right'
                tsIn={innerStartDate}
                tsFin={innerEndDate}
                setTsIn={setInnerStartDate}
                setTsFin={setInnerEndDate}
                variant={calendarVariant}
                className={css(className)}
              />
            ) : null}
          </SplitItem>
        )}
      </Split>
    </div>
  )
}

export default DatetimeRangePicker
