/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TowerHeatmapPivot } from '../models/TowerHeatmapPivot';
import type { TowerIdentifier } from '../models/TowerIdentifier';
import type { TowerMeasurementsData } from '../models/TowerMeasurementsData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TowersAPI {

  /**
   * Get All
   * @returns TowerIdentifier Successful Response
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<TowerIdentifier>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/towers',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Heatmap
   * @returns TowerHeatmapPivot Successful Response
   * @throws ApiError
   */
  public static getHeatmap({
    towerId,
    initialDate,
    finalDate,
  }: {
    towerId: number,
    initialDate?: string,
    finalDate?: string,
  }): CancelablePromise<TowerHeatmapPivot> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/towers/{tower_id}/heatmap',
      path: {
        'tower_id': towerId,
      },
      query: {
        'initial_date': initialDate,
        'final_date': finalDate,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Measurements
   * @returns TowerMeasurementsData Successful Response
   * @throws ApiError
   */
  public static getMeasurements({
    towerId,
    initialDate,
    finalDate,
  }: {
    towerId: number,
    initialDate?: string,
    finalDate?: string,
  }): CancelablePromise<TowerMeasurementsData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/towers/{tower_id}/measurements',
      path: {
        'tower_id': towerId,
      },
      query: {
        'initial_date': initialDate,
        'final_date': finalDate,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
