import { Button, Modal, Split, SplitItem } from '@patternfly/react-core'
import { ReactElement } from 'react'

interface IModalCalendar {
  toggleModal: () => void
  isModalOpen: boolean
  children: ReactElement
}

const ModalCalendar = ({ toggleModal, isModalOpen, children }: IModalCalendar) => {
  return (
    <Modal
      showClose={false}
      aria-label='ModalCalendar'
      variant='small'
      onClose={toggleModal}
      actions={[
        <Button key='confirm' variant='primary' onClick={toggleModal}>
          Confirmar
        </Button>,
      ]}
      isOpen={isModalOpen}
    >
      <Split>
        <SplitItem isFilled></SplitItem>
        <SplitItem>{children}</SplitItem>
        <SplitItem isFilled></SplitItem>
      </Split>
    </Modal>
  )
}

export default ModalCalendar
