/*

  - Não Utilizar este componente -

  Descontinuado em favor do 'src/components/selects/Select.tsx'

  - Não Utilizar este componente -

  */

import { Select, SelectOption } from '@patternfly/react-core/deprecated'
import { useState } from 'react'
import LoadingButton from '../../../../components/LoadingButton'

import './style.css'

const SelectList = props => {
  /*

  - Não Utilizar este componente -

  Descontinuado em favor do 'src/components/selects/Select.tsx'

  - Não Utilizar este componente -

  */

  const { list, startSelected, onChange, isLoading, maxHeight } = props
  const [isOpen, setOpen] = useState(false)
  const handleToggle = toggled => {
    setOpen(toggled)
  }

  /*

  - Não Utilizar este componente -

  Descontinuado em favor do 'src/components/selects/Select.tsx'

  - Não Utilizar este componente -

  */

  const handleSelect = (_, selection) => {
    if (onChange) onChange(selection)
    setOpen(false)
  }

  if (!list || list.length === 0 || isLoading) {
    return <LoadingButton />
  }

  return (
    <div className={'pf-v5-u-p-sm'}>
      <Select
        direction={props.direction}
        id={`select_${JSON.stringify(list.map(i => i.label))}`}
        position='right'
        isOpen={isOpen}
        onSelect={handleSelect}
        selections={startSelected}
        onToggle={(_event, e) => handleToggle(e)}
        maxHeight={maxHeight || 600}
        menuAppendTo='parent'
      >
        {list.map((item, index) => {
          return (
            <SelectOption
              inputId={`sel_opt_${item.label}_${item.value}_inp_id`}
              id={`sel_opt_${item.label}_${item.value}`}
              key={index}
              value={item.value}
            >
              {' '}
              {item.label}{' '}
            </SelectOption>
          )
        })}
      </Select>
    </div>
  )
}
/*

- Não Utilizar este componente -

Descontinuado em favor do 'src/components/selects/Select.tsx'

- Não Utilizar este componente -

*/

export default SelectList
