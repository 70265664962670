import {
  Button,
  Flex,
  Panel,
  PanelHeader,
  PanelMain,
  PickOptional,
  Text,
} from '@patternfly/react-core'
import { SelectProps } from '@patternfly/react-core/deprecated'
import { Eraser } from 'lucide-react'
import { useContext, useMemo, useState } from 'react'

import { CalibrationEvents, MastsAPI } from '../../../client'
import { ActionState } from '../../../components/actionState'
import { Divider } from '../../../components/Divider'
import EntityTable from '../../../components/EntityTable'
import ModalConfirmationWarning from '../../../components/ModalConfirmationWarning'
import Select from '../../../components/selects/Select'
import MastContext, { Sensors } from '../../../contexts/MastContext'
import useMobile from '../../../hooks/useMobile'
import { queryClient } from '../../../services/api'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { fmtDateStringToBRLDateString } from '../../../utils/formatDate'
import { getFirstSegment } from '../../../utils/getFirstSegment'

import { ICalibrationsProps } from '.'
import {
  BUTTONS_HEADER_HEIGHT,
  DEFAULT_SELECT_SENSOR,
  DEFAULT_SELECT_USUARIO,
  getCalibrationKey,
  GRID_SIZE,
} from './constants'

export default function TabelaCalibration({
  eventSelected,
  sensorFilter,
  setEventSelected,
  usuarioFilter,
  clearFilters,
  setSensorFilter,
  setUsuarioFilter,
  calibrationEvents,
  isLoadingCalibrationEvents,
  setIsModalCalibrationOpen,
  setDataToUpdate,
}: {
  eventSelected: ICalibrationsProps['eventSelected']
  sensorFilter: ICalibrationsProps['sensorFilter']
  setEventSelected: ICalibrationsProps['setEventSelected']
  usuarioFilter: ICalibrationsProps['usuarioFilter']
  clearFilters: ICalibrationsProps['clearFilters']
  setSensorFilter: ICalibrationsProps['setSensorFilter']
  setUsuarioFilter: ICalibrationsProps['setUsuarioFilter']
  calibrationEvents: CalibrationEvents[]
  isLoadingCalibrationEvents?: boolean
  setIsModalCalibrationOpen: React.Dispatch<React.SetStateAction<boolean>>
  setDataToUpdate: React.Dispatch<React.SetStateAction<CalibrationEvents | undefined>>
}) {
  const isMobile = useMobile()
  const { mast, sensors } = useContext(MastContext)

  const filtersCalibrationEvents = useMemo(() => {
    return calibrationEvents.filter(c => {
      if (sensorFilter && c.sensor_id !== sensorFilter) return false
      if (usuarioFilter && c.username !== usuarioFilter) return false
      return true
    })
  }, [sensorFilter, usuarioFilter, calibrationEvents])

  const [isModalConfirmationWarningOpen, setIsModalConfirmationWarningOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<CalibrationEvents>()
  const [actionState, setActionState] = useState<ActionState>('idle')

  const deleteEventCalibration = async () => {
    setActionState('pending')
    try {
      if (!dataToDelete || !dataToDelete?.id) return
      await MastsAPI.deleteCalibration({ id: dataToDelete.id, mastId: dataToDelete?.mast_id })
      setActionState('success')
    } catch (error) {
      setActionState('failure')
      return
    } finally {
      await queryClient.invalidateQueries(getCalibrationKey(mast.id))
      setActionState('idle')
      setDataToDelete(undefined)
      setIsModalConfirmationWarningOpen(false)
    }
  }

  return (
    <Panel
      className='pf-v5-u-box-shadow-sm'
      style={{
        backgroundColor: '#ffffff',
        height: calcVerticalHeight({ elementSize: GRID_SIZE / 2, gridSize: GRID_SIZE }),
        overflow: 'hidden',
      }}
    >
      <PanelHeader
        style={{
          paddingTop: 0,
          height: `${BUTTONS_HEADER_HEIGHT}rem`,
          ...(isMobile && { height: 'auto' }),
        }}
      >
        <Flex
          style={{
            ...(isMobile && { flexDirection: 'column' }),
            height: `${BUTTONS_HEADER_HEIGHT}rem`,
            gap: '5px',
          }}
        >
          <Flex
            style={{
              margin: 0,
              ...(isMobile && { flexDirection: 'column', gap: 5 }),
              height: `${BUTTONS_HEADER_HEIGHT}rem`,
              alignItems: 'center',
            }}
          >
            <Select<typeof DEFAULT_SELECT_SENSOR>
              items={itemsSensor(calibrationEvents, sensors)}
              itemValueName={'nome'}
              itemKeyName={'id'}
              selected={selectedSensor(sensorFilter, sensors)}
              onChange={v => {
                if (v.id === 0) return setSensorFilter(null)
                setSensorFilter(v.id)
              }}
              selectProps={selectProps(isMobile)}
              className='pf-v5-u-p-0'
              enableFilter
            />
            <Select<typeof DEFAULT_SELECT_USUARIO>
              items={itemsUsuario(calibrationEvents)}
              itemValueName={'nome'}
              itemKeyName={'nome'}
              selected={selectedUsuario(usuarioFilter)}
              onChange={v => {
                if (v.nome === DEFAULT_SELECT_USUARIO.nome) return setUsuarioFilter(null)
                setUsuarioFilter(v.nome)
              }}
              selectProps={selectProps(isMobile)}
              className='pf-v5-u-p-0'
            />
            <Button
              variant='control'
              style={{
                height: '36px',
                margin: 0,
                width: 'fit-content',
                ...(isMobile && { width: '100%' }),
              }}
              onClick={clearFilters}
              isDisabled={
                sensorFilter === null && usuarioFilter === null && eventSelected === undefined
              }
              className='pf-v5-u-p-none'
            >
              <Flex style={{ margin: 0, padding: 0, gap: 5 }}>
                <Eraser size={'16px'} style={{ margin: 0, alignSelf: 'center' }} />
                <Text style={{ margin: 0, padding: 0 }}>Limpar</Text>
              </Flex>
            </Button>
          </Flex>
          <Divider />
          <Button
            onClick={() => {
              setDataToUpdate(undefined)
              setIsModalCalibrationOpen(true)
            }}
            style={{
              height: '36px',
              margin: 0,
              width: 'fit-content',
              ...(isMobile && { width: '100%' }),
            }}
          >
            Adicionar
          </Button>
        </Flex>
      </PanelHeader>
      <PanelMain>
        <EntityTable<CalibrationEvents>
          className='table_calibration'
          items={filtersCalibrationEvents}
          isLoading={isLoadingCalibrationEvents}
          itemKeyName='id'
          isCompact
          columnConfig={[
            { key: 'id', description: 'Id', formatter: getFirstSegment },
            {
              key: 'sensor_id',
              description: 'Sensor',
              formatter: v => sensors.find(s => s.id === v)?.label || '',
            },
            {
              key: 'start_time',
              description: 'Início',
              formatter: fmtDateStringToBRLDateString,
            },
            {
              key: 'end_time',
              description: 'Fim',
              formatter: fmtDateStringToBRLDateString,
            },
            { key: 'slope', description: 'Slope' },
            { key: 'offset', description: 'Offset' },
            { key: 'username', description: 'Usuário' },
          ]}
          onClickDelete={evt => {
            setDataToDelete(evt)
            setIsModalConfirmationWarningOpen(true)
          }}
          onClickEdit={evt => {
            setDataToUpdate(evt)
            setIsModalCalibrationOpen(true)
          }}
          style={{
            maxHeight: `calc(${calcVerticalHeight({
              elementSize: GRID_SIZE / 2,
              gridSize: GRID_SIZE,
            })} - ${BUTTONS_HEADER_HEIGHT}rem)`,
            minHeight: `calc(${calcVerticalHeight({
              elementSize: GRID_SIZE / 2,
              gridSize: GRID_SIZE,
            })} - ${BUTTONS_HEADER_HEIGHT}rem)`,
          }}
          onSelect={e => setEventSelected(e)}
          {...(eventSelected !== undefined && {
            selected: filtersCalibrationEvents.find(e => e.id === eventSelected.id),
          })}
        />
      </PanelMain>
      <ModalConfirmationWarning<CalibrationEvents | undefined>
        isOpen={isModalConfirmationWarningOpen}
        handleModalToggle={() => {
          setActionState('idle')
          setIsModalConfirmationWarningOpen(prev => !prev)
        }}
        handleSubmit={deleteEventCalibration}
        title='Remover evento de calibração'
        element={dataToDelete}
        actionState={actionState}
        key={`modal-confirmation-warning-${dataToDelete?.id}`}
      />
    </Panel>
  )
}

function selectProps(isMobile: boolean): PickOptional<SelectProps> {
  return {
    menuAppendTo: 'parent',
    maxHeight: `calc(${calcVerticalHeight({
      elementSize: GRID_SIZE / 2,
      gridSize: GRID_SIZE,
    })} - ${BUTTONS_HEADER_HEIGHT}rem)`,
    ...(isMobile && { width: '89vw' }),
  }
}

function itemsSensor(calibrationEvents: CalibrationEvents[], sensors: Sensors[]) {
  function getUniqueBySensorId() {
    return calibrationEvents.filter(
      (item, index, self) => index === self.findIndex(t => t.sensor_id === item.sensor_id)
    )
  }

  return [
    DEFAULT_SELECT_SENSOR,
    ...getUniqueBySensorId().map(c => ({
      id: c.sensor_id,
      nome: sensors.find(s => s.id === c.sensor_id)?.label || '',
    })),
  ]
}

function selectedSensor(sensorFilter: number | null, sensors: Sensors[]) {
  return sensorFilter !== null
    ? {
        id: sensorFilter,
        nome: sensors.find(s => s.id === sensorFilter)?.label || '',
      }
    : DEFAULT_SELECT_SENSOR
}

function itemsUsuario(calibrationEvents: CalibrationEvents[]) {
  function getUniqueByUser() {
    return calibrationEvents.filter(
      (item, index, self) => index === self.findIndex(t => t.username === item.username)
    )
  }

  return [DEFAULT_SELECT_USUARIO, ...getUniqueByUser().map(c => ({ nome: c.username }))]
}

function selectedUsuario(usuarioFilter: string | null) {
  return usuarioFilter !== null ? { nome: usuarioFilter } : DEFAULT_SELECT_USUARIO
}
