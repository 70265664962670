import { EnergyBridgePage } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'

import useVertical from '../../hooks/useVertical'
import Plot from '../shared/Plot'

const PlotEnergyBridgeTotals = ({
  revision,
  energyBridge,
  isLoading,
}: {
  revision: number
  energyBridge: EnergyBridgePage
  isLoading: boolean
}) => {
  const isVertical = useVertical()

  if (isLoading) return null
  const layout = {
    template: layoutTemplate,
    margin: { b: isVertical ? 105 : 55, t: 10, l: 50, r: 30 },
    hovermode: 'x',
    showlegend: false,
    yaxis: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      fixedrange: true,
      title: 'Production [GWh]',
    },
    xaxis: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      fixedrange: true,
    },
  }
  const config = { ...defaultConfig, displayModeBar: false }
  const style = {
    height: calcVerticalHeight({
      gridSize: isVertical ? 1 : 2,
      elementSize: 1,
      customOffset: isVertical ? '5rem' : '1rem',
    }),
    minHeight: '20rem',
    minWidth: isVertical ? '700px' : '300px',
  }

  const {
    budget_gwh,
    windness_gwh,
    electrical_eff_gwh,
    energy_av_gwh,
    curtailment_gwh,
    power_perf_gwh,
    remainder_gwh,
    actual_gwh,
  } = energyBridge.totals

  const budget = budget_gwh || 1
  const actual = actual_gwh || 0

  const formatText = (value: number) =>
    `${value.toFixed(2)} GWh ${((value / budget) * 100).toFixed(2)}%`
  const data = [
    {
      name: 'Energy Bridge',
      type: 'waterfall',
      decreasing: { marker: { color: COLORS.cinza_medio } },
      increasing: { marker: { color: COLORS.azul_escuro } },
      totals: { marker: { color: COLORS.verde } },
      base: 100,
      measure: [
        'absolute',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'absolute',
      ],
      text: [
        `${budget.toFixed(2)} GWh`,
        formatText(windness_gwh || 0),
        formatText(electrical_eff_gwh || 0),
        formatText(energy_av_gwh || 0),
        formatText(curtailment_gwh || 0),
        formatText(power_perf_gwh || 0),
        formatText(remainder_gwh || 0),
        `${actual.toFixed(2)} GWh`,
      ],
      x: [
        'Budget',
        'Wind Regime',
        'Electrical Efficiency',
        'Energy-based Av.',
        'Grid Curtailment',
        'Power Performance',
        'Uncertainty',
        'Actual',
      ],
      y: [
        budget_gwh,
        windness_gwh,
        electrical_eff_gwh,
        energy_av_gwh,
        curtailment_gwh,
        power_perf_gwh,
        remainder_gwh,
        actual_gwh,
      ],
    },
  ]

  return (
    <div className='pf-v5-u-p-sm' style={{ overflowX: 'auto', width: '100%' }}>
      <Plot
        divId='EnergyBridgeTotalsPlot'
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        key={revision}
      />
    </div>
  )
}

export default PlotEnergyBridgeTotals
