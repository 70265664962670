import {
  Button,
  Drawer,
  DrawerActions,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentBody,
  DrawerHead,
  DrawerPanelContent,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core'
import { SelectVariant } from '@patternfly/react-core/deprecated'
import { CogIcon } from '@patternfly/react-icons'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import {
  Alarms,
  AlarmView,
  ExtendedAlarms,
  ProductionAPI,
  ProductionTurbDataSignals,
} from '../../client'
import MultiSelect from '../../components/selects/MultiSelect'
import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { COLOR_SCALES } from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import Empty from './Empty'
import Loading from './Loading'
import { PAGINATION_HEIGHT, TAB_HEADER_HEIGHT } from './lowerSectionHeight'
import SettingsPanel from './SettingsPanel'

const colorScale = COLOR_SCALES.categorical_reversed
const traceOffset = 0.06

const getDomain = (signalQty: number) => [0, 0.99 - signalQty * traceOffset]

const WRAPPER_STYLE = {
  height: calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    customOffset: `${TAB_HEADER_HEIGHT + PAGINATION_HEIGHT}rem`,
  }),
}

const FILTER_BAR_HEIGHT = 3

const PLOT_STYLE = {
  height: calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    customOffset: `${TAB_HEADER_HEIGHT + PAGINATION_HEIGHT + FILTER_BAR_HEIGHT}rem`,
  }),
}

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

interface IAlarmsSelect {
  description: string
}
interface IData {
  signal: ProductionTurbDataSignals
  x: string[]
  name: string
  mode: string
  signalX: {
    signal: string
  }
  signalY: {
    signal: string
  }
  marker: {
    color: string
  }
}

interface IPoints {
  data: IData
  y: number
  x: string
  yaxis: {
    _id: string
  }
}

const Timeline = ({
  alarmView,
  extendedAlarmView,
  revision,
  isFetching,
  site_id,
  tsIn,
  tsFin,
  turb_id,
  isSettingsOpen,
  setSettingsOpen,
  spChartAnnotations,
  setSpChartAnnotations,
  spChartAnnotationMode,
  setSpChartAnnotationMode,
}: {
  revision: number
  isFetching: boolean
  alarmView?: AlarmView
  extendedAlarmView?: AlarmView
  site_id: number
  tsIn: string
  tsFin: string
  turb_id: number
  isSettingsOpen: boolean
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>
  spChartAnnotations: any
  setSpChartAnnotations: React.Dispatch<React.SetStateAction<any>>
  spChartAnnotationMode: boolean
  setSpChartAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { turbSignals, turbSignalMap, site } = useContext(SitesContext)
  const getActivePower = () => turbSignals.filter(e => e.signal_id === site.signal_id_pwr)
  const [selectedSignals, setSelectedSignals] = useState<ProductionTurbDataSignals[]>(
    getActivePower()
  )
  const [selectedAlarms, setSelectedAlarms] = useState<IAlarmsSelect[]>([])
  const [availableAlarms, setAvailableAlarms] = useState<IAlarmsSelect[]>([])
  const [events, setEvents] = useState<(Alarms | ExtendedAlarms)[]>([])

  useEffect(() => {
    const activePower = getActivePower()
    setSelectedSignals(activePower)
  }, [site])

  useEffect(() => {
    let events = alarmView ? [...alarmView.alarms] : []

    if (extendedAlarmView && extendedAlarmView.alarms) {
      events = [...events, ...extendedAlarmView.alarms]
    }

    const uniqueAlarmList = [...new Set(events.map(e => e.description))]
    const uniqueAlarmObjectList = uniqueAlarmList.sort().map(e => {
      return { description: e }
    })

    setEvents(events)
    setSelectedAlarms(uniqueAlarmObjectList)
    setAvailableAlarms(uniqueAlarmObjectList)
  }, [alarmView, site])

  const enabled = [
    !!site_id,
    !!selectedSignals && selectedSignals.length > 0,
    !!turb_id || turb_id > 0,
    !isFetching,
  ].every(p => p)

  const { data: turbdata } = useQuery(
    ['turbdataAlarmTimeline', site_id, tsIn, tsFin, turb_id, selectedSignals, isFetching],
    async () =>
      ProductionAPI.getTurbdata({
        siteId: site_id,
        signalId: selectedSignals.map(s => s.signal_id),
        turbId: [turb_id],
        tsIn,
        tsFin,
      }),
    { ...defaultQueryOptions, enabled, placeholderData: [] }
  )

  if (isFetching) {
    return (
      <div style={PLOT_STYLE}>
        <Loading />
      </div>
    )
  }
  if (!alarmView || !alarmView.alarms) {
    return (
      <div style={PLOT_STYLE}>
        <Empty />
      </div>
    )
  }

  const domain = getDomain(selectedSignals.length)

  const layout = {
    template: layoutTemplate,
    margin: { b: 50, t: 35, l: 50, r: 10 },
    showlegend: false,
    hovermode: 'closest',
    annotations: spChartAnnotations,
    xaxis: {
      ...defaultAxis,
      type: 'date',
      showticklabels: true,
      domain,
    },
    yaxis: {
      ...defaultAxis,
      automargin: true,
      type: 'category',
    },
  }

  const config = { ...defaultConfig }
  const alarmTrace = events
    .filter(a => selectedAlarms.some(alarm => alarm.description === a.description))
    .filter(a => !!a.dur_min && !!a.ts_in && a.ts_in && a.dur_min > 19)
    .map(a => {
      return {
        x: [a.ts_in, a.ts_fin],
        y: [a.description, a.description],
        name: a.description,
        orientation: 'h',
        hoverinfo: 'all',
        mode: 'dots',
      }
    })

  // Actually should never ever be empty given that we are setting a placeholderData
  if (!turbdata) {
    console.error('turbdata is empty. This section should never be reached.')
    return null
  }

  const turbdataTrace = turbdata
    .map((tb, tbi) => {
      if (tb.signals.length === 0) return null
      return tb.signals.map((s, si) => {
        const i = tbi * tb.signals.length + si + 2
        const signal = turbSignalMap.get(s.signal_id)
        if (!signal) return null
        const color = colorScale[si][tbi]

        Object.assign(layout, {
          [`yaxis${i}`]: {
            title: { text: `${signal.description}`, font: { color } },
            side: 'right',
            anchor: 'free',
            overlaying: 'y',
            position: 1 - (i - 1) * traceOffset,
            range: [signal.min_graph, signal.max_graph],
            autorange: false,
            linewidth: 2,
            gridwidth: 2,
            mirror: 'ticks',
            zeroline: false,
          },
          [`xaxis${i}`]: {
            domain,
            type: 'datetime',
            linewidth: 2,
            gridwidth: 2,
            mirror: 'ticks',
            zeroline: false,
          },
        })

        return {
          x: s.data.map(d => d.ts),
          y: s.data.map(d => d.val),
          type: 'scatter',
          mode: 'line',
          signal: signal,
          opacity: 0.4,
          marker: { color },
          yaxis: `y${i}`,
        }
      })
    })
    .flat()

  const formatStringDate = (stringDate: string) => {
    // formatStringDate | 2023-04-14T07:00:00.000000
    const removedMilliseconds = stringDate.split('.')[0] // 2023-04-14T07:00:00
    const cleanDateString = removedMilliseconds.replace('T', ' ') // 2023-04-14 07:00:00
    return cleanDateString
  }

  const data = [...alarmTrace, ...turbdataTrace].filter(d => !!d)

  const onClick = (points: IPoints[]) => {
    if (!spChartAnnotationMode) return

    const _annotations = points.map(p => {
      const { data, yaxis, x, y } = p

      const haveAnnotations = spChartAnnotations.length > 0

      if (points[0].data.mode === 'dots') {
        const infoText = `${data.name}<br> início: ${formatStringDate(
          data.x[0]
        )}<br> fim: ${formatStringDate(data.x[1])}`

        const isAlreadyInAnnotations = spChartAnnotations.filter(
          (e: { text: string }) => e?.text === infoText
        ).length

        if (isAlreadyInAnnotations) return undefined

        return {
          text: infoText,
          ax: haveAnnotations ? spChartAnnotations[0].ax : -75,
          ay: haveAnnotations ? spChartAnnotations[0].ay : -50,
          bordercolor: 'black',
          borderwidth: 2,
          bgcolor: 'white',
          borderpad: 5,
          font: { color: 'black' },
          yref: yaxis._id,
          x,
          y,
        }
      } else {
        return {
          text: `${data.signal.description}<br> ts:${x} - val:${y.toFixed(2)}`,
          ax: haveAnnotations ? spChartAnnotations[0].ax : -75,
          ay: haveAnnotations ? spChartAnnotations[0].ay : -50,
          bordercolor: data.marker.color,
          borderwidth: 2,
          bgcolor: 'white',
          borderpad: 5,
          font: { color: 'black' },
          yref: yaxis._id,
          x,
          y,
        }
      }
    })

    const filteredSpChartAnnotations = spChartAnnotations.filter((e: any) => !!e)

    setSpChartAnnotations([...filteredSpChartAnnotations, ..._annotations])
  }

  return (
    <Stack style={WRAPPER_STYLE}>
      <StackItem>
        <Split isWrappable>
          <SplitItem isFilled>
            <MultiSelect
              items={turbSignals}
              itemKeyName={'signal'}
              itemValueName={'description'}
              selected={selectedSignals}
              setSelected={setSelectedSignals}
              minSelections={0}
              maxSelections={4}
              placeholderText={'Escolha um sinal'}
              selectProps={{ variant: SelectVariant.typeaheadMulti }}
            />
          </SplitItem>
          <SplitItem isFilled>
            <MultiSelect
              items={availableAlarms}
              itemKeyName={'description'}
              itemValueName={'description'}
              selected={selectedAlarms}
              setSelected={setSelectedAlarms}
              minSelections={0}
              maxSelections={1000}
              placeholderText={'Alarmes'}
              selectProps={{ variant: SelectVariant.checkbox, footer: <></> }}
            />
          </SplitItem>
          <SplitItem>
            <Button
              key='settings_btn'
              className='pf-v5-u-m-sm pf-v5-u-display-block-on-sm pf-v5-u-display-none'
              onClick={() => setSettingsOpen(!isSettingsOpen)}
              variant='control'
              style={{ backgroundColor: 'white' }}
            >
              <CogIcon />
            </Button>
          </SplitItem>
        </Split>
      </StackItem>
      <StackItem>
        <Drawer isInline isExpanded={isSettingsOpen}>
          <DrawerContent
            panelContent={
              <DrawerPanelContent>
                <DrawerHead>
                  <TextContent>
                    <Text component={TextVariants.h1} className='pf-v5-u-m-sm'>
                      Configurações
                    </Text>
                  </TextContent>
                  <DrawerActions>
                    <DrawerCloseButton onClick={() => setSettingsOpen(false)} />
                  </DrawerActions>
                </DrawerHead>
                <SettingsPanel
                  spChartAnnotations={spChartAnnotations}
                  setSpChartAnnotations={setSpChartAnnotations}
                  spChartAnnotationMode={spChartAnnotationMode}
                  setSpChartAnnotationMode={setSpChartAnnotationMode}
                  /*layoutId={layoutId} setLayoutId={setLayoutId}*/
                />
              </DrawerPanelContent>
            }
          >
            <DrawerContentBody>
              <div style={PLOT_STYLE}>
                <Plot
                  key={revision}
                  onClick={({ points }: { points: IPoints[] }) => onClick(points)}
                  divId='alarm-timeline'
                  useResizeHandler
                  data={data}
                  layout={layout}
                  config={config}
                  revision={revision}
                  style={PLOT_STYLE}
                />
              </div>
            </DrawerContentBody>
          </DrawerContent>
        </Drawer>
      </StackItem>
    </Stack>
  )
}

export default Timeline
