import { useEffect } from 'react'
import { Pages, PagesMaintenance, Site } from '../../client'
import { ActionState, requestStateMessages } from '../../components/actionState'
import { SetStateGeneric } from '../../utils/SetStateGeneric'
import SelectList from '../shared/selects/DeprecatedSelectList'

import {
  Button,
  Form,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  TextArea,
} from '@patternfly/react-core'

function ModalPageMaintenance({
  description,
  handleModalToggle,
  isModalOpen,
  modalInfo,
  pages,
  selectedMainId,
  selectedPage,
  selectedSite,
  setDescription,
  setSelectedPage,
  setSelectedSite,
  sites,
  actionState = 'idle',
}: {
  description: PagesMaintenance['description']
  handleModalToggle: any
  isModalOpen: boolean
  modalInfo: any
  pages: Pages[] | undefined
  selectedMainId: any
  selectedPage: PagesMaintenance['page_id']
  selectedSite: PagesMaintenance['site_id']
  setDescription: SetStateGeneric<PagesMaintenance['description']>
  setSelectedPage: SetStateGeneric<PagesMaintenance['page_id']>
  setSelectedSite: SetStateGeneric<PagesMaintenance['site_id']>
  sites: Site[]
  actionState: ActionState
}) {
  const handleEnviar = () => {
    if (!selectedPage || !selectedSite || !description) return
    modalInfo.callback.mutate({
      requestBody: {
        pm_id: selectedMainId,
        page_id: selectedPage,
        site_id: selectedSite,
        description,
      },
    })
  }

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => handleModalToggle(), 750)
      return () => clearTimeout(timerCloseModal)
    }
    if (actionState === 'failure') {
      const timerCloseModal = setTimeout(() => handleModalToggle(), 5000)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      aria-label='create page maintenance'
      variant={ModalVariant.large}
      title={`${modalInfo.title} página em manutenção`}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button key='send' variant='primary' form='modal-with-form-form' onClick={handleEnviar}>
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      {requestStateMessages()[actionState]}
      <Form id='modal-with-form-form'>
        <Split>
          <SplitItem>
            <SelectList
              startSelected={selectedSite}
              onChange={setSelectedSite}
              isLoading={false}
              list={sites?.map(s => {
                return { value: s.site_id, label: s.site_name }
              })}
            />
          </SplitItem>
          <SplitItem isFilled />
          <SplitItem>
            <SelectList
              startSelected={pages?.find(p => p.page_id === selectedPage)?.page_id}
              onChange={setSelectedPage}
              isLoading={false}
              list={pages?.map(p => {
                return { value: p.page_id, label: p.name }
              })}
            />
          </SplitItem>
        </Split>
        <TextArea
          resizeOrientation='vertical'
          value={description as string}
          onChange={(_, value) => setDescription(value)}
          aria-label='description text area'
        />
      </Form>
    </Modal>
  )
}

export default ModalPageMaintenance
