import createPlotlyComponent from 'react-plotly.js/factory'

/* eslint-disable */

const Plotly = window.Plotly
const Plot = createPlotlyComponent(Plotly)

const csvIcon = {
  width: 400,
  height: 600,
  path: 'M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z',
}

const helpIcon = {
  width: 297,
  height: 299,
  path: 'M292.359 149.5C292.359 229.504 227.946 294.328 148.5 294.328C69.0542 294.328 4.64062 229.504 4.64062 149.5C4.64062 69.5426 69.0542 4.67188 148.5 4.67188C227.946 4.67188 292.359 69.5426 292.359 149.5ZM152.36 52.5586C120.748 52.5586 100.586 65.9651 84.7529 89.7923C82.7017 92.8792 83.388 97.0424 86.3278 99.2867L106.456 114.651C109.475 116.956 113.777 116.408 116.123 113.412C126.485 100.18 133.591 92.5072 149.363 92.5072C161.214 92.5072 175.872 100.185 175.872 111.754C175.872 120.5 168.7 124.991 156.999 131.596C143.354 139.297 125.297 148.882 125.297 172.859V175.195C125.297 179.065 128.414 182.203 132.258 182.203H164.742C168.586 182.203 171.703 179.065 171.703 175.195V174.417C171.703 157.795 219.958 157.103 219.958 112.125C219.958 78.2527 185.057 52.5586 152.36 52.5586V52.5586ZM148.5 197.387C133.786 197.387 121.816 209.437 121.816 224.25C121.816 239.062 133.786 251.113 148.5 251.113C163.214 251.113 175.184 239.062 175.184 224.25C175.184 209.437 163.214 197.387 148.5 197.387Z',
}

export default Plot

export { Plotly, csvIcon, helpIcon }
