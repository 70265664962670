import {
  CalendarMonth as BaseCalendarMonth,
  CalendarProps,
  capitalize,
} from '@patternfly/react-core'

const LOCALE = 'pt-BR'

const customStyle = {
  backgroundColor: 'var(--pf-v5-c-popover__content--BackgroundColor)',
  padding: '1rem 1rem 1rem 0.5rem',
}

const adjustDate = (dt: Date, days: number) => {
  const newDate = new Date(dt)
  newDate.setDate(newDate.getDate() + days)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const add24h = (dt: Date) => adjustDate(dt, 1)
const rem24h = (dt: Date) => adjustDate(dt, -1)

interface ICalendarDateProps extends CalendarProps {
  startDate?: Date
  endDate?: Date
  minEnabled?: Date
  maxEnabled?: Date
  allowEqualDates?: boolean
}

const CalendarDate = ({
  date,
  onChange,
  style,
  startDate,
  endDate,
  validators,
  minEnabled,
  maxEnabled,
  allowEqualDates = false,
  ...props
}: ICalendarDateProps) => {
  const disablePreStartDates = (date: Date) => {
    if (!!minEnabled && date < minEnabled) return false
    if (!!maxEnabled && date > add24h(maxEnabled)) return false

    if (!allowEqualDates) {
      if (endDate) return date <= rem24h(endDate)
      if (startDate) return date >= add24h(startDate)
    } else {
      if (endDate) return date < add24h(endDate)
      if (startDate) return date > rem24h(startDate)
    }

    return true
  }

  const _validators = validators ? [...validators] : []

  return (
    <BaseCalendarMonth
      date={date}
      style={{ ...customStyle, ...style }}
      locale={LOCALE}
      monthFormat={date => capitalize(date.toLocaleDateString(LOCALE, { month: 'long' }))}
      onChange={onChange}
      validators={[disablePreStartDates, ..._validators]}
      {...props}
    />
  )
}

export default CalendarDate
