import { useState } from 'react'
import PageFrame from '../shared/Page'

import { Wizard } from '@patternfly/react-core/deprecated'

import { stepsAxis, stepsExport, stepsLegend, stepsMove, stepsZoom } from './steps'

const HelpPlotly = () => {
  const [isNavOpen, setNavOpen] = useState(false)

  const steps = [...stepsAxis, ...stepsExport, ...stepsLegend, ...stepsMove, ...stepsZoom]

  return (
    <PageFrame pageName='Help - Plotly' isNavOpen={isNavOpen} setNavOpen={setNavOpen}>
      <Wizard steps={steps} height={'85vh'} footer={<></>} isNavExpandable />
    </PageFrame>
  )
}

export default HelpPlotly
