import { Button, Icon, InputGroup, InputGroupItem, InputGroupText } from '@patternfly/react-core'
import { OutlinedCalendarAltIcon } from '@patternfly/react-icons'
import { css } from '@patternfly/react-styles'
import { brlMonths } from '../../utils/months'

const MonthTextInput = ({
  date,
  className,
  showCalendarIcon = false,
  onClick,
}: {
  date: Date
  className?: string
  showCalendarIcon?: boolean
  onClick?: () => void
}) => {
  return (
    <InputGroup className={css('pf-v5-u-my-sm', 'pf-v5-u-w-100-on-sm', className)}>
      <InputGroupItem>
        <Button
          className='pf-v5-u-text-align-left pf-v5-u-text-align-center-on-md'
          style={{ width: '100%', minWidth: '9rem', backgroundColor: 'white' }}
          variant='control'
          onClick={onClick}
        >
          {brlMonths[date.getMonth()]} {date.getFullYear()}
        </Button>
      </InputGroupItem>
      <InputGroupItem>
        {showCalendarIcon ? (
          <InputGroupText className='icon-calendar-text-input'>
            <Icon color='black'>
              <OutlinedCalendarAltIcon />
            </Icon>
          </InputGroupText>
        ) : null}
      </InputGroupItem>
    </InputGroup>
  )
}

export default MonthTextInput
