import {
  Button,
  DualListSelector,
  FlexItem,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextArea,
  TextInput,
} from '@patternfly/react-core'
import { useMutation } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { GroupIn, GroupOut, GroupsAPI, PermissionTypes } from '../../client'
import { ActionState, requestStateMessages } from '../../components/actionState'
import Select from '../../components/selects/Select'
import PermissionContext from '../../contexts/PermissionContext'
import { SetStateGeneric } from '../../utils/SetStateGeneric'
import {
  dataValidator,
  DefaultOptions,
  getAvailableOptions,
  getIdList,
  mutateOptions,
  OPTIONS_DEFAULT,
} from './constants'

function ModalGroup({
  isModalOpen,
  handleModalToggle,
  type,
  instanceDefault,
  setInstanceDefault,
}: {
  isModalOpen: boolean
  handleModalToggle: () => void
  instanceDefault?: GroupOut | undefined
  setInstanceDefault?: SetStateGeneric<GroupOut | undefined>
  type: 'create' | 'edit'
}) {
  const { users, pages, permissionTypes, isLoading } = useContext(PermissionContext)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [groupNameValue, setGroupNameValue] = useState<string>('')
  const [groupDescriptionValue, setGroupDescriptionValue] = useState<string>('')

  const PERMISSION_TYPE_DEFAULT = isLoading ? undefined : permissionTypes[0]
  const [permissionType, setPermissionType] = useState(PERMISSION_TYPE_DEFAULT)
  const [membersOptions, setMembersOptions] = useState<DefaultOptions>(OPTIONS_DEFAULT)
  const [pagesOptions, setPagesOptions] = useState<DefaultOptions>(OPTIONS_DEFAULT)

  const setPageIdList = (chosenOptions: string[]) => {
    const pagesIdList: string[] = []

    pages.forEach(p => {
      chosenOptions.forEach(el => {
        if (el === p.name && p.page_id) pagesIdList.push(p.page_id)
      })
    })

    return pagesIdList
  }

  const setMemberIdList = (chosenOptions: string[]) => {
    const membersIdList: string[] = []

    users.forEach(u => {
      chosenOptions.forEach(el => {
        if (el === u.username) membersIdList.push(u.id)
      })
    })

    return membersIdList
  }

  function onMembersListChange(
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) {
    setMembersOptions({ availableOptions: newAvailableOptions, chosenOptions: newChosenOptions })
  }

  function onPagesListChange(
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) {
    setPagesOptions({ availableOptions: newAvailableOptions, chosenOptions: newChosenOptions })
  }

  const onMutate = () => setActionState('pending')
  const onError = () => setActionState('failure')
  const { mutate: createGroup } = useMutation({
    mutationFn: (data: GroupIn) => GroupsAPI.create({ requestBody: data }),
    onMutate,
    onError,
    onSuccess: () => {
      mutateOptions.onSuccess()
      setActionState('success')
    },
  })

  const { mutate: editGroup } = useMutation({
    mutationFn: (data: GroupIn) => GroupsAPI.update({ requestBody: data }),
    onMutate,
    onError,
    onSuccess: () => {
      mutateOptions.onSuccess()
      setActionState('success')
    },
  })

  function handleSubmit() {
    const _body = {
      id: instanceDefault?.id || undefined,
      name: groupNameValue,
      description: groupDescriptionValue,
      perm_type_id: permissionType?.perm_type_id,
    }

    if (type === 'create') {
      const body = {
        ..._body,
        user_ids: setMemberIdList(membersOptions.chosenOptions),
        page_ids: setPageIdList(pagesOptions.chosenOptions),
      } as GroupIn
      if (dataValidator(body)) createGroup(body)
      else {
        console.log('[ModalGroup.tsx - handleSubmit - create] Dados inválidos')
        console.log(JSON.stringify(body))
      }
    }

    if (type === 'edit') {
      if (!instanceDefault) {
        console.error('[ModalGroup.tsx - handleSubmit - update] instanceDefault is undefined')
        return
      }

      const body = {
        ..._body,
        user_ids: getIdList(users, membersOptions.chosenOptions, 'username', 'id'),
        page_ids: getIdList(pages, pagesOptions.chosenOptions, 'name', 'page_id'),
      } as GroupIn

      if (dataValidator(body)) editGroup(body)
      else {
        console.log('[ModalGroup.tsx - handleSubmit - update] Dados inválidos')
        console.log(JSON.stringify(body))
      }
    }
  }

  useEffect(() => {
    if (users.length && type === 'create') {
      setMembersOptions({ availableOptions: users.map(u => u.username), chosenOptions: [] })
    }
  }, [users])

  useEffect(() => {
    if (pages.length && type === 'create') {
      setPagesOptions({ availableOptions: pages.map(p => p.name), chosenOptions: [] })
    }
  }, [pages])

  useEffect(() => {
    if (instanceDefault && type === 'edit') {
      setGroupNameValue(instanceDefault.name)
      setGroupDescriptionValue(instanceDefault.description)
      setMembersOptions({
        availableOptions: getAvailableOptions(users, instanceDefault.user_names, 'username'),
        chosenOptions: instanceDefault.user_names,
      })
      setPagesOptions({
        availableOptions: getAvailableOptions(pages, instanceDefault.page_names, 'name'),
        chosenOptions: instanceDefault.page_names,
      })
    } else {
      setGroupNameValue('')
      setGroupDescriptionValue('')
    }
  }, [instanceDefault, type])

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
        setInstanceDefault && setInstanceDefault(undefined)
        setActionState('idle')
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      variant={ModalVariant.small}
      title={`${type === 'create' ? 'Criar' : 'Editar'} grupo`}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button key='create' variant='primary' form='modal-with-form-form' onClick={handleSubmit}>
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      {actionState !== 'idle' && (
        <FlexItem flex={{ default: 'flex_1' }} alignSelf={{ default: 'alignSelfCenter' }}>
          {requestStateMessages()[actionState]}
        </FlexItem>
      )}
      <Form id='modal-with-form-form'>
        <div className='container-main'>
          <div className='container-input'>
            <FormGroup label='Grupo' isRequired fieldId='simple-form-group-name-01'>
              <TextInput
                isRequired
                type='text'
                id='simple-form-group-name-01'
                name='simple-form-group-name-01'
                placeholder='Grupo'
                value={groupNameValue}
                onChange={(_, value) => setGroupNameValue(value)}
              />
            </FormGroup>
            {permissionType && (
              <FormGroup label='Permissão' isRequired fieldId='simple-form-group-permission-01'>
                <Select<PermissionTypes>
                  isLoading={isLoading}
                  onChange={setPermissionType}
                  itemKeyName='perm_type_id'
                  itemValueName='description'
                  items={permissionTypes}
                  selected={permissionType}
                  className='pf-v5-u-pb-sm'
                />
              </FormGroup>
            )}
          </div>
          <FormGroup label='Descrição' fieldId='simple-form-group-description-01'>
            <TextArea
              id='group-description'
              value={groupDescriptionValue}
              onChange={(_, value) => setGroupDescriptionValue(value)}
              aria-label='text area group description'
            />
          </FormGroup>
        </div>

        <DualListSelector
          availableOptionsTitle='Adicionar membros'
          availableOptions={membersOptions.availableOptions.sort()}
          chosenOptionsTitle='Membros adicionados'
          chosenOptions={membersOptions.chosenOptions.sort()}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onMembersListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='basicSelector'
        />
        <DualListSelector
          availableOptionsTitle='Vincular páginas'
          availableOptions={pagesOptions.availableOptions.sort()}
          chosenOptionsTitle='Páginas vinculadas'
          chosenOptions={pagesOptions.chosenOptions.sort()}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onPagesListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='basicSelector'
        />
      </Form>
    </Modal>
  )
}

export default ModalGroup
