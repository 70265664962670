import COLORS, { blue_100 } from '../../utils/colorScale'
import { defaultAxis, defaultConfig } from '../../utils/plotly'

import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  PLOT_STYLE,
  PLOT_TEMPORAL_YEAR_TO_DATE,
} from './constants'
import useData from './useData'

const layout = {
  paper_bgcolor: COLORS.branco,
  plot_bgcolor: COLORS.branco_clear,
  showlegend: true,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis2: { overlaying: 'x' },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}
const config = { ...defaultConfig, displayModeBar: false }

const PlotToDate = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
}) => {
  const { isLoading: dataIsLoading, monthly, daily } = useData(dateIn, dateFin)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_YEAR_TO_DATE}
          useResizeHandler
          key={revision}
          data={[]}
          layout={layout}
          config={config}
          style={PLOT_STYLE}
          onLegendClick={() => false}
        />
      </div>
    )
  }

  const _defaultAxis = { ...defaultAxis, type: 'bar', hoverinfo: 'all' }

  let monthlyTotal = 0
  const monthlySum = Object.fromEntries(COUNTERDATA_TYPE_LIST.map(d => [d, 0]))

  monthly.forEach(md => {
    COUNTERDATA_TYPE_LIST.forEach(d => {
      monthlySum[d] = monthlySum[d] + Number(md[d])
      monthlyTotal = monthlyTotal + Number(md[d])
    })
  })

  let dailyTotal = 0
  const dailySum = Object.fromEntries(COUNTERDATA_TYPE_LIST.map(d => [d, 0]))

  daily.forEach(md => {
    COUNTERDATA_TYPE_LIST.forEach(d => {
      dailySum[d] = dailySum[d] + Number(md[d])
      dailyTotal = dailyTotal + Number(md[d])
    })
  })

  const monthlyTraces = COUNTERDATA_TYPE_LIST.map(cat => {
    return {
      ..._defaultAxis,
      y: [(monthlySum[cat] / monthlyTotal) * 100],
      x: ['Acumulado Anual'],
      name: COUNTERDATA_TYPES[cat].description,
      marker: { color: COUNTERDATA_TYPES[cat].color },
    }
  })
  const dailyTraces = COUNTERDATA_TYPE_LIST.map(cat => {
    return {
      ..._defaultAxis,
      y: [(dailySum[cat] / dailyTotal) * 100],
      x: ['Acumulado Mensal'],
      name: COUNTERDATA_TYPES[cat].description,
      marker: { color: COUNTERDATA_TYPES[cat].color },
      showlegend: false,
    }
  })

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_YEAR_TO_DATE}
        useResizeHandler
        key={revision}
        data={[...dailyTraces, ...monthlyTraces]}
        layout={layout}
        config={config}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotToDate
