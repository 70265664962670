import { useContext } from 'react'
import { TurbinesHealthHeatmap } from '../../../client'
import Empty from '../../../components/Empty'
import SitesContext from '../../../contexts/SitesContext'
import useMobile from '../../../hooks/useMobile'
import Plot from '../../shared/Plot'

import * as localeDictionary from 'plotly.js/lib/locales/pt-br.js'
import { formatDateMonthStr } from '../../../utils/formatDate'

const colorScale = [
  [0.0, '#00d45c'],
  [0.5, '#F7CF00'],
  [1.0, '#b20c1d'],
]

const HeatmapPlot = ({ dataHeatmap }: { dataHeatmap?: TurbinesHealthHeatmap[] }) => {
  const { turbSignalMap } = useContext(SitesContext)
  const isMobile = useMobile()

  if (!dataHeatmap || dataHeatmap.length === 0) return null

  const [x, y, z] = [Array(), Array(), Array()]

  dataHeatmap
    .sort((a, b) => b.signal_id - a.signal_id)
    .forEach(item => {
      x.push(item.ts)
      y.push(turbSignalMap?.get(item.signal_id)?.description)
      z.push(item.val)
    })

  const xHeatmap = [...new Set(x)]
  const yHeatmap = [...new Set(y)]
  const zHeatmap = yHeatmap.map((_, i) => {
    return z.slice(i * xHeatmap.length, (i + 1) * xHeatmap.length)
  })

  const heightLineHeatmap = 28
  const bottomHeatmap = 70

  const dataPlot = [
    {
      z: zHeatmap,
      x: xHeatmap.map(date => formatDateMonthStr(new Date(date))),
      y: yHeatmap,
      xgap: 0.5,
      ygap: 0.5,
      type: 'heatmap',
      hoverongaps: false,
      colorscale: colorScale,
      zmin: 0,
      zmax: 100,
      colorbar: {
        len: 1.04,
        outlinecolor: '#fff',
      },
      showscale: false,
      hovertemplate: 'Data: %{x} <br>Test: %{y} <br>Value: %{z}',
    },
  ]

  if (dataPlot[0].z.map(arr => arr.every(value => value === null)).every(bool => bool))
    return (
      <Empty title='Nenhum dado encontrado para o período, sistema e aerogerador selecionados.' />
    )

  return (
    <Plot
      data={dataPlot}
      className='pf-v5-u-mx-2xl-on-lg pf-v5-u-my-sm-on-lg plot'
      style={{
        height: `${heightLineHeatmap * yHeatmap.length + bottomHeatmap}px`,
        minWidth: '700px',
      }}
      layout={{
        autosize: true,
        dragmode: false,
        paper_bgcolor: '#00000000',
        plot_bgcolor: '#00000000',
        hoverlabel: {
          bgcolor: '#FFF',
          tickformat: '%d %b',
          namelength: 30,
          font: { size: 14 },
        },
        margin: { l: 253, r: isMobile ? 50 : 0, b: bottomHeatmap, t: 10, pad: 5 },
        yaxis: {
          automargin: true,
          fixedrange: true,
          tickfont: { size: 11 },
        },
        xaxis: {
          visible: true,
          automargin: true,
          showgrid: false,
          fixedrange: true,
          tickformat: '%d %b',
          tickangle: -90,
          tickfont: { size: 13 },
          nticks: xHeatmap.length + 1,
          type: 'category',
        },
      }}
      useResizeHandler
      config={{
        responsive: true,
        displaylogo: false,
        displayModeBar: false,
        locales: { 'pt-BR': localeDictionary },
        locale: 'pt-BR',
      }}
    />
  )
}

export default HeatmapPlot
