import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionToggle,
  Button,
  Panel,
  PanelHeader,
  PanelMain,
} from '@patternfly/react-core'
import { CompressArrowsAltIcon, ExpandArrowsAltIcon } from '@patternfly/react-icons'

import { useEffect, useState } from 'react'
import { TurbinesHealthHeatmap, TurbinesHealthSummary } from '../../../client'
import Empty from '../../../components/Empty'
import useMobile from '../../../hooks/useMobile'
import ColorScale from '../../../utils/colorScale'
import HeatmapPlot from './HeatmapPlot'
import HeatmapPlotSummary from './HeatmapPlotSummary'

interface IHeatmapAccordionProps {
  summaryData: TurbinesHealthSummary[]
  heatmapData: TurbinesHealthHeatmap[]
  isLoading: boolean
}

const HeatmapAccordion = (props: IHeatmapAccordionProps) => {
  const { summaryData, heatmapData, isLoading } = props
  const isMobile = useMobile()
  const [expanded, setExpanded] = useState<string[]>([])
  const [summaryDataStorage, setSummaryDataStorage] = useState<TurbinesHealthSummary[]>([])
  const [heatmapDataStorage, setHeatmapDataStorage] = useState<TurbinesHealthHeatmap[]>([])

  const panelStyle = {
    minHeight: '15rem',
    background: ColorScale.branco,
  }

  useEffect(() => {
    if (!summaryData) return
    setSummaryDataStorage([...summaryData])
  }, [summaryData])

  useEffect(() => {
    if (!heatmapData) return
    setHeatmapDataStorage([...heatmapData])
  }, [heatmapData])

  const testsList = [...new Set([...summaryDataStorage].map(item => item.test))].sort((a, b) =>
    a < b ? -1 : 1
  )

  const toggle = (id: string) => {
    const index = expanded.indexOf(id)
    const newExpanded: string[] =
      index >= 0
        ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)]
        : [...expanded, id]
    setExpanded(newExpanded)
  }

  const filterSummaryData = (summaryData: TurbinesHealthSummary[], test: string) => {
    return summaryData.filter(summary => summary.test === test)
  }

  const filterHeatmapData = (heatmapData: TurbinesHealthHeatmap[], test: string) => {
    return heatmapData.filter(heatmap => heatmap.test === test)
  }

  const toggleAll = (action: string) => {
    if (action === 'minimizar') {
      setExpanded([...testsList].reverse().slice(0, 1))
      return
    }

    setExpanded([...testsList])
  }

  return (
    <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
      <PanelHeader>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <b>Heatmap</b>
          <div>
            <Button
              variant='control'
              size='sm'
              onClick={() => toggleAll('expandir')}
              className='pf-v5-u-mr-sm'
            >
              <ExpandArrowsAltIcon />
            </Button>
            <Button variant='control' size='sm' onClick={() => toggleAll('minimizar')}>
              <CompressArrowsAltIcon />
            </Button>
          </div>
        </div>
      </PanelHeader>
      <PanelMain>
        <Accordion asDefinitionList={false} isBordered>
          {testsList.map(test => (
            <AccordionItem key={`${test}_accordion_item`}>
              <AccordionToggle
                onClick={() => toggle(`${test}`)}
                isExpanded={expanded.includes(`${test}`)}
                id={`${test}`}
                className='toggle-accordion-turb-health'
              >
                {!isMobile ? (
                  <HeatmapPlotSummary
                    dataHeatmap={filterSummaryData(summaryDataStorage, test)}
                    testTitle={test}
                  />
                ) : (
                  <p className='pf-v5-u-text-align-left'>{test}</p>
                )}
              </AccordionToggle>
              <AccordionContent
                id={`${test}_expand`}
                isHidden={!expanded.includes(`${test}`)}
                style={{ minHeight: 'fit-content', maxHeight: 'unset' }}
                isFixed
              >
                <HeatmapPlot
                  dataHeatmap={filterHeatmapData(heatmapDataStorage, test)}
                  key={`heatmap_${test}`}
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
        {!isLoading && summaryDataStorage?.length === 0 && heatmapDataStorage?.length === 0 && (
          <Empty title='Nenhum dado encontrado para o período, aerogerador e sistema selecionados.' />
        )}
      </PanelMain>
    </Panel>
  )
}

export default HeatmapAccordion
