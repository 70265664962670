import { PageSection } from '@patternfly/react-core'

const PanelStickyHeader = ({ title }: { title: string }) => {
  return (
    <PageSection
      style={{ width: 'fit-content', boxShadow: 'none', backgroundColor: 'rgb(0,0,0,0)' }}
      type='breadcrumb'
      stickyOnBreakpoint={{ default: 'top' }}
    >
      {title}
    </PageSection>
  )
}

export default PanelStickyHeader
