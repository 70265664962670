import { CSSProperties, useContext } from 'react'
import { MastData, SignalTypes } from '../../../client'
import MastContext from '../../../contexts/MastContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../../utils/plotly'
import { sortByDateKey } from '../../../utils/sort'
import Plot from '../../shared/Plot'
import useData from '../useData'

const config = { ...defaultConfig }
const style: CSSProperties = {
  minHeight: '10rem',
  height: calcVerticalHeight({ gridSize: 4, elementSize: 3 }),
  width: '100%',
}

const PlotSignal = ({
  dtIn,
  dtFin,
  signalType,
  revision,
}: {
  dtIn: Date
  dtFin: Date
  signalType: SignalTypes
  revision: number
}) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const { mastDataBySensor } = useData(dtIn, dtFin)
  const sensors = allSensors.filter(s => signalType.id === s.signal_type_id)

  const Y_AXIS_RANGE: Record<string, [number, number]> = {
    BAT: [10, 15],
    TMP: [0, 40],
    BAR: [800, 1000],
  }

  const layout = {
    template: layoutTemplate,
    range: [dtIn, dtFin],
    autorange: false,
    yaxis: { range: Y_AXIS_RANGE[signalType.type] },
  }

  const data = sensors
    .flatMap((sensor, i) => {
      const _mastData = mastDataBySensor.get(sensor.id)
      if (!_mastData) {
        return null
      }
      const mastData = sortByDateKey<MastData>(_mastData, 'ts', 'asc')
      const x = mastData.map(d => d.ts)
      const avg: number[] = []
      const sensorColor: string = plot_color_scale_200[i]
      mastData.forEach(md => {
        avg.push(md.avg)
      })

      return [
        {
          x,
          y: avg,
          type: 'scatter',
          mode: 'line',
          name: `${sensor.label} - AVG`,
          marker: { color: sensorColor },
        },
      ]
    })
    .filter(d => d)

  return (
    <div style={style}>
      <Plot
        key={mast.id}
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        revision={revision}
      />
    </div>
  )
}
export default PlotSignal
