import {
  Alert,
  Button,
  Hint,
  HintBody,
  Modal,
  ModalVariant,
  Stack,
  StackItem,
} from '@patternfly/react-core'
import React, { useEffect } from 'react'
import { ActionState } from './actionState'

function ModalConfirmationWarning<Type>({
  title,
  isOpen,
  handleModalToggle,
  handleSubmit,
  element,
  additionalInformation,
  actionState = 'idle',
  alertMessage = 'Tem certeza que deseja remover este item? Essa ação não poderá ser desfeita.',
  buttonLabel = 'Remover',
  pendingMessage = 'Removendo item',
  successMessage = 'Item removido com sucesso',
  failureMessage = 'Ocorreu um erro ao deletar',
  errorsResponse = [],
}: {
  title: string
  isOpen: boolean
  handleModalToggle: () => void
  handleSubmit: (element: Type) => void
  element: Type
  additionalInformation?: React.ReactNode
  actionState: ActionState
  alertMessage?: string
  buttonLabel?: string
  pendingMessage?: string
  successMessage?: string
  failureMessage?: string
  errorsResponse?: string[]
}) {
  useEffect(() => {
    if (isOpen && actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
    if (isOpen && actionState === 'failure') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
      }, 5000)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  const alertMessages = {
    idle: <></>,
    pending: <Alert variant='info' title={pendingMessage} isInline />,
    success: <Alert variant='success' title={successMessage} isInline />,
    failure: <Alert variant='danger' title={failureMessage} isInline />,
  }

  return (
    <Modal
      variant={ModalVariant.small}
      title={title}
      aria-label='modal-confirm-warning'
      id='modal-confirm-warning'
      isOpen={isOpen}
      onClose={handleModalToggle}
      actions={[
        <Button
          key='create'
          variant='danger'
          onClick={() => {
            handleSubmit(element)
            if (actionState === 'success') handleModalToggle()
          }}
        >
          {buttonLabel}
        </Button>,
        <Button key='cancelar' variant='primary' onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Stack hasGutter>
        <StackItem>{alertMessages[actionState]}</StackItem>
        {errorsResponse?.map((error, index) => (
          <StackItem key={index}>
            <Hint>
              <HintBody>{error}</HintBody>
            </Hint>
          </StackItem>
        ))}
      </Stack>
      {alertMessage}
      {additionalInformation}
    </Modal>
  )
}

export default ModalConfirmationWarning
