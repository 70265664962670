import { Button } from '@patternfly/react-core'
import { useState } from 'react'

export function useButtonActiveType() {
  const [activeType, setActiveType] = useState<'Total' | 'Por Categoria'>('Total')

  const ButtonsExibição = () => {
    return (
      <div className='pf-v5-u-p-sm'>
        <Button
          variant={activeType === 'Total' ? 'primary' : 'control'}
          onClick={() => setActiveType('Total')}
        >
          Total
        </Button>
        <Button
          variant={activeType === 'Por Categoria' ? 'primary' : 'control'}
          onClick={() => setActiveType('Por Categoria')}
        >
          Por Categoria
        </Button>
      </div>
    )
  }

  return { activeType, ButtonsExibição }
}
