import { Skeleton } from '@patternfly/react-core'

interface IProps {
  height?: string
  width?: string
}

const SkeletonImage = (props: IProps) => {
  const { height, width } = props

  const style = {
    height: height ? height : '100%',
    width: width ? width : 'auto',
    marginTop: '0.5em',
    marginBottom: '0.5em',
  }

  return (
    <div style={style}>
      <Skeleton height='100%' width='100%' />
    </div>
  )
}

export default SkeletonImage
