import { Button, SearchInput, Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { CheckIcon, CloseIcon } from '@patternfly/react-icons'
import { useMutation } from '@tanstack/react-query'
import { useContext, useEffect, useMemo, useState } from 'react'
import { GroupsAPI, UsersAPI } from '../../client'
import { GroupOut } from '../../client/models/GroupOut'
import { UserOut } from '../../client/models/UserOut'
import { ActionState } from '../../components/actionState'
import EntityTable from '../../components/EntityTable'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import PermissionContext, {
  QUERY_KEY_GROUPS,
  QUERY_KEY_USERS,
} from '../../contexts/PermissionContext'
import { queryClient as qq } from '../../services/api'
import PageFrame from '../shared/Page'
import { DataToDeleteType, ModalInfoType } from './constants'
import ModalGroup from './ModalGroup'
import ModalUser from './ModalUser'

function UsuariosGrupos() {
  const { users, groups, isLoading } = useContext(PermissionContext)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const [editUser, setEditUser] = useState<UserOut>()
  const [editGroup, setEditGroup] = useState<GroupOut>()
  const [isUserModalOpen, setUserModalOpen] = useState(false)
  const [isGroupModalOpen, setGroupModalOpen] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState(0)
  const [modalInfo, setModalInfo] = useState<ModalInfoType>()
  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<DataToDeleteType>()
  const [actionState, setActionState] = useState<ActionState>('idle')

  const handleModalConfirmationWarningToggle = () => setModalConfirmationWarningOpen(prev => !prev)
  const handleUserModalToggle = () => setUserModalOpen(prev => !prev)
  const handleGroupModalToggle = () => setGroupModalOpen(prev => !prev)

  const onMutate = () => setActionState('pending')
  const onError = () => setActionState('failure')
  const onSuccess = () => {
    qq.invalidateQueries([QUERY_KEY_USERS])
    qq.invalidateQueries([QUERY_KEY_GROUPS])
    setActionState('success')
  }

  const { mutate: deleteUser } = useMutation({
    mutationFn: (id: string) => UsersAPI.delete({ userId: id }),
    onMutate,
    onError,
    onSuccess,
  })

  const { mutate: deleteGroup } = useMutation({
    mutationFn: (id: string) => GroupsAPI.delete({ groupId: id }),
    onMutate,
    onError,
    onSuccess,
  })

  const handleDeleteClick = (data: UserOut | GroupOut) => {
    const id = data.id
    const type = 'user_ids' in data ? 'GROUP' : 'USER'

    setModalInfo({
      modalTitle: type === 'USER' ? 'Remover usuário' : 'Remover grupo',
      modalElement: data,
    })

    setDataToDelete({ id, type })
    handleModalConfirmationWarningToggle()
  }

  async function handleDeleteSubmit() {
    if (dataToDelete && dataToDelete.type === 'USER') {
      deleteUser(dataToDelete.id)
      return
    }

    if (dataToDelete && dataToDelete.type === 'GROUP') {
      deleteGroup(dataToDelete.id)
      return
    }
  }

  const [filterUserValue, setFilterUserValue] = useState('')
  const [filterGroupValue, setFilterGroupValue] = useState('')

  const usersFiltered = useMemo(() => {
    if (!users) return []
    if (filterUserValue === '') return users
    return users.filter(user => user.username.toLowerCase().includes(filterUserValue.toLowerCase()))
  }, [filterUserValue, users])

  const groupsFiltered = useMemo(() => {
    if (!groups) return []
    if (filterGroupValue === '') return groups
    return groups.filter(group => group.name.toLowerCase().includes(filterGroupValue.toLowerCase()))
  }, [filterGroupValue, groups])

  const clearFilterUsers = () => setFilterUserValue('')
  const clearFilterGroups = () => setFilterGroupValue('')
  const styleTable: React.CSSProperties = { overflowY: 'visible', height: '71vh' }

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        setDataToDelete(undefined)
        setModalInfo(undefined)
        setActionState('idle')
        setModalConfirmationWarningOpen(false)
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <PageFrame
      pageName='Usuários e Grupos'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        activeTabKey === 0 && (
          <div className='pf-v5-u-p-sm'>
            <SearchInput
              placeholder='Filtrar usuário'
              value={filterUserValue}
              onChange={(_event, value) => setFilterUserValue(value)}
              onClear={clearFilterUsers}
            />
          </div>
        ),
        activeTabKey === 1 && (
          <div className='pf-v5-u-p-sm'>
            <SearchInput
              placeholder='Filtrar Grupos'
              value={filterGroupValue}
              onChange={(_event, value) => setFilterGroupValue(value)}
              onClear={clearFilterGroups}
            />
          </div>
        ),
        <Button
          key='create-user'
          variant='control'
          className='pf-v5-u-m-sm'
          onClick={() => {
            setEditUser(undefined)
            handleUserModalToggle()
          }}
        >
          Criar Usuário
        </Button>,
        <Button
          key='create-group'
          variant='control'
          className='pf-v5-u-m-sm'
          onClick={() => {
            setEditGroup(undefined)
            handleGroupModalToggle()
          }}
        >
          Criar Grupo
        </Button>,
      ]}
    >
      <Tabs
        activeKey={activeTabKey}
        onSelect={(_, tabIndex: number | string) => {
          setActiveTabKey(Number(tabIndex))
          if (tabIndex === 0) clearFilterUsers()
          if (tabIndex === 1) clearFilterGroups()
        }}
        isBox
        aria-label='Users and groups'
      >
        <Tab eventKey={0} title={<TabTitleText>Usuários</TabTitleText>}>
          <EntityTable<UserOut>
            items={usersFiltered || []}
            itemKeyName='id'
            columnConfig={[
              { key: 'username', description: 'Nome' },
              {
                key: 'group_names',
                description: 'Grupos',
                formatter: (v: string[]) => (
                  <span>
                    {v.map((g, i) => (
                      <div key={`kg-${i}`}>{g}</div>
                    ))}
                  </span>
                ),
              },
              { key: 'email', description: 'Email' },
              {
                key: 'disabled',
                description: 'Habilitado',
                formatter: (v: boolean) => (v ? <CloseIcon /> : <CheckIcon />),
              },
            ]}
            onClickDelete={handleDeleteClick}
            onClickEdit={user => {
              setEditUser(user)
              handleUserModalToggle()
            }}
            style={styleTable}
            isFilterable={!!filterUserValue}
            onClearFilters={clearFilterUsers}
          />
        </Tab>
        <Tab eventKey={1} title={<TabTitleText>Grupos</TabTitleText>}>
          <EntityTable<GroupOut>
            items={groupsFiltered || []}
            itemKeyName='id'
            columnConfig={[
              { key: 'name', description: 'Nome' },
              { key: 'description', description: 'Descrição' },
              {
                key: 'user_names',
                description: 'Membros',
                formatter: (v: string[]) => (
                  <span>
                    {v.map((u, i) => (
                      <div key={`k-${i}`}>{u}</div>
                    ))}
                  </span>
                ),
              },
            ]}
            onClickDelete={handleDeleteClick}
            onClickEdit={group => {
              setEditGroup(group)
              handleGroupModalToggle()
            }}
            style={styleTable}
            isFilterable={!!filterGroupValue}
            onClearFilters={clearFilterGroups}
          />
        </Tab>
      </Tabs>

      {isModalConfirmationWarningOpen && (
        <ModalConfirmationWarning<UserOut | GroupOut | undefined>
          isOpen={isModalConfirmationWarningOpen}
          handleModalToggle={handleModalConfirmationWarningToggle}
          handleSubmit={handleDeleteSubmit}
          title={modalInfo?.modalTitle as string}
          element={modalInfo?.modalElement}
          actionState={actionState}
        />
      )}
      <ModalUser
        key={editUser ? 'user_edit' : 'user_create'}
        type={editUser ? 'edit' : 'create'}
        isModalOpen={isUserModalOpen}
        handleModalToggle={handleUserModalToggle}
        instanceDefault={editUser}
        setInstanceDefault={setEditUser}
      />
      <ModalGroup
        key={editGroup ? 'group_edit' : 'group_create'}
        type={editGroup ? 'edit' : 'create'}
        isModalOpen={isGroupModalOpen}
        handleModalToggle={handleGroupModalToggle}
        instanceDefault={editGroup}
        setInstanceDefault={setEditGroup}
      />
    </PageFrame>
  )
}

export default UsuariosGrupos
