/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Windness } from '../models/Windness';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WindnessAPI {

  /**
   * Get All
   * @returns Windness Successful Response
   * @throws ApiError
   */
  public static getAll({
    siteId,
    year,
  }: {
    siteId: number,
    year: number,
  }): CancelablePromise<Windness> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/windness',
      path: {
        'site_id': siteId,
      },
      query: {
        'year': year,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getReport({
    siteId,
    yyyy,
  }: {
    siteId: number,
    yyyy?: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/windness/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'yyyy': yyyy,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
