import {
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Divider,
  Panel,
  PanelHeader,
  PanelMain,
} from '@patternfly/react-core'
import ColorScale from '../../utils/colorScale'

const TableAnnualResume = ({ rows, title }: { rows: (string | number)[][]; title: string }) => {
  return (
    <Panel
      className='pf-v5-u-my-sm'
      style={{
        minHeight: '15rem',
        background: ColorScale.branco,
      }}
    >
      <PanelHeader>
        <b>{title}</b>
      </PanelHeader>
      <Divider />
      <PanelMain>
        <DataList isCompact aria-label='data list wrapper annual' gridBreakpoint='none'>
          {rows.map((row, rowIdx) => (
            <DataListItem key={JSON.stringify(row) + String(rowIdx)}>
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={row.map((cell, cellIndex) => (
                    <DataListCell
                      width={1}
                      key={String(cell) + '_' + String(cellIndex) + '_' + String(rowIdx)}
                    >
                      {!cellIndex ? <b>{cell}</b> : cell}
                    </DataListCell>
                  ))}
                />
              </DataListItemRow>
            </DataListItem>
          ))}
        </DataList>
      </PanelMain>
    </Panel>
  )
}

export default TableAnnualResume
