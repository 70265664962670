import {
  Divider,
  Panel,
  PanelHeader,
  PanelMain,
  Progress,
  ProgressMeasureLocation,
} from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { TurbineHealthAPI, TurbinesHealth } from '../../../client'
import EntityTable from '../../../components/EntityTable'
import SitesContext from '../../../contexts/SitesContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import ColorScale from '../../../utils/colorScale'
import { SetStateGeneric } from '../../../utils/SetStateGeneric'
import { farol } from './commons'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

const fetchData = async (siteId?: number) => {
  if (!siteId) return
  return TurbineHealthAPI.getTurbineHealth({ siteId })
}

export type direction = 'asc' | 'desc' | null
type System =
  | 'MAIN+SHAFT'
  | 'PITCH'
  | 'HYDRAULIC'
  | 'TRANSFORMER'
  | 'ENVIRONMENT'
  | 'GEARBOX'
  | 'YAW'
  | 'CONVERTER'
  | 'ELECTRIC'
  | 'GENERATOR'

const HealthTable = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string
  setSearchValue: SetStateGeneric<string>
}) => {
  const { site, turbsMap } = useContext(SitesContext)
  const { isLoading, data: health } = useQuery(
    ['turb-health', site?.site_id],
    async () => fetchData(site?.site_id),
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id,
    }
  )
  const healthSearch = (turbData: TurbinesHealth) => {
    const turbName = turbsMap.get(turbData.turb_id)?.name
    return turbName?.toLowerCase().includes(searchValue.toLowerCase())
  }

  const sortedHealthData = useMemo(() => {
    return health?.filter(healthSearch).sort((a, b) => a?.turb_id - b?.turb_id)
  }, [health, searchValue])

  const verticalH = { gridSize: 1, elementSize: 1, customOffset: '3.0rem' }
  const panelStyle = {
    height: calcVerticalHeight({ ...verticalH, customOffset: '0rem' }),
    minHeight: '15rem',
    background: ColorScale.branco,
  }
  const wrapperStyle = {
    height: calcVerticalHeight(verticalH),
  }

  function LinkBase({
    val,
    instance,
    system,
  }: {
    val: any
    instance: TurbinesHealth
    system: System
  }) {
    return (
      <Link
        target={'_blank'}
        to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
          instance.turb_id
        }?system=${system}`}
      >
        {farol.get(`${val}`)}
      </Link>
    )
  }

  return (
    <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
      <PanelHeader>
        <b>Turbine Health</b>
      </PanelHeader>
      <Divider />
      <PanelMain>
        <EntityTable<TurbinesHealth>
          items={sortedHealthData || []}
          isLoading={isLoading}
          itemKeyName='turb_id'
          isCompact
          columnConfig={[
            {
              key: 'turb_id',
              description: 'WTG',
              formatter: value => turbsMap.get(value)?.name || '',
            },
            {
              key: 'health',
              description: 'Health',
              formatter: (v, instance) => (
                <Progress
                  id={`health_turb_${instance?.turb_id}`}
                  aria-label={`health_turb_${instance?.turb_id}`}
                  {...(v && { value: v })}
                  measureLocation={
                    v && v > 0 ? ProgressMeasureLocation.inside : ProgressMeasureLocation.none
                  }
                />
              ),
            },
            {
              key: 'main_shaft',
              description: 'Main Shaft',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'MAIN+SHAFT'} />
              ),
            },
            {
              key: 'pitch',
              description: 'Pitch',
              formatter: (v, instance) => <LinkBase val={v} instance={instance} system={'PITCH'} />,
            },
            {
              key: 'hydraulic',
              description: 'Hydraulic',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'HYDRAULIC'} />
              ),
            },
            {
              key: 'transformer',
              description: 'Transformer',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'TRANSFORMER'} />
              ),
            },
            {
              key: 'environment',
              description: 'Environment',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'ENVIRONMENT'} />
              ),
            },
            {
              key: 'gearbox',
              description: 'Gearbox',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'GEARBOX'} />
              ),
            },
            {
              key: 'yaw',
              description: 'Yaw',
              formatter: (v, instance) => <LinkBase val={v} instance={instance} system={'YAW'} />,
            },
            {
              key: 'converter',
              description: 'Converter',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'CONVERTER'} />
              ),
            },
            {
              key: 'electric',
              description: 'Electric',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'ELECTRIC'} />
              ),
            },
            {
              key: 'generator',
              description: 'Generator',
              formatter: (v, instance) => (
                <LinkBase val={v} instance={instance} system={'GENERATOR'} />
              ),
            },
          ]}
          isFilterable={!!searchValue}
          onClearFilters={() => setSearchValue('')}
          style={wrapperStyle}
        />
      </PanelMain>
    </Panel>
  )
}

export default HealthTable
