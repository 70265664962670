import { Divider as DividerPatternfly } from '@patternfly/react-core'
import useMobile from '../hooks/useMobile'

export function Divider() {
  const isMobile = useMobile()
  return (
    <DividerPatternfly
      orientation={{ default: isMobile ? 'horizontal' : 'vertical' }}
      style={{
        ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
        margin: 0,
      }}
    />
  )
}
