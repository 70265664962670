import { useContext } from 'react'
import LoadingButton from '../../../../components/LoadingButton'
import SitesContext from '../../../../contexts/SitesContext'
import SelectList from '../DeprecatedSelectList'

const SelectListSites = ({ onChange }: { onChange?: (site_id: number) => void }) => {
  const { site, sites, setActiveSite, isLoading } = useContext(SitesContext)

  const handleChange = (site_id: number) => {
    if (onChange !== undefined) onChange(site_id)
    setActiveSite(site_id)
  }
  if (isLoading) {
    return <LoadingButton />
  }

  return (
    <SelectList
      startSelected={site?.site_id}
      onChange={handleChange}
      isLoading={isLoading}
      list={sites?.map(s => {
        return { value: s.site_id, label: s.site_name }
      })}
    />
  )
}

export default SelectListSites
