import { Button, Modal, ModalVariant, Spinner, Text, TextVariants } from '@patternfly/react-core'
import * as localeDictionary from 'plotly.js/lib/locales/pt-br.js'
import { ITowersName } from '..'
import { TowerIdentifier } from '../../../client'
import PageNoData from '../../shared/PageNoData'
import Plot from '../../shared/Plot'
import './styles.css'

const HeatmapChart = ({ data }: { data?: Array<Array<any>> }) => {
  if (!data) return null
  if (data.length === 0) return <PageNoData message='Sem dados para essa torre.' />
  const dataSort = data.sort((a, b) => (a[1] < b[1] ? 1 : -1))

  const [x, y, z]: string[][] = [[], [], []]
  dataSort.forEach(item => {
    x.push(item[0])
    y.push(item[1])
    z.push(item[2])
  })

  const xHeatmap = [...new Set(x)]
  const yHeatmap = [...new Set(y)]

  const zHeatmap = yHeatmap.map((_, i) => {
    return z.slice(i * xHeatmap.length, (i + 1) * xHeatmap.length)
  })

  const colorScaleValue = [
    [0.0, '#00d45c'],
    [0.5, '#F7CF00'],
    [1.0, '#b20c1d'],
  ]

  const d = [
    {
      z: zHeatmap,
      x: xHeatmap,
      y: yHeatmap,
      xgap: 0.5,
      ygap: 0.5,
      type: 'heatmap',
      hoverongaps: false,
      colorscale: colorScaleValue,
      zmin: 0,
      zmax: 100,
      colorbar: {
        len: 1.04,
        outlinecolor: '#fff',
      },
    },
  ]

  return (
    <div style={{ overflowX: 'auto', height: '100%', width: '90%' }}>
      <Plot
        data={d}
        className='pf-v5-u-mx-2xl-on-lg plot'
        style={{ height: '100%', minWidth: '700px' }}
        layout={{
          autosize: true,
          dragmode: false,
          paper_bgcolor: '#00000000',
          plot_bgcolor: '#00000000',
          hoverlabel: {
            bgcolor: '#FFF',
            tickformat: '%d %b',
            namelength: 30,
            font: { size: 14 },
          },
          margin: { l: 0, r: 0, b: 10, t: 10, pad: 5 },
          yaxis: {
            automargin: true,
            fixedrange: true,
            tickfont: { size: 13 },
          },
          xaxis: {
            automargin: true,
            showgrid: false,
            fixedrange: true,
            tickformat: '%d %b',
            tickangle: -90,
            tickfont: { size: 13 },
            nticks: xHeatmap.length + 1,
          },
        }}
        useResizeHandler
        config={{
          responsive: true,
          displaylogo: false,
          displayModeBar: false,
          locales: { 'pt-BR': localeDictionary },
          locale: 'pt-BR',
        }}
      />
    </div>
  )
}

const SpinnerModal = () => {
  return (
    <Spinner
      className='pf-v5-u-display-flex pf-v5-u-align-content-center'
      size='xl'
      aria-label='Loading'
    />
  )
}

const ModalHeatmap = ({
  setTowerId,
  isModalOpen,
  setModalOpen,
  heatmap,
  beforeIndex,
  afterIndex,
  towerIds,
  towersName,
  handleModalToggle,
  heatmapIsLoading,
}: {
  isModalOpen: boolean
  handleModalToggle: () => void
  towersName: ITowersName
  towers: TowerIdentifier[]
  towerId: number
  setTowerId: (value: number) => void
  setModalOpen: (value: boolean) => void
  towerIds: number[]
  heatmapIsLoading: boolean
  heatmap?: Array<Array<any>>[]
  beforeIndex: number
  afterIndex: number
}) => {
  const handleClick = (id: number, isHeatmap: boolean) => {
    setTowerId(id)
    if (isHeatmap) setModalOpen(true)
  }

  return (
    <Modal
      aria-label='heatmap modal'
      variant={ModalVariant.large}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      hasNoBodyWrapper
      id='modalHeatmap'
    >
      <div className='pf-v5-u-p-lg' style={{ width: '100%', height: '100%' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: '500',
            padding: '0px 15px',
            textAlign: 'center',
          }}
          component={TextVariants.h2}
          id='titleModal'
        >
          {towersName.atual}
        </Text>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            id='buttonBefore'
            variant='control'
            className='pf-v5-u-m-sm'
            onClick={() => handleClick(towerIds[beforeIndex], true)}
          >
            {towersName.before}
          </Button>
          <Button
            id='buttonNext'
            variant='control'
            className='pf-v5-u-m-sm'
            onClick={() => handleClick(towerIds[afterIndex], true)}
          >
            {towersName.after}
          </Button>
        </div>

        <div
          className='pf-v5-u-display-flex pf-v5-u-justify-content-center pf-v5-u-align-items-center'
          style={{
            height: '35rem',
            display: 'block',
            margin: '0px auto',
          }}
          id='containerPlot'
        >
          {heatmapIsLoading ? <SpinnerModal /> : <HeatmapChart data={heatmap} />}
        </div>
      </div>
    </Modal>
  )
}

export default ModalHeatmap
