import { Grid, GridItem } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { index } from 'd3-array'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  TurbineHealthAPI,
  TurbinesHealthHeatmap,
  TurbinesHealthSummary,
  TurbineSystems,
} from '../../../client'
import DatetimeRangePicker from '../../../components/calendar/DatetimeRangePicker'
import SitesContext from '../../../contexts/SitesContext'
import { formatDateWithoutTime, withoutTimeToDate } from '../../../utils/formatDate'
import PageFrame from '../../shared/Page'
import SelectList from '../../shared/selects/DeprecatedSelectList'
import SelectListSites from '../../shared/selects/DeprecatedSelectListSites'
import EventsTable from '../EventsTable'
import HeatmapAccordion from './HeatmapAccordion'
import SelectListWtGs from './SelectListWtGs'

import HelpContent from './HelpContent'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)

const fetchData = async (siteId?: number) => {
  if (!siteId) return
  return TurbineHealthAPI.getTurbineHealthEvents({ siteId, deltaDays: 180 })
}

const HeatmapTurbineHealth = () => {
  const { turbId, siteName } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isExpanded, setIsExpanded] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const {
    isLoading: sitesIsLoading,
    site,
    setActiveSite,
    turbs,
    turbsMap,
    sitesMap,
    sites,
  } = useContext(SitesContext)

  const [turbSelected, setTurbSelected] = useState<number | null>(null)
  const [isNavOpen, setNavOpen] = useState(false)

  const today = new Date()
  const actualMonth = today.getMonth()
  const actualYear = today.getFullYear()
  const [startDate, setStartDate] = useState(() => {
    const startParam = searchParams.get('start')
    if (startParam) return withoutTimeToDate(startParam)
    return new Date(actualYear, actualMonth, today.getDate() - 15)
  })
  const [endDate, setEndDate] = useState(() => {
    const endParam = searchParams.get('end')
    if (endParam) return withoutTimeToDate(endParam)
    return today
  })
  const [systemSelected, setSystemSelected] = useState(1)
  const [isValidMaxRange, setIsValidMaxRange] = useState(false)

  const { isLoading: systemListIsloading, data: systemList } = useQuery(
    ['turb-health-system-list'],
    async () => {
      return await TurbineHealthAPI.getTurbineSystems()
    },
    { ...defaultQueryOptions, enabled: !!site?.site_id }
  )

  const { isLoading: systemIsloading, data: systemMap } = useQuery(
    ['turb-health-system'],
    async () => {
      const systems = await TurbineHealthAPI.getTurbineSystems()
      return index(systems, (s: TurbineSystems) => s.id)
    },
    { ...defaultQueryOptions, enabled: !!site?.site_id }
  )

  const { isLoading: summaryIsLoading, data: _summaryData } = useQuery(
    ['turb-health-summary', site.site_id, turbSelected, systemSelected],
    async () => {
      if (!turbSelected || !site?.site_id) return null
      const objRequest = {
        siteId: site.site_id,
        turbId: turbSelected,
        systemId: systemSelected,
      }

      return await TurbineHealthAPI.getTurbineHealthSummary(objRequest)
    },
    { ...defaultQueryOptions, enabled: !!site?.site_id || !!turbSelected }
  )

  const { isLoading: heatmapIsLoading, data: _heatmapData } = useQuery(
    ['turb-health-data', site.site_id, turbSelected, systemSelected, startDate, endDate],
    async () => {
      if (!turbSelected || !site?.site_id) return null
      const objRequest = {
        siteId: site.site_id,
        turbId: turbSelected,
        systemId: systemSelected,
        tsIn: formatDateWithoutTime(startDate),
        tsFin: formatDateWithoutTime(endDate),
      }

      return await TurbineHealthAPI.getTurbineHealthHeatmap(objRequest)
    },
    { ...defaultQueryOptions, enabled: !!site?.site_id || !!turbSelected }
  )

  const { isLoading: eventsIsloading, data: events } = useQuery(
    ['turb-health-events', site?.site_id],
    async () => fetchData(site?.site_id),
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id,
    }
  )

  const navigator = (
    siteId?: number,
    turbId?: number,
    dateIn?: Date,
    dateEnd?: Date,
    system?: string
  ) => {
    const _siteName = siteId ? sitesMap.get(siteId)?.site.toLowerCase() : site.site.toLowerCase()
    const _turbId = turbId || turbSelected
    const _system = system || systemMap?.get(systemSelected)?.system
    const _dateIn = formatDateWithoutTime(dateIn || startDate)
    const _dateEnd = formatDateWithoutTime(dateEnd || endDate)

    const newUrl =
      `/turbine-health/heatmap/${_siteName}/${_turbId}?system=${_system}&start=${_dateIn}&end=${_dateEnd}`.replace(
        ' ',
        '+'
      )
    if (newUrl === location.pathname + location.search) return

    navigate(newUrl, { state: location.key })
  }

  const isLoading = sitesIsLoading || !turbSelected

  const isContentLoading =
    turbSelected !== null &&
    (sitesIsLoading ||
      systemIsloading ||
      systemListIsloading ||
      summaryIsLoading ||
      heatmapIsLoading ||
      eventsIsloading)

  useEffect(() => {
    const newSite = sites.find(s => s.site.toLowerCase() === siteName?.toLowerCase())

    if (!newSite) return
    setActiveSite(newSite.site_id)
  }, [isLoading, site, location])

  useEffect(() => {
    const startParam = searchParams.get('start')
    const endParam = searchParams.get('end')
    const systemParam = searchParams.get('system')

    if (!systemParam) return
    if (!startParam || !endParam) {
      setSearchParams({
        system: systemParam,
        start: formatDateWithoutTime(startDate),
        end: formatDateWithoutTime(endDate),
      })
      return
    }

    setStartDate(withoutTimeToDate(startParam))
    setEndDate(withoutTimeToDate(endParam))
  }, [location])

  useEffect(() => {
    if (!turbId) return

    setTurbSelected(Number(turbId))
  }, [turbsMap, location])

  useEffect(() => {
    const _systemSelected = systemList?.find(system => system.system === searchParams.get('system'))
    if (_systemSelected) {
      setSystemSelected(_systemSelected.id)
    }
  }, [systemList, location])

  useEffect(() => {
    if (!turbSelected || !site.site || !systemMap) return
    navigator()
  }, [systemSelected, turbSelected, startDate, endDate])

  const handleChangeSite = (siteId: number) => {
    navigator(siteId, 1, new Date(actualYear, actualMonth, today.getDate() - 15), today)
  }

  const handleSystemSelect = (selectionSystem: number) => {
    const _systemSelected = systemMap?.get(selectionSystem)
    const systemId = _systemSelected?.id

    if (systemSelected === systemId) return
    if (!systemId) return

    setSystemSelected(systemId)
  }

  const summaryData = _summaryData?.filter(item => {
    const arrDate = item.ts.split('T')[0].split('-')
    const date = new Date(Number(arrDate[0]), Number(arrDate[1]) - 1, Number(arrDate[2]))

    return date >= startDate && date <= endDate
  })

  const filterEvents = events?.filter(event => {
    const eventTsIn = new Date(event.ts_in)
    const eventTsFin = event.ts_fin === null ? null : new Date(event.ts_fin)

    const isRangeTs = eventTsIn <= endDate && (eventTsFin === null || eventTsFin >= startDate)

    return event.system_id === systemSelected && event.turb_id === turbSelected && isRangeTs
  })

  // TODO: Verificar se faz sentido comparar segundos e não dias de calendário
  const maxRangeValidator = (startDate: Date, endDate: Date) => {
    const maxDays = 30
    const diff = Math.abs(endDate.getTime() - startDate.getTime())
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24))

    if (days < maxDays) {
      setIsValidMaxRange(true)
      return true
    }

    setIsValidMaxRange(false)
    return false
  }

  return (
    <PageFrame
      pageName='Turb Health Heatmap'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={sitesIsLoading}
      isContentLoading={isContentLoading}
      siteName={site?.site_name}
      siteId={site?.site_id}
      helpContent={<HelpContent />}
      filters={[
        <SelectListSites key='SelectListSites' onChange={handleChangeSite} />,
        <SelectList
          key={`SelectListSystem_${site?.site_id}`}
          startSelected={systemSelected}
          onChange={handleSystemSelect}
          isLoading={false}
          list={systemList?.map(system => {
            return { value: system.id, label: capitalize(system.name) }
          })}
        />,
        <DatetimeRangePicker
          key='DatetimeRangePicker_month'
          value={{ startDate, endDate }}
          onChange={(newStart: Date, newEnd: Date) => {
            setStartDate(newStart)
            setEndDate(newEnd)
          }}
          calendarVariant='date'
          rangeValidators={[maxRangeValidator]}
          messageAlert={isValidMaxRange ? null : 'O número máximo para o intervalo é de 30 dias.'}
        />,
        <SelectListWtGs
          key={`SelectListTurbs_${site?.site_id}`}
          startSelected={turbSelected as number}
          onChange={setTurbSelected}
          isLoading={false}
          listTurbs={turbs?.map(turb => {
            return { value: turb.turb_id, label: turb.name }
          })}
          maxHeight={350}
        />,
      ]}
    >
      <Grid hasGutter>
        <GridItem lg={8} md={12}>
          <HeatmapAccordion
            summaryData={summaryData as TurbinesHealthSummary[]}
            heatmapData={_heatmapData as TurbinesHealthHeatmap[]}
            isLoading={isContentLoading || isLoading}
          />
        </GridItem>
        <GridItem lg={4} md={12}>
          <EventsTable
            title={'Events'}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            isLoading={isContentLoading}
            events={filterEvents}
            turbsMap={turbsMap}
            showLink={false}
            showTurbine={true}
            systemMap={systemMap}
          />
        </GridItem>
      </Grid>
    </PageFrame>
  )
}

export default HeatmapTurbineHealth
