import { Button, ToolbarItem, Tooltip } from '@patternfly/react-core'
import { PanelBottomClose, PanelTopClose, Rows2 } from 'lucide-react'
import useMobile from '../../hooks/useMobile'

const ResizeButtons = ({
  setResizeMode,
  resizeMode,
}: {
  setResizeMode: (m: number) => void
  resizeMode: number
}) => {
  const isMobile = useMobile()

  const handleResize = (mode: number) => {
    setResizeMode(mode)
    localStorage.setItem('resizeParadasERestricoes', JSON.stringify(mode))
  }

  return (
    <ToolbarItem
      style={{
        ...(isMobile ? { padding: '4px 0', paddingTop: '6px' } : { padding: '4px 0.35rem' }),
        margin: 0,
      }}
    >
      <Tooltip content={'Redimensionar para 75%'}>
        <Button
          variant='control'
          type='button'
          onClick={() => {
            handleResize(-1)
          }}
          isActive={resizeMode === -1}
          style={{ display: 'flex', alignItems: 'center', height: '36px' }}
        >
          <PanelTopClose width={'20px'} height={'20px'} />
        </Button>
      </Tooltip>
      <Tooltip content={'Redimensionar para 50%'}>
        <Button
          variant='control'
          type='button'
          onClick={e => {
            handleResize(0)
          }}
          isActive={resizeMode === 0}
          style={{ display: 'flex', alignItems: 'center', height: '36px' }}
        >
          <Rows2 width={'20px'} height={'20px'} />
        </Button>
      </Tooltip>
      <Tooltip content={'Redimensionar para 25%'}>
        <Button
          variant='control'
          type='button'
          onClick={e => {
            handleResize(1)
          }}
          isActive={resizeMode === 1}
          style={{ display: 'flex', alignItems: 'center', height: '36px' }}
        >
          <PanelBottomClose width={'20px'} height={'20px'} />
        </Button>
      </Tooltip>
    </ToolbarItem>
  )
}

export default ResizeButtons
