import { Flex } from '@patternfly/react-core'
import { useState } from 'react'
import notFound from '../../assets/img/404_rioenergy.svg'
import useMobile from '../../hooks/useMobile'
import PageFrame from '../shared/Page'

const NotFound = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const isMobile = useMobile()
  const telao = sessionStorage.getItem('telao')

  return (
    <PageFrame pageName='404' isNavOpen={isNavOpen} setNavOpen={setNavOpen}>
      <Flex style={{ alignItems: 'center', flexDirection: 'column' }}>
        <img
          src={notFound}
          alt='página não encontrada'
          id='not-found-img'
          style={{ width: '50%', margin: 0 }}
        />
        <h1
          style={{
            fontSize: '1.5rem',
            ...(isMobile && { fontSize: '1rem' }),
            ...(telao === 'on' && { fontSize: '3rem' }),
            fontWeight: 'bold',
          }}
        >
          Página não encontrada
        </h1>
      </Flex>
    </PageFrame>
  )
}

export default NotFound
