import { Divider, Panel, PanelHeader, PanelMain } from '@patternfly/react-core'
import { CheckIcon, CloseIcon } from '@patternfly/react-icons'
import { useState } from 'react'
import { SettingsTurbineMap, Site, Turbines } from '../../client'
import EntityTable from '../../components/EntityTable'
import ColorScale from '../../utils/colorScale'
import ModalTurbs from './ModalSettingsTurbs'

const DisabledIcon = () => <CloseIcon />
const EnabledIcon = () => <CheckIcon />

const panelStyle = {
  height: 'fit-content',
  minHeight: '15rem',
  background: ColorScale.branco,
}

const TableTurbs = ({
  turbsMap,
  isLoading,
  setSettingTurb,
  filteredSettings,
  inputValue,
  setInputValue,
  settingTurb,
  updateTurb,
  site,
}: {
  turbsMap: Map<number, Turbines>
  isLoading: boolean
  setSettingTurb: React.Dispatch<React.SetStateAction<SettingsTurbineMap | undefined>>
  filteredSettings: SettingsTurbineMap[] | undefined
  inputValue: string
  settingTurb: SettingsTurbineMap | undefined
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  updateTurb: (value: SettingsTurbineMap) => void
  site: Site
}) => {
  const [isModalUpdateOpen, setModalUpdateOpen] = useState(false)

  function closeModal() {
    setModalUpdateOpen(false)
  }

  const handleModalUpdateOpen = (item: SettingsTurbineMap) => {
    setSettingTurb(item)
    setModalUpdateOpen(!isModalUpdateOpen)
  }

  if (isLoading) return null

  return (
    <div className='pf-v5-u-box-shadow-md'>
      <Panel style={panelStyle}>
        <PanelHeader>
          <b>Mapa - Aerogeradores {site.site_name}</b>
        </PanelHeader>
        <Divider />
        <PanelMain className='container-table'>
          <EntityTable<SettingsTurbineMap>
            items={filteredSettings || []}
            isLoading={isLoading}
            itemKeyName='turb_id'
            isCompact
            columnConfig={[
              {
                key: 'turb_id',
                description: 'Aerogerador',
                formatter: value => turbsMap.get(value)?.name || '',
              },
              { key: 'position', description: 'Posição' },
              {
                key: 'visible',
                description: 'Visível',
                formatter: value => (value ? <EnabledIcon /> : <DisabledIcon />),
              },
            ]}
            onClickEdit={handleModalUpdateOpen}
            isFilterable={!!inputValue}
            onClearFilters={() => setInputValue('')}
          />
        </PanelMain>
        <ModalTurbs
          isModalOpen={isModalUpdateOpen}
          turbsMap={turbsMap}
          isLoading={isLoading}
          settingTurb={settingTurb}
          updateTurb={updateTurb}
          onCloseModal={closeModal}
          onlyEditable={true}
        />
      </Panel>
    </div>
  )
}

export default TableTurbs
