import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { AlarmHours } from '../../client'
import ColorScale from '../../utils/colorScale'

interface IProps {
  dataTable: AlarmHours[]
  style: { height: string }
  EmptyComponent: React.ReactNode
}

const columns = ['Minutos', 'Nº', 'Descrição']

const TableAlarmsHours = (props: IProps) => {
  const { dataTable, style, EmptyComponent } = props

  return (
    <div style={{ backgroundColor: ColorScale.branco }}>
      <OuterScrollContainer style={style} className='pf-v5-u-box-shadow-sm'>
        <InnerScrollContainer>
          <Table variant='compact' aria-label='container-table-alarms-hours' isStickyHeader>
            <Thead>
              <Tr>
                {columns.map(col => (
                  <Th modifier='wrap' key={col}>
                    {col}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {dataTable.map(el => (
                <Tr key={el.error}>
                  <Td dataLabel={columns[0]}>{Math.floor(el.hours)}</Td>
                  <Td dataLabel={columns[1]}>{el.error}</Td>
                  <Td dataLabel={columns[2]}>{el.description}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {dataTable.length === 0 && EmptyComponent}
        </InnerScrollContainer>
      </OuterScrollContainer>
    </div>
  )
}

export default TableAlarmsHours
