import { InfoWindow, Marker, useGoogleMap } from '@react-google-maps/api'
import { useQuery } from '@tanstack/react-query'
import { index } from 'd3-array'
import { useCallback, useEffect } from 'react'
import { queryClient } from '../../services/api'

import { IExibicao, turbsMapType } from '.'
import markerAmbientalStop from '../../assets/img/markerAmbientalStop.png'
import markerNoCommunication from '../../assets/img/markerNoCommunication.png'
import markerOperational from '../../assets/img/markerOperational.png'
import markerStopped from '../../assets/img/markerStopped.png'
import {
  ProductionAPI,
  ProductionRealtimeTurb,
  SettingsTurbineMap,
  Site,
  Turbines,
} from '../../client'

const formatTurbName = (turbina: Turbines) => {
  return `${turbina.wf_id < 10 ? '0' + turbina.wf_id : turbina.wf_id}-${turbina.name.replace(
    /[^\d]/g,
    ''
  )}`
}
const iconFactory = (marker: string, iconSize: number) => {
  const _iconSize = { width: iconSize, height: iconSize }
  return {
    url: marker,
    size: _iconSize,
    scaledSize: _iconSize,
  }
}

interface IStatusStyle {
  markerIcon: any
  contentFormatter: (turbName: string, rtl: ProductionRealtimeTurb | undefined) => JSX.Element
}

const performanceStatusStyles = new Map<string, IStatusStyle>()

performanceStatusStyles.set('operational', {
  markerIcon: iconFactory(markerOperational, 15),
  contentFormatter: (turbName: string, rlt: ProductionRealtimeTurb | undefined) => (
    <>
      <b>{turbName}</b>&nbsp;
      {rlt?.nws?.toFixed(1) || 0}m/s&nbsp;
      {rlt?.pwr ? (rlt.pwr / 1000).toFixed(1) : 0}
      MW
    </>
  ),
})
performanceStatusStyles.set('no_communication', {
  markerIcon: iconFactory(markerNoCommunication, 20),
  contentFormatter: (turbName: string) => <b>{turbName} Sem Comunic. </b>,
})

performanceStatusStyles.set('stop', {
  markerIcon: iconFactory(markerStopped, 20),
  contentFormatter: (turbName: string) => <b>{turbName} Parada</b>,
})

performanceStatusStyles.set('ambiental_stop', {
  markerIcon: iconFactory(markerAmbientalStop, 20),
  contentFormatter: (turbName: string) => <b>{turbName} Parada Amb. </b>,
})

const defaultQueryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 2,
}

const InfoMarker = ({
  turb,
  styles,
  rlt,
  exibicao,
  showTurbsData,
  config,
}: {
  turb: Turbines
  styles: IStatusStyle
  rlt: ProductionRealtimeTurb | undefined
  exibicao: 'performance' | 'ocorrencias'
  showTurbsData: boolean
  config: SettingsTurbineMap | undefined
}) => {
  const configPosition = config?.position ?? 'right'

  const lat = turb?.lat && !isNaN(turb?.lat) ? turb.lat : -15.7
  const lon = turb?.lon && !isNaN(turb?.lon) ? turb.lon : -47.9
  const turbCoords = new google.maps.LatLng(lat, lon)

  const status = rlt?.status ?? 'no_communication'

  const infoWindow = (
    <InfoWindow position={turbCoords}>
      <div className={`mapCard mapCard--${status} mapCard--position-${configPosition}`}>
        {showTurbsData && (
          <p className={`mapCard__title mapCard__title--${status}`}>
            {styles.contentFormatter(formatTurbName(turb), rlt)}
          </p>
        )}
      </div>
    </InfoWindow>
  )

  return (
    <Marker key={turb.name} position={turbCoords} icon={styles.markerIcon} title={`${turb.name}`}>
      {' '}
      <>{exibicao === 'performance' && infoWindow}</>
    </Marker>
  )
}

const Performance = ({
  turbs,
  site,
  exibicao,
  showTurbsData,
  turbsMap,
}: {
  turbs: Turbines[]
  site: Site
  exibicao: IExibicao
  showTurbsData: boolean
  turbsMap: turbsMapType
}) => {
  const { isLoading, data: turbsRtl } = useQuery(
    ['turbsRealtime', site?.site_id],
    async () => {
      const turbinesRealtime = await ProductionAPI.getRealtimeTurb({ siteId: site.site_id })
      return index(turbinesRealtime, t => t.turb_id)
    },
    { enabled: !!site?.site_id, ...defaultQueryOptions }
  )
  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(['turbsRealtime', site?.site_id])
    }, 1000 * 15)
    return () => clearInterval(interval)
  }, [site?.site_id])

  const map = useGoogleMap()
  useEffect(() => {
    const panTimeout = setTimeout(() => {
      if (!map) return
      const siteZoom = site?.map_zoom_level ? site.map_zoom_level + 1 : 10
      const lat = site?.lat ? site.lat : -15.7
      const lon = site?.lon ? site.lon : -47.9
      const siteCoords = new google.maps.LatLng(lat, lon)
      map.setZoom(siteZoom)
      map.panTo(siteCoords)
    }, 750)
    return () => clearTimeout(panTimeout)
  }, [exibicao, site, isLoading])

  const PerformanceCallback = useCallback(() => {
    return (
      <>
        {turbs.map(turb => {
          const rtl = turbsRtl?.get(turb.turb_id)
          const config = turbsMap[turb.turb_id]
          const isVisible = config?.visible

          if (isVisible === false) return null

          const defaultStyle = performanceStatusStyles.get('operational')
          const realtimeStyle = performanceStatusStyles.get(
            rtl?.status ? rtl.status : 'no_communication'
          )

          const styles = exibicao === 'performance' ? realtimeStyle : defaultStyle
          if (!styles) return null
          return (
            <InfoMarker
              showTurbsData={showTurbsData}
              key={turb.turb_id}
              styles={styles}
              rlt={rtl}
              config={config}
              exibicao={exibicao}
              turb={turb}
            />
          )
        })}
      </>
    )
  }, [turbs, isLoading, site, exibicao, showTurbsData, turbsMap])

  if (isLoading || !turbs || turbs.length === 0) return null
  return <PerformanceCallback />
}

export default Performance
