/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Me } from '../models/Me';
import type { UserIn } from '../models/UserIn';
import type { UserOut } from '../models/UserOut';
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersAPI {

  /**
   * Get Me
   * @returns Me Successful Response
   * @throws ApiError
   */
  public static getMe(): CancelablePromise<Me> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/me/',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get All
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<UserOut>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/users',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Update
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public static update({
    requestBody,
  }: {
    requestBody: UserUpdate,
  }): CancelablePromise<UserOut> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Create
   * @returns UserOut Successful Response
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: UserIn,
  }): CancelablePromise<UserOut> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Delete
   * @returns string Successful Response
   * @throws ApiError
   */
  public static delete({
    userId,
  }: {
    userId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/admin/users/{user_id}',
      path: {
        'user_id': userId,
      },
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

}
