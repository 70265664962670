import { useMemo } from 'react'
import { CalibrationEvents, Masts } from '../../../client'
import { Sensors } from '../../../contexts/MastContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import ColorScale, { COLOR_SCALES } from '../../../utils/colorScale'
import { formatDateTimeBrl } from '../../../utils/formatDate'
import { getFirstSegment } from '../../../utils/getFirstSegment'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../../utils/plotly'
import Plot from '../../shared/Plot'
import { EMPTY_TRACE_CALIBRATION, GRID_SIZE } from './constants'

const CONFIG = { ...defaultConfig, displayModeBar: false }

const makeTracePlotTimelineCalibration = ({
  eventStart,
  eventEnd,
  eventId,
  sensorLabel,
  isFiltered,
  isSelected = true,
}: {
  eventStart: string
  eventEnd: string
  eventId?: string
  sensorLabel: string
  isFiltered?: boolean
  isSelected?: boolean
}) => {
  const eventIn = new Date(eventStart)
  const eventFin = new Date(eventEnd)

  return {
    type: 'bar',
    orientation: 'h',
    base: [eventIn.getTime()],
    x: [eventFin.getTime() - eventIn.getTime()],
    y: [sensorLabel],
    marker: {
      color: isFiltered ? COLOR_SCALES.green : COLOR_SCALES.blue,
      opacity: isSelected ? 0.8 : 0.3,
      line: {
        color: ColorScale.branco,
        width: 1,
      },
    },
    hovertemplate:
      `<b>${sensorLabel}</b><br>` +
      `Inicio: ${formatDateTimeBrl(eventIn)}<br>Fim: ${formatDateTimeBrl(eventFin)}` +
      `<br>Id: ${eventId}` +
      '<br><extra></extra>',
  }
}

const PlotTimeline = ({
  calibrationEvents,
  sensors,
  revision,
  eventSelected,
  sensorFilter,
  mast,
  setIsModalCalibrationOpen,
  setDataToUpdate,
}: {
  calibrationEvents?: CalibrationEvents[]
  sensors: Sensors[]
  revision: number
  mast: Masts
  eventSelected?: CalibrationEvents
  sensorFilter: number | null
  setIsModalCalibrationOpen: React.Dispatch<React.SetStateAction<boolean>>
  setDataToUpdate: React.Dispatch<React.SetStateAction<CalibrationEvents | undefined>>
}) => {
  const traces = useMemo(() => {
    const traces = sensors.map(s => {
      const emptyTrace = [{ ...EMPTY_TRACE_CALIBRATION, y: [`${s.label}`] }]
      const evs = calibrationEvents?.filter(c => c.sensor_id === s.id)
      const evTraces =
        evs?.length === 0
          ? emptyTrace
          : evs?.map(e => {
              const isSelected = eventSelected?.id === e.id
              return {
                ...makeTracePlotTimelineCalibration({
                  eventStart: e.start_time,
                  eventEnd: e.end_time,
                  sensorLabel: `${s.label}`,
                  ...(e.id && { eventId: getFirstSegment(e.id) }),
                  isFiltered: sensorFilter !== null && sensorFilter === s.id,
                  ...(eventSelected && { isSelected }),
                }),
                _sensor: e,
              }
            })
      return [...(evTraces ?? [])]
    })
    return traces.flat()
  }, [sensors, calibrationEvents, revision])

  const layout = {
    template: layoutTemplate,
    margin: { t: 10, r: 10, l: 100, b: 25 },
    barmode: 'stack',
    showlegend: false,
    hovermode: 'closest',
    hoverlabel: { bgcolor: ColorScale.branco, font: { color: ColorScale.preto } },
    xaxis: { ...defaultAxis, type: 'date', range: [mast.dataset_start, mast.dataset_end] },
    yaxis: {
      ...defaultAxis,
      automargin: true,
      type: 'category',
      fixedrange: true,
    },
  }

  type OnClickPlot = {
    points: Array<{
      data: { _sensor: CalibrationEvents }
    }>
    event: MouseEvent
  }

  function onClickPlot(e: OnClickPlot) {
    setDataToUpdate(e.points[0].data._sensor)
    setIsModalCalibrationOpen(true)
  }

  return (
    <div>
      <Plot
        data={traces}
        layout={layout}
        config={CONFIG}
        style={{
          height: calcVerticalHeight({ elementSize: GRID_SIZE / 2, gridSize: GRID_SIZE }),
          minHeight: '5rem',
          width: '100%',
        }}
        key={revision}
        useResizeHandler
        revision={revision}
        onClick={onClickPlot}
      />
    </div>
  )
}

export default PlotTimeline
