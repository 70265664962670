/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_workbook_upload_sites__site_id__downtime_ons_workbook_upload_post } from '../models/Body_workbook_upload_sites__site_id__downtime_ons_workbook_upload_post';
import type { DowntimeAero } from '../models/DowntimeAero';
import type { DowntimeBop } from '../models/DowntimeBop';
import type { DowntimeMetadata } from '../models/DowntimeMetadata';
import type { DowntimeONS } from '../models/DowntimeONS';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DowntimeAPI {

  /**
   * Get Options
   * @returns DowntimeMetadata Successful Response
   * @throws ApiError
   */
  public static getOptions(): CancelablePromise<DowntimeMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/downtime/options',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Energy Loss
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getEnergyLoss({
    siteId,
    yyyy,
    mm,
  }: {
    siteId: number,
    yyyy?: number,
    mm?: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/downtime/energy-loss',
      path: {
        'site_id': siteId,
      },
      query: {
        'yyyy': yyyy,
        'mm': mm,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Downtime Aero
   * @returns DowntimeAero Successful Response
   * @throws ApiError
   */
  public static getDowntimeAero({
    siteId,
    yyyy,
    mm,
  }: {
    siteId: number,
    yyyy?: number,
    mm?: number,
  }): CancelablePromise<Array<DowntimeAero>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/downtime/aero',
      path: {
        'site_id': siteId,
      },
      query: {
        'yyyy': yyyy,
        'mm': mm,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Downtime Aero
   * @returns DowntimeAero Successful Response
   * @throws ApiError
   */
  public static createDowntimeAero({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: DowntimeAero,
  }): CancelablePromise<DowntimeAero> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sites/{site_id}/downtime/aero',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Downtime Aero
   * @returns DowntimeAero Successful Response
   * @throws ApiError
   */
  public static updateDowntimeAero({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: DowntimeAero,
  }): CancelablePromise<DowntimeAero> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/{site_id}/downtime/aero',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Merge Downtime Aero
   * @returns any Successful Response
   * @throws ApiError
   */
  public static mergeDowntimeAero({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: Array<string>,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/sites/{site_id}/downtime/aero',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Downtime Aero
   * @returns string Successful Response
   * @throws ApiError
   */
  public static deleteDowntimeAero({
    siteId,
    downtimeId,
  }: {
    siteId: number,
    downtimeId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/sites/{site_id}/downtime/aero/{downtime_id}',
      path: {
        'site_id': siteId,
        'downtime_id': downtimeId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Downtime Bop
   * @returns DowntimeBop Successful Response
   * @throws ApiError
   */
  public static getDowntimeBop({
    siteId,
    yyyy,
    mm,
  }: {
    siteId: number,
    yyyy?: number,
    mm?: number,
  }): CancelablePromise<Array<DowntimeBop>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/downtime/bop',
      path: {
        'site_id': siteId,
      },
      query: {
        'yyyy': yyyy,
        'mm': mm,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Downtime Bop
   * @returns DowntimeBop Successful Response
   * @throws ApiError
   */
  public static createDowntimeBop({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: DowntimeBop,
  }): CancelablePromise<DowntimeBop> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sites/{site_id}/downtime/bop',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Downtime Bop
   * @returns DowntimeBop Successful Response
   * @throws ApiError
   */
  public static updateDowntimeBop({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: DowntimeBop,
  }): CancelablePromise<DowntimeBop> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/{site_id}/downtime/bop',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Downtime Bop
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteDowntimeBop({
    siteId,
    downtimeId,
  }: {
    siteId: number,
    downtimeId: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/sites/{site_id}/downtime/bop/{downtime_id}',
      path: {
        'site_id': siteId,
        'downtime_id': downtimeId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Downtime Ons
   * @returns DowntimeONS Successful Response
   * @throws ApiError
   */
  public static getDowntimeOns({
    siteId,
    yyyy,
    mm,
  }: {
    siteId: number,
    yyyy?: number,
    mm?: number,
  }): CancelablePromise<Array<DowntimeONS>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/downtime/ons',
      path: {
        'site_id': siteId,
      },
      query: {
        'yyyy': yyyy,
        'mm': mm,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Downtime Ons
   * @returns DowntimeONS Successful Response
   * @throws ApiError
   */
  public static createDowntimeOns({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: DowntimeONS,
  }): CancelablePromise<DowntimeONS> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sites/{site_id}/downtime/ons',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Downtime Ons
   * @returns DowntimeONS Successful Response
   * @throws ApiError
   */
  public static updateDowntimeOns({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: DowntimeONS,
  }): CancelablePromise<DowntimeONS> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/{site_id}/downtime/ons',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Downtime Ons
   * @returns number Successful Response
   * @throws ApiError
   */
  public static deleteDowntimeOns({
    siteId,
    restricaoId,
  }: {
    siteId: number,
    restricaoId: number,
  }): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/sites/{site_id}/downtime/ons/{restricao_id}',
      path: {
        'site_id': siteId,
        'restricao_id': restricaoId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Workbook Upload
   * @returns any Successful Response
   * @throws ApiError
   */
  public static workbookUpload({
    siteId,
    formData,
  }: {
    siteId: number,
    formData: Body_workbook_upload_sites__site_id__downtime_ons_workbook_upload_post,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sites/{site_id}/downtime/ons/workbook_upload',
      path: {
        'site_id': siteId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
