import { createRoot } from 'react-dom/client'
import App from './App'
import tracing from './services/tracing'

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
} else {
  console.error('Root node not found')
}

tracing()
