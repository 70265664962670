/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchRemoveResponse } from '../models/BatchRemoveResponse';
import type { News } from '../models/News';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NewsAPI {

  /**
   * Get All
   * @returns News Successful Response
   * @throws ApiError
   */
  public static getAll({
    limit,
  }: {
    limit?: (number | null),
  }): CancelablePromise<Array<News>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/news',
      query: {
        'limit': limit,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create
   * @returns News Successful Response
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: News,
  }): CancelablePromise<News> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/news',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update
   * @returns News Successful Response
   * @throws ApiError
   */
  public static update({
    requestBody,
  }: {
    requestBody: News,
  }): CancelablePromise<News> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/news',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Batch Delete
   * @returns BatchRemoveResponse Successful Response
   * @throws ApiError
   */
  public static batchDelete({
    newsIds,
  }: {
    newsIds?: Array<string>,
  }): CancelablePromise<BatchRemoveResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/news/batch_delete',
      query: {
        'news_ids': newsIds,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete
   * @returns string Successful Response
   * @throws ApiError
   */
  public static delete({
    newsId,
  }: {
    newsId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/news/{news_id}',
      path: {
        'news_id': newsId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
