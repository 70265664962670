/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupIn } from '../models/GroupIn';
import type { GroupOut } from '../models/GroupOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GroupsAPI {

  /**
   * Get All
   * @returns GroupOut Successful Response
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<GroupOut>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/groups',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Update
   * @returns GroupOut Successful Response
   * @throws ApiError
   */
  public static update({
    requestBody,
  }: {
    requestBody: GroupIn,
  }): CancelablePromise<GroupOut> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/groups',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Create
   * @returns GroupOut Successful Response
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: GroupIn,
  }): CancelablePromise<GroupOut> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/groups',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Delete
   * @returns string Successful Response
   * @throws ApiError
   */
  public static delete({
    groupId,
  }: {
    groupId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/admin/groups/{group_id}',
      path: {
        'group_id': groupId,
      },
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

}
