import '@patternfly/patternfly/patternfly-addons.css'
import '@patternfly/patternfly/patternfly.min.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import AppRouter from './Routes'
import { queryClient } from './services/api'

const { REACT_APP_ENVIRONMENT } = process.env

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.PUBLIC_URL ?? ''}>
        <AppRouter />
        {REACT_APP_ENVIRONMENT === 'dev' && <ReactQueryDevtools />}
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
