import { Select, SelectOption } from '@patternfly/react-core/deprecated'
import { useContext, useEffect, useState } from 'react'
import SitesContext from '../../../../contexts/SitesContext'

const SelectListWtGs = props => {
  const { defaultsToComplex, wtGsSelected, setWtGsSelected } = props
  const { turbs, site } = useContext(SitesContext)
  const _turbs = defaultsToComplex ? [{ turb_id: 0, name: 'Complexo' }, ...turbs] : [...turbs]
  const [isOpen, setIsOpen] = useState(false)

  const handledSelect = (_, selection) => {
    setWtGsSelected(_turbs.find(t => t.name === selection))
    setIsOpen(false)
  }

  useEffect(() => {
    setWtGsSelected(_turbs[0])
  }, [turbs])

  const mapSelectOptions = ents =>
    ents.map(t => (
      <SelectOption id={`turb_selection_${t.turb_id}`} key={t.turb_id} value={t.name} />
    ))

  const options = mapSelectOptions(_turbs)

  const onFilter = (_, textInput) => {
    if (textInput === '') return options

    return mapSelectOptions(
      _turbs.filter(e => e.name.toLowerCase().includes(textInput.toLowerCase()))
    )
  }

  return (
    <Select
      key={site?.site_id}
      onToggle={() => setIsOpen(!isOpen)}
      onSelect={handledSelect}
      selections={wtGsSelected.name}
      isOpen={isOpen}
      className='pf-v5-u-p-sm'
      maxHeight='30rem'
      hasInlineFilter
      inlineFilterPlaceholderText='Filtre por WTG'
      onFilter={onFilter}
    >
      {mapSelectOptions(_turbs)}
    </Select>
  )
}

export default SelectListWtGs
