import {
  Button,
  Panel,
  PanelMain,
  PanelMainBody,
  Stack,
  StackItem,
  Switch,
} from '@patternfly/react-core'
import { TimesIcon, UndoIcon } from '@patternfly/react-icons'
import { useState } from 'react'

const SettingsPanel = ({
  spChartAnnotations,
  setSpChartAnnotations,
  spChartAnnotationMode,
  setSpChartAnnotationMode,
}: {
  spChartAnnotations: any
  setSpChartAnnotations: React.Dispatch<React.SetStateAction<any>>
  spChartAnnotationMode: boolean
  setSpChartAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [indexMultipliersSp, setIndexMultipliersSp] = useState(0)

  const multipliers = [
    { x: 1, y: -1 },
    { x: -1, y: 1 },
  ]

  const rotateAnnotationsSp = () => {
    const newAnnotations = spChartAnnotations.map((annotation: any) => {
      annotation.ax = annotation.ax * multipliers[indexMultipliersSp].x
      annotation.ay = annotation.ay * multipliers[indexMultipliersSp].y

      return annotation
    })

    setIndexMultipliersSp(indexMultipliersSp === 0 ? 1 : 0)
    setSpChartAnnotations(newAnnotations)
  }

  return (
    <Panel>
      <PanelMain tabIndex={0}>
        <PanelMainBody>
          <Stack>
            <StackItem>
              <Switch
                className='pf-v5-u-mr-sm'
                label='Modo anotação habilitado'
                labelOff='Modo anotação desabilitado'
                aria-label='sp-annotation-mode'
                isChecked={spChartAnnotationMode}
                onChange={() => {
                  setSpChartAnnotationMode(!spChartAnnotationMode)
                  setSpChartAnnotations([])
                  setIndexMultipliersSp(0)
                }}
              />
              <Button
                className='pf-v5-u-mr-sm'
                isDanger
                variant='secondary'
                isDisabled={!spChartAnnotationMode}
                onClick={() => {
                  if (spChartAnnotations.length > 0) {
                    setSpChartAnnotations([])
                    setIndexMultipliersSp(0)
                  }
                }}
              >
                <TimesIcon />
              </Button>
              <Button
                variant='secondary'
                isDisabled={!spChartAnnotationMode}
                onClick={() => {
                  if (spChartAnnotations.length > 0) {
                    rotateAnnotationsSp()
                  }
                }}
              >
                <UndoIcon />
              </Button>
            </StackItem>
          </Stack>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}

export default SettingsPanel
