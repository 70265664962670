/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Circuit } from '../models/Circuit';
import type { Site } from '../models/Site';
import type { Transformer } from '../models/Transformer';
import type { Turbines } from '../models/Turbines';
import type { WindFarms } from '../models/WindFarms';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SitesAPI {

  /**
   * Get Transformers
   * @returns Transformer Successful Response
   * @throws ApiError
   */
  public static getTransformers(): CancelablePromise<Array<Transformer>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/transformers',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Circuits
   * @returns Circuit Successful Response
   * @throws ApiError
   */
  public static getCircuits(): CancelablePromise<Array<Circuit>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/circuits',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Farms
   * @returns WindFarms Successful Response
   * @throws ApiError
   */
  public static getFarms(): CancelablePromise<Array<WindFarms>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/farms',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Wind Turbines
   * @returns Turbines Successful Response
   * @throws ApiError
   */
  public static getWindTurbines(): CancelablePromise<Array<Turbines>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/turbines',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get All
   * Listagem de parques eólicos
   * @returns Site Successful Response
   * @throws ApiError
   */
  public static getAllSitesGet(): CancelablePromise<Array<Site>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get
   * @returns Site Successful Response
   * @throws ApiError
   */
  public static get({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Site> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Sites Farms
   * @returns WindFarms Successful Response
   * @throws ApiError
   */
  public static getSitesFarms({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<WindFarms>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/farms',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Site Wind Turbines
   * @returns Turbines Successful Response
   * @throws ApiError
   */
  public static getSiteWindTurbines({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<Turbines>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
