import { Icon } from '@patternfly/react-core'
import { CheckCircleIcon, DisconnectedIcon, ExclamationCircleIcon } from '@patternfly/react-icons'
import ColorScale from '../../../utils/colorScale'

import NoCommunication from '../../../assets/img/markerNoCommunication.png'

const style = { margin: '0px auto' }

const farol = new Map([
  [
    '-2',
    <Icon key={0} style={style} size='md'>
      <DisconnectedIcon color={ColorScale.cinza_escuro} title='Sem dados' />
    </Icon>,
  ],
  [
    '-1',
    <img
      key={6}
      src={NoCommunication}
      style={{ width: '1.5em', ...style }}
      alt='Sem comunicação'
    />,
  ],
  [
    '0',
    <Icon key={1} style={style} size='md'>
      <CheckCircleIcon color={ColorScale.verde_cadmium} />
    </Icon>,
  ],
  [
    '1',
    <Icon key={2} style={style} size='md'>
      <CheckCircleIcon color={ColorScale.amarelo_cyber} />
    </Icon>,
  ],
  [
    '2',
    <Icon key={3} style={style} size='md'>
      <ExclamationCircleIcon color={ColorScale.laranja_amarelo} />
    </Icon>,
  ],
  [
    '3',
    <Icon key={4} style={style} size='md'>
      <ExclamationCircleIcon color={ColorScale.laranja_international} />
    </Icon>,
  ],
  [
    '4',
    <Icon key={5} style={style} size='md'>
      <ExclamationCircleIcon color={ColorScale.laranja_chama} />
    </Icon>,
  ],
])

export { farol }
