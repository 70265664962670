import DownloadImage from '../../../assets/gif/plotly/download-plot-image.gif'
import ExportCsv from '../../../assets/gif/plotly/export-csv.gif'

import DownloadIcon from '../../../assets/img/icon-download.svg'

import ImgGif from '../../shared/ImgGif'

export const stepExportCsv = (
  <>
    <div>
      <h4>
        <strong>Exportar dados em .csv </strong>
      </h4>
      <p>
        Selecione os dados que deseja exportar e clique no ícone de exportação de dados em .csv.
      </p>

      <ImgGif src={ExportCsv} />
    </div>
  </>
)

export const stepDownloadImage = (
  <>
    <div>
      <h4>
        <strong>Download do plot (.svg ou .png) </strong>
      </h4>
      <p>
        A opção{' '}
        <img
          src={DownloadIcon}
          style={{ width: '1.5em' }}
          alt='Ícone para baixar a imagem do plot em .svg ou .png'
        />{' '}
        permite baixar uma imagem do plot em .svg ou .png.
      </p>

      <ImgGif src={DownloadImage} />
    </div>
  </>
)
