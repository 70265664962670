import { Text } from '@patternfly/react-core'

import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import months from '../../utils/months'
import Plot from '../shared/Plot'
import plotConfigButtons from './plotConfigButtons'
import plotTemplate from './plotTemplate'

import { AvailabilityMonthly } from '../../client'

const ChartAvailableEnergyMonthly = ({
  dataChart,
  yearSelected,
  monthSelected,
  isLoading,
  revision,
}: {
  dataChart: AvailabilityMonthly[]
  yearSelected: number
  monthSelected: number
  isLoading: boolean
  revision: number
}) => {
  if (isLoading || dataChart.length === 0) return null

  const filteredData = dataChart
    .sort((a: AvailabilityMonthly, b: AvailabilityMonthly) => a.mm - b.mm)
    .filter(el => el.yyyy === yearSelected)

  const minAvailableValue = () => {
    let min = 100
    if (filteredData.length === 0) return 0
    filteredData.forEach(el => {
      if (el.available !== null && el.available < min) {
        min = el.available
      }
    })
    return (Math.floor(min / 10) - 1) * 10
  }

  const availableMonths = months.slice(0, filteredData.length)
  const x = [...availableMonths, 'Anual']

  const anual = filteredData.find(el => el.mm === monthSelected)

  const monthlyData = [
    {
      x,
      y: [...filteredData.map(e => e.available), anual?.available_acc],
      type: 'bar',
      marker: { color: COLORS.verde },
      name: 'Disponibilidade',
    },
    {
      x,
      y: [
        ...filteredData.map(e => 100 - (e.bop_parada ?? 100)),
        100 - (anual?.bop_parada_acc ?? 100),
      ],
      name: 'BOP parada',
      type: 'bar',
      marker: { color: COLORS.amarelo_pantone },
    },
    {
      x,
      y: [
        ...filteredData.map(e => 100 - (e.bop_restric ?? 100)),
        100 - (anual?.bop_restric_acc ?? 100),
      ],
      name: 'BOP restrição ONS',
      type: 'bar',
      marker: { color: COLORS.laranja_international },
    },
    {
      x,
      y: [
        ...filteredData.map(e => 100 - (e.turbine_parada ?? 100)),
        100 - (anual?.turbine_parada_acc ?? 100),
      ],
      name: 'Turbina parada',
      type: 'bar',
      marker: { color: COLORS.roxo_indigo },
    },
    {
      x,
      y: [
        ...filteredData.map(e => 100 - (e.turbine_derate ?? 100)),
        100 - (anual?.turbine_derate_acc ?? 100),
      ],
      name: 'Turbina derate',
      type: 'bar',
      marker: { color: COLORS.azul_pantone },
    },
    {
      x,
      y: filteredData.map(e => e.available_acc),
      mode: 'lines',
      line: { dash: 'Solid', width: 2 },
      marker: { color: COLORS.cinza_escuro },
      name: 'Disponibilidade Acumulada',
    },
    {
      x,
      y: filteredData.map(e => e.bop_parada_acc),
      mode: 'lines',
      line: { dash: 'Solid', width: 2 },
      marker: { color: COLORS.amarelo_titanium },
      name: 'BOP parada Acumulada',
      visible: 'legendonly',
    },
    {
      x,
      y: filteredData.map(e => e.bop_restric_acc),
      mode: 'lines',
      line: { dash: 'Solid', width: 2 },
      marker: { color: COLORS.laranja_amarelo },
      name: 'BOP restrição ONS Acumulada',
      visible: 'legendonly',
    },
    {
      x,
      y: filteredData.map(e => e.turbine_parada_acc),
      mode: 'lines',
      line: { dash: 'Solid', width: 2 },
      marker: { color: COLORS.roxo },
      name: 'Turbina parada Acumulada',
      visible: 'legendonly',
    },
    {
      x,
      y: filteredData.map(e => e.turbine_derate_acc),
      mode: 'lines',
      line: { dash: 'Solid', width: 2 },
      marker: { color: COLORS.azul_meia_noite },
      name: 'Turbina derate Acumulada',
      visible: 'legendonly',
    },
  ]

  const layout = {
    template: plotTemplate,
    barmode: 'stack',
    autosize: true,
    yaxis: { range: [minAvailableValue(), 100] },
    legend: { font: { size: 10.5 }, traceorder: 'normal', y: 4 },
  }

  const style = {
    width: '100%',
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '2rem' }),
  }

  return (
    <div>
      <Text component='h2' className='no-bold'>
        {`Disponibilidade energética mensal ${yearSelected}`}
      </Text>
      <Plot
        divId='ChartAvailableEnergyMonthly'
        data={monthlyData}
        layout={layout}
        config={plotConfigButtons}
        useResizeHandler
        style={style}
        key={revision + Number(yearSelected)}
      />
    </div>
  )
}

export default ChartAvailableEnergyMonthly
