import { stepAutoscaleAxis, stepMoveAxis, stepResizeAxis, stepSecretInputAxis } from './StepsAxis'
import { stepDownloadImage, stepExportCsv } from './StepsExport'
import { stepHideLegend, stepIsolateLegend } from './StepsLegends'
import { stepMove } from './StepsMove'
import { stepZoomArea, stepZoomInOut } from './StepsZoom'

export const stepsAxis = [
  {
    name: 'Eixos',
    steps: [
      { name: 'Movendo os eixos', component: stepMoveAxis },
      { name: 'Autoscale com os eixos', component: stepAutoscaleAxis },
      { name: 'Redimensionar', component: stepResizeAxis },
      { name: 'Input Secreto', component: stepSecretInputAxis },
    ],
  },
]

export const stepsExport = [
  {
    name: 'Exportar Dados',
    steps: [
      { name: 'Download do gráfico (.svg ou .png)', component: stepDownloadImage },
      { name: 'Exportar dados (.csv)', component: stepExportCsv },
    ],
  },
]

export const stepsLegend = [
  {
    name: 'Legendas',
    steps: [
      { name: 'Ocultar sinal pela legenda', component: stepHideLegend },
      { name: 'Isolar sinal no gráfico', component: stepIsolateLegend },
    ],
  },
]

export const stepsZoom = [
  {
    name: 'Zoom',
    steps: [
      { name: 'Zoom em uma área', component: stepZoomArea },
      { name: 'Zoom com ícones', component: stepZoomInOut },
    ],
  },
]

export const stepsMove = [
  {
    name: 'Mover o gráfico',
    component: stepMove,
  },
]
