import {
  DragDrop,
  Draggable,
  DraggableItemPosition,
  Droppable,
  Icon,
  Split,
  SplitItem,
} from '@patternfly/react-core'
import { SelectGroup, SelectOption } from '@patternfly/react-core/deprecated'
import { GripVerticalIcon } from '@patternfly/react-icons'

interface IMultiSelectFooter<T> {
  entityValueName: keyof T
  entityKeyName: keyof T
  selected?: T[]
  setSelected: (newSelects: T[]) => void
  placeholderText?: string
}

const MultiSelectFooter = ({
  entityValueName,
  entityKeyName,
  setSelected,
  selected = [],
  placeholderText = '',
}: IMultiSelectFooter<any>) => {
  const onDrop = (source: DraggableItemPosition, dest?: DraggableItemPosition) => {
    if (!dest) return false
    const newSelected = [...selected]
    const sourcePosition = selected[source.index]
    const destPosition = selected[dest.index]
    newSelected[source.index] = destPosition
    newSelected[dest.index] = sourcePosition
    setSelected(newSelected)
    return true
  }

  return (
    <SelectGroup label='Selecionado'>
      <DragDrop onDrop={onDrop}>
        <Droppable>
          {selected.map(s => {
            const idSuffix = `${placeholderText}_${s[entityKeyName]}`
            return (
              <Draggable key={`drag_${idSuffix}`}>
                <Split>
                  <SplitItem isFilled>
                    <SelectOption
                      isChecked
                      id={`selection_${idSuffix}`}
                      value={`${s[entityValueName]}`}
                    />
                  </SplitItem>
                  <SplitItem>
                    <Icon className='pf-v5-u-m-sm' color='grey'>
                      <GripVerticalIcon />
                    </Icon>
                  </SplitItem>
                </Split>
              </Draggable>
            )
          })}
        </Droppable>
      </DragDrop>
    </SelectGroup>
  )
}

export default MultiSelectFooter
