import {
  Nav,
  NavExpandable,
  NavItem,
  NavList,
  PageSidebar,
  PageSidebarBody,
} from '@patternfly/react-core'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { logout } from '../../../services/auth'

import PermissionContext from '../../../contexts/PermissionContext'

const NO_GROUP = {
  group: 'NO_GROUP',
  items: [{ pageName: 'Torres Anemométricas', pageUrl: 'torres-anemometricas' }],
}

const navItems = [
  {
    group: 'Performance',
    items: [
      { pageName: 'Produção', pageUrl: 'producao' },
      { pageName: 'Curva de Potência', pageUrl: 'curva-potencia' },
      { pageName: 'Curva de Potência BOP', pageUrl: 'curva-pot-bop' },
      { pageName: 'Windness', pageUrl: 'windness' },
    ],
  },
  {
    group: 'Disponibilidade',
    items: [
      { pageName: 'Energy Bridge', pageUrl: 'energy-bridge' },
      { pageName: 'Disponibilidade Energética', pageUrl: 'disponibilidade-energetica' },
      { pageName: 'Disponibilidade Temporal', pageUrl: 'disponibilidade-temporal' },
      { pageName: 'Alarmes', pageUrl: 'alarmes' },
      { pageName: 'Contadores', pageUrl: 'contadores' },
      { pageName: 'Paradas e Restrições', pageUrl: 'paradas-restricoes' },
      { pageName: 'Turbine Health', pageUrl: 'turbine-health' },
      { pageName: 'Expected Energy', pageUrl: 'expected-energy' },
      { pageName: 'Expected Energy WTG', pageUrl: 'expected-turb-energy' },
    ],
  },
  {
    group: 'Torres Anemométricas',
    items: [
      { pageName: 'Sumário', pageUrl: 'masts' },
      { pageName: 'Configurações de Torres', pageUrl: 'mast/settings' },
      { pageName: 'Visualização de Dados', pageUrl: 'mast/data-viz' },
      { pageName: 'Mapeamento de Colunas', pageUrl: 'mast/raw-mappings' },
      { pageName: 'Sinalização de Eventos', pageUrl: 'mast/event-flagging' },
      { pageName: 'Calibração de Sensores', pageUrl: 'mast/calibration' },
    ],
  },
  {
    group: 'Administração do A7',
    items: [
      { pageName: 'Usuários e Grupos', pageUrl: 'usuarios-grupos' },
      { pageName: 'Novidades', pageUrl: 'novidades' },
      { pageName: 'Páginas em Manutenção', pageUrl: 'paginas-manutencao' },
      { pageName: 'Configurações de Aeros', pageUrl: 'configuracoes-turbs' },
      { pageName: 'Config de Torres', pageUrl: 'configuracoes-masts' },
      { pageName: 'Ocorrências Mapa', pageUrl: 'ocorrencias' },
    ],
  },
]

const Sidebar = ({
  isNavOpen,
  handleModalHelpToggle,
  helpContent,
}: {
  isNavOpen: boolean
  handleModalHelpToggle: () => void
  helpContent?: ReactNode
}) => {
  const { me, meIsLoading, pages } = useContext(PermissionContext)

  const location = useLocation()

  const [navExpandedState, setNavExpandedState] = useState<string[]>(
    JSON.parse(localStorage.getItem('navExpandedState') || '[]')
  )

  useEffect(() => {
    const navExpandedLocalStorage = localStorage.getItem('navExpandedState')
    if (navExpandedLocalStorage) {
      setNavExpandedState(JSON.parse(navExpandedLocalStorage))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('navExpandedState', JSON.stringify(navExpandedState))
  }, [navExpandedState])

  const handleNavExpanded = (navGroup: string) => {
    if (navExpandedState.includes(navGroup)) {
      const removeItem = navExpandedState.filter(e => e !== navGroup)
      setNavExpandedState(removeItem)
    } else {
      const newItems = [...navExpandedState, navGroup]
      setNavExpandedState(newItems)
    }
  }

  const handleLogOut = () => {
    logout()
    window.location.href = '/'
  }

  if (meIsLoading)
    return (
      <Nav aria-label='Nav'>
        <NavList></NavList>
      </Nav>
    )
  const { authorized } = me

  const pagesPerms = authorized.map(p => p.page_name)

  return (
    <PageSidebar theme='light' isSidebarOpen={isNavOpen}>
      <PageSidebarBody>
        <Nav theme='light' aria-label='Nav'>
          <NavList>
            <NavItem
              groupId='grp-1'
              itemId='grp-1'
              isActive={
                location.pathname.includes('home') || location.pathname.split('/')[1] === ''
              }
            >
              <Link to='/home'>Home</Link>
            </NavItem>
            {NO_GROUP.items
              .filter(it => pagesPerms.includes(it.pageName))
              .map((item, i) => (
                <NavItem
                  key={item.pageName}
                  groupId={NO_GROUP.group}
                  itemId={`${NO_GROUP.group}_itm-${i}`}
                  isActive={location.pathname.includes(item.pageUrl)}
                >
                  <Link to={`/${item.pageUrl}`}>{item.pageName}</Link>
                </NavItem>
              ))}

            {navItems.map(group => {
              return (
                <div key={group.group}>
                  {pagesPerms.some(p => group.items.some(i => i.pageName === p)) && (
                    <NavExpandable
                      key={group.group}
                      title={group.group}
                      groupId={group.group}
                      isExpanded={!navExpandedState.includes(group.group)}
                      onClick={() => handleNavExpanded(group.group)}
                    >
                      {group.items
                        .filter(it => pagesPerms.includes(it.pageName))
                        .map((item, i) => (
                          <NavItem
                            key={item.pageName}
                            groupId={group.group}
                            itemId={`${group.group}_itm-${i}`}
                            isActive={location.pathname.includes(item.pageUrl)}
                          >
                            <Link to={`/${item.pageUrl}`}>{item.pageName}</Link>
                          </NavItem>
                        ))}
                    </NavExpandable>
                  )}
                </div>
              )
            })}

            {!!helpContent && (
              <NavItem
                className='pf-v5-u-display-none-on-md'
                groupId='grp-14'
                itemId='grp-14_itm-1'
                onClick={handleModalHelpToggle}
              >
                <Link to='#'>Ajuda</Link>
              </NavItem>
            )}
            <NavItem
              className='pf-v5-u-display-none-on-md'
              groupId='grp-99999'
              itemId='grp-99999_itm-1'
              onClick={handleLogOut}
            >
              <Link to='#'>Sair</Link>
            </NavItem>
          </NavList>
        </Nav>
      </PageSidebarBody>
    </PageSidebar>
  )
}
export default Sidebar
