import { TowerIdentifier } from '../../client'
import SelectList from '../shared/selects/DeprecatedSelectList'

interface IProp {
  selected: number | undefined
  towers: TowerIdentifier[] | undefined
  onChange: (newState: number) => void
  isLoading: boolean
}

const SelectTowers = ({ selected, towers, onChange, isLoading }: IProp) => {
  return (
    <SelectList
      startSelected={selected}
      onChange={onChange}
      isLoading={!!isLoading}
      list={towers?.map(t => {
        return { value: t.tower_id, label: t.tower_name }
      })}
    />
  )
}

export default SelectTowers
