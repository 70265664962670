/**
 * Extrai a primeira parte de uma string dividida por um hífen.
 *
 * @param value - A string a ser processada.
 * @returns A primeira parte da string após a divisão.
 * @example
 * const result = getFirstSegment("550e8400-e29b-41d4-a716-446655440000"); // "550e8400"
 */
export function getFirstSegment(value: string): string {
  return String(value).split('-')[0]
}
