import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { ProductionTurbDataSignals as Signals, Turbdata } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import useTurbdata from '../../hooks/useTurbdata'
import { ACTIONS, DEFAULT_STATE, IAction, reducer, State } from './reducer'

export interface IContext extends State {
  turbdata: Turbdata[]
  turbdataIsLoading: boolean
  turbdataIsFetching: boolean
  dispatch: Dispatch<IAction>
}

const Context = createContext({
  ...DEFAULT_STATE,
  turbdata: new Array<Turbdata>(),
  dispatch: (_: IAction) => {
    console.log('dispatch not initialized')
  },
  turbdataIsLoading: false,
  turbdataIsFetching: false,
})

const sortSignals = (signals: Signals[]) =>
  [...signals].sort((a, b) => (a.signal > b.signal ? 1 : -1))

export const Provider = ({ children }: { children: ReactNode }) => {
  const {
    site,
    sites,
    allTurbs,
    turbs: siteTurbs,
    turbSignals,
    isLoading: sitesIsLoading,
  } = useContext(SitesContext)

  const defaultState = {
    ...DEFAULT_STATE,
    site,
    sites,
    allTurbs,
    siteTurbs,
    siteSignals: turbSignals,
  }

  const [
    {
      turbs,
      turbsSelected,
      signals,
      siteSignals,
      signalsSelected,
      startDate,
      endDate,
      startDateSelected,
      endDateSelected,
      layout,
      scatterPlot,
      timeseriesPlot,
      selectedDataPoints,
      maxTurbs,
      maxSignals,
      experimentalMode,
    },
    dispatch,
  ]: [State, Dispatch<IAction>] = useReducer(reducer, defaultState)

  const {
    turbdata,
    isLoading: turbdataIsLoading,
    isFetching: turbdataIsFetching,
  } = useTurbdata(turbs, signals, startDate, endDate)

  useEffect(() => {
    if (!site || sitesIsLoading) return

    const _defaultSignals = sortSignals(
      turbSignals.filter(s => [site.signal_id_pwr, site.signal_id_nws].includes(s.signal_id))
    ).map(s => s.signal_id)

    dispatch({
      type: ACTIONS.INITIALIZE,
      payload: {
        ...DEFAULT_STATE,
        site,
        sites,
        siteTurbs,
        siteSignals: turbSignals,
        signalsSelected: _defaultSignals,
      },
    })
    dispatch({ type: ACTIONS.TRIGGER_FETCH, payload: {} })
  }, [site, sitesIsLoading, turbSignals])

  const provides = useMemo(() => {
    return {
      dispatch,
      site,
      sites,
      turbs,
      siteTurbs,
      turbsSelected,
      signals,
      siteSignals,
      signalsSelected,
      startDate,
      endDate,
      startDateSelected,
      endDateSelected,
      layout,
      scatterPlot,
      timeseriesPlot,
      selectedDataPoints,
      turbdata,
      maxTurbs,
      maxSignals,
      experimentalMode,
      turbdataIsLoading,
      turbdataIsFetching,
    }
  }, [
    site,
    sites,
    turbs,
    siteTurbs,
    turbsSelected,
    signals,
    siteSignals,
    signalsSelected,
    startDate,
    endDate,
    startDateSelected,
    endDateSelected,
    layout,
    scatterPlot,
    timeseriesPlot,
    selectedDataPoints,
    turbdata,
    maxTurbs,
    maxSignals,
    experimentalMode,
    turbdataIsLoading,
    turbdataIsFetching,
  ])

  return <Context.Provider value={provides}>{children}</Context.Provider>
}

export default Context
