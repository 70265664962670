import { GridItem, gridSpans } from '@patternfly/react-core'
import { bin, range } from 'd3-array'
import { CSSProperties, useContext, useMemo } from 'react'
import { COLOR_SCALES } from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import Context from './Context'

const HistogramPlot = ({
  style,
  maxPlots = 3,
  wrapperGridSize = 6,
}: {
  style: CSSProperties
  maxPlots?: number
  wrapperGridSize?: number
}) => {
  const { turbdata, siteTurbs, siteSignals, signals, signalsSelected } = useContext(Context)

  const colorScale = COLOR_SCALES.categorical_rotated

  const layout = {
    template: layoutTemplate,
    margin: { b: 50, t: 10, l: 50, r: 50 },
    autosize: true,
    yaxis: { title: 'Frequência de Ocorrência' },
  }

  const traces = useMemo(
    () =>
      turbdata
        .map((t, i) => {
          const signalIdsSelected = signalsSelected
            .map(s => signals.find(si => si.signal_id === s))
            .filter(s => Boolean(s))
            // Código acima (.filter(s => Boolean(s))) evita que o sinal seja undefined,
            // aparentemente o typescript não consegue inferir que o filter remove os undefined.
            // Por isso, e somente por isso, a linha abaixo é necessária.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(s => s!.signal_id)

          const orderedSignals = signalIdsSelected
            .map(s => t.signals.find(si => si?.signal_id === s))
            .filter(s => s)

          return orderedSignals.map((s, v) => {
            const turb = siteTurbs.find(tb => tb.turb_id === t.turb_id)
            const signal = siteSignals.find(si => si.signal_id === s?.signal_id)

            if (!signal || !turb || !s) return undefined

            const { a7_axis_bin, a7_count_filter, min_possible, max_possible } = signal

            const signalData = s.data.map(sd => {
              return sd.val
            })
            let bins = bin().thresholds(range(min_possible, max_possible, a7_axis_bin))(signalData)
            bins = bins.filter(b => b.length >= a7_count_filter)

            Object.assign(layout, {
              [`xaxis${v === 0 ? '' : v + 1}`]: {
                ...defaultAxis,
                title: `${signal.description}`,
                showticklabels: true,
                type: 'category',
                tickangle: 60,
                fixedrange: true,
              },
              [`yaxis${v === 0 ? '' : v + 1}`]: {
                ...defaultAxis,
                title: 'Frequência de Ocorrência',
                fixedrange: true,
              },
            })

            return {
              signal,
              turb,
              x: bins.map(b => b.x0).slice(1),
              y: bins.map(b => b.length),
              name: `${turb.name}`,
              type: 'bar',
              showLegend: true,
              marker: { color: colorScale[i][v] },
              xaxis: v === 0 ? 'x1' : `x${v + 1}`,
              yaxis: v === 0 ? 'y1' : `y${v + 1}`,
            }
          })
        })
        .filter(e => e)
        .flat(),
    [turbdata, layout]
  )

  const config = {
    ...defaultConfig,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
    ],
  }
  const signalIdsSelected = signalsSelected
    .map(s => signals.find(si => si.signal_id === s))
    .filter(s => Boolean(s))
    // eslint-disable-next-line
    .map(s => s!.signal_id)

  const gridSize = (wrapperGridSize /
    (signalIdsSelected.length > maxPlots ? maxPlots : signalIdsSelected.length)) as gridSpans

  return (
    <>
      {signalIdsSelected.slice(0, maxPlots).map(signal_id => {
        return (
          <GridItem key={`hist_grid_${signal_id}`} md={12} lg={gridSize}>
            <Plot
              data={traces.filter(t => t?.signal.signal_id === signal_id)}
              layout={layout}
              showlegend
              config={config}
              useResizeHandler
              style={style}
            />
          </GridItem>
        )
      })}
    </>
  )
}

export default HistogramPlot
