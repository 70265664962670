import { Button, Spinner } from '@patternfly/react-core'

const Enviar = ({ onClick, isFetching }) => {
  const content = isFetching ? (
    <Spinner size='md' aria-label='Loading' className='pf-v5-u-mx-lg' />
  ) : (
    'Atualizar'
  )
  return (
    <Button variant='control' className='pf-v5-u-m-sm' onClick={onClick}>
      {content}
    </Button>
  )
}

export default Enviar
