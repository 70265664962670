import { GroupOut, Pages, Site, UserOut } from '../../client'
import { QUERY_KEY_GROUPS, QUERY_KEY_USERS } from '../../contexts/PermissionContext'
import { queryClient as qq } from '../../services/api'

type AllOptions = UserOut | Pages | Site | GroupOut
type KeyAvailableOptions = 'name' | 'site_name' | 'username'
type KeyIdOptions = 'id' | 'page_id' | 'site_id'
type ChosenOptions = string | undefined

export type ModalInfoType = {
  modalTitle: string
  modalElement: UserOut | GroupOut
}

export type DataToDeleteType = {
  id: string
  type: 'USER' | 'GROUP'
}

export type DefaultOptions = {
  availableOptions: string[]
  chosenOptions: string[]
}

export const getAvailableOptions = (
  allOptions: AllOptions[],
  chosenOptions: ChosenOptions[],
  key: KeyAvailableOptions
): string[] => {
  return (
    allOptions
      ?.filter(o => !chosenOptions.includes(o[key as keyof AllOptions]))
      ?.map(o => o[key as keyof AllOptions]) || []
  )
}

export const getIdList = <T extends string | number>(
  allOpts: AllOptions[],
  chosenOpts: ChosenOptions[],
  key: KeyAvailableOptions,
  keyId: KeyIdOptions
): T[] => {
  return (
    allOpts
      ?.filter(co => chosenOpts.includes(co[key as keyof AllOptions]))
      ?.map(co => co[keyId as keyof AllOptions]) || []
  )
}

export const dataValidator = (data: any) => {
  if (typeof data === 'object') {
    for (const value of Object.entries(data)) {
      if ((value[1] as any[])?.length === 0) {
        return false
      }
    }
    return true
  }
}

export const mutateOptions = {
  onSuccess: () => {
    qq.invalidateQueries([QUERY_KEY_USERS])
    qq.invalidateQueries([QUERY_KEY_GROUPS])
  },
}

export const OPTIONS_DEFAULT: DefaultOptions = { availableOptions: [], chosenOptions: [] }
