import { CSSProperties, useMemo } from 'react'
import { EnergyExpectedSite } from '../../client'
import ColorScale from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from './Plot'

const PlotExpectedEnergy = ({
  expectedEnergySite,
  revision,
  style,
  tsIn,
  tsFin,
}: {
  expectedEnergySite: EnergyExpectedSite[]
  revision: number
  style: CSSProperties
  tsIn?: Date
  tsFin?: Date
}) => {
  const floorToNearestMultipleOf10 = (data: Date) => {
    const minutes = data.getMinutes()
    const minutesFloor = Math.floor(minutes / 10) * 10

    const dateTreatment = new Date(data)
    dateTreatment.setMinutes(minutesFloor)

    return dateTreatment
  }

  const createRange = () => {
    if (tsIn && tsFin) {
      const hoursInterval =
        tsFin.getTime() - tsIn.getTime() < 1000 * 60 * 60 * 24
          ? 1000 * 60 * 60
          : 1000 * 60 * 60 * 12

      const findIndexTsIn: number = expectedEnergySite.findIndex(
        item =>
          new Date(item.ts).getTime() <=
            floorToNearestMultipleOf10(tsIn).getTime() - hoursInterval &&
          new Date(item.ts).getTime() >= floorToNearestMultipleOf10(tsIn).getTime() - hoursInterval
      )

      const findIndexTsFin: number = expectedEnergySite.findIndex(
        item =>
          new Date(item.ts).getTime() <=
            floorToNearestMultipleOf10(tsFin).getTime() + hoursInterval &&
          new Date(item.ts).getTime() >= floorToNearestMultipleOf10(tsFin).getTime() + hoursInterval
      )

      const initialIndex = expectedEnergySite[findIndexTsIn] ? findIndexTsIn : 0
      const finalIndex = expectedEnergySite[findIndexTsFin]
        ? findIndexTsFin
        : expectedEnergySite.length - 1

      return [
        new Date(expectedEnergySite[initialIndex]?.ts),
        new Date(expectedEnergySite[finalIndex]?.ts),
      ]
    }
  }

  const layout = {
    template: layoutTemplate,
    shapes: [
      {
        type: 'rect',
        x0: tsIn,
        x1: tsFin,
        y0: 0,
        yref: 'paper',
        xref: 'x',
        y1: 1,
        fillcolor: '#d3d3d3',
        opacity: 0.3,
        line: {
          width: 0,
        },
      },
    ],
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      ...(tsIn &&
        tsFin && {
          range: createRange(),
        }),
    },
    yaxis: {
      title: 'Potência (MW)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      fixedrange: true,
    },
  }

  const config = {
    ...defaultConfig,
  }

  const traces = useMemo(() => {
    const data = expectedEnergySite.sort((a, b) => (new Date(a.ts) > new Date(b.ts) ? 1 : -1))
    const x = data.map(ee => ee.ts)
    return [
      {
        x,
        y: data.map(ee => ee.pwr_exp / 1000),
        name: 'Potência Esperada Complexo',
        marker: { color: ColorScale.azul_escuro },
        mode: 'lines',
        type: 'scatter',
        line: { dash: 'dashdot', width: 4 },
      },
      {
        x,
        y: data.map(ee => ee.pwr / 1000),
        name: 'Potência Real Complexo',
        marker: { color: ColorScale.verde },
        mode: 'lines',
      },
    ]
  }, [expectedEnergySite])

  return (
    <div className='pf-v5-u-p-sm'>
      <Plot
        useResizeHandler
        key={revision}
        data={traces}
        layout={layout}
        config={config}
        style={style}
      />
    </div>
  )
}

export default PlotExpectedEnergy
