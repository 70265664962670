import { ProductionDaily } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

interface IProps {
  dailyData: ProductionDaily[]
  year: number
  month: number
  revision: number
  plotStyle: any
  isLoading: boolean
}

const filterMonth = (dailyData: ProductionDaily[], year: number, month: number) => {
  return dailyData
    .filter((d: ProductionDaily) => d.yyyy === year && d.mm === month)
    .sort((a: ProductionDaily, b: ProductionDaily) => a.dd - b.dd)
}

const ChartProductionDaily = (props: IProps) => {
  const { dailyData, year, month, revision, plotStyle, isLoading } = props

  if (isLoading) return null

  const config = {
    ...defaultConfig,
    displayModeBar: false,
  }

  const layout = {
    template: layoutTemplate,
    margin: { b: 35, t: 25, l: 50, r: 5 },
    yaxis: {
      title: 'Produção diária [GWh]',
      gridcolor: COLORS.transparent_bg,
      fixedrange: true,
      linewidth: 2,
      gridwidth: 2,
    },
    xaxis: {
      title: 'Dia',
      type: 'category',
      fixedrange: true,
      mirror: 'ticks',
      linewidth: 2,
      gridwidth: 2,
    },
    xaxis2: {
      type: 'category',
      fixedrange: true,
      mirror: 'ticks',
      linewidth: 2,
      gridwidth: 2,
      gridcolor: COLORS.transparent_bg,
      overlaying: 'x',
    },
    plot_bgcolor: COLORS.transparent_bg,
  }
  const style = { ...plotStyle }
  const data = [
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      name: 'Realizado',
      type: 'bar',
      marker: { color: COLORS.verde },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      name: 'Meta compensada',
      type: 'bar',
      xaxis: 'x2',
      marker: { color: COLORS.azul_pantone, opacity: 0.4 },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      name: 'P50 Diário',
      type: 'scatter',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.azul_escuro },
      hoverinfo: 'all',
    },
  ]
  filterMonth(dailyData, year, month).forEach((e: ProductionDaily) => {
    data[0].x.push(e.dd)
    data[0].y.push(e.actual_day ? e.actual_day / 1000 : 0)

    data[1].x.push(e.dd)
    data[1].y.push(e.meta_compensada ? e.meta_compensada / 1000 : 0)

    data[2].x.push(e.dd)
    data[2].y.push(e.p50_day ? e.p50_day / 1000 : 0)
  })

  return (
    <div className='pf-v5-u-p-sm-on-sm'>
      <Plot
        divId='ChartProductionDaily'
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        key={revision}
      />
    </div>
  )
}

export default ChartProductionDaily
