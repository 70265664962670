import {
  Button,
  EmptyStateIcon,
  Flex,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
} from '@patternfly/react-core'
import { CogIcon, TimesIcon } from '@patternfly/react-icons'
import { useContext, useMemo, useState } from 'react'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import ModalPCReclassification from './ModalPCReclassification'

import { AvailabilityAPI, AvailabilityPowerCurveEvents } from '../../client'
import { ActionState } from '../../components/actionState'
import EntityTable from '../../components/EntityTable'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { formatDateTimeBrl } from '../../utils/formatDate'
import { getFirstSegment } from '../../utils/getFirstSegment'
import {
  CACHE_KEY_PC,
  GRID_SIZE,
  RESPONSABILIDADES,
  TABLE_HEADER_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ResizeButtons from './ResizeButtons'
import useData, { keyFromDate } from './useData'

const TablePCReclassification = ({
  selectedDate,
  pcReclassification,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  pcReclassification: AvailabilityPowerCurveEvents[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { site } = useContext(SitesContext)
  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<string>('')

  const [reclassification, setReclassification] = useState<AvailabilityPowerCurveEvents>()
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const handleDeleteClick = (item: string) => {
    setDataToDelete(item)
    handleModalConfirmationWarningToggle()
  }
  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setDeleteModalOpen(!isDeleteModalOpen)
  }

  const deleteReclassification = async (id: string) => {
    setActionState('pending')
    try {
      await AvailabilityAPI.deletePcReclassification({
        eventId: id,
        siteId: site.site_id,
      })
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setIsEditModalOpen(false)
    queryClient.invalidateQueries([CACHE_KEY_PC, site?.site_id, keyFromDate(selectedDate, 1)])
  }

  const handleEditReclassification = (item: AvailabilityPowerCurveEvents) => {
    setReclassification(item)
    setIsEditModalOpen(true)
  }

  const { componentes, tipos } = useData(selectedDate)

  function getTipo(id?: number) {
    const found = tipos.find(item => item.id === id)
    return found ? found.tipo : ''
  }

  function getComponent(id?: number | null) {
    const found = componentes.find(c => c.id === id)
    return found ? found.component : ''
  }

  function getResponsabilidade(id?: boolean) {
    const found = RESPONSABILIDADES.find(item => item.id === id)
    return found ? found.nome : ''
  }

  const buttonGroupItems = <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />

  const toolbarItems = (
    <div
      style={{
        display: 'flex',
        ...(isMobile && { flexDirection: 'column' }),
        width: '100%',
      }}
    >
      <ToolbarGroup
        variant='button-group'
        style={{
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
          margin: 0,
        }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </div>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <>
      <div style={{ height: heightBottom, backgroundColor: 'orange' }}>
        {isMobile && (
          <Flex
            style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
          >
            <Button
              style={{
                backgroundColor: 'transparent',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
                color: 'black',
              }}
              onClick={() => setToggleFilters(!toggleFilters)}
            >
              {!toggleFilters ? (
                <>
                  Ações
                  <EmptyStateIcon icon={CogIcon} />
                </>
              ) : (
                <EmptyStateIcon icon={TimesIcon} />
              )}
            </Button>
          </Flex>
        )}
        {!isMobile || toggleFilters ? (
          <Flex
            style={{
              backgroundColor: 'white',
              position: 'relative',
            }}
          >
            <Toolbar
              id='toolbar-group-types'
              style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}
            >
              <ToolbarContent style={{ width: '100%' }}>{toolbarItems}</ToolbarContent>
            </Toolbar>
          </Flex>
        ) : null}

        <EntityTable<AvailabilityPowerCurveEvents>
          items={pcReclassification}
          itemKeyName='id'
          isCompact
          columnConfig={[
            { key: 'id', description: 'Id', formatter: getFirstSegment },
            { key: 'ts_in', description: 'Início', formatter: v => formatDateTimeBrl(new Date(v)) },
            { key: 'ts_fin', description: 'Fim', formatter: v => formatDateTimeBrl(new Date(v)) },
            { key: 'username', description: 'Usuário' },
            { key: 'tipo_id', description: 'Tipo', formatter: id => getTipo(id) },
            { key: 'component_id', description: 'Componente', formatter: id => getComponent(id) },
            {
              key: 'responsabilidade_re',
              description: 'Responsabilidade',
              formatter: id => getResponsabilidade(id),
            },
          ]}
          onClickEdit={handleEditReclassification}
          onClickDelete={e => handleDeleteClick(e.id)}
          style={{
            maxHeight: tableAndContainerHeight,
            minHeight: tableAndContainerHeight,
          }}
        />
      </div>

      <ModalConfirmationWarning<string>
        isOpen={isDeleteModalOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteReclassification(id)}
        title='Remover Reclassificação'
        element={dataToDelete}
        actionState={actionState}
      />

      {reclassification ? (
        <ModalPCReclassification
          isOpen={isEditModalOpen}
          handleModalToggle={() => {
            setIsEditModalOpen(!isEditModalOpen)
          }}
          actionState={'idle'}
          selectedDate={selectedDate}
          dataToUpdate={reclassification}
          handleDeleteClick={handleDeleteClick}
          modalConfirmation={
            <ModalConfirmationWarning<string>
              isOpen={isDeleteModalOpen}
              handleModalToggle={handleModalConfirmationWarningToggle}
              handleSubmit={id => deleteReclassification(id)}
              title='Remover Reclassificação'
              element={dataToDelete}
              actionState={actionState}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default TablePCReclassification
