import { Alert } from '@patternfly/react-core'
import { ReactNode } from 'react'

export type ActionState = 'idle' | 'pending' | 'success' | 'failure'

export const requestStateMessages = ({
  titlePending = 'Processando',
  titleSuccess = 'Salvo com sucesso',
  titleFailure = 'Ocorreu um erro ao processar o evento',
}: {
  titlePending?: string
  titleSuccess?: string
  titleFailure?: string
} = {}): Record<ActionState, ReactNode> => {
  return {
    idle: <></>,
    pending: <Alert variant='info' title={titlePending} isInline />,
    success: <Alert variant='success' title={titleSuccess} isInline />,
    failure: <Alert variant='danger' title={titleFailure} isInline />,
  }
}
