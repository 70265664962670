import { AlarmHours } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

interface IProps {
  chartData: AlarmHours[]
  revision: number
  isLoading: boolean
}

const ChartAlarmsHours = (props: IProps) => {
  const { chartData, revision, isLoading } = props

  if (isLoading) return null

  const layout = {
    template: layoutTemplate,
    showlegend: false,
    margin: { b: 60, t: 15, r: 30 },
    hovermode: 'y',
    yaxis: { ...defaultAxis, automargin: true, title: 'Alarmes', fixedrange: true },
    xaxis: { ...defaultAxis, automargin: true, title: 'Minutos', fixedrange: true },
  }

  const config = {
    ...defaultConfig,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toImage',
    ],
  }

  const trace = {
    x: chartData.map(e => e.hours),
    y: chartData.map(e =>
      `${e.error}`.substring(0, 5) === 'EVENT' ? `N ${e.error.split('_')[1]}` : `N ${e.error}`
    ),
    type: 'bar',
    orientation: 'h',
    marker: { color: COLORS.verde_malachite },
    yaxis: 'y',
    xaxis: 'x',
  }

  return (
    <div className='pf-v5-u-mb-md pf-v5-u-box-shadow-sm'>
      <Plot
        key={`${revision}`}
        data={[trace]}
        layout={layout}
        config={config}
        useResizeHandler
        revision={revision}
        style={{ width: '100%', height: '40vh' }}
      />
    </div>
  )
}

export default ChartAlarmsHours
