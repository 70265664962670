import { DateTime } from 'luxon'
import { useState } from 'react'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import useVertical from '../../hooks/useVertical'
import PageFrame from '../shared/Page'

const CalendarComponentTest = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)

  const minEnabled = new Date('2023-10-10')
  const maxEnabled = new Date('2023-10-31')

  const [tsIn, setTsIn] = useState(new Date(Date.parse(maxEnabled.toISOString()) - 8 * 86400000))
  const [tsFin, setTsFin] = useState(new Date(Date.parse(maxEnabled.toISOString()) - 1 * 86400000))

  return (
    <PageFrame
      pageName='Ocorrências'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={false}
      filters={[
        <DatetimeRangePicker
          key='DatetimeRangePicker1'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart, newEnd) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
          minEnabled={minEnabled}
          maxEnabled={maxEnabled}
        />,
        <DatetimeRangePicker
          key='DatetimeRangePicker2'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart, newEnd) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
          minEnabled={minEnabled}
          maxEnabled={maxEnabled}
          allowEqualDates={true}
        />,
        <DatetimeRangePicker
          key='DatetimeRangePicker2'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart, newEnd) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='datetime'
          minEnabled={minEnabled}
          maxEnabled={maxEnabled}
        />,
        <DatetimeRangePicker
          key='DatetimeRangePicker2'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart, newEnd) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='datetime'
          minEnabled={minEnabled}
          maxEnabled={maxEnabled}
          allowEqualDates={true}
        />,
      ]}
    >
      <div>
        <b>Start Date: </b>
        {DateTime.fromJSDate(tsIn).toFormat('yyyy/MM/dd HH:mm')}
        <b> End Date: </b>
        {DateTime.fromJSDate(tsFin).toFormat('yyyy/MM/dd HH:mm')}
      </div>
      <br />
    </PageFrame>
  )
}

export default CalendarComponentTest
