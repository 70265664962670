import { useContext, useState } from 'react'
import './home.css'

import { Gallery, Text, TextContent } from '@patternfly/react-core'

import SitesContext from '../../contexts/SitesContext'
import SiteInfosCard from './SiteInfosCard'
import SiteLocationMap from './SiteLocationMap'

import PageFrame from '../shared/Page'

const PageHome = () => {
  const { sites } = useContext(SitesContext)
  const [isNavOpen, setNavOpen] = useState<boolean>(window.innerHeight < window.innerWidth)
  const [overSiteId, setOverSiteId] = useState<number>(1)

  return (
    <PageFrame pageName='Home' isNavOpen={isNavOpen} setNavOpen={setNavOpen}>
      <TextContent className='title-page'>
        <Text component='h2'>Projetos em Operação</Text>
        <Text component='p'>Produção e disponibilidade em tempo real</Text>
      </TextContent>

      <div className='cards'>
        <>
          <Gallery hasGutter>
            {sites.map((site, i) => {
              return <SiteInfosCard key={i} site={site} setOverSiteId={setOverSiteId} />
            })}
          </Gallery>
        </>
      </div>
      <SiteLocationMap overSiteId={overSiteId} />
    </PageFrame>
  )
}
export default PageHome
