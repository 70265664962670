import { SearchInput } from '@patternfly/react-core'
import { useMemo, useState } from 'react'
import { CivilOcorrencia, OccurrencesAPI, Site } from '../../client'
import EntityTable from '../../components/EntityTable'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ModalConfirmationWarning from '../shared/DeprecatedModalConfirmationWarning'
import { getOcorrenciasQueryKey } from './constants'

const heightPage = calcVerticalHeight({
  gridSize: 1,
  elementSize: 1,
  pgHasFilter: true,
  customOffset: '2rem',
})

export const TableListOcorrencias = ({
  ocorrencias,
  setIsOpenModalEditarOcorrencia,
  setOcorrencia,
  canEdit,
  site,
}: {
  ocorrencias: CivilOcorrencia[] | undefined
  setOcorrencia: React.Dispatch<React.SetStateAction<CivilOcorrencia | null>>
  setIsOpenModalEditarOcorrencia: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  site: Site
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState({})
  const [deleteSuccessOcorrencia, setDeleteSuccessOcorrencia] = useState(false)

  const ocorrenciaData = useMemo(() => {
    return ocorrencias?.filter(ocorrencia =>
      ocorrencia.descricao_ocorrencia?.toLowerCase().includes(inputValue.toLowerCase())
    )
  }, [ocorrencias, inputValue])

  const handleSelectEditOcorrencia = (ocorrencia: CivilOcorrencia) => {
    setOcorrencia(ocorrencia)
    setIsOpenModalEditarOcorrencia(true)
  }

  const handleDeleteSubmit = async (ocorrencia: CivilOcorrencia) => {
    try {
      await OccurrencesAPI.delete({ ocorrenciaId: ocorrencia.id as string })
      setDeleteSuccessOcorrencia(true)
      await queryClient.invalidateQueries(getOcorrenciasQueryKey(site.site_id))
    } catch (err) {
      console.error(err)
    }
  }

  const handleModalConfirmationWarningToggle = () => {
    setDeleteSuccessOcorrencia(false)
    setModalConfirmationWarningOpen(!isModalConfirmationWarningOpen)
  }

  function handleDeleteClick(item: CivilOcorrencia) {
    setDataToDelete(item)
    handleModalConfirmationWarningToggle()
  }

  return (
    <div className='container-aero-tab pf-v5-u-box-shadow-sm'>
      <SearchInput
        className='search-input-table-aero'
        placeholder='Filtrar por descrição'
        value={inputValue}
        onChange={(_, value) => setInputValue(value)}
        onClear={() => setInputValue('')}
      />
      <div className='container-table'>
        <EntityTable<CivilOcorrencia>
          items={ocorrenciaData ?? []}
          itemKeyName='id'
          isCompact
          columnConfig={[
            { key: 'item_id', description: 'Item' },
            { key: 'natureza_origem', description: 'Natureza' },
            { key: 'criticidade', description: 'Criticidade' },
            { key: 'descricao_ocorrencia', description: 'Descrição' },
            { key: 'data_origem', description: 'Data' },
            { key: 'situacao', description: 'Status' },
          ]}
          {...(canEdit && {
            onClickEdit: handleSelectEditOcorrencia,
            onClickDelete: handleDeleteClick,
          })}
          style={{ width: '100%', maxHeight: heightPage, overflow: 'auto', minHeight: '10rem' }}
        />
      </div>
      <ModalConfirmationWarning
        isModalOpen={isModalConfirmationWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={handleDeleteSubmit}
        title='Remover ocorrência'
        element={dataToDelete}
        deleteSuccess={deleteSuccessOcorrencia}
      />
    </div>
  )
}
