import { useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'

const isMobile = () => {
  const parser = new UAParser(window.navigator.userAgent)
  const device = parser.getDevice()
  return device.type === 'mobile'
}

const useMobile = () => {
  const [isDeviceMobile, setIsMobile] = useState(isMobile())

  useEffect(() => {
    const resizeCallback = () => {
      setIsMobile(isMobile())
    }
    window.addEventListener('resize', resizeCallback)
    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  }, [])

  return isDeviceMobile
}

export default useMobile
