import criticidadesNames, { ICriticidadeNivel } from '../../utils/criticidadeNames'
import SelectList from '../shared/selects/DeprecatedSelectList'

export const CriticidadeSelect = ({
  exibicao,
  selectedCriticidade,
  setSelectedCriticidade,
}: {
  exibicao: string
  selectedCriticidade: ICriticidadeNivel
  setSelectedCriticidade: React.Dispatch<React.SetStateAction<ICriticidadeNivel>>
}) => {
  return (
    <>
      {exibicao === 'ocorrencias' && (
        <SelectList
          startSelected={criticidadesNames[selectedCriticidade].name}
          onChange={setSelectedCriticidade}
          list={Object.values(criticidadesNames)?.map(c => {
            return {
              value: String(c.id),
              label: c.name,
            }
          })}
        />
      )}
    </>
  )
}
