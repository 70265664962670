import {
  Bullseye,
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
} from '@patternfly/react-core'
import { SearchIcon } from '@patternfly/react-icons'

const Empty = ({ title = 'Nenhum dado encontrado.' }: { title?: string }) => {
  return (
    <Bullseye>
      <EmptyState variant={EmptyStateVariant.sm}>
        <EmptyStateHeader
          titleText={<>{title}</>}
          icon={<EmptyStateIcon icon={SearchIcon} />}
          headingLevel='h3'
        />
      </EmptyState>
    </Bullseye>
  )
}

export default Empty
