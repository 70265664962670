import React, { useEffect } from 'react'

import { Alert, Button, Modal, ModalVariant } from '@patternfly/react-core'

function ModalConfirmationWarning(props) {
  const { title, isModalOpen, handleModalToggle, handleSubmit, element, deleteSuccess } = props

  useEffect(() => {
    if (deleteSuccess) {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [deleteSuccess])

  const AlertModal = () => {
    const variant = deleteSuccess ? 'success' : 'danger'
    const message = deleteSuccess ? 'Item removido com sucesso' : 'Ocorreu um erro ao deletar'

    return <Alert className='pf-v5-u-mb-lg' variant={variant} isInline title={message} />
  }

  return (
    <Modal
      variant={ModalVariant.small}
      title={title}
      aria-label='modal delete'
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button
          key='create'
          variant='danger'
          form='modal-with-form-form'
          onClick={() => {
            handleSubmit(element)
            if (deleteSuccess === undefined) handleModalToggle()
          }}
        >
          Remover
        </Button>,
        <Button key='cancelar' variant='primary' onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      {deleteSuccess && <AlertModal />}
      Tem certeza que deseja remover este item? Essa ação não poderá ser desfeita.
    </Modal>
  )
}

export default ModalConfirmationWarning
