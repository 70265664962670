export const cartesianToPolar = (x: number, y: number) => {
  const radius = Math.round(Math.sqrt(x * x + y * y))
  const radians = Math.round(Math.atan2(y, x))
  const angle = Math.round(radians * (180 / Math.PI))
  return { radians, radius, angle }
}

export const degreesToPolar = (radians: number, radius: number) => {
  const x = Math.round(radius * Math.cos(radians))
  const y = Math.round(radius * Math.sin(radians))
  return { x, y }
}

export const from180To360 = (angle: number) => Math.round((angle + 360) % 360)

export const from360To180 = (angle: number) => {
  if (angle < 180) return angle
  return Math.round((angle - 360) % 360)
}

export const angleToRadians = (angle: number) => angle * (Math.PI / 180)

export const rotatePoint = (ax: number, ay: number, angleDelta: number) => {
  const { radius, angle } = cartesianToPolar(ax, ay)
  const newAngleRad = angleToRadians(angle + angleDelta)
  return degreesToPolar(newAngleRad, radius)
}
