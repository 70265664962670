import SelectList from './DeprecatedSelectList'

interface ISelectYears {
  yearSelected: number
  setYearSelected: (yearSelected: number) => void
  yearList: number[]
}

const SelectYears = (props: ISelectYears) => {
  const { yearSelected, setYearSelected, yearList } = props

  return (
    <SelectList
      startSelected={yearSelected}
      onChange={setYearSelected}
      list={yearList.map(y => {
        return { label: y, value: y }
      })}
    />
  )
}

export default SelectYears
