import { Modal, ModalVariant } from '@patternfly/react-core'
import { OutlinedQuestionCircleIcon } from '@patternfly/react-icons'
import { ReactNode } from 'react'

interface IProps {
  isModalOpen: boolean
  handleModalToggle: () => void
  helpContent?: ReactNode
}

function ModalHelp(props: IProps) {
  const { isModalOpen, handleModalToggle, helpContent } = props

  return (
    <Modal
      aria-label='news modal'
      variant={ModalVariant.large}
      title='Ajuda'
      titleIconVariant={OutlinedQuestionCircleIcon}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      height={400}
    >
      {helpContent}
    </Modal>
  )
}

export default ModalHelp
