import { PickOptional, TextInput, TextInputProps } from '@patternfly/react-core'
import { CSSProperties } from 'react'

const NumberInput = ({
  isRequired = true,
  value,
  onChange,
  inputProps = {},
  className = '',
  style = {},
}: {
  isRequired?: boolean
  value: number
  onChange: (n: number) => void
  inputProps?: PickOptional<TextInputProps>
  className?: string
  style?: CSSProperties
}) => {
  return (
    <TextInput
      id={`${value}-number-input`}
      isRequired={isRequired}
      type='number'
      value={value}
      onChange={e => onChange(parseInt((e?.target as HTMLInputElement).value))}
      className={className}
      style={style}
      {...inputProps}
    />
  )
}

export default NumberInput
