import { Bullseye, Spinner } from '@patternfly/react-core'
import { TowerMeasurementsTrace } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const colorScale = [
  COLORS.verde,
  COLORS.azul_escuro,
  COLORS.laranja_international,
  COLORS.roxo,
  COLORS.azul_pantone,
  COLORS.limao,
  COLORS.roxo_indigo,
  COLORS.laranja_chama,
  COLORS.azul_meia_noite,
  COLORS.amarelo_cyber,
  COLORS.verde_cadmium,
  COLORS.laranja_amarelo,
  COLORS.verde_malachite,
  COLORS.amarelo_pantone,
  COLORS.cinza_escuro,
  COLORS.amarelo_titanium,
  COLORS.ciano_process,
]

interface IProp {
  measurements: TowerMeasurementsTrace[] | undefined
  isLoading: boolean
  signal_type: number
  revision: number
}

const PlotTowerSignal = ({ isLoading, measurements, signal_type, revision }: IProp) => {
  if (isLoading) {
    return (
      <Bullseye className='pf-v5-u-m-xl'>
        <Spinner size='xl' aria-label='Loading' />
      </Bullseye>
    )
  }
  if (!measurements || measurements.length === 0) return null
  const selected = measurements.filter(m => m.signal_type === signal_type)
  if (selected.length === 0) return null

  const traces = selected.map((signal, i) => {
    return {
      x: signal.data.map(datetime => datetime[0]),
      y: signal.data.map(value => value[1]),
      type: 'scatter',
      marker: { color: colorScale[i] },
      name: signal.signal.split('_')[0],
    }
  })
  const { signal_description, signal_unit } = selected[0]
  const layout = {
    template: layoutTemplate,
    xaxis: { ...defaultAxis },
    yaxis: {
      ...defaultAxis,
      title: { text: `${signal_description} [${signal_unit}]` },
    },
  }
  const config = { ...defaultConfig }
  const style = { width: '100%', height: '30vh', minHeight: '10rem' }
  return (
    <div>
      <Plot
        data={traces}
        config={config}
        layout={layout}
        style={style}
        revision={revision}
        divId={`plot${signal_type}`}
        key={revision * signal_type}
        useResizeHandler
      />
    </div>
  )
}

export default PlotTowerSignal
