import { QueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { OpenAPI } from '../client'
import { getToken } from './auth'

const { REACT_APP_API_URL } = process.env

export { REACT_APP_API_URL }

export const queryClient = new QueryClient()
export const QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const api = axios.create({
  baseURL: REACT_APP_API_URL,
})

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

if (REACT_APP_API_URL) {
  OpenAPI.BASE = REACT_APP_API_URL
}

export default api
