import { useQuery } from '@tanstack/react-query'
import { index, range } from 'd3-array'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import {
  ProductionAPI,
  ProductionBOPDataSignals,
  ProductionTurbDataSignals,
  Site,
  SitesAPI,
  Turbines,
  WindFarms,
} from '../client'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: true,
  staleTime: 1000 * 60 * 60 * 60,
}

interface ISiteId {
  site_id: number
}
interface ITurbId {
  turb_id: number
}
interface IWfId {
  wf_id: number
}
interface ISignalId {
  signal_id: number
}

const sortBySiteId = (a: ISiteId, b: ISiteId) => a.site_id - b.site_id

const loadMetadata = async () => {
  const requests = [
    SitesAPI.getAllSitesGet(),
    SitesAPI.getFarms(),
    SitesAPI.getWindTurbines(),
    ProductionAPI.getAllBopdataSignals(),
    ProductionAPI.getAllTurbdataSignals(),
  ]
  const [_sites, _allFarms, _allTurbs, _allBopSignals, _allTurbSignals] = await Promise.all(
    requests
  )

  const sites = _sites.sort(sortBySiteId) as Site[]
  const allFarms = _allFarms.sort(sortBySiteId) as WindFarms[]
  const allTurbs = _allTurbs.sort(sortBySiteId) as Turbines[]
  const allBopSignals = _allBopSignals.sort(sortBySiteId) as ProductionBOPDataSignals[]
  const allTurbSignals = _allTurbSignals.sort(sortBySiteId) as ProductionTurbDataSignals[]

  allBopSignals.sort((a, b) => a.signal_id - b.signal_id)
  allTurbSignals.sort((a, b) => a.signal_id - b.signal_id)

  return { sites, allFarms, allTurbs, allTurbSignals, allBopSignals }
}

interface ISitesContextDefaults {
  site: Site
  sites: Site[]
  allFarms: WindFarms[]
  allTurbs: Turbines[]
  allBopSignals: ProductionBOPDataSignals[]
  allTurbSignals: ProductionTurbDataSignals[]
}

interface ISitesContext extends ISitesContextDefaults {
  isLoading: boolean
  turbs: Turbines[]
  turbSignals: ProductionTurbDataSignals[]
  bopSignals: ProductionBOPDataSignals[]
  windFarms: WindFarms[]
  commissioningDate: Date
  setActiveSite: (site_id: number) => void
  sitesMap: Map<number, Site>
  windFarmsMap: Map<number, WindFarms>
  turbsMap: Map<number, Turbines>
  bopSignalMap: Map<number, ProductionBOPDataSignals>
  turbSignalMap: Map<number, ProductionTurbDataSignals>
  siteActiveYears: Array<number>
}

const sitesContextDefaults: ISitesContext = {
  isLoading: true,
  site: { site: '', site_id: 0, site_name: '' } as Site,
  sites: new Array<Site>(),
  allFarms: new Array<WindFarms>(),
  allTurbs: new Array<Turbines>(),
  turbs: new Array<Turbines>(),
  bopSignals: new Array<ProductionBOPDataSignals>(),
  turbSignals: new Array<ProductionTurbDataSignals>(),
  windFarms: new Array<WindFarms>(),
  commissioningDate: new Date('2020-01-01T00:00:00'),
  setActiveSite: (_: number) => {
    return
  },
  sitesMap: new Map<number, Site>(),
  windFarmsMap: new Map<number, WindFarms>(),
  turbsMap: new Map<number, Turbines>(),
  bopSignalMap: new Map<number, ProductionBOPDataSignals>(),
  turbSignalMap: new Map<number, ProductionTurbDataSignals>(),
  siteActiveYears: [],
  allBopSignals: new Array<ProductionBOPDataSignals>(),
  allTurbSignals: new Array<ProductionTurbDataSignals>(),
}

const SitesContext = createContext<ISitesContext>(sitesContextDefaults)

export const SitesProvider = ({ children }: { children: ReactNode }) => {
  const { siteName } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [filterIsLoading, setFilterIsLoading] = useState(true)
  const [site, setSite] = useState<Site>(sitesContextDefaults.site)
  const [turbs, setTurbs] = useState<Turbines[]>(sitesContextDefaults.turbs)
  const [bopSignals, setBopSignals] = useState<ProductionBOPDataSignals[]>(
    sitesContextDefaults.bopSignals
  )
  const [turbSignals, setTurbSignals] = useState<ProductionTurbDataSignals[]>(
    sitesContextDefaults.turbSignals
  )
  const [windFarms, setWindFarms] = useState(sitesContextDefaults.windFarms)
  const [commissioningDate, setCommissioningDate] = useState<Date>(
    sitesContextDefaults.commissioningDate
  )
  const [sites, setSites] = useState(sitesContextDefaults.sites)
  const [sitesMap, setSitesMap] = useState(sitesContextDefaults.sitesMap)
  const [windFarmsMap, setWindFarmsMap] = useState(sitesContextDefaults.windFarmsMap)
  const [turbsMap, setTurbsMap] = useState(sitesContextDefaults.turbsMap)
  const [turbSignalMap, setTurbSignalMap] = useState(sitesContextDefaults.turbSignalMap)
  const [bopSignalMap, setBopSignalMap] = useState(sitesContextDefaults.bopSignalMap)
  const [siteActiveYears, setSiteActiveYears] = useState<Array<number>>(
    sitesContextDefaults.siteActiveYears
  )

  const handleLoadMetadata = (data: ISitesContextDefaults, site_id?: number) => {
    const { sites, allTurbs, allFarms, allBopSignals, allTurbSignals } = data
    let _site_id = 1
    if (siteName)
      _site_id = sites.find(s => s.site.toLowerCase() === siteName.toLowerCase())?.site_id ?? 1
    if (site_id) _site_id = site_id

    const filterSite = (e: ISiteId) => e.site_id === _site_id
    const _site = sites.find(filterSite) ?? sites[0]
    if (!_site) {
      console.error('Nenhum site encontrado')
      return
    }
    const _siteFarms = allFarms.filter(filterSite).sort((a, b) => a.wf_id - b.wf_id)
    const _siteTurbs = allTurbs.filter(filterSite).sort((a, b) => a.turb_id - b.turb_id)
    const _stTurbSigs = allTurbSignals.filter(filterSite).sort((a, b) => a.signal_id - b.signal_id)
    const _stBopSigs = allBopSignals.filter(filterSite).sort((a, b) => a.signal_id - b.signal_id)

    setSite(_site)
    setWindFarms(_siteFarms)
    setTurbs(_siteTurbs)
    setTurbSignals(_stTurbSigs)
    setBopSignals(_stBopSigs)

    setSites(sites)
    setSitesMap(index(sites, (s: ISiteId) => s.site_id))
    setWindFarmsMap(index(_siteFarms, (w: IWfId) => w.wf_id))
    setTurbsMap(index(_siteTurbs, (t: ITurbId) => t.turb_id))
    setTurbSignalMap(index(_stTurbSigs, (t: ISignalId) => t.signal_id))
    setBopSignalMap(index(_stBopSigs, (t: ISignalId) => t.signal_id))

    setCommissioningDate(new Date(_site.commissioning_date))
    const siteActiveYears = range(
      new Date(_site.commissioning_date).getFullYear(),
      new Date().getFullYear() + 1
    ).reverse()
    setSiteActiveYears(siteActiveYears)

    setFilterIsLoading(false)
  }

  const { isLoading: metadataIsLoading, data } = useQuery(['sites'], loadMetadata, {
    ...defaultQueryOptions,
    placeholderData: sitesContextDefaults,
    onSuccess: handleLoadMetadata,
  })
  const isLoading = metadataIsLoading || filterIsLoading

  const setActiveSite = (site_id: number) => {
    if (metadataIsLoading) return console.error('Metadata is still loading')
    if (!data) return console.error('Metadata unavailable')
    handleLoadMetadata(data, site_id)
  }

  const metadata = data ?? sitesContextDefaults

  useEffect(() => {
    if (isLoading || !siteName) return
    const newSite = sites.find(s => s.site.toLowerCase() === siteName.toLowerCase())
    if (!newSite) return
    setActiveSite(newSite.site_id)
  }, [isLoading, sites, siteName])

  const provides = {
    ...metadata,
    isLoading,
    site,
    turbs,
    windFarms,
    commissioningDate,
    sitesMap,
    windFarmsMap,
    turbsMap,
    siteActiveYears,
    setActiveSite,
    bopSignals,
    turbSignals,
    turbSignalMap,
    bopSignalMap,
  }
  return <SitesContext.Provider value={provides}>{children}</SitesContext.Provider>
}

export default SitesContext
export type { ISitesContext }
