import { Buffer } from 'buffer'
import { OpenAPI } from '../client'

const TOKEN_KEY = '@rioEnergy-Token'
const KEEP_LOGGED_KEY = '@rioEnergy-KeepLogged'

const isTokenExpired = token => {
  if (!token) return null
  const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp
  const isValid = Math.floor(new Date().getTime() / 1000) <= expiry
  if (isValid) return true
  logout()
  return false
}

export const isAuthenticated = () => {
  return getToken() !== null
}

export const getToken = () => {
  const keepLogged = localStorage.getItem(KEEP_LOGGED_KEY) === 'true'
  const token = keepLogged ? localStorage.getItem(TOKEN_KEY) : sessionStorage.getItem(TOKEN_KEY)
  return isTokenExpired(token) ? token : null
}
export const login = (token, keepLogged) => {
  if (token === undefined || keepLogged === undefined) return
  localStorage.setItem(KEEP_LOGGED_KEY, keepLogged)
  if (keepLogged) {
    localStorage.setItem(TOKEN_KEY, token)
  } else {
    sessionStorage.setItem(TOKEN_KEY, token)
  }
}

export const logout = () => {
  localStorage.removeItem(KEEP_LOGGED_KEY)
  localStorage.removeItem(TOKEN_KEY)
  sessionStorage.removeItem(TOKEN_KEY)
  window.location.href = '/'
}

OpenAPI.TOKEN = getToken
