const ColorScale = {
  // Paleta principal https://coolors.co/00d35c-c4f27a-1a1b86-000000-12121d-4a4a57-ecf1f4-fafcfe-ffffff
  verde: '#00D35C',
  branco: '#FFFFFF',
  limao: '#C4F27A',
  azul_escuro: '#1A1B86',
  preto: '#000000',
  cinza_escuro: '#12121D',
  cinza_medio: '#4A4A57',
  cinza_claro: '#ECF1F4',
  cinza_medio_claro: '#6A6E73',
  branco_clear: '#FAFCFE',
  // Paleta de apoio (Não oficial) https://coolors.co/18197a-311aa1-4719bb-7417ef-e84800-ff4f00-ff9200-ffd400-ffdf3f-dddd00
  azul_meia_noite: '#18197A',
  azul_pantone: '#311AA1',
  rosa_raspberry: '#D71E53',
  roxo: '#4719BB',
  roxo_indigo: '#7417EF',
  laranja_chama: '#E84800',
  laranja_international: '#E55B1C',
  laranja_amarelo: '#FF9200',
  amarelo_cyber: '#FFD400',
  amarelo_pantone: '#FFDF3F',
  amarelo_titanium: '#DDDD00',
  verde_malachite: '#62E36B',
  verde_cadmium: '#005A27',
  ciano_process: '#2BBFF0',
  vermelho_aerospace: '#F94F00',
  vermelho_puro: '#FF0000',
  // Paleta para os plots
  grid_color_plot: '#D2D2D2',
  color_font_plot: '#252525',
  transparent_bg: '#00000000',
}

export default ColorScale

export const green_700 = '#001b0c'
export const green_600 = '#003517'
export const green_500 = '#006a2e'
export const green_400 = '#00853a'
export const green_300 = '#009f45'
export const green_200 = '#00d35c'
export const green_100 = '#96e2a7'
export const green_050 = '#f3faf2'

export const blue_700 = '#001223'
export const blue_600 = '#041430'
export const blue_500 = '#0d1755'
export const blue_400 = '#1a1b86'
export const blue_300 = '#5890da'
export const blue_200 = '#65b5f6'
export const blue_100 = '#98cdf8'
export const blue_050 = '#cae4fa'

export const light_green_400 = '#253600'
export const light_green_300 = '#75943d'
export const light_green_200 = '#c4f27a'
export const light_green_100 = '#d4f49b'
export const light_green_050 = '#e4f5bc'

export const orange_600 = '#3b1f00'
export const orange_500 = '#6c2b00'
export const orange_400 = '#ce4300'
export const orange_300 = '#ff4f00'
export const orange_200 = '#ff793b'
export const orange_100 = '#ffcdb1'
export const orange_050 = '#fff6ec'

export const red_500 = '#2C0000'
export const red_400 = '#470000'
export const red_300 = '#7D1007'
export const red_200 = '#A30000'
export const red_100 = '#C9190B'
export const red_050 = '#FAEAE8'

// Cores para plots

export const green_c_100 = '#bae8b5'
export const green_c_200 = '#5dde89'
export const green_c_300 = '#00d35c'
export const green_c_400 = '#10963a'
export const green_c_500 = '#1f5818'

export const blue_c_100 = '#89c2fb'
export const blue_c_200 = '#489ef4'
export const blue_c_300 = '#0066cc'
export const blue_c_400 = '#004a94'
export const blue_c_500 = '#002e5c'

export const red_c_100 = '#d31103'
export const red_c_200 = '#a30000'
export const red_c_300 = '#820d02'
export const red_c_400 = '#470000'
export const red_c_500 = '#2e0000'

export const gold_c_100 = '#ffe49e'
export const gold_c_200 = '#ffd56b'
export const gold_c_300 = '#ffc738'
export const gold_c_400 = '#f0ac00'
export const gold_c_500 = '#c78f00'

export const coral_c_100 = '#ffb56b'
export const coral_c_200 = '#ff9124'
export const coral_c_300 = '#f57a00'
export const coral_c_400 = '#c26100'
export const coral_c_500 = '#8f4700'

export const cyan_c_100 = '#9bdede'
export const cyan_c_200 = '#6bcdcd'
export const cyan_c_300 = '#009494'
export const cyan_c_400 = '#005f61'
export const cyan_c_500 = '#003838'

export const plot_cs_green = [
  green_c_300,
  green_c_200,
  green_c_100,
  green_c_400,
  green_c_500,
  green_700,
  green_600,
  green_500,
  green_400,
  green_300,
]
export const plot_cs_blue = [
  blue_c_300,
  blue_c_200,
  blue_c_100,
  blue_c_400,
  blue_c_500,
  blue_600,
  blue_500,
  blue_400,
  blue_300,
  blue_200,
]
export const plot_cs_red = [
  red_c_300,
  red_c_200,
  red_c_100,
  red_c_400,
  red_c_500,
  red_500,
  red_400,
  red_300,
  red_200,
  red_100,
]
export const plot_cs_gold = [
  gold_c_300,
  gold_c_200,
  gold_c_100,
  gold_c_400,
  gold_c_500,
  orange_600,
  orange_500,
  orange_400,
  orange_300,
  orange_200,
]
export const plot_cs_coral = [
  coral_c_300,
  coral_c_200,
  coral_c_100,
  coral_c_400,
  coral_c_500,
  orange_600,
  orange_500,
  orange_400,
  orange_300,
  orange_200,
]
export const plot_cs_cyan = [
  cyan_c_300,
  cyan_c_200,
  cyan_c_100,
  cyan_c_400,
  cyan_c_500,
  blue_300,
  blue_200,
  blue_100,
  cyan_c_200,
  cyan_c_400,
]

export const plot_color_scale_100 = [green_c_100, blue_c_100, coral_c_100, red_c_100, gold_c_100]
export const plot_color_scale_200 = [green_c_200, blue_c_200, coral_c_200, red_c_200, gold_c_200]
export const plot_color_scale_300 = [green_c_300, blue_c_300, coral_c_300, red_c_300, gold_c_300]
export const plot_color_scale_400 = [green_c_400, blue_c_400, coral_c_400, red_c_400, gold_c_400]
export const plot_color_scale_500 = [green_c_500, blue_c_500, coral_c_500, red_c_500, gold_c_500]

const reverse = (colorScale: string[]) => [...colorScale].reverse()

export const COLOR_SCALES = {
  green: plot_cs_green,
  blue: plot_cs_blue,
  red: plot_cs_red,
  gold: plot_cs_gold,
  orange: plot_cs_coral,
  cyan: plot_cs_cyan,
  categorical: [
    plot_cs_green,
    plot_cs_blue,
    plot_cs_coral,
    plot_cs_red,
    plot_cs_gold,
    plot_cs_green,
    plot_cs_blue,
    plot_cs_coral,
    plot_cs_red,
    plot_cs_gold,
  ],
  categorical_reversed: [
    reverse(plot_cs_green),
    reverse(plot_cs_blue),
    reverse(plot_cs_red),
    reverse(plot_cs_gold),
    reverse(plot_cs_coral),
    reverse(plot_cs_green),
    reverse(plot_cs_blue),
    reverse(plot_cs_red),
    reverse(plot_cs_gold),
    reverse(plot_cs_coral),
  ],
  categorical_rotated: [
    plot_color_scale_400,
    plot_color_scale_300,
    plot_color_scale_200,
    plot_color_scale_100,
    plot_color_scale_500,
    plot_color_scale_400,
    plot_color_scale_300,
    plot_color_scale_200,
    plot_color_scale_100,
    plot_color_scale_500,
  ],
  categorical_alternating: [
    plot_cs_green,
    reverse(plot_cs_blue),
    plot_cs_red,
    reverse(plot_cs_gold),
    plot_cs_coral,
    reverse(plot_cs_green),
    plot_cs_blue,
    reverse(plot_cs_red),
    plot_cs_gold,
    reverse(plot_cs_coral),
  ],
  categorical_alternating_offset: [
    reverse(plot_cs_green),
    plot_cs_blue,
    reverse(plot_cs_red),
    plot_cs_gold,
    reverse(plot_cs_coral),
    plot_cs_green,
    reverse(plot_cs_blue),
    plot_cs_red,
    reverse(plot_cs_gold),
    plot_cs_coral,
  ],
}

export const makeLink = (colors: string[]) => {
  return `https://coolors.co/${colors.join('-')}`
}
