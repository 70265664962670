import {
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Grid,
  GridItem,
  Modal,
} from '@patternfly/react-core'

import semImagem from '../../assets/img/semimagem.png'
import { CivilOcorrencia } from '../../client'
import { REACT_APP_API_URL } from '../../services/api'
import criticidadesNames, { ICriticidadeNivel } from '../../utils/criticidadeNames'

interface IModalOcorrenciasProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  ocorrencia: CivilOcorrencia | null | undefined
}

export const ModalOcorrencias = (props: IModalOcorrenciasProps) => {
  const { isModalOpen, setIsModalOpen, ocorrencia } = props
  const handleModalToggle = () => setIsModalOpen(!isModalOpen)

  const itemMembers = [
    ['Natureza', ocorrencia?.natureza_origem],
    [
      'Criticidade',
      `${ocorrencia?.criticidade} - ${
        criticidadesNames[(ocorrencia?.criticidade as unknown as ICriticidadeNivel) ?? '0']?.name
      }`,
    ],
    ['Situação', ocorrencia?.situacao],
    ['Data de Origem', ocorrencia?.data_origem],
    ['Coordenadas', `Lat Lon: ${ocorrencia?.lat}  ${ocorrencia?.lon}`],
    ['Ocorrência', ocorrencia?.descricao_ocorrencia],
  ]
  const imgPath = ocorrencia?.img_path
    ? `${REACT_APP_API_URL}/static/${ocorrencia.img_path}`
    : semImagem

  return (
    <Modal
      aria-label='news modal'
      className='pf-v5-u-w-100vw-on-sm pf-v5-u-w-75vw-on-lg'
      title={`Descrição do item ${ocorrencia?.item_id}`}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
    >
      <Grid hasGutter>
        <GridItem sm={12} lg={6}>
          <DataList aria-label='data list wrapper' gridBreakpoint='xl'>
            {itemMembers.map((m, i) => {
              const [name, value] = m
              return (
                <DataListItem key={i}>
                  <DataListItemRow>
                    <DataListItemCells
                      dataListCells={[
                        <DataListCell width={1} key={name}>
                          <b>{name}</b>
                        </DataListCell>,
                        <DataListCell width={4} key={value}>
                          {value}
                        </DataListCell>,
                      ]}
                    />
                  </DataListItemRow>
                </DataListItem>
              )
            })}
          </DataList>
        </GridItem>
        <GridItem sm={12} lg={6}>
          <a href={imgPath} target='_blank' rel='noreferrer'>
            <img src={imgPath} alt='foto da ocorrência' />
          </a>
        </GridItem>
      </Grid>
    </Modal>
  )
}
