import { FileUpload, Form, FormGroup } from '@patternfly/react-core'
import { useEffect, useState } from 'react'

export const UploadXlsx = ({
  planilhaFile,
  setPlanilhaFile,
  isRejectedXlsx,
  setIsRejectedXlsx,
}: {
  planilhaFile: File | null
  setPlanilhaFile: React.Dispatch<React.SetStateAction<File | null>>
  isRejectedXlsx: boolean
  setIsRejectedXlsx: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [filename, setFilename] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!planilhaFile) setFilename('')
  }, [planilhaFile])

  const handleFileRejected = (_rejectedFiles: any, _event: any) => {
    setIsRejectedXlsx(true)
    setFilename('')
    setPlanilhaFile(null)
  }
  const handleFileReadStarted = (_fileHandle: File): void => {
    setIsLoading(true)
  }
  const handleFileReadFinished = (_fileHandle: File): void => {
    setIsLoading(false)
  }

  const handleClear = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setFilename('')
    setPlanilhaFile(null)
    setIsRejectedXlsx(false)
  }

  const handleDropAccepted = (_acceptedFiles: File[], _event: any) => {
    setIsRejectedXlsx(false)
    setFilename(_acceptedFiles[0].name)
    setPlanilhaFile(_acceptedFiles[0])
  }

  return (
    <Form>
      <FormGroup fieldId='text-file-with-restrictions-example'>
        <FileUpload
          id='customized-preview-file'
          filename={filename}
          filenamePlaceholder='Arraste e solte um arquivo ou carregue um'
          onReadFinished={(_event, _fileHandle: File) => handleFileReadFinished(_fileHandle)}
          onReadStarted={(_event, _fileHandle: File) => handleFileReadStarted(_fileHandle)}
          onClearClick={handleClear}
          hideDefaultPreview
          browseButtonText='Upload'
          isLoading={isLoading}
          validated={isRejectedXlsx ? 'error' : 'default'}
          dropzoneProps={{
            accept: {
              'application/excel': ['.xlsx', '.csv', '.xls'],
            },
            onDropRejected: handleFileRejected,
            onDropAccepted: handleDropAccepted,
          }}
        />
      </FormGroup>
    </Form>
  )
}
