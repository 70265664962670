import { Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { CSSProperties, useContext, useState } from 'react'
import { AvailabilityAPI, CDTMonthlyTurb, Site } from '../../client'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import EntityTable from '../../components/EntityTable'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import Page from '../shared/Page'

const style: CSSProperties = {
  height: calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    customOffset: 1,
  }),
  minHeight: '12rem',
}

const placeHolderData: CDTMonthlyTurb = {
  site_id: 0,
  turb_id: 0,
  turb_name: '',
  full_performance: null,
  downtime: null,
  partial_performance: null,
  de_rate: null,
  bop_outage: null,
  out_environmental_specs: null,
  corrective_maintenance: null,
  scheduled_maintenance: null,
  forced_outage: null,
  external_grid_curtailment: null,
  customer_stop_time: null,
  information_unavailable: null,
}

const defaultQueryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 15,
}

const formatDate = (date?: Date) => date?.toISOString()?.split('T')[0]

const fetchData = async (dateIn: Date, dateFin: Date, siteId: number) => {
  return AvailabilityAPI.getCounters({
    siteId,
    dateIn: formatDate(dateIn),
    dateFin: formatDate(dateFin),
  })
}

const Contadores = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const { site, isLoading: siteIsLoading, sites, setActiveSite } = useContext(SitesContext)

  const dtNow = new Date()
  const minDate = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDay() - 7)
  const maxDate = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDay() - 1)
  const [tsIn, setTsIn] = useState(minDate)
  const [tsFin, setTsFin] = useState(maxDate)

  const { isLoading: dataIsLoading, data: countersData = [] } = useQuery<CDTMonthlyTurb[]>(
    ['CountersTable', site?.site_id, tsIn, tsFin],
    async () => fetchData(tsIn, tsFin, site?.site_id ?? placeHolderData),
    {
      ...defaultQueryOptions,
      enabled: !siteIsLoading && !!site?.site_id,
    }
  )

  const handleChange = (selected: Site) => {
    setActiveSite(selected.site_id)
  }

  console.log(countersData)

  return (
    <Page
      pageName='Contadores'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={siteIsLoading}
      isContentLoading={dataIsLoading}
      filters={[
        <Select<Site>
          className='pf-u-v5-m-none'
          key='filter-site'
          itemKeyName='site_id'
          itemValueName='site_name'
          selected={site}
          items={sites}
          onChange={handleChange}
        />,
        <DatetimeRangePicker
          key='DateSelect'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart: Date, newEnd: Date) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
          maxEnabled={maxDate}
        />,
      ]}
    >
      <Panel>
        <PanelMain>
          <PanelMainBody>
            <EntityTable<CDTMonthlyTurb>
              items={countersData}
              itemKeyName='site_id'
              isLoading={dataIsLoading}
              isCompact
              style={style}
              columnConfig={[
                { key: 'turb_name', description: 'Wind Turbine' },
                { key: 'full_performance', description: 'Full Perf.' },
                { key: 'downtime', description: 'Downtime' },
                { key: 'partial_performance', description: 'Partial Perf.' },
                { key: 'de_rate', description: 'Derate' },
                { key: 'bop_outage', description: 'BOP Outage' },
                { key: 'out_environmental_specs', description: 'Environmental' },
                { key: 'corrective_maintenance', description: 'Corrective Maint.' },
                { key: 'scheduled_maintenance', description: 'Scheduled Maint.' },
                { key: 'forced_outage', description: 'Forced Outage' },
                { key: 'external_grid_curtailment', description: 'Curtailment' },
                { key: 'customer_stop_time', description: 'Customer Stop Time' },
                { key: 'information_unavailable', description: 'Info. Unavailable' },
              ]}
            />
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </Page>
  )
}

export default Contadores
