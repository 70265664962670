import { useContext, useState } from 'react'
import SitesContext from '../../../contexts/SitesContext'
import useVertical from '../../../hooks/useVertical'
import PageFrame from '../../shared/Page'
import HealthTable from './HealthTable'

import { Grid, GridItem, SearchInput } from '@patternfly/react-core'
import ColorScale from '../../../utils/colorScale'
import SelectSite from '../../shared/selects/SelectSite'
import Events from './Events'

const TurbHealth = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const [inputValue, setInputValue] = useState('')
  const { isLoading, site } = useContext(SitesContext)

  return (
    <PageFrame
      pageName='Turbine Health'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      siteName={site?.site_name}
      siteId={site?.site_id}
      filters={[
        <SelectSite key='SelectListSites' enableUrlParams onChange={() => setInputValue('')} />,
        <SearchInput
          key='turb-search'
          className='pf-v5-u-m-sm'
          style={{ background: ColorScale.branco, maxWidth: '13rem' }}
          placeholder='Search by turbine'
          value={inputValue}
          onChange={(_, value) => setInputValue(value)}
          onClear={() => setInputValue('')}
        />,
      ]}
    >
      <Grid hasGutter>
        <GridItem lg={12} xl={8}>
          <HealthTable searchValue={inputValue} setSearchValue={setInputValue} />
        </GridItem>
        <GridItem lg={12} xl={4}>
          <Events searchValue={inputValue} />
        </GridItem>
      </Grid>
    </PageFrame>
  )
}

export default TurbHealth
