import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'

import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'

export const DEFAULT_PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%' },
}
export const DEFAULT_PLOT_CONFIG = { ...defaultConfig, displayModeBar: false }

export type CounterdataProperties = {
  color: string
  description: string
}

export type CounterdataTypes = {
  full_performance: CounterdataProperties
  downtime: CounterdataProperties
  partial_performance: CounterdataProperties
  de_rate: CounterdataProperties
  bop_outage: CounterdataProperties
  out_environmental_specs: CounterdataProperties
  corrective_maintenance: CounterdataProperties
  scheduled_maintenance: CounterdataProperties
  forced_outage: CounterdataProperties
  external_grid_curtailment: CounterdataProperties
  customer_stop_time: CounterdataProperties
  information_unavailable: CounterdataProperties
}

export const COUNTERDATA_TYPES: CounterdataTypes = {
  full_performance: { color: ColorScale.verde, description: 'Full Performance' },
  downtime: { color: ColorScale.roxo_indigo, description: 'Downtime' },
  partial_performance: { color: ColorScale.rosa_raspberry, description: 'Partial Performance' },
  de_rate: { color: ColorScale.rosa_raspberry, description: 'De-rate' },
  bop_outage: { color: ColorScale.amarelo_pantone, description: 'Bop Outage' },
  out_environmental_specs: { color: ColorScale.limao, description: 'Out of Env Specs' },
  corrective_maintenance: {
    color: ColorScale.azul_meia_noite,
    description: 'Corrective Maintenance',
  },
  scheduled_maintenance: { color: ColorScale.azul_escuro, description: 'Scheduled Maintenance' },
  forced_outage: { color: ColorScale.vermelho_puro, description: 'Forced Outage' },
  external_grid_curtailment: {
    color: ColorScale.vermelho_aerospace,
    description: 'External Grid Curtailment',
  },
  customer_stop_time: { color: ColorScale.ciano_process, description: 'Customer Stop Time' },
  information_unavailable: {
    color: ColorScale.cinza_medio,
    description: 'Info Unavailable',
  },
}

export type CounterdataTypeKeys = keyof CounterdataTypes

export const COUNTERDATA_TYPE_LIST: CounterdataTypeKeys[] = Object.keys(
  COUNTERDATA_TYPES
) as CounterdataTypeKeys[]

export const DOWNTIME_KEYS_DEFAULT: CounterdataTypeKeys[] = [
  'downtime',
  'corrective_maintenance',
  'scheduled_maintenance',
  'forced_outage',
  'customer_stop_time',
]

export const ONLINE_KEYS_DEFAULTS: CounterdataTypeKeys[] = [
  'full_performance',
  'partial_performance',
  'de_rate',
  'bop_outage',
  'out_environmental_specs',
  'external_grid_curtailment',
]

export const IGNORED_KEYS_DEFAULTS: CounterdataTypeKeys[] = ['information_unavailable']

export interface categories {
  downtime: CounterdataTypeKeys[]
  online: CounterdataTypeKeys[]
  ignored: CounterdataTypeKeys[]
}

export const CATEGORIES_DEFAULTS: categories = {
  downtime: DOWNTIME_KEYS_DEFAULT,
  online: ONLINE_KEYS_DEFAULTS,
  ignored: IGNORED_KEYS_DEFAULTS,
}

export const PLOT_HEIGHT = calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '8rem' })
export const PLOT_STYLE = { width: '100%', height: PLOT_HEIGHT }

export const PLOT_TEMPORAL_MONTHLY = 'PLOT_TEMPORAL_MONTHLY'
export const PLOT_TEMPORAL_MONTHLY_PARKS = 'PLOT_TEMPORAL_MONTHLY_PARKS'
export const PLOT_TEMPORAL_MONTHLY_TURBS = 'PLOT_TEMPORAL_MONTHLY_TURBS'
export const PLOT_TEMPORAL_DAILY = 'PLOT_TEMPORAL_DAILY'
export const PLOT_TEMPORAL_DAILY_PARKS = 'PLOT_TEMPORAL_DAILY_PARKS'
export const PLOT_TEMPORAL_DAILY_TURBS = 'PLOT_TEMPORAL_DAILY_TURBS'
export const PLOT_TEMPORAL_YEAR_TO_DATE = 'PLOT_TEMPORAL_YEAR_TO_DATE'
export const PLOT_TEMPORAL_MONTH_WTG = 'PLOT_TEMPORAL_MONTH_WTG'
