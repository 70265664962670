import { Select, SelectOption } from '@patternfly/react-core/deprecated'
import { useEffect, useState } from 'react'
import Arrow from '../../../components/Arrow'
import LoadingButton from '../../../components/LoadingButton'

type ListTurbsProps = {
  value: number
  label: string
}

interface ISelectListWtGsProps {
  startSelected: number
  onChange: (turb: number) => void
  listTurbs: ListTurbsProps[]
  isLoading: boolean
  maxHeight?: number
}

const SelectListWtGs = (props: ISelectListWtGsProps) => {
  const { listTurbs, startSelected, onChange, isLoading, maxHeight } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = (toggled: boolean) => {
    setIsOpen(toggled)
  }

  const handleSelect = (selection: number) => {
    if (onChange) onChange(selection)
    setIsOpen(false)
  }

  const handleArrow = (side: string) => {
    if (side === 'left') {
      if (startSelected === 1) {
        onChange(listTurbs.length)
        return
      }
      onChange(startSelected - 1)
    } else {
      if (startSelected === listTurbs.length) {
        onChange(1)
        return
      }
      onChange(startSelected + 1)
    }
  }

  useEffect(() => {
    if (startSelected > listTurbs.length || startSelected === 0) onChange(1)
  }, [startSelected])

  if (!listTurbs || listTurbs.length === 0 || isLoading) {
    return <LoadingButton />
  }

  return (
    <div style={{ display: 'flex' }}>
      <Arrow direction='left' variant='control' onClick={() => handleArrow('left')} />
      <Select
        id={`select_${JSON.stringify(listTurbs.map(i => i.label))}`}
        className='pf-v5-u-py-sm'
        position='right'
        isOpen={isOpen}
        onSelect={(_, selection) => handleSelect(selection as number)}
        selections={startSelected}
        onToggle={(_event, e) => handleToggle(e)}
        maxHeight={maxHeight || 600}
        menuAppendTo='parent'
      >
        {listTurbs.map(item => {
          return (
            <SelectOption
              inputId={`turb_health_sel_opt_${item.label}_${item.value}_inp_id`}
              id={`turb_health_sel_opt_${item.label}_${item.value}`}
              key={`turb_health_sel_opt_${item.label}_${item.value}`}
              value={item.value}
            >
              {' '}
              {item.label}{' '}
            </SelectOption>
          )
        })}
      </Select>
      <Arrow direction='right' variant='control' onClick={() => handleArrow('right')} />
    </div>
  )
}

export default SelectListWtGs
