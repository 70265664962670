import { Button } from '@patternfly/react-core'
import { Wizard, WizardContextConsumer, WizardFooter } from '@patternfly/react-core/deprecated'

const stepFreeze = (
  <>
    <p>
      Trata-se de um teste que verifica o congelamento dos sensores, ou seja, detecta quando o sinal
      de um sensor no sistema SCADA fica fixo por um intervalo de tempo determinado (tipicamente
      2h).
    </p>
  </>
)

const stepOutOfRange = (
  <>
    <p>
      As grandezas físicas medidas (e.g. temperatura, velocidade, potência etc.) em um sistema real
      sempre estão restritas a uma faixa de variação. Este teste é uma espécie de sanity check que
      verifica se os valores medidos estão dentro de faixas predefinidas.
    </p>
  </>
)

const stepDeviation = (
  <>
    <p>
      Normalmente, as condições de operação de aerogeradores vizinhos tendem a ser bem próximas, o
      que permite fazer comparações entre essas máquinas. O teste em questão baseia-se nesse fato e
      verifica se os sinais de um aerogerador divergem significativamente dos sinais dos
      aerogeradores vizinhos. O desvio é quantificado em termos da diferença absoluta.
    </p>
  </>
)

const stepTempDiffBitbloom = (
  <>
    <p>
      São testes mais sofisticados para grandes componentes, cujo objetivo é prever o valor da
      temperatura do componente utilizando um modelo de aprendizado de máquina e compará-lo ao valor
      real medido. O desvio calculado é utilizado como uma métrica para indicar situações anormais e
      o grau de severidade.
    </p>
  </>
)

const steps = [
  { name: 'Freeze', component: stepFreeze },
  { name: 'Out of Range', component: stepOutOfRange },
  { name: 'Deviation', component: stepDeviation },
  { name: 'Temp Diff e Bitbloom', component: stepTempDiffBitbloom, enableNext: false },
]

const CustomFooter = (
  <WizardFooter>
    <WizardContextConsumer>
      {({ activeStep, onNext, onBack }) => {
        return (
          <>
            <Button
              variant='secondary'
              onClick={onBack}
              className={activeStep.name === steps[0].name ? 'pf-m-disabled' : ''}
            >
              Anterior
            </Button>
            <Button
              variant='primary'
              onClick={onNext}
              className={activeStep.name === steps.at(-1)?.name ? 'pf-m-disabled' : ''}
            >
              Próximo
            </Button>
          </>
        )
      }}
    </WizardContextConsumer>
  </WizardFooter>
)

function HelpContent() {
  return <Wizard steps={steps} height={550} footer={CustomFooter} isNavExpandable />
}

export default HelpContent
