import { Spinner } from '@patternfly/react-core'
import { TowerMeasurementsTrace } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import PageNoData from '../shared/PageNoData'
import Plot, { Plotly } from '../shared/Plot'
export type { TowerMeasurementsTrace } from '../../client/models/TowerMeasurementsTrace'
interface IPlot {
  plotId: number
  isMain: boolean
  minGraph: number
  maxGraph: number
  yaxisTitle: string
}
interface IMappedRow {
  [key: number]: IPlot
}

interface IPoints {
  pointNumber: number
}

type LocalLayoutType = {
  annotations?: Array<{
    text: string
    xref: string
    yref: string
    showarrow: boolean
    font: {
      size: number
    }
  }>
}

function TimeSeriesPlot({
  towerData,
  revision,
}: Readonly<{
  towerData?: TowerMeasurementsTrace[]
  revision: number
}>) {
  if (!towerData) {
    return <Spinner className='pf-v5-u-m-xl' size='xl' aria-label='Loading' />
  }
  if (towerData.length === 0) return <PageNoData message='Sem dados para essa torre.' />

  const colorScale = [
    COLORS.azul_meia_noite,
    COLORS.verde,
    COLORS.laranja_chama,
    '#226CB5',
    '#93EB73',
    '#FAB43C',
    '#2BBFF0',
    COLORS.limao,
    '#FFE560',
    COLORS.azul_meia_noite,
    COLORS.verde,
    COLORS.laranja_chama,
    '#226CB5',
    '#93EB73',
    '#FAB43C',
    '#2BBFF0',
    COLORS.limao,
    '#FFE560',
  ]

  const rowSignalId: IMappedRow = {
    1: { plotId: 0, isMain: true, minGraph: 0, maxGraph: 20, yaxisTitle: 'Veloc. do Vento [m/s]' },
    3: {
      plotId: 1,
      isMain: true,
      minGraph: -10,
      maxGraph: 370,
      yaxisTitle: 'Direção do Vento [º]',
    },
    9: { plotId: 1, isMain: true, minGraph: 0, maxGraph: 1500, yaxisTitle: 'Piranômetro [W/m²]' },
    4: { plotId: 2, isMain: true, minGraph: 0, maxGraph: 120, yaxisTitle: 'Temp. [ºC] ou RH [%]' },
    6: { plotId: 2, isMain: true, minGraph: 0, maxGraph: 120, yaxisTitle: 'Temp. [ºC] ou RH [%]' },
    5: {
      plotId: 3,
      isMain: true,
      minGraph: 750,
      maxGraph: 1200,
      yaxisTitle: 'Pressão Atm. [mbar]',
    },
    11: { plotId: 3, isMain: false, minGraph: 7, maxGraph: 17, yaxisTitle: 'Bateria [V]' },
  }
  const plotIds = [0, 1, 2, 3]

  const handleFormatTrace = (plotRow: IPlot, ignoreIsMain?: boolean) => {
    const xaxis = {
      ...defaultAxis,
      type: 'datetime',
      domain: plotRow.plotId === 3 ? [0.01, 0.98] : [0, 0.98],
    }

    let yaxis, yaxisKey, xaxisKey, yaxisId

    if (plotRow.isMain || ignoreIsMain) {
      yaxisId = 'y'
      yaxisKey = 'yaxis'
      yaxis = {
        ...defaultAxis,
        range: [plotRow.minGraph, plotRow.maxGraph],
        title: { text: plotRow.yaxisTitle, font: { size: 11 } },
        side: 'left',
        position: 0,
      }
      xaxisKey = 'xaxis'
    } else {
      yaxisId = 'y2'
      yaxisKey = 'yaxis2'
      yaxis = {
        ...defaultAxis,
        range: [plotRow.minGraph, plotRow.maxGraph],
        title: { text: plotRow.yaxisTitle, font: { size: 11 } },
        side: 'right',
        overlaying: 'y',
      }
      xaxisKey = 'xaxis2'
    }

    const layout = {
      template: layoutTemplate,
      margin: { b: 20, t: 30, l: 50, r: plotRow.plotId === 3 ? 30 : 5 },
      [yaxisKey]: yaxis,
      [xaxisKey]: xaxis,
    }

    return { layout, yaxisId }
  }

  const traces = towerData.map((tw, i) => {
    const plotRow: IPlot = rowSignalId[tw.signal_type]
    if (!plotRow) return null

    const { layout, yaxisId } = handleFormatTrace(plotRow)

    return {
      x: tw.data.length ? tw.data.map(d => d[0]) : [],
      y: tw.data.length ? tw.data.map(d => d[1]) : [],
      type: 'scatter',
      marker: { color: colorScale[i] },
      name: tw.signal.split('_')[0],
      signal_type: tw.signal_type,
      yaxis: yaxisId,
      layout: layout,
      plotId: plotRow.plotId,
      signal: tw.signal,
      plotRow,
    }
  })

  const telao = sessionStorage.getItem('telao')
  const heightPlot =
    telao === 'on'
      ? 'calc(( 100vh - 76px - 54px)/4)'
      : 'calc((100vh - 76px - 52px - 24px - 12px)/4)'
  const style = { width: '100%', height: heightPlot }

  const handleHover = (points: IPoints[], divId: string) => {
    const plotId = ['plot0', 'plot1', 'plot2', 'plot3']
    const pointNum = points[0].pointNumber

    const arrPlotId = plotId.filter(id => id !== divId)

    arrPlotId.forEach(id => {
      Plotly.Fx.hover(id, [
        { curveNumber: 0, pointNumber: pointNum },
        { curveNumber: 1, pointNumber: pointNum },
        { curveNumber: 2, pointNumber: pointNum },
        { curveNumber: 3, pointNumber: pointNum },
        { curveNumber: 4, pointNumber: pointNum },
        { curveNumber: 5, pointNumber: pointNum },
      ])
    })
  }

  const handleUnHover = (divId: string) => {
    const plotId = ['plot0', 'plot1', 'plot2', 'plot3']

    const arrPlotId = plotId.filter(id => id !== divId)
    arrPlotId.forEach(id => {
      Plotly.Fx.hover(id, [])
    })
  }

  return (
    <div>
      {plotIds.map(p => {
        const divId = `plot${p}`
        const localLayout: LocalLayoutType = {}

        const tracesSemNullFiltrados = traces.filter(t => t != null).filter(t => t?.plotId === p)

        const _traces =
          tracesSemNullFiltrados.length === 1
            ? tracesSemNullFiltrados.map(t => {
                if (!t) return null
                const plotRow: IPlot = rowSignalId[t.signal_type]
                const { layout, yaxisId } = handleFormatTrace(plotRow, true) // condition to fix the multiple hover
                Object.assign(localLayout, layout)

                return {
                  ...t,
                  layout,
                  yaxis: yaxisId,
                }
              })
            : tracesSemNullFiltrados.map(t => {
                Object.assign(localLayout, t?.layout)
                return t
              })

        if (!_traces.length) {
          localLayout['annotations'] = [
            {
              text: 'Sem dados para essa torre',
              xref: 'paper',
              yref: 'paper',
              showarrow: false,
              font: {
                size: 24,
              },
            },
          ]
        }

        return (
          <Plot
            onHover={({ points }: { points: IPoints[] }) => handleHover(points, divId)}
            onUnhover={() => handleUnHover(divId)}
            divId={divId}
            useResizeHandler
            key={`${p}_${revision}_${_traces?.map(t => t?.signal)}`}
            data={_traces}
            layout={localLayout}
            config={defaultConfig}
            style={style}
          />
        )
      })}
    </div>
  )
}

export default TimeSeriesPlot
