import { Divider, Pagination, Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useRef, useState } from 'react'
import { AlarmsAPI } from '../../client'
import SiteContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import TableAlarms from './TableAlarms'
import Timeline from './Timeline'

const defaultQueryOptions = { refetchOnWindowFocus: false, retry: false, staleTime: 1000 * 60 * 5 }

const PAGE_SIZE = 200

const TAB_STYLE = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 1, customOffset: '0rem' }),
  backgroundColor: 'white',
}

const AlarmTabs = ({
  site_id,
  tsIn,
  tsFin,
  turb_id,
  isNavOpen,
}: {
  site_id: number
  turb_id: number
  tsIn: string
  tsFin: string
  isNavOpen: boolean
}) => {
  const { isLoading: siteIsLoading, site, sites, turbsMap } = useContext(SiteContext)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(PAGE_SIZE)
  const [activeKey, setActiveTabKey] = useState<string | number>('alarmes')
  const [revision, setRevision] = useState(1)
  const totalRef = useRef(1)
  const isMobile = useMobile()

  const [spChartAnnotations, setSpChartAnnotations] = useState([])
  const [spChartAnnotationMode, setSpChartAnnotationMode] = useState(false)

  const [isSettingsOpen, setSettingsOpen] = useState(false)

  const onSetPage = (_: any, newPage: number) => {
    setPage(newPage)
  }
  const onPerPageSelect = (_: any, newPerPage: number, newPage: number) => {
    setPerPage(newPerPage)
    setPage(newPage)
  }

  const {
    isFetching,
    isLoading: alarmsIsLoading,
    data: alarmView,
  } = useQuery(
    ['alarms', site_id, tsIn, tsFin, perPage, page, turb_id],
    async () =>
      AlarmsAPI.getAll({
        siteId: site_id,
        tsIn,
        tsFin,
        turbId: turb_id,
        limit: perPage,
        offset: perPage * (page - 1),
      }),
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id && !siteIsLoading,
      onSuccess: d => {
        if (d.count) {
          totalRef.current = d.count
        }
      },
    }
  )
  const { isLoading: extendedAlarmsIsLoading, data: extendedAlarmView } = useQuery(
    ['extended_alarms', site_id, tsIn, tsFin, turb_id, perPage, page],
    async () =>
      AlarmsAPI.getExtended({
        siteId: site_id,
        tsIn,
        tsFin,
        turbId: turb_id,
        limit: perPage,
        offset: perPage * (page - 1),
      }),
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id && !siteIsLoading,
    }
  )
  const prefetch = async (site_id: number, turb_id: number, perPage: number, page: number) => {
    await queryClient.prefetchQuery(
      ['alarms', site_id, tsIn, tsFin, turb_id, perPage, page],
      async () =>
        AlarmsAPI.getAll({
          siteId: site_id,
          tsIn,
          tsFin,
          turbId: turb_id,
          limit: perPage,
          offset: perPage * (page - 1),
        }),
      defaultQueryOptions
    )
  }
  const isLoading = siteIsLoading || alarmsIsLoading

  useEffect(() => {
    setPage(1)
    setPerPage(PAGE_SIZE)
  }, [site_id])

  useEffect(() => {
    if (isLoading || !alarmView?.count) return

    totalRef.current = alarmView.count

    if (page === 1) {
      sites
        .filter(s => s.site_id !== site_id)
        .forEach(s => prefetch(s.site_id, turb_id, perPage, page))
    }
    if (perPage * page <= alarmView.count) {
      prefetch(site_id, turb_id, perPage, page + 1)
    }
    if (page > 1) {
      prefetch(site_id, turb_id, perPage, page - 1)
    }
  }, [isLoading, page, perPage, site_id])

  useEffect(() => {
    setRevision(revision + 1)
  }, [isNavOpen, site_id, turb_id, page, perPage, activeKey])

  return (
    <div className='pf-v5-u-box-shadow-md' style={TAB_STYLE}>
      <Tabs
        activeKey={activeKey}
        onSelect={(_, eventKey) => {
          setActiveTabKey(eventKey)
        }}
        aria-label='category-tabs'
        isOverflowHorizontal
        isFilled
      >
        <Tab eventKey='alarmes' title={<TabTitleText>Alarmes</TabTitleText>} aria-label='Alarmes'>
          <TableAlarms
            turbsMap={turbsMap}
            alarms={alarmView?.alarms}
            alarmsIsLoading={alarmsIsLoading}
          />
        </Tab>
        {site.extended_alarms_table ? (
          <Tab
            eventKey='alarmes_ext'
            title={<TabTitleText>Alarmes Estendidos</TabTitleText>}
            aria-label='Alarmes Estendidos'
          >
            <TableAlarms
              turbsMap={turbsMap}
              alarms={extendedAlarmView?.alarms}
              alarmsIsLoading={extendedAlarmsIsLoading}
            />
          </Tab>
        ) : null}
        {turb_id > 0 && !isMobile ? (
          <Tab
            eventKey='grafico'
            title={<TabTitleText>Série Temporal</TabTitleText>}
            aria-label='Gráfico'
          >
            {activeKey === 'grafico' && (
              <Timeline
                revision={revision}
                alarmView={alarmView}
                extendedAlarmView={extendedAlarmView}
                isFetching={isFetching}
                site_id={site_id}
                tsIn={tsIn}
                tsFin={tsFin}
                turb_id={turb_id}
                isSettingsOpen={isSettingsOpen}
                setSettingsOpen={setSettingsOpen}
                spChartAnnotations={spChartAnnotations}
                setSpChartAnnotations={setSpChartAnnotations}
                spChartAnnotationMode={spChartAnnotationMode}
                setSpChartAnnotationMode={setSpChartAnnotationMode}
              />
            )}
          </Tab>
        ) : null}
      </Tabs>
      <Divider />
      <Pagination
        className='pf-v5-u-mb-lg'
        dropDirection='up'
        perPage={perPage}
        page={page}
        onSetPage={onSetPage}
        onPerPageSelect={onPerPageSelect}
        itemCount={totalRef.current}
        perPageOptions={[
          { title: `${PAGE_SIZE} alarmes`, value: PAGE_SIZE },
          { title: `${PAGE_SIZE * 5} alarmes`, value: PAGE_SIZE * 5 },
          { title: `${PAGE_SIZE * 10} alarmes`, value: PAGE_SIZE * 10 },
        ]}
        titles={{
          perPageSuffix: ' por página',
          toFirstPageAriaLabel: 'Voltar a página inicial',
          ofWord: 'de',
          items: 'alarmes',
        }}
      />
      <br />
    </div>
  )
}

export default AlarmTabs
