import {
  Badge,
  Button,
  Divider,
  Menu,
  MenuContent,
  MenuItem,
  MenuList,
  Popover,
  PopoverPosition,
  Split,
  SplitItem,
  Switch,
} from '@patternfly/react-core'
import { CaretDownIcon, CheckIcon, TagIcon, TimesIcon } from '@patternfly/react-icons'
import { useEffect, useState } from 'react'

import {
  categories,
  CATEGORIES_DEFAULTS,
  CounterdataTypeKeys,
  COUNTERDATA_TYPES,
} from './constants'

type CounterdataCategory = {
  description: string
  category_name: CounterdataTypeKeys
  color: string
}

const OPTIONS: CounterdataCategory[] = Object.entries(COUNTERDATA_TYPES).map(e => {
  return {
    category_name: e[0] as CounterdataTypeKeys,
    description: e[1].description,
    color: e[1].color,
  }
})

const SelectCategories = ({
  categorySelection,
  setCategorySelection,
}: {
  categorySelection: categories
  setCategorySelection: (cat: categories) => void
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isSelectionValid, setIsSelectionValid] = useState(true)

  const [innerCatSelection, setInnerCatSelection] = useState(categorySelection)

  useEffect(() => {
    const { online, downtime } = innerCatSelection

    if (online.length === 0 || downtime.length === 0) {
      setIsSelectionValid(false)
      return
    }

    setIsSelectionValid(true)
  }, [innerCatSelection])

  useEffect(() => {
    if (isVisible) {
      return
    }

    setCategorySelection(innerCatSelection)
  }, [isVisible])

  const handleSwitch = (checked: boolean, category_name: CounterdataTypeKeys) => {
    const { online, downtime, ignored } = innerCatSelection

    if (checked) {
      setInnerCatSelection({
        online,
        downtime: [...downtime, category_name],
        ignored: ignored.filter(cat => cat !== category_name),
      })

      return
    }
    setInnerCatSelection({
      online: online.filter(cat => cat !== category_name),
      downtime: downtime.filter(cat => cat !== category_name),
      ignored: [...ignored, category_name],
    })
  }

  const handleSelect = (
    category_name: CounterdataTypeKeys,
    isEnabled: boolean,
    isChecked: boolean
  ) => {
    if (!isEnabled) return

    const { online, downtime, ignored } = innerCatSelection

    if (isChecked) {
      setInnerCatSelection({
        online: online.filter(dt => dt !== category_name),
        downtime: [...downtime, category_name],
        ignored,
      })
      return
    }
    setInnerCatSelection({
      online: [...online, category_name],
      downtime: downtime.filter(dt => dt !== category_name),
      ignored,
    })
  }

  const handleReset = () => {
    setInnerCatSelection(CATEGORIES_DEFAULTS)
    setCategorySelection(CATEGORIES_DEFAULTS)
  }

  const closeButton = isSelectionValid ? (
    <CheckIcon color='green' className='pf-v5-u-mx-sm' />
  ) : (
    <TimesIcon color='red' className='pf-v5-u-mx-sm' />
  )

  const button = (
    <div className='pf-v5-u-my-sm'>
      <Button variant='control'>
        Categorias Online
        <Badge className='pf-v5-u-mx-md'>{categorySelection.online.length}</Badge>
        {isVisible ? closeButton : <CaretDownIcon className='pf-v5-u-mx-sm' />}
      </Button>
    </div>
  )

  const menu = (
    <Menu isPlain>
      <MenuContent className='pf-v5-u-p-md'>
        <MenuList>
          {OPTIONS.map(opt => {
            const isEnabled = !innerCatSelection.ignored.includes(opt.category_name)
            const isChecked = innerCatSelection.online.includes(opt.category_name)
            const selectHandler = () => handleSelect(opt.category_name, isEnabled, isChecked)
            return (
              <Split key={opt.category_name} hasGutter className='pf-v5-u-mx-md'>
                <SplitItem isFilled>
                  <MenuItem hasCheckbox isSelected={isChecked} onClick={selectHandler}>
                    <TagIcon className='pf-v5-u-mx-md' style={{ color: opt.color }} />
                    <span style={{ color: isEnabled ? 'black' : 'grey' }}>{opt.description}</span>
                  </MenuItem>
                </SplitItem>

                <SplitItem>
                  <Switch
                    isChecked={isEnabled}
                    onChange={(_, checked) => handleSwitch(checked, opt.category_name)}
                    aria-label={`switch-cat-${opt.category_name}`}
                  />
                </SplitItem>
              </Split>
            )
          })}
          <Divider />
          <MenuItem onClick={handleReset}>Restaurar Padrão</MenuItem>
        </MenuList>
      </MenuContent>
    </Menu>
  )

  return (
    <Popover
      aria-label='DatetimeMonthPickerPopOver'
      position={PopoverPosition.bottom}
      enableFlip
      flipBehavior={['bottom', 'bottom-end', 'bottom-start', 'top-end', 'top-start']}
      hideOnOutsideClick
      hasAutoWidth
      hasNoPadding
      withFocusTrap={false}
      showClose={false}
      bodyContent={menu}
      isVisible={isVisible}
      shouldOpen={(_event, _fn) => setIsVisible(true)}
      shouldClose={(_event, _fn) => isSelectionValid && setIsVisible(false)}
    >
      {button}
    </Popover>
  )
}

export default SelectCategories
