import { Button, Flex, Grid, GridItem, Text, TextContent } from '@patternfly/react-core'
import { useState } from 'react'
import imgMapa from '../../assets/img/sitemap-cinza.png'
import PageFrame from '../shared/Page'
import './home-alternativa.css'

const PageHome = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  return (
    <PageFrame pageName='Home' isNavOpen={isNavOpen} setNavOpen={setNavOpen}>
      <Flex className='home-alternative__container'>
        <Grid className='home-alternative__container__grid' hasGutter>
          <GridItem span={6}>
            <Flex className='home-alternative__container__grid-item'>
              <TextContent>
                <Text className='container__grid__title' component='h1'>
                  Bem vindo ao A7
                </Text>
                <Text className='container__grid__content' component='p'>
                  Visando sua comodidade e segurança, concedemos acesso estritamente às páginas de
                  cadastro. Seu login personalizado garante que você possa realizar suas tarefas sem
                  acessar dados sensíveis. Valorizamos sua contribuição.
                </Text>
                <Button key='create'>Cadastrar</Button>
              </TextContent>
            </Flex>
          </GridItem>
          <GridItem span={6}>
            <img src={imgMapa} alt='' />
          </GridItem>
        </Grid>
      </Flex>
    </PageFrame>
  )
}
export default PageHome
