import { Grid, GridItem } from '@patternfly/react-core'
import { QueryKey, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TowerIdentifier, TowersAPI } from '../../client'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import PageFrame from '../shared/Page'
import PageNoData from '../shared/PageNoData'
import PlotTowerSignal from './PlotTowerSignal'
import SelectTowers from './SelectTowers'

const queryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

const fetchMeasurements = async (tower?: TowerIdentifier, tsIn?: string, tsFin?: string) => {
  if (!tower?.tower_id) return
  const r = await TowersAPI.getMeasurements({
    towerId: tower.tower_id,
    initialDate: tsIn,
    finalDate: tsFin,
  })
  return r.data
}

const TowersTimeseries = () => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [tower, setTower] = useState<TowerIdentifier>()
  const [tsIn, setTsIn] = useState<Date>(new Date(2020, 1, 1))
  const [tsFin, setTsFin] = useState<Date>(new Date())

  const handleTowerChange = (tower_id: number, _towers?: TowerIdentifier[]) => {
    const finder = (twr: TowerIdentifier) => twr.tower_id === tower_id
    const twr = towers ? towers.find(finder) : _towers?.find(finder)
    if (!twr) return
    setTower(twr)
    const _tsFin = new Date(twr.tower_dataset_end)
    const _tsIn = new Date(Date.parse(twr.tower_dataset_end) - 7 * 86400000)
    setTsFin(_tsFin)
    setTsIn(_tsIn)
  }

  const { isLoading: towersIsLoading, data: towers } = useQuery(
    ['towers'] as unknown as QueryKey,
    async () => {
      const towers = await TowersAPI.getAll()
      return towers.sort((a, b) => a.tower_id - b.tower_id)
    },
    {
      ...queryOptions,
      onSuccess: towers => {
        if (towers.length > 0) {
          handleTowerChange(towers[0].tower_id, towers)
        }
      },
    }
  )

  const { data: measurements, isLoading: measurementsIsLoading } = useQuery(
    [['measurements'], tower?.tower_id, tsIn, tsFin],
    () => fetchMeasurements(tower, tsIn?.toISOString(), tsFin?.toISOString()),
    { ...queryOptions, enabled: !!tower }
  )

  const { towerId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (towersIsLoading || !towers) return
    handleTowerChange(parseInt(towerId ? towerId : '0'))
  }, [towersIsLoading, towers])

  useEffect(() => {
    if (!tower || !tower.tower_id || towersIsLoading) return
    navigate(`/torres-anemometricas/${tower.tower_id}`, { replace: true })
  }, [tower])

  return (
    <PageFrame
      pageName='Torres Anemométricas'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={towersIsLoading}
      filters={[
        <DatetimeRangePicker
          key={`DateSelect_${tower?.tower_id}`}
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart: Date, newEnd: Date) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
        />,
        <SelectTowers
          towers={towers}
          key='SelectTowers'
          selected={tower?.tower_id}
          onChange={handleTowerChange}
          isLoading={towersIsLoading}
        />,
      ]}
    >
      <Grid hasGutter>
        <GridItem>
          {measurements?.length === 0 && <PageNoData message='Sem dados no período selecionado.' />}
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={1}
            revision={Number(isNavOpen)}
          />
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={3}
            revision={Number(isNavOpen)}
          />
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={4}
            revision={Number(isNavOpen)}
          />
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={5}
            revision={Number(isNavOpen)}
          />
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={6}
            revision={Number(isNavOpen)}
          />
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={11}
            revision={Number(isNavOpen)}
          />
          <PlotTowerSignal
            isLoading={measurementsIsLoading}
            measurements={measurements}
            signal_type={8}
            revision={Number(isNavOpen)}
          />
        </GridItem>
      </Grid>
    </PageFrame>
  )
}

export default TowersTimeseries
