import { WindFarms } from '../../client'
import SelectList from '../shared/selects/DeprecatedSelectList'

interface IProps {
  selectedWindFarm: string
  windFarms: WindFarms[]
  onChange: (newVal: any) => void
  isLoading: boolean
}

const SelectWindFarms = (props: IProps) => {
  const { selectedWindFarm, windFarms, onChange, isLoading } = props

  return (
    <SelectList
      startSelected={selectedWindFarm}
      onChange={onChange}
      isLoading={!!isLoading}
      list={windFarms.map((w: WindFarms) => {
        return { value: w.wf_id, label: w.wf }
      })}
    />
  )
}
export default SelectWindFarms
