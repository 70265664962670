import useMobile from '../../hooks/useMobile'

const HOURS = [...Array(24).keys()]
const MINUTES = [...Array(60).keys()]
const NUMEROS = { hours: HOURS, minutes: MINUTES, seconds: MINUTES }

interface IProp {
  selected?: number
  onSelect: (newSelected: number) => void
  type: 'hours' | 'minutes' | 'seconds'
}

const TimeSelector = ({ selected, onSelect, type }: IProp) => {
  const numeros = NUMEROS[type]
  const isMobile = useMobile()
  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        maxHeight: isMobile ? '4.5rem' : '17rem',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          padding: '0.1rem 1rem 1rem 0.1rem',
          boxSizing: 'content-box',
        }}
      >
        <div style={{ padding: '0.1rem' }}>
          <div>
            {numeros.map(h => (
              <div
                onClick={() => onSelect(h)}
                key={h}
                style={{
                  padding: '0.1rem',
                  textAlign: 'center',
                  color: h === selected ? 'white' : 'black',
                  backgroundColor: h === selected ? 'var(--pf-v5-global--active-color--100)' : '',
                  cursor: 'pointer',
                }}
              >
                {String(h).padStart(2, '0')}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeSelector
