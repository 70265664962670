import {
  Alert,
  Button,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  TextInput,
} from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import { CalibrationEvents, MastsAPI } from '../../../client'
import { ActionState } from '../../../components/actionState'
import DatetimeRangePicker from '../../../components/calendar/DatetimeRangePicker'
import Select from '../../../components/selects/Select'
import MastContext, { Sensors } from '../../../contexts/MastContext'
import PermissionContext from '../../../contexts/PermissionContext'
import { queryClient } from '../../../services/api'
import { toISOString } from '../../../utils/formatDate'
import { getCalibrationKey } from './constants'
import PlotCalibration from './PlotCalibration'

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar o evento' isInline />,
}

const ModalCalibration = ({
  isOpen,
  onClose,
  dataToUpdate,
}: {
  isOpen: boolean
  onClose: () => void
  dataToUpdate?: CalibrationEvents
}) => {
  const { me } = useContext(PermissionContext)
  const { mast, sensors } = useContext(MastContext)

  const isCreateModal = !dataToUpdate

  const defaultSlope = 1
  const defaultOffset = 0
  let defaultEndTime: Date
  let defaultStartTime: Date
  if (isCreateModal) {
    defaultEndTime = new Date(mast.dataset_end)
    defaultStartTime = new Date(
      defaultEndTime.getFullYear(),
      defaultEndTime.getMonth(),
      defaultEndTime.getDate() - 7
    )
  } else {
    defaultEndTime = new Date(dataToUpdate.end_time)
    defaultStartTime = new Date(dataToUpdate.start_time)
  }

  const [startTime, setStartTime] = useState(defaultStartTime)
  const [endTime, setEndTime] = useState(defaultEndTime)

  const sensorDefault = sensors.find(s => s.id === dataToUpdate?.sensor_id) ?? sensors[0]
  const [sensorSelected, setSensorSelected] = useState<Sensors>(sensorDefault)
  const [slope, setSlope] = useState<number>(dataToUpdate?.slope ?? defaultSlope)
  const [offset, setOffset] = useState<number>(dataToUpdate?.offset ?? defaultOffset)
  const [revision, setRevision] = useState(0)
  const refreshPlot = () => setRevision(prev => prev + 1)

  const closeModal = () => {
    onClose()
  }
  const [actionState, setActionState] = useState<ActionState>('idle')

  const handleSubmit = async () => {
    setActionState('pending')
    const requestMethod = dataToUpdate ? MastsAPI.updateCalibration : MastsAPI.createCalibration
    const requestBody: CalibrationEvents = {
      ...dataToUpdate,
      mast_id: mast.id,
      start_time: toISOString(startTime),
      end_time: toISOString(endTime),
      sensor_id: sensorSelected.id,
      username: me.current_user.username,
      serial_number: mast.active_datalogger_serial,
      offset,
      slope,
    }

    try {
      await requestMethod({ mastId: mast.id, requestBody })
      setActionState('success')
      await queryClient.invalidateQueries(getCalibrationKey(mast.id))
    } catch (error) {
      setActionState('failure')
      return
    } finally {
      setActionState('idle')
      closeModal()
    }
  }

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        closeModal()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      width={'95%'}
      variant={ModalVariant.large}
      title={`${isCreateModal ? 'Cadastrar' : 'Alterar'} calibração`}
      isOpen={isOpen}
      onClose={onClose}
      aria-label='modal-calibration'
      id='modal-calibration'
      key='modal-calibration1'
      keyParams='modal-calibration2'
      keyType='modal-calibration3'
      actions={[
        <Split key='modal-calibration-actions' hasGutter style={{ width: '100%' }}>
          <SplitItem>
            <Button variant='primary' onClick={handleSubmit} key='create'>
              {isCreateModal ? 'Cadastrar' : 'Alterar'}
            </Button>
          </SplitItem>
          <SplitItem>
            <Button key='cancelar' variant='secondary' isDanger onClick={closeModal}>
              Cancelar
            </Button>
          </SplitItem>
          <SplitItem isFilled></SplitItem>
          {!isCreateModal && (
            <SplitItem>
              <Button
                key='deletar'
                variant='danger'
                isDanger
                isDisabled={isCreateModal}
                onClick={console.log}
              >
                Deletar
              </Button>
            </SplitItem>
          )}
        </Split>,
      ]}
    >
      {REQUEST_STATE_MESSAGES[actionState]}
      <Grid hasGutter>
        <GridItem sm={5}>
          <Grid hasGutter>
            <GridItem>
              <span>Sensor</span>
              <Select<Sensors>
                items={sensors}
                itemValueName={'label'}
                itemKeyName={'id'}
                selected={sensorSelected}
                onChange={sensor => {
                  setSensorSelected(sensor)
                  refreshPlot()
                }}
                enableFilter
                selectProps={{
                  menuAppendTo: 'parent',
                  maxHeight: '250px',
                  isDisabled: !isCreateModal,
                }}
                placeholderText='sensor'
              />
            </GridItem>
            <GridItem>
              <span>Período</span>
              <DatetimeRangePicker
                value={{ startDate: startTime, endDate: endTime }}
                onChange={(newStart: Date, newEnd: Date) => {
                  setStartTime(newStart)
                  setEndTime(newEnd)
                  refreshPlot()
                }}
                variant='label'
                calendarVariant='datetime'
                minEnabled={new Date(mast.dataset_start)}
                maxEnabled={new Date(mast.dataset_end)}
                allowEqualDates
              />
            </GridItem>
            {isCreateModal ? null : (
              <GridItem>
                <span>Calibração Anterior</span>
                <Flex>
                  <FlexItem style={{ flex: 1 }}>
                    <span>Slope</span>
                    <TextInput
                      isDisabled={true}
                      className='pf-u-m-sm'
                      placeholder='Slope'
                      height={100}
                      type='number'
                      step={0.1}
                      aria-label='Slope input field old'
                      value={dataToUpdate?.slope}
                    />
                  </FlexItem>
                  <FlexItem style={{ flex: 1 }}>
                    <span>Offset</span>
                    <TextInput
                      isDisabled={true}
                      className='pf-u-m-sm'
                      placeholder='Offset'
                      height={100}
                      type='number'
                      step={0.1}
                      aria-label='Offset input field old'
                      value={dataToUpdate?.offset}
                    />
                  </FlexItem>
                </Flex>
              </GridItem>
            )}
            <GridItem>
              <span>Calibração</span>
              <Flex>
                <FlexItem style={{ flex: 1 }}>
                  <span>Slope</span>
                  <TextInput
                    className='pf-u-m-sm'
                    placeholder='Slope'
                    height={100}
                    type='number'
                    step={0.1}
                    aria-label='Slope input field'
                    value={slope}
                    onChange={(_event, val) => {
                      setSlope(Number(val))
                    }}
                  />
                </FlexItem>
                <FlexItem style={{ flex: 1 }}>
                  <span>Offset</span>
                  <TextInput
                    className='pf-u-m-sm'
                    placeholder='Offset'
                    height={100}
                    type='number'
                    step={0.1}
                    aria-label='Offset input field'
                    value={offset}
                    onChange={(_event, val) => {
                      setOffset(Number(val))
                    }}
                  />
                </FlexItem>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem sm={7}>
          {isCreateModal ? (
            <PlotCalibration
              revision={revision}
              mast={mast}
              sensor={sensorSelected}
              style={{ width: '100%' }}
              startTime={startTime}
              endTime={endTime}
              slope={slope}
              offset={offset}
            />
          ) : (
            <PlotCalibration
              revision={revision}
              mast={mast}
              sensor={sensorSelected}
              style={{ width: '100%' }}
              startTime={startTime}
              endTime={endTime}
              slope={slope}
              offset={offset}
              oldSlope={dataToUpdate.slope}
              oldOffset={dataToUpdate.offset}
            />
          )}
        </GridItem>
      </Grid>
    </Modal>
  )
}

export default ModalCalibration
