import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { REACT_APP_SENTRY_DSN, REACT_APP_ENVIRONMENT } = process.env

const tracing = () => {
  if (REACT_APP_SENTRY_DSN && REACT_APP_ENVIRONMENT) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: REACT_APP_ENVIRONMENT,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
    })
  }
}

export default tracing
