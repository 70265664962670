import { Button } from '@patternfly/react-core'

export interface IConfigButtonProps {
  title: string
  day: number
  month: number
  year: number
}

const configButtonsDefault = [
  {
    title: '10 dias',
    day: 10,
    month: 0,
    year: 0,
  },
  {
    title: '3 meses',
    day: 0,
    month: 3,
    year: 0,
  },
  {
    title: '6 meses',
    day: 0,
    month: 6,
    year: 0,
  },
  {
    title: '1 ano',
    day: 0,
    month: 0,
    year: 1,
  },
  {
    title: '1,5 ano',
    day: 0,
    month: 6,
    year: 1,
  },
  {
    title: '2 anos',
    day: 0,
    month: 0,
    year: 2,
  },
  {
    title: '3 anos',
    day: 0,
    month: 0,
    year: 3,
  },
]

const ButtonsRange = ({
  startDate,
  setEndDate,
  configButtonsRange = configButtonsDefault,
  removeButtonsRange = [],
}: {
  startDate: Date
  setEndDate: (date: Date) => void
  configButtonsRange?: IConfigButtonProps[]
  removeButtonsRange?: string[]
}) => {
  const handleClick = (objConfigButton: IConfigButtonProps) => {
    const newEndDate = new Date(
      startDate.getFullYear() + objConfigButton.year,
      startDate.getMonth() + objConfigButton.month,
      startDate.getDate() + objConfigButton.day,
      startDate.getHours(),
      startDate.getMinutes()
    )
    setEndDate(newEndDate)
  }

  const filterItems = (configButtonsRange: IConfigButtonProps[]) => {
    if (removeButtonsRange.length === 0) return configButtonsRange

    const buttonsFiltered = configButtonsRange.filter(
      obj => !removeButtonsRange.includes(obj.title)
    )

    return buttonsFiltered
  }

  return (
    <div className='pf-v5-u-my-lg' style={{ textAlign: 'center' }}>
      {filterItems(configButtonsRange).map(objConfigButton => {
        return (
          <Button
            size='sm'
            className='pf-v5-u-m-xs'
            key={`${objConfigButton.title}`}
            onClick={() => handleClick(objConfigButton)}
          >
            {objConfigButton.title}
          </Button>
        )
      })}
    </div>
  )
}

export default ButtonsRange
