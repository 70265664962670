import { useState } from 'react'
import SkeletonImage from './SkeletonImage'

interface IProps {
  src: string
  width?: number
  alt?: string
  style?: any
}

const ImgGif = (props: IProps) => {
  const { src, width: _width, alt, style } = props
  const [isLoading, setIsLoading] = useState(true)

  const isMobile = window.innerWidth < 500
  const width = _width ? `${_width}%` : '50%'
  const height = _width ? `${(_width / 100) * 500}px` : '420px'
  const widthDefault = isMobile ? '100%' : width

  const styleDefault = {
    marginTop: '0.5em',
    marginBottom: '0.5em',
    display: isLoading ? 'none' : 'block',
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isLoading && <SkeletonImage width={widthDefault} height={isMobile ? '230px' : height} />}
      <img
        src={src}
        width={widthDefault}
        alt={alt ? alt : ''}
        style={style ? style : styleDefault}
        onLoad={() => setIsLoading(false)}
        key={src}
      />
    </div>
  )
}

export default ImgGif
