export const sort = (data: number[] | string[], order: 'asc' | 'desc') => {
  return [...data].sort((a, b) => {
    if (typeof a === 'number' && typeof b === 'number') {
      return order === 'asc' ? a - b : b - a
    }
    if (typeof a === 'string' && typeof b === 'string') {
      return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
    }
    console.error('sort: data type is not supported')
    return 0
  })
}

export const sortByKey = <T>(data: T[], key: keyof T, order: 'asc' | 'desc') => {
  return [...data].sort((a, b) => {
    const aKey = a[key]
    const bKey = b[key]
    if (typeof aKey === 'number' && typeof bKey === 'number') {
      return order === 'asc' ? aKey - bKey : bKey - aKey
    }
    if (typeof aKey === 'string' && typeof bKey === 'string') {
      return order === 'asc' ? aKey.localeCompare(bKey) : bKey.localeCompare(aKey)
    }
    console.error('sort: data type is not supported')
    return 0
  })
}

export const sortByDateKey = <T>(data: T[], key: keyof T, order: 'asc' | 'desc') => {
  // Key of T must be parsable to Date
  return [...data].sort((a, b) => {
    const aKey = new Date(a[key] as string).getTime()
    const bKey = new Date(b[key] as string).getTime()

    return order === 'asc' ? aKey - bKey : bKey - aKey
  })
}

export const sortByKeyPosition = <T extends object>(
  data: T[],
  keyPosition: number,
  order: 'asc' | 'desc'
) => {
  return [...data].sort((a, b) => {
    const aKey = Object.values(a)[keyPosition]
    const bKey = Object.values(b)[keyPosition]
    if (typeof aKey === 'number' && typeof bKey === 'number') {
      return order === 'asc' ? aKey - bKey : bKey - aKey
    }
    if (typeof aKey === 'string' && typeof bKey === 'string') {
      return order === 'asc' ? aKey.localeCompare(bKey) : bKey.localeCompare(aKey)
    }
    console.error('sort: data type is not supported')
    return 0
  })
}
