import { Alert, Button } from '@patternfly/react-core'
import { Wizard, WizardContextConsumer, WizardFooter } from '@patternfly/react-core/deprecated'

import LayoutPage from '../../assets/gif/curva-potencia-layout.gif'
import ModoAnotacao from '../../assets/gif/curva-potencia-modo-anotacao.gif'
import ModoExperimental from '../../assets/gif/curva-potencia-modo-experimental.gif'
import ModoSelecao from '../../assets/gif/curva-potencia-modo-selecao.gif'

import { stepHelpPlotlyLink } from '../shared/generalSteps/stelpHelpPlotlyLink'
import ImgGif from '../shared/ImgGif'

const stepLayout = (
  <>
    <p>
      Há 3 opções de layout para a disposição dos gráficos: Padrão, Curva de Potência e Vertical.
    </p>
    <ImgGif src={LayoutPage} width={80} />
  </>
)

const stepModoSelecao = (
  <>
    <p>
      Por padrão, a série temporal tem a <strong>Seleção de laço</strong> desabilitada. Ative o{' '}
      <strong>Modo de seleção</strong> para utilizar.
    </p>
    <ImgGif src={ModoSelecao} width={80} />
  </>
)

const stepModoAnotacao = (
  <>
    <p>
      Ative o <strong>Modo anotação</strong> para poder opter detalhes de um ponto das curva da
      série temporal.
    </p>
    <ImgGif src={ModoAnotacao} width={80} />
  </>
)

const stepLimites = (
  <>
    <p>
      Ative o <strong>Modo experimental</strong> para aumentar o limite de aeros e de sinais.
    </p>
    <Alert isInline variant='warning' title='Modo experimental'>
      <span>
        Tenha em mente que no modo experimental, a aplicação pode não funcionar da maneira esperada
        e/ou consumir muitos recursos de CPU e Memoria.
      </span>
    </Alert>

    <div>
      <ImgGif src={ModoExperimental} width={60} />
    </div>
  </>
)

const steps = [
  { name: 'Layout da página', component: stepLayout },
  { name: 'Limite de aeros e sinais', component: stepLimites },
  { name: 'Modo seleção', component: stepModoSelecao },
  { name: 'Modo anotação', component: stepModoAnotacao, enableNext: false },
  ...stepHelpPlotlyLink,
]

const CustomFooter = (
  <WizardFooter>
    <WizardContextConsumer>
      {({ activeStep, onNext, onBack }) => {
        return (
          <>
            <Button
              variant='secondary'
              onClick={onBack}
              className={activeStep.name === steps[0].name ? 'pf-m-disabled' : ''}
            >
              Anterior
            </Button>
            <Button
              variant='primary'
              onClick={onNext}
              className={activeStep.name === steps.at(-1)?.name ? 'pf-m-disabled' : ''}
            >
              Próximo
            </Button>
          </>
        )
      }}
    </WizardContextConsumer>
  </WizardFooter>
)

function HelpContent() {
  return <Wizard steps={steps} height={550} footer={CustomFooter} isNavExpandable />
}

export default HelpContent
