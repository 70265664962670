import { CSSProperties, useContext, useMemo, useRef } from 'react'
import Empty from '../../components/Empty'
import { COLOR_SCALES } from '../../utils/colorScale'
import { defaultConfig, IPlotData, IPlotDataPoints, layoutTemplate } from '../../utils/plotly'
import Plot, { csvIcon } from '../shared/Plot'
import { PLOT_DIVS } from './constants'
import Context from './Context'
import { ACTIONS } from './reducer'

interface ITraces {
  x: string[] | undefined
  y: number[] | undefined
  selectedpoints: any[]
  type: string
  mode: string
  marker: {
    color: string
  }
  name: string
  yaxis: string
}

const formatData = (data: ITraces) => {
  return data?.x?.map((_, index) => ({
    name: data.name,
    x: data.x?.[index] || '',
    y: data.y?.[index] || '',
  }))
}

const gerarCsv = (selectionData?: IPlotData[], allData?: ITraces[]) => {
  const allDataFormatted = allData?.flatMap(data => formatData(data))

  const data = selectionData?.length && selectionData?.length > 0 ? selectionData : allDataFormatted

  if (!data || data.length === 0) return console.error('objeto para gerar CSV está indefinido')

  let csv = 'timestamp,valor,aerogerador,sinal\n'

  data.forEach(i => {
    if (i) csv += `${i.x},${Number(Number(i.y).toFixed(2))},${i.name.replace(' ', ',')}\n`
  })

  const csvElement = document.createElement('a')
  csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  csvElement.target = '_blank'
  csvElement.download = 'timeseries_selection.csv'
  csvElement.click()
}

const colorScale = COLOR_SCALES.categorical_rotated

const traceOffset = 0.04
const getDomain = (signalQty: number) => {
  const quotient = Math.trunc(signalQty / 2)
  const remainder = signalQty % 2

  return [0 + (quotient + remainder) * traceOffset, 0.99 - quotient * traceOffset]
}

// TODO: Levar em consideração o tamanho da div para calcular o tamanho do offset
const positionOffset = traceOffset - 0.016
const getPosition = (signalIndex: number) => {
  if (signalIndex % 2 === 0) {
    return 0 + signalIndex * positionOffset
  }
  return 1 - signalIndex * positionOffset
}

const TimeSeriesPlot = ({ revision, style }: { revision: number; style: CSSProperties }) => {
  const { turbdata, timeseriesPlot, siteTurbs, signalsSelected, siteSignals, signals, dispatch } =
    useContext(Context)

  const domain = getDomain(turbdata[0]?.signals?.length ?? 2)
  const layout = {
    template: layoutTemplate,
    margin: { b: 35, t: 20, l: 40, r: 30 },
    hovermode: timeseriesPlot.annotationMode ? 'closest' : 'x unified',
    annotations: timeseriesPlot.annotations,
  }

  const current: any[] = []
  const selectionsRef = useRef(current)

  const traces: ITraces[] = useMemo(
    () =>
      turbdata
        .map((t, i) => {
          const signalIdsSelected = signalsSelected
            .map(s => signals.find(si => si.signal_id === s))
            .filter(s => Boolean(s))
            // Código acima (.filter(s => Boolean(s))) evita que o sinal seja undefined,
            // aparentemente o typescript não consegue inferir que o filter remove os undefined.
            // Por isso, e somente por isso, a linha abaixo é necessária.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(s => s!.signal_id)

          const orderedSignals = signalIdsSelected
            .map(s => t.signals.find(si => si?.signal_id === s))
            .filter(s => s)

          return orderedSignals.map((s, v) => {
            const turb = siteTurbs.find(tb => tb.turb_id === t.turb_id)
            const signal = siteSignals.find(si => si.signal_id === s?.signal_id)

            if (!signal || !turb) return undefined

            Object.assign(layout, {
              [`yaxis${v === 0 ? '' : v + 1}`]: {
                title: { text: `${signal.description}`, font: { color: colorScale[0][v] } },
                [`${v === 0 ? '__ignore__' : 'overlaying'}`]: 'y',
                side: v % 2 === 0 ? 'left' : 'right',
                anchor: 'free',
                position: getPosition(v),
                range: [signal.min_graph, signal.max_graph],
                autorange: false,
                linewidth: 2,
                gridwidth: 2,
                mirror: 'ticks',
                zeroline: false,
              },
              [`xaxis${v === 0 ? '' : v + 1}`]: {
                domain,
                type: 'datetime',
                linewidth: 2,
                gridwidth: 2,
                mirror: 'ticks',
                zeroline: false,
              },
            })

            return {
              x: s?.data.map(d => d.ts),
              y: s?.data.map(d => d.val),
              selectedpoints: selectionsRef.current
                ?.filter(p => p.curveNumber === v)
                .map(p => p.pointIndex),
              type: 'scatter',
              mode: timeseriesPlot.selectionMode ? 'markers' : 'line',
              marker: { color: colorScale[i][v] },
              name: `${turb.name} ${signal.signal}`,
              yaxis: v === 0 ? 'y1' : `y${v + 1}`,
            }
          })
        })
        .flat()
        .filter((item: ITraces | undefined): item is ITraces => !!item),
    [turbdata, layout]
  )

  const onClick = (e: { points: IPlotDataPoints[] }) => {
    if (!timeseriesPlot.annotationMode) return

    const _annotations = e.points.map(p => {
      const { data, yaxis, x, y } = p
      const haveAnnotations = timeseriesPlot.annotations.length > 0

      return {
        text: `${data.name}<br>ts:${x} - val:${y.toPrecision(3)}`,
        ax: haveAnnotations ? timeseriesPlot.annotations[0]?.ax : -75,
        ay: haveAnnotations ? timeseriesPlot.annotations[0]?.ay : -75,
        bordercolor: data.marker.color,
        borderwidth: 2,
        bgcolor: 'white',
        borderpad: 5,
        font: { color: 'black' },
        yref: yaxis._id,
        standoff: 5,
        captureevents: true,
        x,
        y,
      }
    })
    dispatch({
      type: ACTIONS.SET_TIMESERIES_ANNOTATIONS,
      payload: {
        timeseriesPlot: {
          ...timeseriesPlot,
          annotations: [...timeseriesPlot.annotations, ..._annotations],
        },
      },
    })
  }
  const onSelected = (e: { points: IPlotDataPoints[] }) => {
    selectionsRef.current = e?.points.map(p => {
      return {
        pointIndex: p.pointIndex,
        x: p.x,
        y: p.y,
        curveNumber: p.curveNumber,
        name: p.data.name,
      }
    })
  }
  const config = {
    ...defaultConfig,
    modeBarButtonsToAdd: [
      {
        name: 'exportar dados selecionados em .csv',
        icon: csvIcon,
        click: () => gerarCsv(selectionsRef.current, traces),
      },
      ...defaultConfig.modeBarButtonsToAdd,
    ],
  }

  const onClickAnnotation = (e: any) => {
    dispatch({
      type: ACTIONS.SET_TIMESERIES_ANNOTATIONS,
      payload: {
        timeseriesPlot: {
          ...timeseriesPlot,
          annotations: [
            ...timeseriesPlot.annotations.filter(p => {
              return (
                Math.trunc(Number(p.x)) !== Math.trunc(e.annotation.x) &&
                Math.trunc(Number(p.y)) !== Math.trunc(e.annotation.y)
              )
            }),
          ],
        },
      },
    })
  }

  if (turbdata.length === 0) return <Empty title='Sem dados' />

  return (
    <div className='pf-v5-u-p-sm'>
      <Plot
        divId={PLOT_DIVS.TIMESERIES}
        useResizeHandler
        key={revision}
        data={traces}
        layout={layout}
        config={config}
        style={style}
        onClick={onClick}
        onSelected={onSelected}
        onClickAnnotation={onClickAnnotation}
      />
    </div>
  )
}

export default TimeSeriesPlot
