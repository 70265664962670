import { List, ListItem } from '@patternfly/react-core'

import ImgGif from '../../shared/ImgGif'

import DragToZoom from '../../../assets/gif/plotly/drag-to-zoom.gif'
import ZoomInOut from '../../../assets/gif/plotly/zoom-in-out.gif'

import DragZoomIcon from '../../../assets/img/icon-drag-zoom.svg'
import HomeRestartIcon from '../../../assets/img/icon-home-restart.svg'
import ZoomLessIcon from '../../../assets/img/icon-zoom-less.svg'
import ZoomPlusIcon from '../../../assets/img/icon-zoom-plus.svg'

export const stepZoomArea = (
  <>
    <div>
      <h4>
        <strong>Zoom em área selecionada</strong>
      </h4>
      <p>
        A opção <img src={DragZoomIcon} style={{ width: '1.5em' }} alt='' /> permite aplicar o zoom
        ao clicar e arrastar uma área do gráfico.
      </p>

      <ImgGif src={DragToZoom} />
      <p>
        Para retornar, clique 2 vezes no gráfico ou clique na opção{' '}
        <img src={HomeRestartIcon} style={{ width: '1.5em' }} alt='' /> .
      </p>
    </div>
  </>
)

export const stepZoomInOut = (
  <>
    <div>
      <h4>
        <strong>Zoom com os ícones + e -</strong>
      </h4>
      <p>As opções a seguir permitem aplicar o zoom com um clique:</p>
      <List>
        <ListItem>
          {' '}
          para aplicar zoom <img src={ZoomPlusIcon} style={{ width: '1em' }} alt='' />
        </ListItem>
        <ListItem>
          {' '}
          para retirar zoom <img src={ZoomLessIcon} style={{ width: '1em' }} alt='' />
        </ListItem>
      </List>
      <ImgGif src={ZoomInOut} />
      <p>
        Para retornar, clique 2 vezes no gráfico ou clique na opção{' '}
        <img src={HomeRestartIcon} style={{ width: '1.5em' }} alt='' /> .
      </p>
    </div>
  </>
)
