import { Alert, Bullseye } from '@patternfly/react-core'
import { Link } from 'react-router-dom'

const ErrorPage = ({ message }: { message?: string }) => {
  return (
    <Bullseye>
      <Alert
        isInline
        variant='danger'
        component='h1'
        title={message ?? 'Um erro ocorreu ao processar sua requisição'}
        actionLinks={<Link to='/'>Voltar para página inicial</Link>}
      >
        <p>Nosso time de analistas já foi notificado sobre problema.</p>
      </Alert>
    </Bullseye>
  )
}

export default ErrorPage
