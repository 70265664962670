import {
  Bullseye,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Spinner,
} from '@patternfly/react-core'
import { CSSProperties } from 'react'

interface IRowConfig<T> {
  key: keyof T
  description: string
  formatter?: (val: any) => string
}

const EntityDataList = <T extends object>({
  instance,
  rowConfig,
  isLoading = false,
  className = '',
  isCompact = true,
  style,
}: {
  instance: T
  itemKeyName: keyof T
  rowConfig?: IRowConfig<T>[]
  noDataMessage?: string
  isLoading?: boolean
  className?: string
  isCompact?: boolean
  style?: CSSProperties
}) => {
  const rows = rowConfig
    ? rowConfig
    : (Object.keys(instance).map(k => ({ key: k, description: k })) as IRowConfig<T>[])

  let innerIsLoading = isLoading
  if (!instance || rows.length === 0) {
    innerIsLoading = true
  }

  return (
    <DataList
      style={style}
      aria-label='entity-data-list'
      isCompact={isCompact}
      className={className}
    >
      {isLoading ? (
        <Bullseye>
          <Spinner className='pf-v5-u-ml-lg pf-v5-u-mr-lg' size='md' aria-label='Loading' />
        </Bullseye>
      ) : (
        rows.map((r, i) => (
          <DataListItem key={`${r.description}-${i}`} aria-labelledby='item-m'>
            <DataListItemRow>
              <DataListItemCells
                dataListCells={[
                  <DataListCell key='primary content'>
                    <b id='item-m'>{r.description}</b>
                  </DataListCell>,
                  <DataListCell key='secondary content'>
                    {r.formatter ? r.formatter(String(instance[r.key])) : String(instance[r.key])}
                  </DataListCell>,
                ]}
              />
            </DataListItemRow>
          </DataListItem>
        ))
      )}
    </DataList>
  )
}

export default EntityDataList
