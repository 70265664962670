import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { Alarms, ExtendedAlarms, Turbines } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import Empty from './Empty'
import Loading from './Loading'
import { PAGINATION_HEIGHT, TAB_HEADER_HEIGHT } from './lowerSectionHeight'

const TABLE_STYLE = {
  height: calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    customOffset: `${TAB_HEADER_HEIGHT + PAGINATION_HEIGHT}rem`,
  }),
}

const formatDate = (date: string) => {
  const _dt = new Date(date)
  _dt.setMilliseconds(0)
  return _dt.toLocaleString('pt-BR')
}

const TableAlarms = ({
  alarms,
  turbsMap,
  alarmsIsLoading,
}: {
  alarms?: Alarms[] | ExtendedAlarms[]
  turbsMap: Map<number, Turbines>
  alarmsIsLoading: boolean
}) => {
  return (
    <OuterScrollContainer style={TABLE_STYLE}>
      <InnerScrollContainer>
        <Table aria-label='Alarms Table' variant='compact' isStickyHeader>
          <Thead>
            <Tr>
              <Th>WTG</Th>
              <Th>Inicio</Th>
              <Th>Fim</Th>
              <Th modifier='wrap'>Duração [min]</Th>
              <Th>Alarme</Th>
              <Th>Descrição</Th>
            </Tr>
          </Thead>
          <Tbody>
            {alarms &&
              alarms.length > 0 &&
              alarms.map((a, i) => (
                <Tr key={`${i}_${a.turb_id}_${a.site_id}`}>
                  <Td dataLabel='WTG'>{turbsMap.get(a.turb_id)?.name}</Td>
                  <Td dataLabel='Inicio'>{formatDate(a.ts_in)}</Td>
                  <Td dataLabel='Fim'>{formatDate(a.ts_fin)}</Td>
                  <Td dataLabel='Duração'>{a.dur_min}</Td>
                  <Td dataLabel='Alarme'>{a.error}</Td>
                  <Td dataLabel='Descrição'>{a.description}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {(!alarms || alarms.length === 0) && !alarmsIsLoading && <Empty />}
        {alarmsIsLoading && <Loading />}
      </InnerScrollContainer>
    </OuterScrollContainer>
  )
}

export default TableAlarms
