import { useGoogleMap } from '@react-google-maps/api'
import { Site } from '../../client'

export const ResetZoom = ({ site }: { site: Site }) => {
  const map = useGoogleMap()
  const siteZoom = site?.map_zoom_level ? site.map_zoom_level + 1 : 10
  const lat = site?.lat ? site.lat : -15.7
  const lon = site?.lon ? site.lon : -47.9
  const siteCoords = new google.maps.LatLng(lat, lon)

  if (!map) return <></>

  const handleZoomOut = () => {
    map.setZoom(siteZoom)
    map.panTo(siteCoords)
  }
  return (
    <button
      className='map__btn'
      onClick={handleZoomOut}
      style={{ bottom: 26, right: 60, borderRadius: 3, padding: '5px 10px' }}
    >
      Resetar Zoom
    </button>
  )
}
