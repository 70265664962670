import { CSSProperties, useMemo } from 'react'
import { AvailabilityTimeSeries } from '../../client'
import ColorScale from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const PlotExpectedEnergy = ({
  timeseries,
  revision,
  style,
}: {
  timeseries: AvailabilityTimeSeries[]
  revision: number
  style: CSSProperties
}) => {
  const layout = {
    template: layoutTemplate,
    margin: { b: 15, t: 10, l: 50, r: 35 },
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      rangeslider: { range: undefined },
      rangeselector: {
        buttons: [
          {
            count: 1,
            label: '24h',
            step: 'day',
            stepmode: 'forward',
          },
          {
            count: 2,
            label: '48h',
            step: 'day',
            stepmode: 'forward',
          },
        ],
      },
    },
    yaxis: {
      title: 'Potência (kW)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
    },
    yaxis2: {
      title: 'Categoria BOP',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      range: [-5, 5],
      side: 'right',
      overlaying: 'y',
      type: 'category',
    },
  }
  const config = {
    ...defaultConfig,
    displayModeBar: true,
  }

  const traces = useMemo(() => {
    const data = timeseries.sort((a, b) => (new Date(a.ts) > new Date(b.ts) ? 1 : -1))
    const x = data.map(ee => ee.ts)
    const opts = {
      mode: 'lines',
      type: 'scatter',
    }
    return [
      {
        x,
        y: data.map(ee => ee.pwr_exp),
        name: 'Potência Esperada',
        marker: { color: ColorScale.azul_escuro },
        ...opts,
      },
      {
        x,
        y: data.map(ee => ee.pwr),
        name: 'Potência Real',
        marker: { color: ColorScale.verde },
        mode: 'lines',
        line: {
          dash: 'dashdot',
          width: 4,
        },
      },
      {
        x,
        y: data.map(ee => ee.bop),
        name: 'Categoria BOP',
        yaxis: 'y2',
        line: { shape: 'hv', width: 3 },
        mode: 'lines',
        marker: { color: ColorScale.laranja_amarelo },
      },
    ]
  }, [timeseries])

  return (
    <div
      style={{ backgroundColor: ColorScale.branco }}
      className='pf-v5-u-p-sm pf-v5-u-box-shadow-sm'
    >
      <Plot
        useResizeHandler
        key={revision}
        data={traces}
        layout={layout}
        config={config}
        style={style}
      />
    </div>
  )
}

export default PlotExpectedEnergy
