import { ProductionMtd } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

interface IProps {
  prodToDateData: ProductionMtd[]
  year: number
  month: number
  type: string
  revision: number
  plotStyle: any
  isLoading: boolean
}

const ChartProductionToDate = (props: IProps) => {
  const { prodToDateData, year, month, type, revision, plotStyle, isLoading } = props

  if (isLoading) return null

  const x = ['Realizado', 'P50']
  const prodData =
    type === 'mtd'
      ? prodToDateData.find((d: ProductionMtd) => d.yyyy === year && d.mm === month)
      : prodToDateData.find((d: ProductionMtd) => d.yyyy === year)

  const data = [
    {
      ...defaultAxis,
      x,
      y: [prodData?.[`realizado_${type}` as keyof ProductionMtd], null],
      name: 'Realizado',
      type: 'bar',
      marker: { color: COLORS.verde },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x,
      y: [null, prodData?.[`p50_${type}` as keyof ProductionMtd]],
      xaxis: 'x2',
      name: 'P50',
      type: 'bar',
      marker: { color: COLORS.azul_pantone },
      hoverinfo: 'all',
    },
  ]
  const xaxis = {
    title: type === 'mtd' ? 'Month to Date' : 'Year to Date',
    fixedrange: true,
    linewidth: 2,
    gridwidth: 2,
  }
  const layout = {
    template: layoutTemplate,
    margin: { b: 35, t: 35, l: 40, r: 10 },
    yaxis: {
      title: 'Produção [GWh]',
      fixedrange: true,
      linewidth: 2,
      gridwidth: 2,
      gridcolor: COLORS.transparent_bg,
    },
    xaxis,
    xaxis2: { ...xaxis, gridcolor: COLORS.transparent_bg, overlaying: 'x' },
    plot_bgcolor: COLORS.transparent_bg,
    showlegend: false,
    title: {
      text: `Δ ${type === 'mtd' ? 'Mês' : 'Ano'}: ${prodData?.delta2} [GWh] (${prodData?.delta1}%)`,
      font: { size: 14 },
    },
  }
  const style = { ...plotStyle }
  const config = {
    ...defaultConfig,
    displayModeBar: false,
  }
  return (
    <div className='pf-v5-u-p-sm'>
      <Plot
        divId='ChartProductionMonthly'
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        key={revision}
      />
    </div>
  )
}

export default ChartProductionToDate
