import { Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { group } from 'd3-array'
import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react'
import { EnergyExpectedWindFarm } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import ColorScale from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

interface IPower {
  pwr: number
  pwr_exp: number
}

const PlotExpectedEnergyWindFarms = ({
  expectedEnergyWindFarm,
  revision,
  style,
}: {
  expectedEnergyWindFarm: EnergyExpectedWindFarm[]
  revision: number
  style: CSSProperties
}) => {
  const { windFarms, site, turbs } = useContext(SitesContext)
  const [innerRevision, setInnerRevision] = useState(3)
  const [activeTabKey, setActiveTabKey] = useState<number | string>(1)

  const turbByWfId = group(turbs, t => t.wf_id)

  useEffect(() => {
    setInnerRevision(revision + innerRevision)
  }, [activeTabKey])

  useEffect(() => {
    setActiveTabKey(windFarms[0].wf_id)
  }, [site])

  const layout = {
    template: layoutTemplate,
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
    },
    yaxis: {
      title: 'Potência (MW)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      fixedrange: true,
    },
    yaxis2: {
      title: 'Velocidade do Vento (m/s)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      overlaying: 'y',
      side: 'right',
      zeroline: false,
      fixedrange: true,
    },
  }
  const config = {
    ...defaultConfig,
  }

  const traces = useMemo(() => {
    return windFarms.map(wf => {
      const data = expectedEnergyWindFarm
        .filter(ee => ee.wf_id === wf.wf_id)
        .sort((a, b) => (new Date(a.ts) > new Date(b.ts) ? 1 : -1))

      const x = data.map(ee => ee.ts)
      const yPwrExp = data.map(ee => ee.pwr_exp / 1000)
      const yPwr = data.map(ee => ee.pwr / 1000)
      const yNwsPwrExp = data.map(ee => ee.nws_pwr_exp / 1000)
      const yNwsMean = data.map(ee => ee.nws / (turbByWfId.get(ee.wf_id)?.length ?? turbs.length))

      const bellowExp: IPower[] = data.filter(ee => {
        if (ee.pwr < ee.pwr_exp) {
          return {
            pwr: ee.pwr,
            pwr_exp: ee.pwr_exp,
          }
        }
        return {
          pwr_exp: 0,
          pwr: 0,
        }
      })

      const bellowExpReverse = bellowExp.slice().reverse()

      const pwrHatchValues = bellowExp.map(d => d.pwr / 1000)
      const pwrExpHatchValues = bellowExpReverse.map(d => d.pwr_exp / 1000)

      const valueLoop = pwrHatchValues.concat(pwrExpHatchValues)
      const tsLoop = x.concat(x.slice().reverse())

      return [
        {
          x,
          y: yNwsPwrExp,
          name: 'Potência Esperada Nacelle',
          marker: { color: ColorScale.laranja_amarelo },
          wf,
          mode: 'lines',
          width: 4,
        },
        {
          x,
          y: yPwrExp,
          name: 'Potência Esperada',
          marker: { color: ColorScale.azul_escuro },
          wf,
          mode: 'lines',
          type: 'scatter',
          line: { dash: 'dashdot' },
        },
        {
          x,
          y: yPwr,
          name: 'Potência Real',
          marker: { color: ColorScale.verde },
          wf,
          mode: 'lines',
          width: 4,
        },
        {
          x,
          y: yNwsMean,
          name: 'Velocidade do Vento Nacelle',
          marker: { color: ColorScale.amarelo_cyber },
          wf,
          mode: 'lines',
          yaxis: 'y2',
        },
        {
          x: tsLoop,
          y: valueLoop,
          wf,
          name: 'Pot. < PotEsp.',
          fill: 'toself',
          fillpattern: {
            shape: '',
            size: 5,
          },
          fillcolor: ColorScale.vermelho_puro,
          opacity: 0.375,
          line: { color: ColorScale.transparent_bg },
        },
      ]
    })
  }, [expectedEnergyWindFarm])

  return (
    <div>
      <Tabs
        id='expected-energy-main-tabs'
        activeKey={activeTabKey}
        onSelect={(_, tabIndex) => setActiveTabKey(tabIndex)}
      >
        {windFarms.map(wf => (
          <Tab key={wf.wf} eventKey={wf.wf_id} title={<TabTitleText> {wf.wf} </TabTitleText>}>
            <div className='pf-v5-u-p-sm'>
              <Plot
                useResizeHandler
                key={innerRevision}
                data={traces.flatMap(t => t).filter(t => t.wf.wf === wf.wf)}
                layout={layout}
                config={config}
                style={style}
              />
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}

export default PlotExpectedEnergyWindFarms
