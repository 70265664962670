import {
  Button,
  Divider,
  EmptyStateIcon,
  Flex,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { CogIcon, TimesIcon } from '@patternfly/react-icons'
import { useContext, useMemo, useState } from 'react'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'

import { DowntimeAPI, DowntimeONS } from '../../client'
import { ActionState } from '../../components/actionState'
import EntityTable from '../../components/EntityTable'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { formatDateTimeBrl } from '../../utils/formatDate'
import {
  CACHE_KEY_ONS,
  GRID_SIZE,
  TABLE_HEADER_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ModalRestricao from './ModalRestricao'
import ModalUploadONSSheet from './ModalUploadONSSheet'
import ResizeButtons from './ResizeButtons'
import { keyFromDate } from './useData'

function getDuration(init: string, fin: string) {
  const difMin = (Date.parse(fin) - Date.parse(init)) / (60 * 1000)

  if (Number.isInteger(difMin)) return `${difMin}`
  return `${difMin.toFixed(2)}`
}

type DowntimeONSCustom = DowntimeONS & { duracao: number }

const TableRestricoes = ({
  selectedDate,
  downtimeOns,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  downtimeOns: DowntimeONS[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { site } = useContext(SitesContext)

  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [
    isModalConfirmationWarningSelectedDatasOpen,
    setModalConfirmationWarningSelectedDatasOpen,
  ] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<number[]>([])
  const [dataToUpdate, setDataToUpdate] = useState<DowntimeONS | null>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [isModalUpdateOpen, setModalUploadOpen] = useState(false)

  const onsDowntimeData = useMemo(() => {
    if (!downtimeOns) return []
    return downtimeOns.map(el => ({
      id: el.id,
      ts_in: el.ts_in,
      ts_fin: el.ts_fin,
      motivo: el.motivo,
      username: el.username,
      tipo_restricao: el.tipo_restricao,
      potencia_solicitada: el.potencia_solicitada,
      operador_ons: el.operador_ons,
      duracao: Number(getDuration(el.ts_in, el.ts_fin)),
      site_id: el.site_id,
    }))
  }, [downtimeOns])

  const [checkboxSelected, setCheckboxSelected] = useState<DowntimeONSCustom[]>([])

  const deleteDowntimeOns = async (restricaoId: number[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        restricaoId.map(async data => {
          await DowntimeAPI.deleteDowntimeOns({ siteId: site.site_id, restricaoId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setCheckboxSelected([])
    onCloseModal()
    await queryClient.invalidateQueries([CACHE_KEY_ONS, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const onCloseModal = () => {
    setDataToUpdate(null)
    setIsModalOpen(false)
  }

  const handleModalUpdateOpen = (item: DowntimeONS) => {
    if (!isModalOpen && !!downtimeOns) {
      const found = downtimeOns.find(el => el.id === item.id)
      if (found) setDataToUpdate(found)
    }

    setIsModalOpen(!isModalOpen)
  }

  const handleClickCreateModal = () => {
    setIsModalOpen(true)
    setDataToUpdate(null)
  }

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalConfirmationWarningOpen(!isModalConfirmationWarningOpen)
  }

  const handleDeleteClick = (item: number) => {
    setDataToDelete([item])
    handleModalConfirmationWarningToggle()
  }

  const handleDeleteManyClick = (item: number[]) => {
    setDataToDelete(item)
    handleModalConfirmationWarningSelectedDatasToggle()
  }

  const handleModalConfirmationWarningSelectedDatasToggle = () => {
    setActionState('idle')
    setModalConfirmationWarningSelectedDatasOpen(!isModalConfirmationWarningSelectedDatasOpen)
  }

  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const buttonGroupItems = (
    <>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          onClick={handleClickCreateModal}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '88vw' }),
          }}
        >
          Adicionar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          variant='secondary'
          onClick={() => setModalUploadOpen(true)}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '88vw' }),
          }}
        >
          Enviar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          variant='danger'
          onClick={() => handleDeleteManyClick(checkboxSelected.map(i => i.id as number))}
          isDisabled={!checkboxSelected[0]}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '88vw' }),
          }}
        >
          Remover
        </Button>
      </ToolbarItem>
      <Divider
        orientation={{ default: isMobile ? 'horizontal' : 'vertical' }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />
      <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />
    </>
  )

  const items = (
    <>
      <ToolbarGroup
        variant='button-group'
        style={{ ...(isMobile && { display: 'flex', flexDirection: 'column' }) }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <div style={{ height: heightBottom, backgroundColor: 'orange' }}>
      {isMobile && (
        <Flex
          style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
        >
          <Button
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'center',
              height: '35px',
              color: 'black',
            }}
            onClick={() => setToggleFilters(!toggleFilters)}
          >
            {!toggleFilters ? (
              <>
                Ações
                <EmptyStateIcon icon={CogIcon} />
              </>
            ) : (
              <EmptyStateIcon icon={TimesIcon} />
            )}
          </Button>
        </Flex>
      )}

      {!isMobile || toggleFilters ? (
        <Flex
          style={{
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Toolbar id='toolbar-group-types' style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ToolbarContent>{items}</ToolbarContent>
          </Toolbar>
        </Flex>
      ) : null}

      <EntityTable<DowntimeONSCustom>
        items={onsDowntimeData}
        itemKeyName='id'
        isCompact
        columnConfig={[
          { key: 'id', description: 'Id' },
          { key: 'ts_in', description: 'Início', formatter: v => formatDateTimeBrl(new Date(v)) },
          { key: 'ts_fin', description: 'Fim', formatter: v => formatDateTimeBrl(new Date(v)) },
          { key: 'tipo_restricao', description: 'Tipo Restrição' },
          { key: 'operador_ons', description: 'Operador ONS' },
          { key: 'potencia_solicitada', description: 'Potência Demandada [MW ou MVAr]' },
          { key: 'motivo', description: 'Motivo' },
          { key: 'duracao', description: 'Duração [min]' },
        ]}
        onClickEdit={handleModalUpdateOpen}
        onClickDelete={e => handleDeleteClick(e.id || 0)}
        style={{
          maxHeight: tableAndContainerHeight,
          minHeight: tableAndContainerHeight,
        }}
        selectedMode='checkbox'
        checkboxSelected={checkboxSelected}
        setCheckboxSelected={setCheckboxSelected}
      />
      <ModalRestricao
        isOpen={isModalOpen}
        onClose={onCloseModal}
        dataToUpdate={dataToUpdate}
        selectedDate={selectedDate}
        handleDeleteClick={handleDeleteClick}
        modalConfirmation={
          <ModalConfirmationWarning<number>
            title='Remover restrição ONS'
            isOpen={isModalConfirmationWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeOns([id])}
            element={dataToDelete[0]}
            actionState={actionState}
          />
        }
      />
      <ModalConfirmationWarning<number>
        title='Remover restrição ONS'
        isOpen={isModalConfirmationWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteDowntimeOns([id])}
        element={dataToDelete[0]}
        actionState={actionState}
      />
      <ModalUploadONSSheet isOpen={isModalUpdateOpen} setOpen={setModalUploadOpen} site={site} />
      <ModalConfirmationWarning<DowntimeONS[]>
        isOpen={isModalConfirmationWarningSelectedDatasOpen}
        handleModalToggle={handleModalConfirmationWarningSelectedDatasToggle}
        handleSubmit={data => deleteDowntimeOns(data.map(i => i.id as number))}
        title='Remover restrições ONS'
        element={checkboxSelected}
        actionState={actionState}
        alertMessage='Tem certeza que deseja remover estes itens? Essa ação não poderá ser desfeita.'
        additionalInformation={
          checkboxSelected.length > 0 && (
            <EntityTable<DowntimeONSCustom>
              items={checkboxSelected}
              itemKeyName='id'
              isCompact
              columnConfig={[
                { key: 'id', description: 'Id' },
                {
                  key: 'ts_in',
                  description: 'Início',
                  formatter: v => formatDateTimeBrl(new Date(v)),
                },
                {
                  key: 'ts_fin',
                  description: 'Fim',
                  formatter: v => formatDateTimeBrl(new Date(v)),
                },
                { key: 'tipo_restricao', description: 'Tipo Restrição' },
                { key: 'operador_ons', description: 'Operador ONS' },
              ]}
            />
          )
        }
      />
    </div>
  )
}

export default TableRestricoes
