import { Text, TextContent } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import state1 from '../../../../assets/img/estado-1.png'
import state2 from '../../../../assets/img/estado-2.png'
import state3 from '../../../../assets/img/estado-3.png'
import state4 from '../../../../assets/img/estado-4.png'
import { Site } from '../../../../client'
import api, { queryClient as qq } from '../../../../services/api'
import ColorScale from '../../../../utils/colorScale'

interface IAvailableInfos {
  siteId: number
  site: Site
}

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  placeholderData: [
    {
      operational: null,
      no_communication: null,
      stop: null,
      ambiental_stop: null,
    },
  ],
}

const AvailableInfos = ({ siteId, site }: IAvailableInfos) => {
  const { data } = useQuery(
    ['avail_realtime', siteId],
    () => api.get(`/sites/${siteId}/availability/real-time`).then(r => r.data),
    queryOptions
  )

  const { operational, no_communication, stop, ambiental_stop } = data

  useEffect(() => {
    const interval = setInterval(() => {
      qq.invalidateQueries(['avail_realtime', siteId])
    }, 1000 * 15)
    return () => clearInterval(interval)
  }, [])

  const thumbStates = [state1, state2, state3, state4]

  return (
    <div className='pf-v5-u-display-flex pf-v5-u-justify-content-space-between'>
      <TextContent>
        <Text
          component='p'
          style={{ color: ColorScale.verde_cadmium }}
          className='txt-verde pf-v5-u-font-weight-bold'
        >
          Em operação: {operational}
        </Text>
        <Text
          component='p'
          style={{ color: ColorScale.cinza_medio }}
          className='txt-lilas pf-v5-u-font-weight-bold'
        >
          Sem comunicação: {no_communication}
        </Text>
        <Text
          component='p'
          style={{ color: ColorScale.laranja_chama }}
          className='txt-salmao pf-v5-u-font-weight-bold'
        >
          Parada: {stop}
        </Text>
        <Text
          component='p'
          style={{ color: ColorScale.azul_pantone }}
          className='txt-azul pf-v5-u-font-weight-bold'
        >
          Parada ambiental: {ambiental_stop}
        </Text>
      </TextContent>
      <Link to={`/mapa/${site.site.toLowerCase()}`}>
        <img
          style={{
            maxWidth: '100%',
            maxHeight: window.innerHeight < window.innerWidth ? '4rem' : '15rem',
          }}
          src={thumbStates[siteId - 1]}
          alt=''
        />
      </Link>
    </div>
  )
}

export default AvailableInfos
