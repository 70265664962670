import { InternMap } from 'd3-array'
import { CSSProperties, useMemo } from 'react'
import { MastData, Masts } from '../../../client'
import { Sensors } from '../../../contexts/MastContext'
import ColorScale from '../../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../../utils/plotly'
import { sortByDateKey } from '../../../utils/sort'
import LoaderSpinner from '../../shared/Page/LoaderSpinner'
import Plot from '../../shared/Plot'
import useData from '../useData'

const CONFIG = { ...defaultConfig, displayModeBar: true }
const HOUR = 1000 * 60 * 60
const style: CSSProperties = { height: '75vh' }

/*
x * slope + offset = y

(y - offset) / slope = x

x * newSlope + newOffset = z

Therefore:

((y - offset) / slope) * newSlope + newOffset = z
*/

const calibrate = (x: number, slope: number, offset: number) => x * slope + offset
const unCalibrate = (y: number, slope: number, offset: number) => (y - offset) / slope
const reCalibrate = (y: number, oS: number, oO: number, nS: number, nO: number) =>
  ((y - oO) / oS) * nS + nO

const makeTraceCalibration = ({
  mastDataBySensor,
  sensor,
  slope,
  offset,
  startTime,
  endTime,
}: {
  mastDataBySensor: InternMap<number, MastData[]>
  sensor: Sensors
  slope: number
  offset: number
  oldSlope?: number
  oldOffset?: number
  startTime: Date
  endTime: Date
}) => {
  if (!mastDataBySensor || !sensor) return []

  const data = sortByDateKey(mastDataBySensor.get(sensor.id) ?? [], 'ts', 'asc')

  if (data.length === 0) return []

  const x = data.map(d => d.ts)
  const opts = { x, mode: 'lines', type: 'scatter' }
  return [
    {
      name: 'Dado Bruto',
      y: data.map(d => d.avg),
      marker: { color: ColorScale.verde },
      ...opts,
    },
    {
      name: 'Dado Calibrado',
      y: data.map(d =>
        new Date(d.ts) >= startTime && new Date(d.ts) <= endTime
          ? calibrate(d.avg, slope, offset)
          : d.avg
      ),
      line: { dash: 'dot' },
      marker: { color: ColorScale.azul_escuro },
      ...opts,
    },
  ]
}

const makeTraceReCalibration = ({
  mastDataBySensor,
  sensor,
  slope,
  offset,
  oldSlope,
  oldOffset,
  startTime,
  endTime,
}: {
  mastDataBySensor: InternMap<number, MastData[]>
  sensor: Sensors
  slope: number
  offset: number
  oldSlope: number
  oldOffset: number
  startTime: Date
  endTime: Date
}) => {
  if (!mastDataBySensor || !sensor) return []

  const data = sortByDateKey(mastDataBySensor.get(sensor.id) ?? [], 'ts', 'asc')

  if (data.length === 0) return []

  const x = data.map(d => d.ts)
  const opts = { x, mode: 'lines', type: 'scatter' }
  return [
    {
      name: 'Calibração Anterior',
      y: data.map(d => d.avg),
      line: { dash: 'dashed+dot' },
      marker: { color: ColorScale.amarelo_pantone },
      ...opts,
    },
    {
      name: 'Dado Bruto',
      y: data.map(d =>
        new Date(d.ts) >= startTime && new Date(d.ts) <= endTime
          ? unCalibrate(d.avg, oldSlope, oldOffset)
          : d.avg
      ),
      marker: { color: ColorScale.verde },
      ...opts,
    },
    {
      name: 'Nova Calibração',
      y: data.map(d =>
        new Date(d.ts) >= startTime && new Date(d.ts) <= endTime
          ? reCalibrate(d.avg, oldSlope, oldOffset, slope, offset)
          : d.avg
      ),
      line: { dash: 'dot' },
      marker: { color: ColorScale.azul_escuro },
      ...opts,
    },
  ]
}

const PlotCalibration = ({
  revision,
  startTime,
  endTime,
  slope,
  offset,
  sensor,
  oldSlope,
  oldOffset,
}: {
  revision: number
  style: CSSProperties
  mast: Masts
  startTime: Date
  endTime: Date
  slope: number
  offset: number
  oldSlope?: number
  oldOffset?: number
  sensor: Sensors
}) => {
  const isRecalibrate = oldSlope !== undefined && oldOffset !== undefined
  const greaterThen24h = endTime.getTime() - startTime.getTime() < HOUR * 24

  const plotFullRange = greaterThen24h
    ? [new Date(startTime.getTime() - HOUR * 12), new Date(endTime.getTime() + HOUR * 12)]
    : [new Date(startTime.getTime() - HOUR * 24 * 5), new Date(endTime.getTime() + HOUR * 24 * 5)]

  const layout = {
    template: layoutTemplate,
    margin: { b: 20, t: 33, l: 50, r: 35 },
    barmode: 'stack',
    uirevision: true,
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      range: plotFullRange,
      autorange: true,
    },
    yaxis: {
      title: `${sensor?.description} [${sensor?.units}]`,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      automargin: true,
      fixedrange: false,
      autorange: true,
    },
    yaxis2: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      side: 'right',
      overlaying: 'y',
      type: 'category',
      automargin: true,
      fixedrange: false,
    },
    shapes: [
      {
        type: 'rect',
        x0: startTime,
        x1: endTime,
        y0: 0,
        yref: 'paper',
        xref: 'x',
        y1: 1,
        fillcolor: '#d3d3d3',
        opacity: 0.3,
        line: {
          width: 0,
        },
      },
    ],
  }

  const { dataIsLoading, mastDataBySensor } = useData(plotFullRange[0], plotFullRange[1])

  const tracer = isRecalibrate ? makeTraceReCalibration : makeTraceCalibration

  const traces = useMemo(
    () =>
      tracer({
        mastDataBySensor,
        sensor,
        slope,
        offset,
        startTime,
        endTime,
        oldSlope: oldSlope ?? 1,
        oldOffset: oldOffset ?? 0,
      }),
    [mastDataBySensor, slope, offset, dataIsLoading, sensor]
  )

  return (
    <div style={style}>
      {dataIsLoading ? (
        <LoaderSpinner />
      ) : (
        <Plot
          useResizeHandler
          revision={revision}
          key={revision}
          style={style}
          data={traces}
          layout={layout}
          config={CONFIG}
          uirevision='time'
        />
      )}
    </div>
  )
}

export default PlotCalibration
