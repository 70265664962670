import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { replaceFilterParams } from '../../../utils/url'

import { Alert, PickOptional } from '@patternfly/react-core'
import {
  Select,
  SelectOption,
  SelectOptionObject,
  SelectProps,
  SelectVariant,
} from '@patternfly/react-core/deprecated'
import { css } from '@patternfly/react-styles'

import MultiSelectFooter from './MultiSelectFooter'

const MultiSelect = <T extends object>({
  items,
  itemValueName,
  itemKeyName,
  setSelected,
  selected = [],
  placeholderText = 'Escolha',
  defaultItems = [],
  minSelections = 0,
  maxSelections = 10000,
  maxHeight = 350,
  className,
  enableUrlParams = false,
  selectProps = {},
}: {
  items: T[]
  itemValueName: keyof T
  itemKeyName: keyof T
  selected?: T[]
  setSelected: (newSelects: T[]) => void
  placeholderText?: string
  defaultItems?: T[]
  minSelections?: number
  maxSelections?: number
  maxHeight?: number
  className?: string
  enableUrlParams?: boolean
  selectProps?: PickOptional<SelectProps>
}) => {
  const [isAlert, setIsAlert] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const paramItems: number[] = JSON.parse(searchParams.get(String(itemKeyName)) ?? '[]')

  useEffect(() => {
    // Load selected turbs from url params if not already selected after sites load
    if (!enableUrlParams || items.length === 0 || selected?.length > 0) return

    setSelected(items.filter(i => paramItems.includes(Number(i[itemKeyName]))))
  }, [items])

  const handleSelect = (newlySelected: T[]) => {
    setSelected(newlySelected)
    if (!enableUrlParams) return
    const newLocation = replaceFilterParams(location, newlySelected, itemKeyName)
    navigate(newLocation)
  }

  const onToggle = () => {
    if (selected.length <= maxSelections) {
      setIsOpen(!isOpen)
      setIsAlert(false)
      return
    }
    setIsAlert(true)
    handleSelect(selected.slice(0, maxSelections))
  }

  const onSelect = (
    _e: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
    selection: string | SelectOptionObject
  ) => {
    const selectedEntities = items.find(t => t[itemValueName] === selection)

    if (selectedEntities && selected.includes(selectedEntities)) {
      const newSelected = selected.filter(t => t[itemValueName] !== selection)

      if (newSelected.length < minSelections) {
        return setIsAlert(true)
      }
      handleSelect(newSelected)
      setIsAlert(false)
      return
    }
    if (!selectedEntities) {
      return
    }
    let newSelected = [...selected, selectedEntities]
    if (newSelected.length > maxSelections) {
      setIsAlert(true)
    }
    newSelected = [...newSelected.slice(0, maxSelections + 1)]
    handleSelect(newSelected)
  }

  const mapSelectOptions = (entities: any[]) =>
    entities.map(t => (
      <SelectOption
        id={`selection_${t[itemKeyName]}_${t[itemValueName]}`}
        key={`${t[itemKeyName]}_${t[itemValueName]}`}
        value={`${t[itemValueName]}`}
      />
    ))

  const options = mapSelectOptions(items)

  const selectionsFilter = (arr: any[]) => arr.filter(t => t).map(t => t[itemValueName])

  const selections: string[] =
    selectionsFilter(selected).length > 0
      ? selectionsFilter(selected)
      : selectionsFilter(defaultItems)

  const onFilter = (_: any, textInput: string) => {
    if (textInput === '') return options

    return mapSelectOptions(
      items.filter(e => String(e[itemValueName]).toLowerCase().includes(textInput.toLowerCase()))
    )
  }
  const alertMsg = `Escolha de ${minSelections} a ${maxSelections} itens`

  return (
    <Select
      hasInlineFilter={selectProps?.variant === SelectVariant.checkbox}
      noResultsFoundText={`Nenhum ${placeholderText} encontrado`}
      onFilter={onFilter}
      onToggle={onToggle}
      onSelect={onSelect}
      onClear={() => {
        const newSelected = items.filter(e => selections.includes(String(e[itemValueName])))
        handleSelect([...newSelected.slice(0, minSelections)])
      }}
      selections={selections}
      isOpen={isOpen}
      placeholderText={placeholderText}
      inlineFilterPlaceholderText={`Filtre por ${placeholderText}`}
      className={css('pf-v5-u-p-sm', className)}
      isGrouped
      maxHeight={maxHeight}
      footer={
        <>
          {isAlert ? <Alert variant='danger' isInline title={alertMsg} /> : null}
          <MultiSelectFooter
            entityValueName={itemValueName}
            entityKeyName={itemKeyName}
            selected={selected}
            setSelected={handleSelect}
          />
        </>
      }
      {...selectProps}
    >
      {options}
    </Select>
  )
}

export default MultiSelect
