import { Divider, Grid, GridItem, Panel, PanelHeader } from '@patternfly/react-core'
import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { MastIngestionSummary, SignalTypes } from '../../../client'
import EntityTable from '../../../components/EntityTable'
import MastContext from '../../../contexts/MastContext'
import useMobile from '../../../hooks/useMobile'
import useVertical from '../../../hooks/useVertical'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { fmtDateStringToBRLDateString } from '../../../utils/formatDate'
import Page from '../../shared/Page'
import LoaderSpinner from '../../shared/Page/LoaderSpinner'
import useData from '../useData'
import PlotSignal from './PlotSignal'
import PlotWindRose from './PlotWindRose'

const panelStyle: CSSProperties = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 1, pgHasFilter: false }),
  width: '100%',
}

const fmtPercentage = (n: number) => {
  return String(Number(n.toFixed(2)) * 100)
}

const SEGUNDOS_INTERVALO = 10

const MastsSummary = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const [revision, setRevision] = useState(1)
  const {
    summaries,
    isLoading: summaryIsLoading,
    setActiveMast,
    mast,
    allMasts,
    sensors,
    signalTypes,
  } = useContext(MastContext)

  const signalTypesOnMast: SignalTypes[] = []
  signalTypes.forEach(st => {
    sensors.forEach(s => {
      if (st.type === s.type && !signalTypesOnMast.some(item => item.type === st.type)) {
        signalTypesOnMast.push(st)
      }
    })
  })

  const tsFin = new Date(mast.dataset_end)
  const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)

  const { dataIsLoading, mastDataBySensor, mastData } = useData(tsIn, tsFin)

  const handleSelectSummary = (mastSummary: MastIngestionSummary) => {
    const _mast = allMasts.find(m => m.id === mastSummary.mast_id)
    if (!_mast) return
    setActiveMast(_mast)
  }

  useEffect(() => setRevision(revision + 1), [mast, isNavOpen, dataIsLoading])

  const isPresentation = sessionStorage.getItem('telao') === 'on'

  const indexRef = useRef(0)

  useEffect(() => {
    if (!isPresentation || summaries.length === 0) return

    const intervalId = setInterval(async () => {
      const _indexTemp = indexRef.current === summaries.length - 1 ? 0 : indexRef.current + 1

      indexRef.current = _indexTemp
      handleSelectSummary(summaries[_indexTemp])
    }, SEGUNDOS_INTERVALO * 1000)

    return () => clearInterval(intervalId)
  }, [summaries, isPresentation])

  useEffect(() => {
    // In case a mast does not have a corresponding summary
    if (summaries.length > 1 && !summaries.find(s => s.mast_id === mast.id)) {
      handleSelectSummary(summaries[0])
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const isArrowUp = event.key === 'ArrowUp'
      const isArrowDown = event.key === 'ArrowDown'

      if (!isArrowUp && !isArrowDown) return

      const direction = isArrowUp ? -1 : 1
      const newIndex = (indexRef.current + direction + summaries.length) % summaries.length
      indexRef.current = newIndex
      handleSelectSummary(summaries[newIndex])
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [summaries])

  const isMobile = useMobile()

  return (
    <Page
      pageName='Resumo das Torres'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={summaryIsLoading}
      isContentLoading={dataIsLoading}
    >
      <Grid hasGutter lg={4}>
        <GridItem>
          <Panel style={{ ...(!isMobile && panelStyle) }} className='pf-v5-u-box-shadow-md'>
            <PanelHeader>
              <b>Torres</b>
            </PanelHeader>
            <Divider />
            <EntityTable<MastIngestionSummary>
              items={summaries}
              isLoading={summaryIsLoading}
              itemKeyName='mast_id'
              selected={summaries.find(s => s.mast_id === mast.id) ?? summaries[0]}
              onSelect={mast => {
                indexRef.current = summaries.findIndex(s => s.mast_id === mast.mast_id)
                handleSelectSummary(mast)
              }}
              isCompact
              columnConfig={[
                { key: 'mast_name', description: 'Nome' },
                {
                  key: 'dados_ate',
                  description: 'Dados Até',
                  formatter: fmtDateStringToBRLDateString,
                },
                { key: 'dias_sem', description: 'Dias Sem' },
                { key: 'cobertura', description: 'Cobertura', formatter: fmtPercentage },
              ]}
            />
          </Panel>
        </GridItem>
        <GridItem lg={8}>
          {dataIsLoading || !mastData || sensors.length === 0 ? (
            <LoaderSpinner />
          ) : (
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                gridAutoFlow: 'row',
                height: '100%',
              }}
              hasGutter
            >
              <GridItem
                style={{
                  ...(!isMobile && {
                    gridColumn: isMobile ? '1' : '1 / 2',
                    gridRow: isMobile ? '1' : '1 / 3',
                  }),
                }}
                className='pf-v5-u-box-shadow-md'
              >
                <PlotWindRose mastDataBySensor={mastDataBySensor} revision={revision} />
              </GridItem>
              {signalTypesOnMast.map((sigType, i) => (
                <GridItem
                  key={`${sigType.id}-${i}`}
                  style={{
                    ...(!isMobile && {
                      gridColumn: i === 0 ? '2' : 'auto',
                      gridRow: i === 0 ? '1' : 'auto',
                    }),
                  }}
                  className='pf-v5-u-box-shadow-md'
                >
                  <PlotSignal
                    mastDataBySensor={mastDataBySensor}
                    signalType={sigType}
                    revision={revision}
                  />
                </GridItem>
              ))}
            </Grid>
          )}
        </GridItem>
      </Grid>
    </Page>
  )
}

export default MastsSummary
