import {
  ActionGroup,
  Button,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Form,
  FormGroup,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Radio,
} from '@patternfly/react-core'
import { useEffect, useState } from 'react'
import { SettingsTurbineMap, Turbines } from '../../client'
import SelectList from '../shared/selects/DeprecatedSelectList'

export default function ModalTurbs({
  isModalOpen,
  onCloseModal,
  onlyEditable = true,
  turbsMap,
  isLoading,
  settingTurb,
  updateTurb,
}: {
  isModalOpen: boolean
  onCloseModal: () => void
  onlyEditable: boolean
  turbsMap: Map<number, Turbines>
  isLoading: boolean
  settingTurb: SettingsTurbineMap | undefined
  updateTurb: (value: SettingsTurbineMap) => void
}) {
  const [position, setPosition] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [turbEl, setTurbEl] = useState<Turbines | undefined>(undefined)

  async function handleSubmit() {
    if (!settingTurb) return

    const data: SettingsTurbineMap = {
      ...settingTurb,
      visible: isVisible,
      position: position,
    }

    updateTurb(data)
    onCloseModal()
  }

  useEffect(() => {
    if (settingTurb && settingTurb.position && settingTurb.visible) {
      setPosition(settingTurb.position)
      setIsVisible(settingTurb.visible)

      const turbEl = turbsMap.get(settingTurb.turb_id)
      setTurbEl(turbEl)
    }
  }, [settingTurb])

  const options = [
    { value: 'left', label: 'left', disabled: false },
    { value: 'right', label: 'right', disabled: false },
  ]

  return (
    <>
      <Modal
        variant={ModalVariant.small}
        header={<h1>Editar</h1>}
        isOpen={isModalOpen}
        onClose={onCloseModal}
        className='downtime-modal'
        aria-label='Editar'
        id='modalTurbs'
        actions={[
          <Form
            key='formModalTurbs'
            style={{
              paddingRight: 'var(--pf-v5-global--spacer--md)',
              paddingLeft: 'var(--pf-v5-global--spacer--md)',
              flex: '1',
            }}
          >
            <Grid hasGutter md={12}>
              <GridItem span={6}>
                <FormGroup
                  role='radiogroup'
                  isInline
                  fieldId='basic-form-radio-group'
                  label='Posição'
                >
                  <SelectList
                    hasPadding={false}
                    startSelected={position}
                    onChange={(value: string) => setPosition(value)}
                    isLoading={isLoading}
                    list={options}
                  />
                </FormGroup>
              </GridItem>

              <GridItem span={6}>
                <FormGroup
                  role='radiogroup'
                  isInline
                  fieldId='check-is-visible-group'
                  label='Visível'
                >
                  <Radio
                    isChecked={isVisible}
                    onChange={() => setIsVisible(true)}
                    name='check-is-visible'
                    label='Sim'
                    id='check-is-visible-true'
                  />
                  <Radio
                    isChecked={!isVisible}
                    onChange={() => setIsVisible(false)}
                    name='check-is-visible'
                    label='Não'
                    id='check-is-visible-false'
                  />
                </FormGroup>
              </GridItem>
            </Grid>

            <ActionGroup>
              <Button variant='primary' onClick={() => handleSubmit()}>
                Salvar
              </Button>
              <Button variant='secondary' isDanger onClick={() => onCloseModal()}>
                Cancelar
              </Button>
            </ActionGroup>
          </Form>,
        ]}
      >
        {turbEl && !onlyEditable && (
          <DataList aria-label='data-list-turb'>
            <DataListItem aria-labelledby='turb-name'>
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={[
                    <DataListCell key='turb-name key'>
                      <span id='turb-name'>Aerogerador:</span>
                    </DataListCell>,
                    <DataListCell key='turb-name content'>{turbEl.name}</DataListCell>,
                  ]}
                />
              </DataListItemRow>
            </DataListItem>
          </DataList>
        )}
      </Modal>
    </>
  )
}
