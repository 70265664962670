import { useState } from 'react'

import { Alert, DragDrop, Draggable, Droppable } from '@patternfly/react-core'
import { Select, SelectGroup, SelectOption, SelectVariant } from '@patternfly/react-core/deprecated'

const entSiteFilter = (t, site) => t?.site_id && t.site_id === site?.site_id

const MultiSelectFooter = props => {
  const { entityName, entityIdName, selected, setSelected, placeholderText } = props

  const onDrop = (source, dest) => {
    const newSelected = [...selected]
    const sourcePosition = selected[source.index]
    const destPosition = selected[dest.index]
    newSelected[source.index] = destPosition
    newSelected[dest.index] = sourcePosition
    setSelected(newSelected)
    return true
  }

  return (
    <SelectGroup label='Selecionado' key='GrpSel'>
      <DragDrop onDrop={onDrop}>
        <Droppable>
          {selected.map(s => {
            const idSufix = `${placeholderText}_${s[entityIdName]}`
            return (
              <Draggable key={`drag_${idSufix}`}>
                <SelectOption
                  isChecked
                  id={`selection_${idSufix}`}
                  key={s[entityIdName]}
                  value={s[entityName]}
                />
              </Draggable>
            )
          })}
        </Droppable>
      </DragDrop>
    </SelectGroup>
  )
}

const MultiSelect = props => {
  const {
    site,
    entities,
    entityName,
    entityIdName,
    selected,
    setSelected,
    placeholderText,
    defaultEntities,
    minSelections,
    maxSelections,
  } = props

  const [isAlert, setIsAlert] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => {
    if (selected.length <= maxSelections) {
      setIsOpen(!isOpen)
      setIsAlert(false)
      return
    }
    setIsAlert(true)
    setSelected(selected.slice(0, maxSelections))
  }

  const onSelect = (_e, sel) => {
    const selTurb = entities.find(t => t[entityName] === sel)
    if (selected.includes(selTurb)) {
      const newSelected = selected
        .filter(t => t[entityName] !== sel)
        .filter(etf => entSiteFilter(etf, site))
      if (newSelected.length < minSelections) return setIsAlert(true)
      setSelected(newSelected)
      setIsAlert(false)
      return
    }
    const newSelected = [...selected.filter(etf => entSiteFilter(etf, site)), selTurb]
    if (newSelected.length > maxSelections) {
      setIsAlert(true)
    }
    setSelected(newSelected.slice(0, maxSelections + 1))
  }

  const mapSelectOptions = ents =>
    ents.map(t => (
      <SelectOption
        id={`selection_${placeholderText}_${t[entityIdName]}`}
        key={t[entityIdName]}
        value={t[entityName]}
      />
    ))

  const options = mapSelectOptions(entities)

  const selectionsFilter = arr =>
    arr
      .filter(t => t)
      .filter(t => t.site_id === site?.site_id)
      .map(t => t[entityName])
  const selections =
    selectionsFilter(selected).length > 0
      ? selectionsFilter(selected)
      : selectionsFilter(defaultEntities)

  const onFilter = (_, textInput) => {
    if (textInput === '') return options

    return mapSelectOptions(
      entities.filter(e => e[entityName].toLowerCase().includes(textInput.toLowerCase()))
    )
  }
  const alertMsg = `Escolha de ${minSelections} a ${maxSelections} itens`

  return (
    <Select
      hasInlineFilter
      noResultsFoundText={`Nenhum ${placeholderText} encontrado`}
      onFilter={onFilter}
      variant={SelectVariant.checkbox}
      onToggle={onToggle}
      onSelect={onSelect}
      selections={selections}
      isOpen={isOpen}
      placeholderText={placeholderText}
      inlineFilterPlaceholderText={`Filtre por ${placeholderText}`}
      className='pf-v5-u-p-sm'
      isGrouped
      maxHeight={400}
      footer={
        <>
          {isAlert ? <Alert variant='danger' isInline title={alertMsg} /> : <></>}
          <MultiSelectFooter
            site={site}
            entities={entities}
            entityName={entityName}
            entityIdName={entityIdName}
            selected={selected}
            setSelected={setSelected}
          />
        </>
      }
    >
      {options}
    </Select>
  )
}

export default MultiSelect
