import { List, ListItem } from '@patternfly/react-core'

import ImgGif from '../../shared/ImgGif'

import AxisAutoscale from '../../../assets/gif/plotly/double-click-autoscale-axis.gif'
import SecretInput from '../../../assets/gif/plotly/input-secreto.gif'
import MoveAxis from '../../../assets/gif/plotly/move-axis.gif'
import ZoomAxis from '../../../assets/gif/plotly/zoom-axis.gif'

import HomeRestartIcon from '../../../assets/img/icon-home-restart.svg'

import DiagonalUpDownCursor from '../../../assets/img/cursor-diagonal-down-up.svg'
import DiagonalDownUpCursor from '../../../assets/img/cursor-diagonal-up-down.svg'
import HorizontalCursor from '../../../assets/img/cursor-horizontal.svg'
import VerticalCursor from '../../../assets/img/cursor-vertical.svg'

export const stepMoveAxis = (
  <>
    <div>
      <h4>
        <strong>Mover o gráfico com os eixos</strong>
      </h4>
      <p>Clique e arraste o cursor na parte central dos eixos para mover o gráfico:</p>
      <List>
        <ListItem>
          {' '}
          horizontalmente, com{' '}
          <img
            src={HorizontalCursor}
            style={{ width: '1.5em' }}
            alt='Cursor redimensionar horizontal'
          />
        </ListItem>
        <ListItem>
          {' '}
          ou verticalmente, com{' '}
          <img
            src={VerticalCursor}
            style={{ width: '1.5em' }}
            alt='Cursor redimensionar vertical'
          />
        </ListItem>
      </List>
      <ImgGif src={MoveAxis} alt='Movendo o gráfico com os eixos' />
    </div>
  </>
)

export const stepResizeAxis = (
  <>
    <div>
      <h4>
        <strong>Redimensionar o gráfico com os eixos</strong>
      </h4>
      <p>
        Clique e arraste diagonalmente o cursor nas extremidades dos eixos para aplicar ou retirar o
        zoom do gráfico:
      </p>
      <List>
        <ListItem>
          {' '}
          horizontalmente, com{' '}
          <img
            src={HorizontalCursor}
            style={{ width: '1.7em' }}
            alt='Cursor redimensionar horizontal'
          />
        </ListItem>
        <ListItem>
          {' '}
          verticalmente, com{' '}
          <img
            src={VerticalCursor}
            style={{ width: '1.5em' }}
            alt='Cursor redimensionar vertical'
          />
        </ListItem>
        <ListItem>
          {' '}
          ou ambos, com <img src={DiagonalUpDownCursor} style={{ width: '1.5em' }} alt='' /> ou{' '}
          <img src={DiagonalDownUpCursor} style={{ width: '1.5em' }} alt='' />
        </ListItem>
      </List>

      <ImgGif src={ZoomAxis} />
    </div>
  </>
)

export const stepSecretInputAxis = (
  <>
    <div>
      <h4>
        <strong>Redimensionar o gráfico com "Input Secreto"</strong>
      </h4>
      <p>
        Clique nas extremidades dos eixos e aplique o valor desejado para alterar os limites máximos
        e mínimos.
      </p>
      <p>Para datas e horas, o formato é: AAAA-MM-DD HH:MM (ex: 2022-11-26 10:50)</p>

      <ImgGif src={SecretInput} />
    </div>

    <p>
      Para retornar, clique 2 vezes no gráfico ou clique na opção{' '}
      <img src={HomeRestartIcon} style={{ width: '1.5em' }} alt='' /> .
    </p>
  </>
)

export const stepAutoscaleAxis = (
  <>
    <div>
      <h4>
        <strong>Autoscale com os eixos</strong>
      </h4>
      <p>Dê um duplo clique sobre o eixo na qual deseja que o gráfico se ajuste automaticamente.</p>

      <ImgGif src={AxisAutoscale} />
    </div>

    <p>
      Para retornar, clique 2 vezes no gráfico ou clique na opção{' '}
      <img src={HomeRestartIcon} style={{ width: '1.5em' }} alt='' /> .
    </p>
  </>
)
