import { Progress, ProgressMeasureLocation, ProgressSize } from '@patternfly/react-core'
import { useEffect, useState } from 'react'

const LoaderBar = ({ isLoading }: { isLoading?: boolean }) => {
  const [valueProgress, setValueProgress] = useState(0)

  const updateProgress = (value: number) => {
    if (value > 97) return value
    if (value > 90) return value + (100 - value) * 0.01
    if (value > 85) return value + (100 - value) * 0.1
    if (value > 40 && value < 50) return value + 2
    return value + 1.5
  }

  useEffect(() => {
    if (!isLoading) return
    const interval = setInterval(() => {
      setValueProgress(value => updateProgress(value))
    }, 15)

    return () => {
      setValueProgress(0)
      clearInterval(interval)
    }
  }, [isLoading])

  return (
    <div
      style={{
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 99999,
        padding: 0,
        backgroundColor: 'white',
        opacity: isLoading ? 1 : 0,
      }}
    >
      <Progress
        value={valueProgress}
        size={ProgressSize.sm}
        measureLocation={ProgressMeasureLocation.none}
        style={{ gridGap: 0, opacity: isLoading ? 1 : 0 }}
        title=''
        aria-label='loading-bar'
      />
    </div>
  )
}

export default LoaderBar
