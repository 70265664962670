import { Popover, PopoverPosition } from '@patternfly/react-core'
import { useState } from 'react'
import useMobile from '../../hooks/useMobile'

import CalendarMonth from './CalendarMonth'
import ModalCalendar from './ModalCalendar'
import MonthTextInput from './MonthTextInput'

interface IDatetimeMonthPickerProps {
  className?: string
  date: Date
  onChange: (dt: Date) => void
  minEnabled?: Date
  maxEnabled?: Date
  showCalendarIcon?: boolean
  validators?: ((date: Date) => boolean)[]
}

const DatetimeMonthPicker = ({
  date,
  onChange,
  minEnabled,
  maxEnabled,
  className = '',
  showCalendarIcon = true,
  validators,
}: IDatetimeMonthPickerProps) => {
  const isMobile = useMobile()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const calendarMonth = (
    <CalendarMonth
      date={date}
      onChange={onChange}
      maxEnabled={maxEnabled}
      minEnabled={minEnabled}
      validators={validators}
    />
  )
  const monthTextInput = (
    <MonthTextInput
      date={date}
      className={className}
      showCalendarIcon={showCalendarIcon}
      onClick={toggleModal}
    />
  )

  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      {isMobile ? (
        <div className='pf-v5-u-mx-sm'>
          {monthTextInput}
          <ModalCalendar isModalOpen={isModalOpen} toggleModal={toggleModal}>
            {calendarMonth}
          </ModalCalendar>
        </div>
      ) : (
        <Popover
          aria-label='DatetimeMonthPickerPopOver'
          position={PopoverPosition.bottomEnd}
          enableFlip
          flipBehavior={['bottom-end', 'bottom-start', 'top-end', 'top-start']}
          hideOnOutsideClick
          hasAutoWidth
          hasNoPadding
          withFocusTrap={false}
          showClose={false}
          bodyContent={calendarMonth}
          isVisible={isVisible}
          shouldOpen={(_event, _fn) => setIsVisible(true)}
          shouldClose={(_event, _fn) => setIsVisible(false)}
        >
          {monthTextInput}
        </Popover>
      )}
    </>
  )
}

export default DatetimeMonthPicker
